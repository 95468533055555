import React, { useState, useRef } from "react";
import { HttpRequest } from "../../../utils/common.js";
import { useForm } from "react-hook-form";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import Layout from "../../../components/Common/Layout";
import ShippingAddressComponent from "../../../components/Common/ShippingAddress";
import { ShippingAddressFormInput } from "../../../types/common";
import { ShippingAddress } from "../../../types/pages/Mypage/payment_details";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";

type Props = {
  lastName: string;
  firstName: string;
  firstNameKana: string;
  lastNameKana: string;
  postalCode: string;
  fullAddress: string;
  phoneNumber: string;
};

const Index: React.FC<Props> = (props) => {
  const { lastName, firstName, firstNameKana, lastNameKana, postalCode, fullAddress, phoneNumber } = props;

  const { handleSubmit, control, setValue, getValues, watch } = useForm<ShippingAddressFormInput>({
    mode: "onChange",
    defaultValues: {
      last_name: lastName,
      first_name: firstName,
      postal_code: postalCode,
      full_address: fullAddress,
      phone_number: phoneNumber
    }
  });

  const addressFormRef = useRef(null);

  const [isAddressEdited, setIsAddressEdited] = useState<boolean>(true) // 登録済み住所を利用する場合を考慮してデフォルトはTrue
  const [isAddressWarningModalActive, setIsAddressWarningModalActive] = useState<boolean>(false)

  const onSubmit = () => {
    HttpRequest.post("/mypage/shipping_info", {
      account: {
        last_name: getValues("last_name"),
        first_name: getValues("first_name"),
        postal_code: getValues("postal_code"),
        full_address: getValues("full_address"),
        phone_number: getValues("phone_number"),
      }
    }).then(() => {
      location.href = "/mypage/shipping_info/updated";
    });
  };

  const toggleAddressWarningModal = () => {
    setIsAddressWarningModalActive(!isAddressWarningModalActive)
    console.log(isAddressWarningModalActive);
  }

  const handleAddressEdit = (isEdited: boolean) => {
    setIsAddressEdited(isEdited);
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "標準のお届け先情報",
        },
      ]}
    >
      <ConfirmationModal
        toggleModal={toggleAddressWarningModal}
        onSubmit={handleSubmit(onSubmit)}
        isActive={isAddressWarningModalActive}
        isSubmit={true}
        confirmText="上記の内容で変更する"
      >
        <div className="leading-[1.875]">
          <p className="mb-5">
            確実にリターン品をお届けするため、住所をご確認ください。
          </p>
          <p className="mb-1">
            〒{getValues("postal_code")}
          </p>
          <p className="mb-5">
            {getValues("full_address")}
          </p>
        </div>
      </ConfirmationModal>
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          標準のお届け先情報
        </h2>
        <div className="sm:w-[700px] sm:mx-auto">
          <div className="mb-[40px]">
            <p className="text-[#FF0000] mb-5">
              標準のお届け先の変更は、今後ご支援いただいた際に反映されます。
            </p>
            <p className="text-xs">
              支援済みのプロジェクトのお届け先変更は、<a className="link-hover-none underline" href="/mypage/support_history">支援履歴ページ</a>にある各プロジェクトの「支払い明細ページ」にて可能です。
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} method="POST" ref={addressFormRef}>
            <ShippingAddressComponent
              control={control}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              onAddressEdit={handleAddressEdit}
            />
            <div className="mt-[60px] sm:mx-auto sm:w-[250px] h-[50px]">
              <BaseButton
                text="上記の内容で変更する"
                fontSize="text-sm"
                isSubmit={isAddressEdited ? true : false}
                onClick={!isAddressEdited ? toggleAddressWarningModal : undefined}
              />
            </div>
          </form>
          <div className="mt-[60px] text-center">
            <a
              href="/mypage"
              className="left-icon icon-stick-arrow-left font-meidum text-sm"
            >
              マイページトップ
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
