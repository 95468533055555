import React, { useState, useRef } from "react";
import { HttpRequest } from "../../utils/common.js";
import BaseButton from "../../components/Common/Buttons/BaseButton";
import FacebookLoginButton from "../../components/Common/Buttons/FacebookLoginButton";
import LineLoginButton from "../../components/Common/Buttons/LineLoginButton";
import WarningImg from "../../../images/warning.png";
import BaseLinkButton from "../../components/Common/Buttons/BaseLinkButton";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import Layout from "../../components/Common/Layout";
import TextField from "../../components/Common/Form/TextField";
import { useForm } from "react-hook-form";
import { isCareerAddress } from "../../utils/FormUtil";
import { EMAIL_MATCH_PATTERN } from "../../utils/constants"
import TwitterLoginButton from "../../components/Common/Buttons/TwitterLoginButton";
import GoogleLoginButton from "../../components/Common/Buttons/GoogleLoginButton";

type Props = {
  postPath: string;
  facebookLoginPath: string;
  lineLoginPath: string;
  twitterLoginPath: string;
  googleLoginPath: string;
  token: string;
};
const Signup: React.FC<Props> = (props) => {
  const { postPath, facebookLoginPath, lineLoginPath, twitterLoginPath, googleLoginPath, token } = props;
  const [errors, setErrors] = useState({});
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [isConfirmModalActive, setIsConfirmModalActive] =
    useState<boolean>(false);
  const [inputType, setInputType] = useState<string>("password")
  const modalRef = useRef(null);
  const arrowRef = useRef(null);
  interface IFormInput {
    email: string;
    password: string;
    user_name: string;
  }

  const { handleSubmit, control, getValues } = useForm<IFormInput>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      user_name: "",
    }
  });

  const onSubmit = async () => {
    setErrors({});
    HttpRequest.post(postPath, {
      account: {
        email: getValues("email"),
        password: getValues("password"),
        user_name: getValues("user_name"),
      },
    }).then((res) => {
      location.href = res.data.redirect_path;
    }).catch((e) => {
      setErrors(e.response.data.errors);
      if (isCareerAddress(getValues("email"))) {
        toggleConfirmModal(); 
      }
    });
  };

  {/* MEMO: 新規会員登録方法の動画をコメントアウトする */}
  {/* https://trello.com/c/Tx87IIlY */}
  // const updateScreenHeight = () => {
  //   return window.innerHeight
  // }

  // const openModal = () => {
  //   const scroll = window.pageYOffset;
  //   let screenHeight = window.innerHeight;

  //   const setModalPosition = () => {
  //     if (modalRef.current) {
  //       modalRef.current.style.top = `${
  //         scroll + (screenHeight - modalRef.current.clientHeight) / 2
  //       }px`;
  //     }
  //   }

  //   window.addEventListener('resize', () => {
  //     screenHeight = updateScreenHeight();
  //     setModalPosition()
  //   });

  //   setModalPosition();
  //   toggleModal();
  // };

  // const toggleModal = () => {
  //   setIsModalActive(!isModalActive);
  // };

  // const stopMovie = () => {
  //   const el: HTMLIFrameElement = document.querySelector("#popup-YouTube-player");
  //   el.contentWindow.postMessage('{"event":"command","func":"' + "stopVideo" + '","args":""}', '*');
  // }

  // const closeModal = () => {
  //   toggleModal();
  //   stopMovie()
  //   window.removeEventListener('resize', updateScreenHeight);
  // }

  const togglePasswordDisplay = (e) => {
    if (e.target.checked) {
      setInputType("text")
    } else {
      setInputType("password")
    }
  };

  const toggleFormItems = () => {
    if (arrowRef.current && !isOpen) {
      arrowUp();
    } else if (arrowRef.current && isOpen) {
      arrowDown();
    }
    setIsOpen(!isOpen);
  };

  const arrowUp = () => {
    arrowRef.current.classList.remove("icon-arrow-under");
    arrowRef.current.classList.add("icon-arrow-top");
  };

  const arrowDown = () => {
    arrowRef.current.classList.remove("icon-arrow-top");
    arrowRef.current.classList.add("icon-arrow-under");
  };

  const toggleConfirmModal = () => {
    if (isCareerAddress(getValues("email"))) {
      setIsConfirmModalActive(!isConfirmModalActive);
    } else {
      onSubmit();
    }
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "新規会員登録"
        }
      ]}
    >
      <div
        className={`${
          isModalActive
            ? "block fixed z-50 top-0 w-screen h-screen bg-black opacity-60"
            : "hidden"
        }`}
      ></div>
      <ConfirmationModal
        toggleModal={toggleConfirmModal}
        onSubmit={onSubmit}
        isActive={isConfirmModalActive}
        confirmText="OK"
      >
        <p className="mb-[40px] text-center text-lg font-bold">
          キャリアのメールアドレスを
          <br />
          登録される方へお願い
        </p>
        <div className="leading-[1.875]">
          <p className="mb-5">
            ※ご登録後、support@spportunity.co.jpから重要なメールが届きます。予め受信設定をお願いいたします。
          </p>
          <p>
            ※携帯キャリアのメールアドレスでは、システムからのメールが届かない場合がありますのでご注意ください。
          </p>
        </div>
      </ConfirmationModal>
      <div className="sm:mb-[40px]">
        {/* MEMO: 新規会員登録方法の動画をコメントアウトする */}
        {/* https://trello.com/c/Tx87IIlY */}
        {/* <div
          className={`${
            isModalActive
              ? "visible absolute z-50 left-0 right-0 flex justify-center flex-col items-center"
              : "invisible z-0 absolute"
          }`}
          ref={modalRef}
        >
          <iframe
            className="w-[90vw] h-[67vw] sm:w-[700px] sm:h-[526px] sm:p-[30px]"
            src="https://www.youtube.com/embed/2L91zRBqwHU?enablejsapi=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            id="popup-YouTube-player"
          ></iframe>
          <div className="flex justify-center mt-5">
            <button
              onClick={closeModal}
              className="icon-close left-icon w-[180px] h-[40px] font-raleway rounded-default flex items-center justify-center bg-white opacity-80"
            >
              Close
            </button>
          </div>
        </div> */}
        <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto sm:px-0">
          <h2 className="mb-[40px] sm:mb-[60px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
            新規会員登録
          </h2>
          <div className="sm:w-[700px] sm:mx-auto">
            <section className="mb-[40px]">
              <h3
                ref={arrowRef}
                className="right-icon icon-arrow-under flex items-center justify-between mb-5 font-medium"
                onClick={toggleFormItems}
              >
                メールアドレスで登録
              </h3>
              <div className="bg-[#F4F6F8] sm:p-[50px] px-5 py-10">
                {/* MEMO: 新規会員登録方法の動画をコメントアウトする */}
                {/* https://trello.com/c/Tx87IIlY */}
                {/* <div className="mb-[40px] flex justify-center text-center">
                  <button
                    className="left-icon icon-movie flex items-center text-sm"
                    onClick={openModal}
                  >
                    新規会員登録方法を動画で見る
                  </button>
                </div> */}
                <form onSubmit={handleSubmit(toggleConfirmModal)}>
                  <div>
                    <div className="flex items-center mb-1">
                      <label htmlFor="email" className="inline-block text-sm">
                        メールアドレス
                      </label>
                      <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                        必須
                      </span>
                    </div>
                    <TextField
                      control={control}
                      name="email"
                      id="email"
                      placeholder="メールアドレスを入力してください"
                      rules={{
                        required: true,
                        pattern: EMAIL_MATCH_PATTERN
                      }}
                      patternErrorMessage="メールアドレスが正しくありません"
                      onChange={() => {
                        setIsOpen(true);
                        arrowUp();
                        setErrors({});
                      }}
                    />
                    <div className="text-[#FF0000] mt-1 text-xs">
                      {errors["email"]}
                    </div>
                    {isOpen && (
                      <>
                        <div className="mt-[30px]">
                          <div className="flex items-center mb-1">
                            <label
                              htmlFor="password"
                              className="inline-block text-sm"
                            >
                              パスワード
                            </label>
                            <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                              必須
                            </span>
                          </div>
                          <TextField
                            control={control}
                            name="password"
                            rules={{
                              required: true,
                              pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`˜~!@#$%^&*()_+-={}[\]¥|:;<>.?/])[A-Za-z\d`˜~!@#$%^&*()_+-={}[\]¥|:;<>.?/]{8,}$/
                            }}
                            placeholder="パスワードを入力してください"
                            id="password"
                            type={inputType}
                            patternErrorMessage="半角英数字・記号を組み合わせた8文字以上の文字列にしてください"
                          />
                          <div className="text-[#FF0000] mt-1 text-xs">
                            {errors["password"]}
                          </div>
                          <p className="text-[#768084] mt-[10px] text-xs">
                            半角英数字・記号を組み合わせた8文字以上で設定できます。
                          </p>
                          <div className="mt-3">
                            <input
                              onChange={(e) => togglePasswordDisplay(e)}
                              type="checkbox"
                              id="show_password"
                              className="password-checkbox before:content-[''] before:border-[#B1C2CC] relative before:inline-block before:w-4 before:h-4 before:bg-white before:border invisible before:visible"
                            />
                            <label
                              htmlFor="show_password"
                              className="ml-2 text-sm"
                            >
                              パスワードを表示
                            </label>
                          </div>
                        </div>
                        <div className="my-[30px]">
                          <div className="flex items-center mb-1">
                            <label
                              htmlFor="user_name"
                              className="inline-block text-sm"
                            >
                              ユーザー名
                            </label>
                            <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                              必須
                            </span>
                          </div>
                          <TextField
                            control={control}
                            name="user_name"
                            rules={{ required: true }}
                            placeholder="スポーツ大好きすぽたろう"
                            id="user_name"
                          />
                          <div className="text-[#FF0000] mt-1 text-xs">
                            {errors["user_name"]}
                          </div>
                          <div className="text-[#768084] mt-[10px] text-xs leading-normal">
                            <p>サービス上で公開されるお名前です。</p>
                            <p>あだ名やニックネームでも可能です。</p>
                            <p>ユーザー名はあとから変更もできます。</p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {isOpen && (
                    <div className="pt-[15px] border-[#B1C2CC] border-t">
                      <div className="w-[30px] h-[25px] mb-[15px] mx-auto">
                        <img src={WarningImg} alt="注意" />
                      </div>
                      <p className="text-[#768084] mb-[15px] text-xs sm:mb-5">
                        登録することで、スポチュニティのサービス利用規約およびプライバシーポリシーに同意したものとみなされます。
                      </p>
                      <div className="sm:mx-auto h-[50px] sm:w-[360px]">
                        <BaseButton
                          isSubmit={true}
                          text="上記の内容で会員登録する"
                          fontSize="text-sm"
                        />
                      </div>
                      <div className="text-[#768084] mt-5 text-xs leading-normal">
                        <p>
                          ※本人認証メールが届かない場合は
                          <a
                            href="/accounts/confirmation/resend"
                            className="text-[#006AC9] link-hover-none underline"
                          >
                            こちら
                          </a>
                          から再送手続きをしてください。
                        </p>
                        <p>
                          ※再送手続きをしても、本人認証メールが届かない場合は、
                          <a
                            className="text-[#006AC9] link-hover-none underline"
                            href="https://corp.spportunity.com/faq/%E6%96%B0%E8%A6%8F%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E7%99%BB%E9%8C%B2%E3%81%AE%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%8C%E5%B1%8A%E3%81%8D%E3%81%BE%E3%81%9B%E3%82%93/"
                          >
                            FAQ
                          </a>
                          をご確認ください。
                        </p>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </section>
            <div className="text-[#768084] after:content-[''] after:w-[40%] after:h-[1px] after:bg-[#D2DFE6] before:bg-[#D2DFE6] before:content-[''] before:h-[1px] before:w-[40%] flex after:inline-block before:inline-block items-center justify-between text-sm">
              または
            </div>
            <section className="mt-[40px]">
              <h3 className="mb-5">外部アカウントで登録</h3>
              <div className="sm:flex sm:flex-wrap sm:justify-between">
                <div className="mb-[15px] sm:w-[330px]">
                  <FacebookLoginButton href={facebookLoginPath} text="Facebookで登録する" />
                </div>
                <div className="mb-[15px] sm:w-[330px]">
                  <LineLoginButton href={lineLoginPath} text="LINEで登録する" />
                </div>
                <div className="mb-[15px] sm:w-[330px]">
                  <TwitterLoginButton href={twitterLoginPath} text="Xで登録する" />
                </div>
                <div className="mb-[15px] sm:w-[330px]">
                  <GoogleLoginButton path={googleLoginPath} token={token} text="Googleで登録する" />
                </div>
              </div>
            </section>
          </div>
        </section>
        <div className="px-[30px] mt-[40px] sm:w-[700px] sm:mx-auto sm:px-0">
          <div className="text-[#768084] after:content-[''] after:w-[24%] after:h-[1px] after:bg-[#D2DFE6] before:bg-[#D2DFE6] before:content-[''] before:h-[1px] before:w-[24%] flex after:inline-block before:inline-block items-center justify-between text-sm">
            会員登録がお済みの方
          </div>
          <div className="mt-[40px] mx-auto h-[50px] sm:w-[360px]">
            <BaseLinkButton
              text="ログイン"
              link="/accounts/sign_in"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Signup;
