import React, { useState } from "react";
import IconTwitter from "../../../images/icons/icon_twitter.png";
import IconLine from "../../../images/icons/icon_line.png";
import IconFacebook from "../../../images/icons/icon_facebook.png";
import SecondaryButton from "./Buttons/SecondaryButton";
import URLCopyModal from "../Team/URLCopyModal";
import QRCodeModal from "../Team/QRCodeModal";
import WidgetModal from "../Team/WidgetModal";

type Props = {
  referralURL: string;
  widgetURL: string;
  investmentTitle: string;
}

const Share: React.FC<Props> = (props) => {
  const {referralURL, widgetURL, investmentTitle} = props
  const [isURLCopyModalActive, setIsURLCopyModalActive] = useState<boolean>(false)
  const [isQRCodeModalActive, setIsQRCodeModalActive] = useState<boolean>(false)
  const [isWidgetModalActive, setIsWidgetModalActive] = useState<boolean>(false)

  const toggleURLCopyModal = () => {
    setIsURLCopyModalActive(!isURLCopyModalActive)
  }

  const toggleQRCodeModal = () => {
    setIsQRCodeModalActive(!isQRCodeModalActive)
  }

  const openURLCopyModal = () => {
    toggleURLCopyModal()
    navigator.clipboard.writeText(referralURL)
  }

  const toggleWidgetModal = () => {
    setIsWidgetModalActive(!isWidgetModalActive)
  }

  return (
    <div className="py-[30px] sm:py-[40px] bg-white">
      <p className="mb-6 text-center text-lg font-bold">
        さっそく紹介してみよう!
      </p>
      <div className="mb-[14px] before:h-[1px] before:content-[''] before:bg-[#1F2122] before:rotate-[60deg] after:h-[1px] after:rotate-[-60deg] after:bg-[#1F2122] flex before:inline-block after:inline-block items-center justify-center before:w-4 after:w-4 text-center">
        紹介用シェアボタン
      </div>
      <div className="flex items-center justify-center mb-5">
        <div className="w-[50px] h-[50px] mr-8">
          <a href={`http://www.facebook.com/share.php?quote=${investmentTitle}&u=${referralURL}`} className="link-hover-none inline-block">
            <img src={IconFacebook} alt="facebook" />
          </a>
        </div>
        <div className="w-[40px] h-[41px] mr-8">
          <a href={`http://twitter.com/share?url="${referralURL}"&text=${investmentTitle}`} className="link-hover-none inline-block">
            <img src={IconTwitter} alt="twitter" />
          </a>
        </div>
        <div className="w-[50px] h-[51px]">
          <a href={`https://social-plugins.line.me/lineit/share?url=${referralURL}`} className="link-hover-none inline-block">
            <img src={IconLine} alt="line" />
          </a>
        </div>
      </div>
      <URLCopyModal
        isActive={isURLCopyModalActive}
        toggleModal={toggleURLCopyModal}
      />
       <QRCodeModal
        isActive={isQRCodeModalActive}
        toggleModal={toggleQRCodeModal}
        referralURL={referralURL}
      />
       <WidgetModal
        isActive={isWidgetModalActive}
        toggleModal={toggleWidgetModal}
        widgetURL={widgetURL}
      />
      <div className="flex flex-col items-center sm:flex-row sm:justify-between sm:w-[540px] sm:mx-auto">
        <div className="mb-[10px] sm:mb-0 w-[220px] h-[45px] sm:w-[170px]">
          <SecondaryButton
            text="URLコピー"
            iconLeft="icon-copy-large"
            color="text-[#1F2122]"
            onClick={openURLCopyModal}
          />
        </div>
        <div className="mb-[10px] sm:mb-0 w-[220px] h-[45px] sm:w-[170px]">
          <SecondaryButton
            text="QRコード"
            iconLeft="icon-qr-large"
            color="text-[#1F2122]"
            onClick={toggleQRCodeModal}
          />
        </div>
        <div className="w-[220px] h-[45px] sm:w-[170px]">
          <SecondaryButton
            text="埋め込み"
            iconLeft="icon-tag-large"
            color="text-[#1F2122]"
            onClick={toggleWidgetModal}
          />
        </div>
      </div>
    </div>
  );
};

export default Share;
