import React from "react";
import Noimg from "../../../images/noimg.png";
import Layout from "../../components/Common/Layout";

type Props = {};
const Updated: React.FC<Props> = (props) => {
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "ログアウトしました",
        }
      ]}
    >
      <div className="mt-[60px] px-[30px]">
        <p className="mb-5 text-center text-lg font-bold">
          ログアウトしました。
        </p>
        <div className="w-[143px] h-[110px] mx-auto">
          <img src={Noimg} alt="" />
        </div>
        <div className="mt-[60px] text-center">
          <a href="/" className="left-icon icon-stick-arrow-left font-meidum text-sm">
            トップページへ
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default Updated;
