import React from "react";
import SecondaryLinkButton from "../../../../components/Common/Buttons/SecondaryLinkButton";
import Layout from "../../../../components/Common/Layout";
import { FormatCurrency } from "../../../../utils/TextUtil";

type BankTransferInfo = {
  item_name: string;
  item_price: number;
  bank_name: string;
  bank_code: string;
  branch_name: string;
  branch_code: string;
  account_no: string;
  account_name: string;
  bank_limit: string;
};

type ConveniPaymentInfo = {
  item_name: string;
  item_price: number;
  conveni_limit: string;
  conveni_code: string;
  is_family_mart: boolean;
  conveni_name: string;
  receipt_no: string;
  kigyou_code: string;
};

type Props = {
  investment_title: string;
  settlement_amount: number;
  ec_return_name: string;
  pay_method: string;
  bank_transfer_info?: BankTransferInfo;
  conveni_payment_info?: ConveniPaymentInfo;
  payment_details_path: string;
};

const PaymentInfo: React.FC<Props> = (props) => {
  const {
    investment_title,
    settlement_amount,
    ec_return_name,
    pay_method,
    bank_transfer_info,
    conveni_payment_info,
    payment_details_path
  } = props;

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/"
        },
        {
          text: "マイページ",
          href: "/mypage"
        },
        {
          text: "支援履歴",
          href: "/mypage/support_history"
        },
        {
          text: "支払い明細",
          href: payment_details_path
        },
        {
          text: "支払い明細 -振込先情報-"
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:mb-[40px] sm:mt-[60px] sm:mx-auto sm:max-w-main md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          支払い明細 - 振込先情報 -
        </h2>
        <div className="sm:w-[700px] sm:mb-[60px] sm:mx-auto">
          <p className="text-[#006AC9] bg-[#D2DFE6] mb-5 px-5 py-2 font-medium">
            支援方法：
            {pay_method === "bank" ? "銀行振込決済" : "コンビニ決済"}
          </p>
          <div className="mb-[40px]">
            {pay_method === "bank" ? (
              <>
                <p className="mb-5">
                  以下の内容をご確認の上「お支払い期限」(
                  {bank_transfer_info.bank_limit})
                  までに代金をお支払いください。
                </p>
                <p className="text-[#768084] text-xs">
                  ※プロジェクト終了日前であっても
                  <span className="font-bold">7日を経過</span>
                  しますとお振込ができなくなるのでご了承ください。
                </p>
              </>
            ) : (
              <p>
                以下の内容をご確認の上「お支払い期限」(
                {conveni_payment_info.conveni_limit})までに
                <span className="font-bold">
                  {conveni_payment_info.conveni_name}
                </span>
                にて代金をお支払いください。
              </p>
            )}
          </div>
          <div className="bg-[#F4F6F8] mb-[40px] p-5">
            <dl className="mb-5">
              <dt className="text-[#768084] text-xs">店舗(サイト)名</dt>
              <dd>{investment_title}</dd>
            </dl>
            <dl className="mb-5">
              <dt className="text-[#768084] text-xs">商品名</dt>
              <dd>{ec_return_name}</dd>
            </dl>
            <dl className="mb-5">
              <dt className="text-[#768084] text-xs">金額</dt>
              <dd>{`${FormatCurrency(settlement_amount)} 円`}</dd>
            </dl>
            {pay_method === "bank" ? (
              <>
                <dl className="mb-5">
                  <dt className="text-[#768084] text-xs">金融機関名</dt>
                  <dd>{bank_transfer_info.bank_name}</dd>
                </dl>
                <dl className="mb-5">
                  <dt className="text-[#768084] text-xs">金融機関コード</dt>
                  <dd>{bank_transfer_info.bank_code}</dd>
                </dl>
                <dl className="mb-5">
                  <dt className="text-[#768084] text-xs">支店名</dt>
                  <dd>{bank_transfer_info.branch_name}</dd>
                </dl>
                <dl className="mb-5">
                  <dt className="text-[#768084] text-xs">支店コード</dt>
                  <dd>{bank_transfer_info.branch_code}</dd>
                </dl>
                <div className="mb-5">
                  <dl className="mb-1">
                    <dt className="text-[#768084] text-xs">口座番号</dt>
                    <dd>{bank_transfer_info.account_no}</dd>
                  </dl>
                  <p className="text-[#768084] text-xs">
                    ※複数支援の申込をされた場合、
                    <span className="font-bold">
                      それぞれお振込みいただく口座番号は異なります。
                    </span>
                    ご注意ください。
                  </p>
                </div>
                <dl>
                  <dt className="text-[#768084] text-xs">口座名義</dt>
                  <dd>{bank_transfer_info.account_name}</dd>
                </dl>
              </>
            ) : (
              <>
                {!conveni_payment_info || !conveni_payment_info.receipt_no || conveni_payment_info.receipt_no === "" ? (
                  conveni_payment_info.is_family_mart ? (
                    <>
                      <dl className="mb-5">
                        <dt className="text-[#768084] text-xs">企業コード</dt>
                        <dd>
                          お支払い情報の表示準備ができておりません。<br />
                          5分以上空けてから再度お試しください。
                        </dd>
                      </dl>
                      <dl className="mb-5">
                        <dt className="text-[#768084] text-xs">注文番号</dt>
                        <dd>
                          お支払い情報の表示準備ができておりません。<br />
                          5分以上空けてから再度お試しください。
                        </dd>
                      </dl>
                    </>
                  ) : (
                    <dl className="mb-5">
                      <dt className="text-[#768084] text-xs">企業コード</dt>
                      <dd>
                        お支払い情報の表示準備ができておりません。<br />
                        5分以上空けてから再度お試しください。
                      </dd>
                    </dl>
                  )
                ) : conveni_payment_info.is_family_mart ? (
                  <>
                    <dl className="mb-5">
                      <dt className="text-[#768084] text-xs">企業コード</dt>
                      <dd>{conveni_payment_info.kigyou_code}(5桁)</dd>
                    </dl>
                    <dl className="mb-5">
                      <dt className="text-[#768084] text-xs">注文番号</dt>
                      <dd>
                        {conveni_payment_info.receipt_no}(
                        {conveni_payment_info.receipt_no.length}桁)
                      </dd>
                    </dl>
                  </>
                ) : (
                  <dl className="mb-5">
                    <dt className="text-[#768084] text-xs">受付番号</dt>
                    <dd>
                      {conveni_payment_info.receipt_no}(
                      {conveni_payment_info.receipt_no.length}桁)
                    </dd>
                  </dl>
                )}
                <dl>
                  <dt className="text-[#768084] text-xs">お支払期限</dt>
                  <dd>{conveni_payment_info.conveni_limit}</dd>
                </dl>
              </>
            )}
          </div>
          {pay_method === "bank" ? (
            <div className="mb-[40px]">
              <div className="mb-[30px]">
                <p>
                  お振込みは支援者様のお持ちの金融機関口座からお振込をいただけます。
                </p>
                <p>
                  なお、振込手数料は支援者様のご負担となります。予めご了承ください。
                </p>
              </div>
              <div>
                <p>お振込の際は、必ず過不足がないようお願い致します。</p>
                <p>
                  万が一、入金額が申し込み支援金額に満たない場合は、お申込みがキャンセルとなり、後日返金のご案内をさせていただく流れとなります。
                </p>
                <p>
                  また、入金額が申込み支援金額が多い場合も後日返金のご案内をさせていただく流れとなります。
                </p>
              </div>
            </div>
          ) : (
            <div className="mb-5">
              <div className="mb-[40px]">
                {/* MEMO: ファミリーマートとローソン・ミニストップ・セイコーマートは決済方法や受付番号が異なる */}
                {!conveni_payment_info.is_family_mart && (
                  <>
                    <p className="mb-[30px]">
                      お客様がご選択いただいたコンビニブランドは
                      {conveni_payment_info.conveni_name}
                      ですが、以下のいずれのコンビニブランドでもお支払いいただけます。
                    </p>
                    <ul className="mb-[30px]">
                      <li>1.ローソン</li>
                      <li>2.ミニストップ</li>
                      <li>3.セイコーマート</li>
                    </ul>
                  </>
                )}
                <p className="mb-[30px]">
                  コンビニ店頭の設置端末を操作し、
                  {conveni_payment_info.is_family_mart
                    ? "注文番号"
                    : "受付番号"}
                  を入力ください。
                </p>
                {conveni_payment_info.is_family_mart && (
                  <ul className="mb-[30px]">
                    <li>
                      1.
                      ファミリーマートの店内に設置してあるFamiポートのトップメニュー
                      の中から「コンビニでお支払」を選択します。
                    </li>
                    <li>
                      2. 次画面のメニューの中から「収納票発行」を選択します。
                    </li>
                    <li>
                      3.
                      画面の指示に従って「企業コード（5桁）」と、「注文番号（12桁）」を入力します。
                    </li>
                    <li>
                      4. Famiポートより「Famiポート申込券」が発行されます。
                    </li>
                    <li>
                      5.「Famiポート申込券」をお持ちの上、レジにて代金をお支払下さい。
                    </li>
                  </ul>
                )}
                <p>詳しいお支払手順は下記マニュアルにてご確認いただけます。</p>
              </div>
              {conveni_payment_info.is_family_mart ? (
                <div className="mb-[40px]">
                  <div className="sm:flex sm:justify-between sm:mb-5">
                    <div className="mb-[10px] h-[50px] sm:w-[340px] sm:mb-0">
                      <SecondaryLinkButton
                        text="ファミリーマートでのお支払方法"
                        link="http://www.epsilon.jp/mb/conv/famima/index.html"
                        fontSize="text-sm"
                        iconRightEdge="icon-external"
                        target_blank={true}
                      />
                    </div>
                    <div className="mb-[10px] h-[50px] sm:w-[340px] sm:mb-0">
                      <SecondaryLinkButton
                        text="ファミリーマートでのお支払方法(PDF版)"
                        link="http://www.epsilon.jp/document_dl/index_pdf.html"
                        fontSize="text-sm"
                        iconRightEdge="icon-external"
                        target_blank={true}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mb-[40px]">
                  <div className="sm:flex sm:justify-between sm:mb-5">
                    <div className="mb-[10px] h-[50px] sm:w-[340px] sm:mb-0">
                      <SecondaryLinkButton
                        text="ローソンでのお支払方法"
                        link="http://www.epsilon.jp/mb/conv/lawson/index.html"
                        fontSize="text-sm"
                        iconRightEdge="icon-external"
                        target_blank={true}
                      />
                    </div>
                    <div className="mb-[10px] h-[50px] sm:w-[340px] sm:mb-0">
                      <SecondaryLinkButton
                        text="ミニストップでのお支払方法"
                        link="http://www.epsilon.jp/service/conv_ministop.html"
                        fontSize="text-sm"
                        iconRightEdge="icon-external"
                        target_blank={true}
                      />
                    </div>
                  </div>
                  <div className="sm:flex sm:justify-between">
                    <div className="mb-[10px] h-[50px] sm:w-[340px] sm:mb-0">
                      <SecondaryLinkButton
                        text="セイコーマートでのお支払方法"
                        link="http://www.epsilon.jp/mb/conv/seico/index.html"
                        fontSize="text-sm"
                        iconRightEdge="icon-external"
                        target_blank={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="text-[#768084] text-xs">
                <div className="mb-5">
                  <p>
                    ※代金と引換に領収書が発行されますので、必ずお受取りください。
                  </p>
                  <p>実際に代金をお支払いされた証明になります。</p>
                </div>
                <p>
                  ※お支払い後に商品(サービス)のご提供となりますので、お急ぎの方はお早めにお手続きをお願いします。
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="h-[50px] sm:w-[400px] sm:mx-auto">
          <SecondaryLinkButton
            iconLeftEdge="icon-stick-arrow-left"
            text="このプロジェクトの支援履歴に戻る"
            fontSize="text-sm"
            link={payment_details_path}
          />
        </div>
        <div className="mt-[60px] text-center">
          <a
            href="/mypage"
            className="left-icon icon-stick-arrow-left text-sm font-medium"
          >
            マイページトップ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default PaymentInfo;
