import React from "react";
import BaseLinkButton from "../Buttons/BaseLinkButton";
import Heading from "../Heading"
import "../../../../stylesheets/attentionEcReturnCard.scss";
import { truncateText } from "../../../utils/TextUtil";

type Props = {
  label: string;
  name: string;
  imgPath: string;
  link: string;
  headingElement: number;
};

const AttentionEcReturnCard: React.FC<Props> = (props) => {
  const { label, name, imgPath, link, headingElement } = props
  
  return (
    <article className="w-[220px] mr-10 min-w-[220px] attention-ec-return-card">
      <a href={link}>
        <p className="text-xs text-[#768084] mb-[10px] min-h-[1rem] line-clamp-1">{label}</p>
        <div className="mb-[10px] sm:w-[220px] aspect-[4/3] overflow-hidden">
          <img
            src={imgPath}
            alt={name}
            className="object-cover key-visual"
          />
        </div>
        <Heading className="mb-6 h-10" headingElement={headingElement}>
          <span className="title font-bold text-sm">{truncateText(name, 25)}</span>
        </Heading>
      </a>
      <div className="h-10">
        <BaseLinkButton
          text="イチオシ★リターンをみる"
          link={link}
          fontSize="text-xs"
        />
      </div>
    </article>
  );
};

export default AttentionEcReturnCard;
