import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import classnames from "classnames";
import { client } from "../../types/pages/top";
import NoImg from "../../../images/noimage.gif";

type Props = {
  clients: client[];
  zoom?: number;
  textOnly?: boolean;
  mapWidth?: string;
  mapHeight?: string;
  mapClassName?: string;
};

// TODO: APIキーを渡す
const ClientMap: React.FC<Props> = (props) => {
  const {
    clients,
    zoom = 6,
    textOnly = false,
    mapWidth = "100%",
    mapHeight = "360px",
    mapClassName = "",
  } = props;
  const containerStyle = {
    width: mapWidth,
    height: mapHeight,
  };
  const center = {
    lat: 35.681298,
    lng: 139.7640582,
  };
  const infoWindowStyle = {
    background: "white",
    fontSize: 7.5,
  };

  const [selectedMarker, setSelectedMarker] = useState<client | null>(null);

  const handleClick = (event, marker) => {
    setSelectedMarker(marker);
  };

  return (
    <LoadScript googleMapsApiKey="">
      <GoogleMap
        mapContainerClassName={classnames("w-full", "h-full", mapClassName)}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
      >
        {clients.map((item) => 
          {item.lat && item.lng ? (
            <>
            <Marker
              position={{ lat: item.lat, lng: item.lng }}
              onClick={(event) => handleClick(event, item)}
            >
              {selectedMarker && (
                <InfoWindow onCloseClick={() => setSelectedMarker(null)}>
                  <div
                    className="flex flex-row items-center"
                    style={infoWindowStyle}
                  >
                    {textOnly ? (
                      <div>{selectedMarker.name}</div>
                    ) : (
                      <>
                        <div>
                          <a href={selectedMarker.path}>
                            {selectedMarker.imagePath ? (
                              <img src={selectedMarker.imagePath} alt={selectedMarker.name} width={32} height={24} />
                            ) : (
                              <img src={NoImg} alt={selectedMarker.name} width={32} height={24} />
                            )}
                          </a>
                        </div>
                        <div>
                          <a
                            href={selectedMarker.path}
                            className="ml-2 text-blue-500 hover:underline"
                          >
                            {selectedMarker.name}
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </InfoWindow>
              )}
            </Marker>
          </>
          ) : (
            <></>
          )}
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default ClientMap;
