import React, { useState } from "react";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import { HttpRequest } from "../../../utils/common.js";
import Layout from "../../../components/Common/Layout";

type Props = {
  notifyCommunityMail: string;
};

const Index: React.FC<Props> = (props) => {
  const { notifyCommunityMail } = props;
  const [value, setValue] = useState<string>(notifyCommunityMail);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
          
  const onSubmit = async () => {
    await HttpRequest.post("/mypage/notification/update", {
      account: {
        notify_community_mail: value
      }
    }).then(() => {
      location.href = "/mypage/notification/updated";
    });
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "お知らせ設定",
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-10 sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          お知らせ設定
        </h2>
        <div className="sm:w-[252px] sm:mx-auto">
          <div className="mb-[40px] sm:mb-[60px]">
            <p className="text-[#768084] mb-4 text-sm">
              支援者コミュニティ投稿時のメール通知
            </p>
            <div className="sm:flex">
              <div className="mb-4 sm:mb-0 sm:w-1/2">
                <input
                  className="checked:after:absolute checked:after:top-1 checked:after:left-1 checked:after:rounded-[50%] checked:after:inline-block checked:after:w-3 checked:after:h-3 checked:after:content-[''] checked:after:bg-[#1F2122] absolute appearance-none"
                  type="radio"
                  name="mail"
                  id="notification1_true"
                  value="true"
                  onChange={(e) => handleChange(e)}
                  checked={value === "true"}
                />
                <label
                  htmlFor="notification1_true"
                  className="before:mr-[10px] before:border-[#B1C2CC] before:rounded-[50%] before:content-[''] flex before:inline-block items-center before:w-5 before:h-5 text-sm before:border"
                >
                  通知する
                </label>
              </div>
              <div className="sm:w-1/2">
                <input
                  className="checked:after:absolute checked:after:top-1 checked:after:left-1 checked:after:rounded-[50%] checked:after:inline-block checked:after:w-3 checked:after:h-3 checked:after:content-[''] checked:after:bg-[#1F2122] absolute appearance-none"
                  type="radio"
                  name="mail"
                  id="notification1_false"
                  value="false"
                  onChange={(e) => handleChange(e)}
                  checked={value === "false"}
                />
                <label
                  htmlFor="notification1_false"
                  className="before:mr-[10px] before:border-[#B1C2CC] before:rounded-[50%] before:content-[''] flex before:inline-block items-center before:w-5 before:h-5 text-sm before:border"
                >
                  通知しない
                </label>
              </div>
            </div>
          </div>
          <div className="h-[50px]">
            <BaseButton
              text="上記の内容で変更する"
              fontSize="text-sm"
              onClick={() => onSubmit()}
            />
          </div>
        </div>

        <div className="mt-[60px] text-center">
          <a
            href="/mypage"
            className="link-hover-none left-icon icon-stick-arrow-left text-sm"
          >
            マイページトップ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
