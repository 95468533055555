import { useEffect, useState } from "react";

export const useTabs = (investments, players) => {
  const [activeTab, setActiveTab] = useState<number>(0)

  const handleTabChange = (index) => {
    setActiveTab(index)
  }

  useEffect(() => {
    if (investments.length === 0 && players.length > 0) {
      handleTabChange(1)
    }
  },[])


  return { activeTab, handleTabChange };
};
