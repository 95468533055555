import React, { useState } from "react";
import { HttpRequest } from "../../../utils/common.js";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import SecondaryLinkButton from "../../../components/Common/Buttons/SecondaryLinkButton";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";
import Layout from "../../../components/Common/Layout"

type Props = {};
const Index: React.FC<Props> = (props) => {
  const [isConfirmModalActive, setIsConfirmModalActive] =
    useState<boolean>(false);

  const toggleConfirmModal = () => {
    setIsConfirmModalActive(!isConfirmModalActive);
  };

  const deleteAccount = () => {
    HttpRequest.delete("/accounts", {}).then(() => {
      location.href = "/mypage/delete/thanks"
    })
  };

  const openConfirmModal = () => {
    setIsConfirmModalActive(true);
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "退会手続き"
        },
      ]}
    >
      <ConfirmationModal
        isActive={isConfirmModalActive}
        toggleModal={() => toggleConfirmModal()}
        onSubmit={() => deleteAccount()}
        confirmText="退会し、アカウントを削除する"
      >
        <p className="mb-[40px] text-center text-lg font-bold">
          本当に退会手続きを行っても
          <br />
          よろしいでしょうか？
        </p>
        <div>
          <p className="mb-[30px]">
            ※退会後は、会員情報データやこれまでの支援履歴データがすべて削除されます。
          </p>
          <p>※スポチュニティからのお得な情報を今後受け取れなくなります。</p>
        </div>
      </ConfirmationModal>
      <div>
        <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
          <h1 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">退会手続き</h1>
          <div className="mb-[45px] sm:text-center sm:mb-[60px]">
            <p>退会処理を行うと会員情報データが削除されます。</p>
            <p>
              また、支援しているチームや選手からのリターン獲得権利を失います。
            </p>
            <p>
              なお、退会すると月額の自動課金も終了しますが、<br className="hidden sm:block" />ここまで支援された支援金はチームや選手に届けられます。
            </p>
          </div>
          <div className="mb-[10px] sm:mx-auto sm:mb-5 h-[50px] sm:w-[400px]">
            <SecondaryLinkButton
              text="退会せずに戻る"
              link="/mypage"
              fontSize="text-sm"
              iconLeftEdge="icon-stick-arrow-left"
            />
          </div>
          <div className="sm:mx-auto h-[50px] sm:w-[400px]">
            <BaseButton
              text="退会する"
              fontSize="text-sm"
              onClick={() => openConfirmModal()}
            />
          </div>
          <div className="mt-[60px] text-center">
            <a
              href="/mypage"
              className="left-icon icon-stick-arrow-left font-medium text-sm"
            >
              マイページトップ
            </a>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Index;
