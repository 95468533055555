import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PrimaryButton from "../Common/Buttons/PrimaryButton";
import NoImg from "../../../images/noimage.gif";
import { ecReturnImage } from "../../types/components/Team/ecReturn";
import Slider from "react-slick";

type Props = {
  id: number;
  price: number;
  title: string;
  smallImgUrl: string;
  largeImgUrl: Array<ecReturnImage>;
  description: string;
  date: string;
  stockQuantity: string;
  supporterNumber: number;
  isFold: boolean;
  className?: string;
  isNew: boolean;
  isClosed: boolean;
  isPurchaseAvailable: boolean;
  forCorporation: number;
  isSubscription: boolean;
  isSoldOut: boolean;
  destination?: boolean;
};

const ProjectDetailEcReturn: React.FC<Props> = (props) => {
  const {
    id,
    price,
    title,
    smallImgUrl,
    largeImgUrl,
    description,
    date,
    stockQuantity,
    supporterNumber,
    isFold,
    className,
    isNew,
    isClosed,
    isPurchaseAvailable,
    forCorporation,
    isSubscription,
    isSoldOut,
    destination,
  } = props;

  const [cardOpened, setCardOpened] = useState<boolean>(isFold);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [descriptionOpened, setDescriptionOpened] = useState<boolean>(false);
  const [descriptionHeight, setDescriptionHeight] = useState<number>(0);
  let el: HTMLElement | null;
  let openedCard: HTMLElement | null;

  const settings = {
    dots: true,
  };

  useEffect(() => {
    el = document.querySelector(`#return_description${id}`);
    openedCard = document.querySelector(`#opened_card${id}`);
  });

  const toggleCard = () => {
    setCardOpened(!cardOpened);
    if (descriptionHeight === 0) {
      openedCard.classList.remove("hidden");
      setTimeout(() => {
        const height = el.clientHeight;
        setDescriptionHeight(height);
        if (height > 30) {
          setIsVisible(false);
        }
      });
    }

    if (descriptionHeight > 30) {
      setIsVisible(false);
    }
  };

  const toggleShowMore = () => {
    setDescriptionOpened(!descriptionOpened);
    el.classList.toggle("line-clamp-2");
  };

  return (
    <>
      <div id={`return_item${id}`}>
        <section
          className={classNames(
            cardOpened
              ? "hidden"
              : "block" +
                  " w-full" +
                  " relative" +
                  " flex" +
                  " items-center" +
                  " p-2.5" +
                  " p-4" +
                  " bg-white" +
                  " rounded" +
                  " shadow-lg" +
                  " hover:cursor-pointer",
            className
          )}
          onClick={() => toggleCard()}
        >
          {isSoldOut && (
            <p className="left-[40%] rotate-[-25deg] w-[100px] absolute text-center text-accent font-bold bg-white border-2 border-solid border-accent rounded">
              完売
            </p>
          )}
          <div className="w-[20%] h-[100%] mr-2.5">
            {smallImgUrl ? (
              <img src={smallImgUrl} alt={title} />
            ) : (
              <img src={NoImg} alt={title} />
            )}
          </div>
          <div className="w-[80%] after:rotate-[135deg] after:top-[calc((100%-14px)/2)] after:absolute after:right-5 flex flex flex-col after:w-2.5 after:h-2.5 after:border-r-2 after:border-t-2 after:border-solid after:border-black">
            <div className="flex">
              <p className="w-[70%] text-lg font-bold">
                {price.toLocaleString()}円
              </p>
              {isNew && <p className="text-accent font-bold">NEW</p>}
            </div>
            <h2>{title}</h2>
          </div>
        </section>
        <section
          className={
            cardOpened
              ? "block bg-white w-fullp-2.5 p-4 rounded shadow-lg mb-2 relative"
              : "hidden"
          }
          id={`opened_card${id}`}
        >
          {isSoldOut && (
            <p className="left-[6%] top-[40%] rotate-[-25deg] w-[280px] absolute p-2 text-center text-accent text-3xl font-bold bg-white border-2 border-solid border-accent rounded">
              完売
            </p>
          )}
          <div
            className="border-b-[1px] border-400 before:rotate-[-45deg] before:top-[calc((100%-14px)/2)] before:absolute relative before:right-2.5 flex items-center mb-2.5 before:w-2.5 before:h-2.5 before:border-r-2 before:border-t-2 border-solid before:border-solid before:border-black hover:cursor-pointer"
            onClick={() => toggleCard()}
          >
            <p className="w-[77%] text-lg font-bold">
              {price.toLocaleString()}円
            </p>
            {isNew && <p className="text-accent font-bold">NEW</p>}
          </div>
          <div className="w-[100%] h-[100%]">
            {largeImgUrl.length ? (
              <Slider {...settings}>
                {largeImgUrl.map((item: ecReturnImage) => (
                  <img
                    src={item.path}
                    alt={title}
                    key={item.id}
                    className="w-full"
                  />
                ))}
              </Slider>
            ) : (
              <img src={NoImg} alt={title} className="w-full" />
            )}
          </div>
          <h2 className="my-2.5 text-lg font-bold">
            {title}
            {isSubscription ? <span className="ml-2">※月額課金</span> : ""}
          </h2>
          <div className="mb-5">
            <p id={`return_description${id}`} className="line-clamp-2">
              {description}
            </p>
            <div className={isVisible ? "hidden" : ""}>
              <span
                onClick={() => toggleShowMore()}
                className={
                  descriptionOpened
                    ? "hidden"
                    : "text-primary hover:cursor-pointer"
                }
              >
                もっと見る
              </span>
              <span
                className={
                  descriptionOpened
                    ? "text-primary hover:cursor-pointer"
                    : "hidden"
                }
                onClick={() => toggleShowMore()}
              >
                閉じる
              </span>
            </div>
          </div>
          <div>
            <div className="flex">
              <p className="before:content-[''] flex before:inline-block items-center before:mr-1 before:w-4 before:h-4 text-grey-900 text-sm before:bg-icon_people before:bg-contain">
                支援者数 {supporterNumber}人
              </p>
              <p className="before:content-[''] flex before:inline-block items-center ml-4 before:mr-1 before:w-4 before:h-4 text-grey-900 text-sm before:bg-icon_stock before:bg-contain">
                残り {stockQuantity}
              </p>
            </div>
          </div>
          {isSubscription ? (
            <p className="my-5 text-center text-blue-900 text-sm">
              【月額課金中は継続してご提供】
            </p>
          ) : (
            <p className="my-5 text-center text-blue-900 text-sm">
              【{date}に実施予定】
            </p>
          )}
          <div className="w-[100%] h-[44px] leading-[44px] inline-block text-center text-white">
            <PrimaryButton
              buttonText={
                isClosed
                  ? "終了しました"
                  : isSubscription && destination
                  ? "コース変更・キャンセル"
                  : "リターン購入"
              }
              onClick={() => {
                if (!isClosed && isPurchaseAvailable) {
                  location.href = `/ec_returns/${id}/web_settlements/new`
                }
              }}
              disabled={isClosed || !isPurchaseAvailable}
            />
          </div>
          {forCorporation && !isClosed ? (
            <div className="w-[100%] h-[44px] leading-[44px] inline-block mt-2 text-center text-white">
              <PrimaryButton
                buttonText={"資料や見積書"}
                onClick={() => window.alert("法人向けリターンページについては作成中です")}
              />
            </div>
          ) : (
            <></>
          )}
        </section>
      </div>
    </>
  );
};

export default ProjectDetailEcReturn;
