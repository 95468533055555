import React from "react";
import { EcReturnQuestion } from "../../../../types/pages/EcWebsettlement/purchase_ec_return";

type Props = {
  ecReturnQuestions: EcReturnQuestion[];
  setAnswerAt: (index: number, value: string) => void;
  answers: string[];
  register;
};
const EcReturnQuestionList: React.FC<Props> = ({
  ecReturnQuestions,
  setAnswerAt,
  answers,
  register,
}) => {
  return (
    <div>
      {ecReturnQuestions.map((question, questionIndex) => (
        <div
          className="py-4 border-t border-solid border-grey-500"
          key={`question-${questionIndex}`}
        >
          {question.question_type === 1 && (
            <div>
              <p>質問：</p>
              <p className="pl-2">{question.question}</p>
              <input
                type="text"
                placeholder="回答"
                className="p-1 w-full bg-blue-200"
                {...register(`answers.${questionIndex}`, {
                  required: "入力してください",
                })}
                onChange={({ target: { value } }) =>
                  setAnswerAt(questionIndex, value)
                }
              />
            </div>
          )}
          {question.question_type === 2 &&
            question.ec_return_question_question_answers.map(
              (ans, ansIndex) => (
                <div className="flex items-center w-full">
                  <input
                    type="radio"
                    id={`answer-${ansIndex}-of-${questionIndex}`}
                    className="mr-1"
                    name="question"
                    defaultChecked={ans.answer === answers[questionIndex]}
                    value={ans.answer}
                    onChange={({ target: { value } }) =>
                      setAnswerAt(questionIndex, value)
                    }
                  />
                  <label htmlFor={`answer-${ansIndex}-of-${questionIndex}`}>
                    {ans.answer}
                  </label>
                </div>
              )
            )}
        </div>
      ))}
    </div>
  );
};

export default EcReturnQuestionList;
