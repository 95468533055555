import React from "react";
import { fontSize } from "../../../types/common";

type Props = {
  text: string;
  link: string;
  iconRight?: string;
  iconRightEdge?: string;
  iconLeft?: string;
  iconLeftEdge?: string;
  fontSize?: fontSize;
  disabled?: boolean;
  target_blank?: boolean;
};

const SecondaryLinkButton: React.FC<Props> = (props) => {
  const {
    text,
    link,
    iconRight,
    iconLeft,
    iconLeftEdge,
    iconRightEdge,
    fontSize,
    disabled = false,
    target_blank = false,
  } = props;

  return (
    <a
      href={link}
      className={`${fontSize} ${disabled && "opacity-60 btn-hover-secondary pointer-events-none"} text-center w-full h-full border-[#B1C2CC] btn-hover-secondary link-hover-none border rounded-default block`}
      target={target_blank ? "_blank" : undefined}
      rel={target_blank ? "noopener noreferrer" : undefined}
    >
      <span className={`h-full flex items-center justify-center relative z-10 ${
            iconRight ? `right-icon after:absolute after:right-3 ${iconRight}` : `flex-wrap`
          } ${
            iconRightEdge &&
            `${iconRightEdge} right-icon after:absolute after:right-[6%] after:z-10`
          } ${
            iconLeftEdge &&
            `${iconLeftEdge} left-icon before:absolute before:left-[6%] before:z-10`
          } ${iconLeft && `left-icon ${iconLeft}`}`}>
        <span className={`${text.split("\n").length > 1 && "leading-tight"}`}>
          {text.split("\n").map((t, i) => (
            <span key={i}>
              {t}
              <br />
            </span>
          ))}
        </span>
      </span>
    </a>
  );
};

export default SecondaryLinkButton;
