import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RecruitBanner from "../../../images/recruit_banner.jpg"
import ColumnBanner from "../../../images/column_banner.jpg"
import InternshipBanner from "../../../images/internship_banner.jpg"
import RankingBanner from "../../../images/ranking_banner.jpg"
import SimulatorBanner from "../../../images/simulator_banner.jpg"
import AmbassadorBanner from "../../../images/ambassador_banner.jpg"

type Props = {};

const Banner: React.FC<Props> = (props) => {
  const banner = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          arrows: false,
        },
      },
    ],
  };

  const items = [
    { img: SimulatorBanner, link: "/simulator", alt: "支援金額シミュレーション" },
    { img: AmbassadorBanner, link: "https://corp.spportunity.com/ambassador/", alt: "スポチュニティアンバサダー" },
    { img: RecruitBanner, link: "https://corp.spportunity.com/recruit/", alt: "リクルート" },
    { img: ColumnBanner, link: "https://media.spportunity.com/", alt: "スポチュニティコラム" },
    { img: RankingBanner, link: "/ranking", alt: "スポチュニティランキング" },
    { img: InternshipBanner, link: "https://corp.spportunity.com/internship/", alt: "インターンシップ" },
  ]

  const wrapper = useRef(null);

  useEffect(() => {
    const wrapperCurrent = wrapper.current;
    const totalItems = wrapperCurrent?.querySelectorAll(".slick-slide").length
    if (totalItems > 3) {
      const stalker = wrapperCurrent.querySelector(".stalker");
      wrapperCurrent.addEventListener("mouseover", () => {
        if (stalker) {
          stalker.style.display = "block";
        }
      });
      wrapperCurrent.addEventListener("mouseout", () => {
        if (stalker) {
          stalker.style.display = "none";
        }
      });
      document.addEventListener("mousemove", (e) => {
        if (stalker) {
          stalker.style.transform =
            "translate(" + e.clientX + "px, " + e.clientY + "px)";
        }
      });
    }
  }, []);

  return (
    <div className="lg:py-[80px] sm:px-[30px] mx-auto w-full sm:max-w-main lg:px-0">
      <div className="banner-area">
        <div className="slider-wrapper relative" ref={wrapper}>
          <div className="stalker"></div>
          <Slider {...banner}>
            {items.map((item, i) => (
              <div className="sm:max-w-[320px] w-full py-[80px] lg:py-0" key={i}>
                <a href={item.link} className="link-hover-none block overflow-hidden">
                  <img
                    src={item.img}
                    alt={item.alt}
                    className="img-expansion"
                  />
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>

  );
};

export default Banner;
