import React, { FC } from "react";

type BreadcrumbItem = {
  text: string;
  href?: string;
};

type BreadcrumbProps = {
  items: BreadcrumbItem[];
};

const Breadcrumbs: FC<BreadcrumbProps> = ({ items }) => {
  return (
    <div className="sm:max-w-main hidden sm:block sm:mx-auto sm:px-[30px] md:px-0 pt-5">
      {items &&
        <ul className="text-[10px] text-[#768084] flex">
          {items.map((item, i) =>
            item.href ? (
              <React.Fragment key={i}>
                <li>
                  <a href={item.href} className="text-[#768084]">
                    {item.text}
                  </a>
                </li>
                <span className="text-[#768084] mx-1">&gt;</span>
              </React.Fragment>
            ) : (
              <li key={i} className="text-[#768084]">
                {item.text}
              </li>
            )
          )}
        </ul>
      }
    </div>
  );
};

export default Breadcrumbs;
