import React from "react";
import Noimg from "../../../../../images/noimg.png";
import SecondaryLinkButton from "../../../../components/Common/Buttons/SecondaryLinkButton";
import Layout from "../../../../components/Common/Layout";

type Props = {};

const Notification: React.FC<Props> = () => {
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "支援履歴",
          href: "/mypage/support_history",
        },
        {
          text: "支払い明細",
          href: "/mypage/support_history/payment_details",
        },
        {
          text: "支払い明細 -通知再送-"
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-10 sm:mx-auto md:px-0">
        <h1 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">支払い明細 - 通知再送 -</h1>
        <div className="mb-[40px] sm:text-center sm:mb-[60px]">
          <p>
            $パラメータ（例：鯖1匹のお支払い受付番号）$のご案内メールを再送しました。
          </p>
          <p>しばらくお待ちください。</p>
        </div>
        <div className="w-[143px] h-[110px] mb-[60px] mx-auto">
          <img src={Noimg} alt="" />
        </div>
        <div className="sm:mx-auto h-[50px] sm:w-[400px]">
          <SecondaryLinkButton
            iconLeftEdge="icon-stick-arrow-left"
            text="このプロジェクトの支援履歴に戻る"
            fontSize="text-sm"
            link="/mypage/support_history/payment_details"
          />
        </div>
        <div className="mt-[60px] text-center">
          <a href="/mypage" className="left-icon icon-stick-arrow-left text-sm">
            マイページトップ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Notification;
