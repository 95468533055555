import React from "react";
import Banner from "./Banner";
import Breadcrumbs from "./Breadcrumbs";
import Contact from "./Contact";
import Footer from "./Footer";
import ScrollToPageTopButton from "./Buttons/ScrollToPageTopButton";

type BreadcrumbItem = {
  text: string,
  href?: string
}

type Props = {
  breadcrumbs?: Array<BreadcrumbItem>
};

const Layout: React.FC<Props> = (props) => {
  const { children, breadcrumbs } = props;

  return (
    <>
      <div className="pt-[90px] sm:pt-[70px]">
        <Breadcrumbs
          items={breadcrumbs}
        />
        { children }
      </div>
      <Banner />
      <Contact />
      <Footer
        breadcrumbs={breadcrumbs}
      />
      <div className="fixed z-30 bottom-[30px] right-[max(30px,calc((100%-1280px)/2+80px))] scroll-button">
        <ScrollToPageTopButton />
      </div>
    </>
  );
};

export default Layout;
