import React, { useState } from "react";
import InvestmentCard from "../../components/Common/Cards/InvestmentCard";
import Layout from "../../components/Common/Layout";
import Pagination from "../../components/Common/Pagination";
import SearchModal from "../../components/Common/SearchModal";
import { useCheckbox } from "../../hooks/Common/useCheckbox";
import { useToggle } from "../../hooks/Common/useToggle";
import { sportsCategory } from "../../types/pages/top";
import Noimg from "../../../images/noimg.png";
import {
  investment,
  projectStatus,
  projectStatusHash,
  sportsCategoryHash
} from "../../types/pages/search";

type Props = {
  sportsCategories: sportsCategory[];
  sportsCategoryHash: sportsCategoryHash;
  projectStatuses: projectStatus[];
  projectStatusHash: projectStatusHash;
  searchedInvestments: investment[];
  defaultCheckedSportCategoryList: number[];
  defaultCheckedProjectStatusList: number[];
};

const StatusSearch: React.FC<Props> = (props) => {
  const {
    sportsCategories,
    sportsCategoryHash,
    projectStatuses,
    projectStatusHash,
    searchedInvestments,
    defaultCheckedSportCategoryList,
    defaultCheckedProjectStatusList
  } = props;

  const { isActive: showCategoryAll, toggle: toggleCategoryAccordion } =
    useToggle();
  const { isActive: showStatusAll, toggle: toggleStatusAccordion } =
    useToggle();
  const { isActive: showModal, toggle: toggleModal } = useToggle();

  const openModal = () => {
    toggleModal();
  };

  const {
    toggleCheckboxState: toggleSportCategoryCheckboxState,
    toggelAllCheckboxState: toggleAllSportCategoryCheckboxState,
    checkedList: checkedSportCategoryList
  } = useCheckbox(sportsCategories, defaultCheckedSportCategoryList);

  const {
    toggleCheckboxState: toggleProjectStatusCheckboxState,
    toggelAllCheckboxState: toggleAllProjectStatusCheckboxState,
    checkedList: checkedProjectStatusList
  } = useCheckbox(projectStatuses, defaultCheckedProjectStatusList);

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/"
        },
        {
          text: "ステータスからさがす"
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:mt-[60px] sm:mb-[40px] sm:mx-auto sm:max-w-main md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          ステータスからさがす
        </h2>
        <div
          className="right-icon icon-narrow-down after:absolute relative after:right-0 flex items-center pb-5 font-medium hover:cursor-pointer"
          onClick={() => openModal()}
        >
          条件を絞り込む
        </div>
        <div className="bg-[#F4F6F8] p-[10px] mb-5 text-xs sm:p-5">
          <div className="text-[#768084] mb-1">現在の絞り込み条件</div>
          <div className="mb-[10px]">
            <span className="font-bold">スポーツカテゴリー：</span>
            <span>
              {checkedSportCategoryList
                .map((checkedCategory) => {
                  return sportsCategoryHash[checkedCategory].name;
                })
                .join("、")}
            </span>
          </div>
          <div>
            <span className="font-bold">ステータス：</span>
            <span>
              {checkedProjectStatusList
                .map((checkedStatus) => {
                  return projectStatusHash[checkedStatus];
                })
                .join("、")}
            </span>
          </div>
        </div>
        <SearchModal
          toggleModal={toggleModal}
          isActive={showModal}
          onSubmit={() =>
            (location.href = `/search/status?checkedSportCategoryList=${checkedSportCategoryList}&checkedProjectStatusList=${checkedProjectStatusList}`)
          }
        >
          <div className="sm:w-[700px] sm:mx-auto">
            <div className="border-[#B1C2CC] border-b">
              <div
                className={`${
                  showCategoryAll ? "icon-arrow-top" : "icon-arrow-under"
                } right-icon flex items-center justify-between pb-5 mr-1 hover:cursor-pointer`}
                onClick={toggleCategoryAccordion}
              >
                <div>
                  スポーツカテゴリー
                  <span className="ml-1">
                    ({checkedSportCategoryList.length})
                  </span>
                </div>
              </div>
              <div className={`${showCategoryAll ? "block" : "hidden"}`}>
                <div className="mb-6">
                  <input
                    type="checkbox"
                    id="all_sport_category"
                    className="category-checkbox hidden"
                    onChange={(e) => toggleAllSportCategoryCheckboxState(e)}
                    checked={
                      checkedSportCategoryList.length ===
                      sportsCategories.length
                    }
                  />
                  <label
                    htmlFor="all_sport_category"
                    className="category-label before:w-[17px] before:h-[17px] before:content-[''] before:border-[#B1C2CC] relative flex before:inline-block items-center before:mr-4 before:border"
                  >
                    すべて
                  </label>
                </div>
                <div className="pb-2 pt-7 border-t border-dotted sm:flex sm:flex-wrap">
                  {sportsCategories.map((item, i) => (
                    <div className="mb-5 sm:w-1/3" key={i}>
                      <input
                        type="checkbox"
                        id={item.id.toString()}
                        className="category-checkbox hidden"
                        onChange={(e) => toggleSportCategoryCheckboxState(e)}
                        checked={checkedSportCategoryList.includes(item.id)}
                      />
                      <label
                        htmlFor={item.id.toString()}
                        className="category-label before:w-[17px] before:h-[17px] before:content-[''] before:border-[#B1C2CC] relative flex before:inline-block items-center before:mr-4 before:border"
                      >
                        {item.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="border-[#B1C2CC] pt-5 border-b">
              <div
                className={`${
                  showStatusAll ? "icon-arrow-top" : "icon-arrow-under"
                } right-icon icon-arrow-top flex items-center justify-between mb-5 mr-1 hover:cursor-pointer`}
                onClick={toggleStatusAccordion}
              >
                <div>
                  プロジェクトのステータス
                  <span className="ml-1">
                    ({checkedProjectStatusList.length})
                  </span>
                </div>
              </div>
              <div className={`${showStatusAll ? "block" : "hidden"}`}>
                <div className="mb-6">
                  <input
                    type="checkbox"
                    id="all_project_status"
                    className="category-checkbox hidden"
                    onChange={(e) => toggleAllProjectStatusCheckboxState(e)}
                    checked={
                      checkedProjectStatusList.length === projectStatuses.length
                    }
                  />
                  <label
                    htmlFor="all_project_status"
                    className="category-label before:w-[17px] before:h-[17px] before:content-[''] before:border-[#B1C2CC] relative flex before:inline-block items-center before:mr-4 before:border"
                  >
                    すべて
                  </label>
                </div>
                <div className="pb-2 pt-7 border-t border-dotted sm:flex sm:flex-wrap">
                  {projectStatuses.map((item, i) => (
                    <div className="mb-5 sm:w-1/3" key={i}>
                      <input
                        type="checkbox"
                        id={item.id.toString()}
                        className="category-checkbox hidden"
                        onChange={(e) => toggleProjectStatusCheckboxState(e)}
                        checked={checkedProjectStatusList.includes(item.id)}
                      />
                      <label
                        htmlFor={item.id.toString()}
                        className="category-label before:w-[17px] before:h-[17px] before:content-[''] before:border-[#B1C2CC] relative flex before:inline-block items-center before:mr-4 before:border"
                      >
                        {item.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </SearchModal>
        <div className="mb-[40px] sm:mb-[60px] flex justify-between text-xs">
          <div>{searchedInvestments.length}件</div>
          {/* TODO: 並べ替えは後日実装 */}
          {/* <div className="flex">
            <div>並べ替え：</div>
            <select>
              <option value="">注目順</option>
            </select>
          </div> */}
        </div>
        {searchedInvestments.length > 0 ? (
          <ul className="sm:grid sm:gap-x-8 sm:grid-cols-3 sm:mb-5 md:gap-x-10 md:grid-cols-4">
            {searchedInvestments.map((investment) => (
              <li key={investment.id} className="mb-10">
                <InvestmentCard
                  investmentName={investment.investment_name}
                  clientName={investment.client_name}
                  investmentPath={investment.investment_path}
                  totalAmount={investment.total_amount}
                  supporterCount={investment.supporter_count}
                  clientTotalAmount={investment.client_total_amount}
                  clientSupporterCount={investment.client_supporter_count}
                  remainDate={investment.remain_date}
                  targetAmount={investment.target_amount}
                  imgUrl={investment.image_url}
                  teamIcon={investment.team_icon_img}
                  projectStatus={investment.project_status}
                  headingElement={3}
                  col={4}
                />
              </li>
            ))}
          </ul>
        ) : (
          <div className="py-[100px]">
            <div className="w-[143px] h-[110px] mb-[10px] mx-auto">
              <img src={Noimg} alt="該当するプロジェクトはありませんでした" />
            </div>
            <div className="text-[#768084] text-center text-xs">
              該当するプロジェクトはありませんでした
            </div>
          </div>
        )}
        {/* TODO: ページネーションは後日実装 <Pagination /> */}
      </section>
    </Layout>
  );
};

export default StatusSearch;
