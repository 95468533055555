import React, { useState, useEffect } from "react";
import {
  questionnaireTotalization,
  answer,
  questionnaireAnswer,
  questionnaire,
} from "../../types/pages/Team/detail";
import { calcPostedTime } from "../../utils/TextUtil";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

type Props = {
  questionnaire_totalizations: Array<questionnaireTotalization>;
  questionnaire_answer: questionnaireAnswer;
  questionnaire: questionnaire;
};

const Message: React.FC<Props> = (props) => {
  const { questionnaire_totalizations, questionnaire_answer, questionnaire } =
    props;
  const [chartLabel, setChartLabel] = useState<Array<string>>();
  const [chartData, setChartData] = useState<Array<number>>();

  useEffect(() => {
    generateChart();
  }, []);

  const generateChart = () => {
    const totalizations = questionnaire_totalizations;
    const label = [];
    const data = [];

    totalizations.sort((a, b) => {
      if (a.order_num > b.order_num) {
        return 1;
      } else {
        return -1;
      }
    });

    totalizations.forEach((item: questionnaireTotalization) => {
      if (window.screen.width <= 414) {
        const string = item.questionnaire_option.sentence.length > 10 ? item.questionnaire_option.sentence.slice(0, 10) + "..." : item.questionnaire_option.sentence
        label.push(string);
      } else if (window.screen.width < 768) {
        const string = item.questionnaire_option.sentence.length > 16 ? item.questionnaire_option.sentence.slice(0, 16) + "..." : item.questionnaire_option.sentence
        label.push(string);
      } else {
        label.push(item.questionnaire_option.sentence)
      }
      data.push(item.total_count);
    });

    if (label.length > 10) {
      const canvas: HTMLElement = document.querySelector("#questionnaire-canvas")
    canvas.style.height = `${20 * label.length}px`
    }

    setChartLabel(label);
    setChartData(data);
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    maintainAspectRatio: false,
    barThickness: 4,
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "right" as const,
      },
      label: {
        display: false,
      },
    },
    scales: {
      yAxes: {
        ticks: {
          autoSkip: false
        }
      }
    }
  };

  const data = {
    labels: chartLabel,
    datasets: [
      {
        label: "票数",
        data: chartData,
        borderColor: ["rgba(31,33,34,1)"],
        backgroundColor: ["rgba(31,33,34,1)"],
      },
    ],
  };
  return (
    <div>
      <div>
        <div className="text-[22px] mb-[50px] font-bold">
          《{new Date().getMonth() + 1}月{new Date().getDate() - 1}
          日時点の投票結果》
        </div>
        <div className="mb-10">
          <div className="bg-[#1F2122] w-[90px] h-[25px] flex items-center justify-center mb-1 text-white font-raleway text-sm rounded-default">
            Question1
          </div>
          <p className="text-xl font-bold">{questionnaire.title}</p>
        </div>
        <div className="bg-white p-[10px]">
          <Bar options={options} data={data} className="mt-2" id="questionnaire-canvas"/>
        </div>
      </div>
      {questionnaire.free_text_title && (
        <div className="mt-[80px]">
          <div className="mb-10">
            <div className="bg-[#1F2122] w-[90px] h-[25px] flex items-center justify-center mb-1 text-white font-raleway text-sm rounded-default">
              Question2
            </div>
            <p className="text-xl font-bold">{questionnaire.free_text_title}</p>
          </div>
          <ul className="p-10 pt-5 bg-white">
            {questionnaire_answer.answer.map((item: answer, index: number) => (
              <li
                key={index}
                className="border-[#D2DFE6] p-[30px] border-b sm:px-0 sm:py-5"
              >
                <div>
                  <div className="text-[#768084] mb-1 text-xs">
                    {item.account.user_name}
                  </div>
                  <p className="mb-[10px]">{item.sentence}</p>
                  <div className="flex items-center justify-between">
                    <div className="left-icon icon-clock text-[#768084] flex items-center text-xs">
                      {calcPostedTime(item.updated_at)}前
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Message;
