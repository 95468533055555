import React from "react";
import InvestmentCard from "../../components/Common/Cards/InvestmentCard";
import { client, investment } from "../../types/pages/Team/detail";

type Props = {
  client: client;
  investment: investment;
  main_image: string;
  team_image: string;
  prefecture: string
  project_status: number;
};
const Widget: React.FC<Props> = (props) => {
  return (
    <div className="sm:w-[300px] pointer-events-none">
      <InvestmentCard
        clientName={props.client.client_name}
        investmentName={props.investment.title}
        imgUrl={props.main_image}
        investmentPath={`/${props.prefecture}/team/${props.client.id}/detail/`}
        totalAmount={props.investment.total_amount}
        supporterCount={props.investment.supporter_count}
        targetAmount={props.investment.next_target_amount ? props.investment.next_target_amount : props.investment.target_amount}
        remainDate={props.investment.remain_date}
        headingElement={1}
        teamIcon={props.team_image}
        projectStatus={props.project_status}
      />
    </div>
  );
};

export default Widget;
