import React, { useRef } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { DonationForm, PurchaseEcReturnFormValues } from "../../types/pages/EcWebsettlement/purchase_ec_return";
import BaseButton from "../Common/Buttons/BaseButton";
import SelectBox from "../Common/Form/SelectBox";
import TextField from "../Common/Form/TextField";

type Props = {
  toggleModal: () => void;
  isActive: boolean;
  control: Control<DonationForm, any>;
  setValue: UseFormSetValue<PurchaseEcReturnFormValues>;
};

const ConveniSelectionModal: React.FC<Props> = (props) => {
  const { toggleModal, isActive, control, setValue } = props;
  const modalRef = useRef(null);
  if (isActive && modalRef.current) {
    const scroll = window.pageYOffset;
    if (window.innerWidth > 768) {
      modalRef.current.style.top = `${scroll + 120}px`;
    } else {
      modalRef.current.style.top = `${scroll + 30}px`;
    }
  }

  return (
    <>
      <div
        className={`${
          isActive
            ? "block fixed z-50 top-0 w-screen h-screen bg-black opacity-60 right-0 left-0"
            : "hidden"
        }`}
      ></div>
      <div
        className={`${
          isActive
            ? "block z-50 absolute right-0 left-0 w-[90%] mx-auto"
            : "invisible z-0 absolute"
        }`}
        ref={modalRef}
      >
        <div className="sm:py-[60px] p-[30px] sm:px-0 bg-white sm:max-w-[900px] mx-auto">
          <div className="sm:w-[590px] sm:mx-auto">
            <p className="font-bold text-lg text-center sm:mb-[60px] mb-[30px]">コンビニで支払う</p>
            <div className="mb-[30px]">
              <div className="flex items-center mb-1">
                <label htmlFor="conveni_code" className="inline-block text-sm">
                  お支払いコンビニ
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <SelectBox
                name="conveni_code"
                placeholder="選択してください"
                id="conveni_code"
                values={[21,31,32,33]}
                options={["ファミリーマート", "ローソン", "セイコーマート", "ミニストップ"]}
                control={control}
                rules={{
                  required: true
                }}
              />
            </div>
            <div className="mb-[30px]">
              <div className="flex items-center mb-1">
                <label htmlFor="conveni_tel" className="inline-block text-sm">
                  電話番号
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <TextField
                name="conveni_tel"
                placeholder="例: 09012345678"
                id="conveni_tel"
                rules={{
                  required: true,
                  pattern: /^0\d{9,10}$/
                }}
                patternErrorMessage="9〜10桁の半角数字で入力してください"
                control={control}
              />
            </div>
            <div className="mb-[30px]">
              <div className="flex items-center mb-1">
                <label htmlFor="conveni_phonetic_full_name" className="inline-block text-sm">
                  お名前（カナ）
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <TextField
                name="conveni_phonetic_full_name"
                placeholder="カタカナで入力してください"
                id="conveni_phonetic_full_name"
                rules={{
                  required: true,
                  pattern: /^[ァ-ンヴー]*$/,
                  maxLength: 100,
                }}
                patternErrorMessage="全角カタカナで入力してください"
                maxLengthErrorMessage="100文字以内の全角カタカナで入力してください"
                control={control}
              />
            </div>
            <div className="mb-[30px] sm:text-center h-[50px] sm:w-[275px] sm:mx-auto">
              <BaseButton
                text="上記の内容でコンビニ決済を行う"
                fontSize="text-sm"
                isSubmit={true}
                onClick={() => setValue("payment_method", "conveni")}
              />
            </div>
            <p className="text-[#FF0000] text-xs">※「コンビニで支払う」を選択されると後ほど、スポチュニティsupport@spportunity.co.jpから重要なメールが届きます。ボタンを押される前に予め受信設定をお願いいたします。</p>
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <button
            type="button"
            onClick={() => toggleModal()}
            className="icon-close left-icon w-[180px] h-[40px] flex items-center justify-center font-raleway bg-white rounded-default opacity-80"
          >
            もどる
          </button>
        </div>
      </div>
    </>
  );
};

export default ConveniSelectionModal;
