import React from "react";
import { GoogleMap, LoadScript, Marker, useLoadScript } from "@react-google-maps/api";

type Center = {
  lat: number;
  lng: number;
}

type containerStyle = {
  width: string;
  height: string;
}

type Props = {
  apiKey: string;
  center: Center;
  containerStyle: containerStyle;
}

const Map: React.FC<Props> = (props) => {
  const { apiKey, center, containerStyle } = props;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey
  })

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          options={{ fullscreenControl: false }}
        >
          <Marker position={center} />
        </GoogleMap>
      )}
    </>
   
  );
};

export default Map;
