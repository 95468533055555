import React from "react";
import { FormatCurrency } from "../../../utils/TextUtil";
import BaseLinkButton from "../../Common/Buttons/BaseLinkButton";
import SecondaryLinkButton from "../../Common/Buttons/SecondaryLinkButton";

const PaymentDetailItem = ({
  payment_detail,
  purchase_history,
  investment,
  is_scheduled_subscription_detail,
}) => {
  const detail_item_path = payment_detail.has_ec_return
                  ? purchase_history.ec_return_path
                  : payment_detail.payment_detail_item_path;

  return (
    <>
      {/* スマホ用表示 */}    
      <div className="bg-[#F4F6F8] p-5 sm:hidden">
        <div className="aspect-3/4 mb-5 mb-5 w-full">
          <a href={detail_item_path}>
            <img
              src={
                payment_detail.is_monthly_donation || payment_detail.is_tipping
                  ? investment.image_url
                  : purchase_history.ec_return_image_url
              }
              alt=""
            />
          </a>
        </div>
        <div className="pb-5 border-[#D2DFE6] border-b">
          <a href={detail_item_path}>
            <h3 className="font-bold underline">
              {payment_detail.is_monthly_donation
                ? "チームへの継続支援"
                : payment_detail.is_tipping       
                ? "投げ銭"
                : purchase_history.ec_return_name}
            </h3>
          </a>
          {purchase_history.sku_name && (
            <p className="mt-1 text-sm">{purchase_history.sku_name}</p>
          )}
        </div>
        <section className="border-[#D2DFE6] py-5 border-b">
          {payment_detail.has_ec_return && (
            <>
              <h4 className="mb-[10px] font-medium">お届け先</h4>
              <div className="mb-[10px]">
                <div className="mb-1 text-xs">
                  {purchase_history.shipping_address.full_name}
                </div>
                <div className="mb-1 text-xs">
                  <div>{`〒${purchase_history.shipping_address.postal_code}`}</div>
                  <div>{purchase_history.shipping_address.full_address}</div>
                </div>
                <div className="text-xs">
                  {purchase_history.shipping_address.phone_number}
                </div>
              </div>
              {investment.is_active ? (
                <div>
                  <div className="mb-[10px] h-[40px]">
                    <SecondaryLinkButton
                      link={purchase_history.shipping_address.edit_path}
                      disabled={!purchase_history.shipping_address.edit_path}
                      text="お届け先の変更"
                      fontSize="text-xs"
                    />
                  </div>
                  <p className="text-[#FF0000] mb-[10px] text-center text-xs">
                    プロジェクト終了が変更期限です
                  </p>
                </div>
              ) : (
                <p className="tb00] mb-[10px] text-center text-xs">
                  プロジェクト終了後のお届け先の変更は、直接プロジェクト実行者にお問い合わせください。
                </p>
              )}
            </>
          )}
          <div className="h-[40px]">
            <BaseLinkButton
              text={`プロジェクト実行者に\nメールでお問い合わせ`}
              fontSize="text-xs"
              link={`mailto:${investment.owner_email}`}
              iconLeft="icon-email"
            />
          </div>
        </section>
        <div className="pt-5">
          <dl className="mb-4 text-lg font-bold flex">
            <dt>リターン金額：</dt>
            <dd>
              
              {payment_detail.has_ec_return
                ? `${FormatCurrency(purchase_history.ec_return_price)}円 x ${purchase_history.number}口`
                : `${FormatCurrency(payment_detail.amount)}円`
              }
            </dd>
          </dl>  
          <div className="text-[#768084] mb-[25px] left-icon icon-gift-large font-bold">
            {payment_detail.is_monthly_donation
              ? "この支援は寄付のためリターンはありません"
              : payment_detail.is_tipping
              ? "投げ銭のためリターンはありません"
              : purchase_history.is_subscription_ec_return
              ? "月額課金中は継続してご提供"
              : `${purchase_history.ec_return_schedule}に実施予定`}
          </div>
          {is_scheduled_subscription_detail && (
            <div className="flex mb-5">
              <div className="h-[50px] w-[calc((100%-10px)/2)]">
                <SecondaryLinkButton
                  link={payment_detail.change_subscription_course_path}
                  disabled={!payment_detail.change_subscription_course_path}
                  text="コース変更"
                  fontSize="text-sm"
                />
              </div>
              <div className="h-[50px] w-[calc((100%-10px)/2)] ml-[10px]">
                <SecondaryLinkButton
                  link={payment_detail.change_subscription_course_path}
                  disabled={!payment_detail.change_subscription_course_path}
                  text="キャンセル(解約)"
                  fontSize="text-sm"
                />
              </div>
            </div>
          )}
          {(payment_detail.has_ec_return &&
            purchase_history.ec_return_question_and_answers.length) > 0 && (
            <section className="payment-detail-questionnaire">
              <input
                type="checkbox"
                id={`sm-questionnaire-${purchase_history.id}`}
                className="hidden"
              />
              <label
                htmlFor={`sm-questionnaire-${purchase_history.id}`}
                className="mb-[10px] right-icon icon-arrow-under payment-detail-questionnaire-header flex items-center justify-between"
              >
                <h4 className="text-sm">このリターンの設問・回答</h4>
              </label>
              <ul className="payment-detail-questionnaire-items">
                {purchase_history.ec_return_question_and_answers.map(
                  (question_and_answer, question_index) => (
                    <li
                      className="mb-[10px] last:mb-0"
                      key={question_index + 1}
                    >
                      <dl className="flex mb-1 text-xs">
                        <dt className="text-[#768084] mr-[10px] w-[32px]">
                          質問{question_index + 1}
                        </dt>
                        <dd className="w-[calc(100%-42px)]">
                          {question_and_answer.question}
                          {/* 質問内容が入ります。質問内容が入ります。質問内容が入ります。 */}
                        </dd>
                      </dl>
                      <dl className="w-[calc(100%-42px)] ml-[42px] flex text-xs">
                        <dt className="mr-[10px] text-[#768084]">回答</dt>
                        <dd>{question_and_answer.answer}</dd>
                      </dl>
                    </li>
                  )
                )}
              </ul>
            </section>
          )}
        </div>
      </div>

      {/* PC・タブレット用表示 */}
      <div className="bg-[#F4F6F8] hidden p-5 sm:flex">
        <div className="border-[#D3D3D3] w-[calc(100%-200px)] pr-7 border-r">
          <div className="flex mb-5">
            <div className="aspect-3/4 w-[250px] mr-5">
              <a href={detail_item_path}>
                <img
                  src={
                    payment_detail.is_monthly_donation || payment_detail.is_tipping
                      ? investment.image_url
                      : purchase_history.ec_return_image_url
                  }
                  alt=""
                />
              </a>
            </div>
            <div className="w-[calc(100%-270px)]">
              <div className="mb-[10px]">
                <a
                  className="underline"
                  href={detail_item_path}
                >
                  <h3 className="font-bold">
                    {payment_detail.is_monthly_donation
                      ? "チームへの継続支援"
                      : payment_detail.is_tipping 
                      ? "投げ銭"
                      : purchase_history.ec_return_name}
                  </h3>
                </a>
                {purchase_history.sku_name && (
                  <p className="text-sm mt-1">{purchase_history.sku_name}</p>
                )}
              </div>
              <dl className="mb-[10px] font-bold flex">
                <dt>リターン金額：</dt>
                <dd>
                  {payment_detail.has_ec_return
                    ? `${FormatCurrency(purchase_history.ec_return_price)}円 x ${purchase_history.number}口`
                    : `${FormatCurrency(payment_detail.amount)}円`
                  }
                </dd>
              </dl>
              <div className="text-[#768084] left-icon icon-gift-large font-bold">
                {payment_detail.is_monthly_donation
                  ? "この支援は寄付のためリターンはありません"
                  : payment_detail.is_tipping
                  ? "投げ銭のためリターンはありません"
                  : purchase_history.is_subscription_ec_return
                  ? "月額課金中は継続してご提供"
                  : `${purchase_history.ec_return_schedule}に実施予定`}
              </div>
              {is_scheduled_subscription_detail && (
                <div className="flex mt-5">
                  <div className="h-[40px] w-[110px]">
                    <SecondaryLinkButton
                      link={payment_detail.change_subscription_course_path}
                      disabled={
                        !payment_detail.change_subscription_course_path
                      }
                      text="コース変更"
                      fontSize="text-sm"
                    />
                  </div>
                  <div className="h-[40px] w-[164px] ml-[14px]">
                    <SecondaryLinkButton
                      link={payment_detail.change_subscription_course_path}
                      disabled={
                        !payment_detail.change_subscription_course_path
                      }
                      text="キャンセル(解約)"
                      fontSize="text-sm"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {(payment_detail.has_ec_return &&
            purchase_history.ec_return_question_and_answers.length) > 0 && (
            <section className="payment-detail-questionnaire">
              <input
                type="checkbox"
                id={`questionnaire-${purchase_history.id}`}
                className="hidden"
              />
              <label
                htmlFor={`questionnaire-${purchase_history.id}`}
                className="mb-[10px] right-icon icon-arrow-under payment-detail-questionnaire-header flex items-center hover:cursor-pointer"
              >
                <h4 className="mr-[10px] text-sm">
                  このリターンの設問・回答
                </h4>
              </label>
              <ul className="payment-detail-questionnaire-items">
                {purchase_history.ec_return_question_and_answers.map(
                  (question_and_answer, question_index) => (
                    <li
                      className="mb-[10px] last:mb-0"
                      key={question_index + 1}
                    >
                      <dl className="flex mb-1 text-xs">
                        <dt className="text-[#768084] mr-[10px] w-[32px]">
                          質問{question_index + 1}
                        </dt>
                        <dd className="w-[calc(100%-42px)]">
                          {question_and_answer.question}
                        </dd>
                      </dl>
                      <dl className="w-[calc(100%-42px)] ml-[42px] flex text-xs">
                        <dt className="mr-[10px] text-[#768084]">回答</dt>
                        <dd>{question_and_answer.answer}</dd>
                      </dl>
                    </li>
                  )
                )}
              </ul>
            </section>
          )}
        </div>
        <section className="w-[200px] pl-5">
          {payment_detail.has_ec_return && (
            <>
              <h4 className="mb-[10px]">お届け先</h4>
              <div className="mb-[10px]">
                <div className="mb-[10px] text-xs">
                  {purchase_history.shipping_address.full_name}
                </div>
                <div className="mb-[10px] text-xs">
                  <div>{`〒${purchase_history.shipping_address.postal_code}`}</div>
                  <div>{purchase_history.shipping_address.full_address}</div>
                </div>
                <div className="text-xs">
                  {purchase_history.shipping_address.phone_number}
                </div>
              </div>
              {investment.is_active ? (
                <div>
                  <div className="mb-[10px] h-[30px]">
                    <SecondaryLinkButton
                      link={purchase_history.shipping_address.edit_path}
                      disabled={!purchase_history.shipping_address.edit_path}
                      text="お届け先の変更"
                      fontSize="text-xs"
                    />
                  </div>
                  <p className="text-[#FF0000] mb-[10px] text-xs">
                    プロジェクト終了が変更期限です
                  </p>
                </div>
              ) : (
                <p className="text-[#FF0000] mb-[10px] text-xs">
                  プロジェクト終了後のお届け先の変更は、直接プロジェクト実行者にお問い合わせください。
                </p>
              )}
            </>
          )}
          <div className="h-[40px]">
            <BaseLinkButton
              text={`プロジェクト実行者に\nメールでお問い合わせ`}
              fontSize="text-xs"
              link={`mailto:${investment.owner_email}`}
              iconLeft="icon-email"
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default PaymentDetailItem;
