import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

type Props = {
  showWarning?: boolean;
};

const ShippingAddress: React.FC<Props> = (props) => {
  const { showWarning = false } = props;
  return (
    <>
      <div className="mt-5 mx-5 border-b border-t border-gray-300">
        <div className="m-5">
          {showWarning && (
            <p className="m-0 p-0 text-red-500 text-sm">
              ※名前・住所はサイト内では表示されません（リターン送付用）
            </p>
          )}
          <div className="flex flex-row w-full">
            <div className="flex mr-1 w-full">
              <input
                className="block px-4 py-3 w-full text-gray-700 leading-tight bg-blue-50 appearance-none"
                id="last_name"
                type="text"
                placeholder="姓"
              />
            </div>
            <div className="flex ml-1 w-full">
              <input
                className="block px-4 py-3 w-full text-gray-700 leading-tight bg-blue-50 appearance-none"
                id="first_name"
                type="text"
                placeholder="名"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mx-5 border-b border-gray-300">
        <div className="m-5">
          <div className="flex flex-row items-center w-full">
            <div className="w-1/12">
              <label
                className="block flex justify-end mb-1 pr-4 text-black"
                htmlFor="post_code"
              >
                〒
              </label>
            </div>
            <div className="w-9/12">
              <input
                className="block px-4 py-3 w-full leading-tight bg-blue-50 appearance-none"
                id="post_code"
                type="text"
                placeholder="1234567"
              />
            </div>
            <div className="ml-2 w-2/12">
              <button className="btn p-1 py-2 text-sm bg-gray-100 border border-solid border-gray-400 rounded-sm">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                &nbsp;住所検索
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mx-5 border-b border-gray-300">
        <div className="m-5">
          <div className="flex flex-row items-center w-full">
            <select
              className="block px-4 py-3 w-full leading-tight bg-blue-50 rounded-sm focus:outline-none appearance-none"
              id="prefecture"
              onChange={({ target: { value } }) => console.log(value)}
            >
              <option disabled>都道府県</option>
              {[1, 2, 3].map((i) => (
                <option value={i}>{i}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="mt-5 mx-5 border-b border-gray-300">
        <div className="m-5">
          <div className="flex flex-row items-center w-full">
            <select
              className="block px-4 py-3 w-full leading-tight bg-blue-50 rounded-sm focus:outline-none appearance-none"
              id="city"
              onChange={({ target: { value } }) => console.log(value)}
            >
              <option disabled>市区町村</option>
              {[1, 2, 3].map((i) => (
                <option value={i}>{i}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="mt-5 mx-5 border-b border-gray-300">
        <div className="m-5">
          <div className="flex flex-row items-center w-full">
            <input
              className="block px-4 py-3 w-full leading-tight bg-blue-50 appearance-none"
              id="oaza_chome"
              type="text"
              placeholder="町名番地"
            />
          </div>
        </div>
      </div>
      <div className="mt-5 mx-5 border-b border-gray-300">
        <div className="m-5">
          <div className="flex flex-row items-center w-full">
            <input
              className="block px-4 py-3 w-full leading-tight bg-blue-50 appearance-none"
              id="address_other"
              type="text"
              placeholder="マンション名など"
            />
          </div>
        </div>
      </div>
      <div className="mt-5 mx-5 border-b border-gray-300">
        <div className="m-5">
          <div className="flex flex-row items-center w-full">
            <input
              className="block px-4 py-3 w-full leading-tight bg-blue-50 appearance-none"
              id="phone_number"
              type="tel"
              placeholder="電話番号　例：09012345678"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingAddress;
