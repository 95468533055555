interface PurchaseEcReturnFormValues {
  number: number;
  is_terms_checked: boolean;
  answers: string[];
  checked_sku_id: number | null;
  additional_amount: string;
  last_name: string;
  first_name: string;
  postal_code: string;
  full_address: string;
  phone_number: string;
  email: string;
  card_brand: string;
  card_last4: string;
  card_exp_year: string;
  card_exp_month: string;
  questionnaire_id: number;
  questionnaire_option_id: number | null;
  questionnaire_sentence: string | null;
  destination_reason_text: string | null;
  payment_token: string;
  payment_method: PaymentType;
  article_message: string;
  commission: number;
  conveni_code: number;
  conveni_tel: number;
  conveni_phonetic_full_name: string;
};

type PaymentType = "credit_card" | "conveni" | "bank";

type CardInformation = {
  brand: string;
  last4: string;
  exp_year: string;
  exp_month: string;
};

type EcReturnQuestionAnswer = {
  id: number;
  answer: string;
};

type EcReturnQuestion = {
  id: number;
  question: string;
  question_type: number;
  ec_return_question_question_answers: EcReturnQuestionAnswer[];
};

type EcReturn = {
  id: number;
  price: number;
  name: string;
  content: string;
  remaining_number: number;
  actual_remaining_number?: number;
  maximum_number: number;
  support_count: number;
  investment_title: string;
  investment_image_urls: string[];
  client_image_url: string;
  client_name: string;
  ec_return_questions: EcReturnQuestion[];
  prefecture_options: [];
  city_options: [];
  schedule_text: string;
  supporter_count: number;
  is_subscription: boolean;
  purchase_path: string;
  conveniWarning: boolean;
  conveniUnavailable: boolean;
  bankUnavailable: boolean;
  ec_return_skus: EcReturnSku[];
  sku_title: string;
};

type SocialProfile = {
  last_name: string,
  first_name: string,
  postal_code: string,
  full_address: string,
  phone_number: string,
  card_brand: string,
  card_last4: string,
  card_exp_year: string,
  card_exp_month: string,
}

type QuestionnaireOption = {
  id: number,
  sentence: string
}

type Questionnaire = {
  id: number,
  title: string,
  free_text_title: string,
  selectable_answers: QuestionnaireOption[]
}

type ProjectInformation = {
  client_name: string,
  client_path: string,
  investment_path: string,
  investment_title: string,
  investment_image: string;
  ec_return_path: string;
  owner_email: string;
  conveniWarning?: boolean;
  conveniUnavailable?: boolean;
  bankUnavailable?: boolean;
}

type EcReturnSku = {
  id: number;
  maximum_number: number;
  remaining_number: number;
  sku_name: string;
}

type DonationForm = {
  number: string;
  additional_amount: number;
  ec_questionnaire1: string;
  ec_questionnaire2: string;
  last_name: string;
  first_name: string;
  postal_code: string;
  address: string;
  phone_number: string;
  questionnaire1: string;
  questionnaire2: string;
  reasons: string;
  article: string;
  conveni_code: string;
  conveni_tel: string;
  conveni_phonetic_full_name: string;
}

export type {
  PurchaseEcReturnFormValues,
  PaymentType,
  CardInformation,
  EcReturn,
  EcReturnQuestion,
  EcReturnSku,
  SocialProfile,
  Questionnaire,
  ProjectInformation,
  DonationForm
};
