import React, { useState } from "react";
import BaseButton from "../Common/Buttons/BaseButton";
import { HttpRequest } from "../../utils/common"
import { MAX_ARTICLE_LENGTH } from "../../utils/constants"

type Props = {
  clientID: number;
  articleID: number;
  refreshArticles: () => void;
  currentUserClientID: number | null;
}

const MessageInput: React.FC<Props> = (props) => {
  const {clientID, articleID, refreshArticles, currentUserClientID} = props
  const [comment, setComment] = useState<string>("");
  const [errors, setErrors] = useState<object>({});

  const addComment = () => {
    if (comment.replace(/[\s\n]/g, "") === "") {
      setErrors((other) => ({...other, comment: "メッセージが空になっています。"}));
      return;
    }
    if (comment.length > MAX_ARTICLE_LENGTH) {
      setErrors((other) => ({...other, comment: "メッセージが長すぎます。"}));
      return;
    }
    HttpRequest.post("/api/articles/add_comment", {
      client_id: clientID,
      article_id: articleID,
      comment: comment,
    })
    .then(() => {
      setComment("");
      setErrors({});
      refreshArticles();
    })
    .catch((e) => {
      setErrors(e.response.data.errors);
      console.error(e);
    })
  }

  return (
    <div className="mb-5">
      <div className="mb-5">
        <textarea
          className={"block w-full p-[15px] border border-[#B1C2CC] bg-white"} placeholder="コメントを書く"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
        <p className="text-[#FF0000] text-sm mt-1">{errors["comment"]}</p>
        {currentUserClientID === clientID &&
          <p className="text-[#768084] text-xs">※実行者様の投稿の内容は既に支援いただいた方へ通知メールとして配信されます。</p>
        }
      </div>
      {comment &&
        <div className="w-[250px] h-[50px] mx-auto">
          <BaseButton
            text="投稿する"
            onClick={() => {
              addComment();
            }}
          />
        </div>
      }
    </div>
  );
};

export default MessageInput;
