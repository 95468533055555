import React from "react";
import { fontSize } from "../../../types/common"

type Props = {
  text: string;
  link: string;
  iconLeft?: string;
  iconRight?: string;
  iconRightEdge?: string;
  iconLeftEdge?: string;
  isExternal?: boolean
  fontSize?: fontSize;
  fontRaleway?: boolean;
  disabled?: boolean;
};

const BaseLinkButton: React.FC<Props> = (props) => {
  const { text, link, iconRight, iconLeft, iconLeftEdge, iconRightEdge, isExternal = false, fontSize, fontRaleway = false, disabled = false } =
    props;
  return (
    <a
      className={`${fontSize} ${fontRaleway && "font-raleway"} ${disabled ? "pointer-events-none text-[#1F2122] bg-[#F4F6F8]": "btn-hover border-[#1F2122] border-2 text-white bg-[#1F2122]"} w-full h-full block text-center rounded-default`}
      href={link}
      target={isExternal ? "_blank" : "_self"}
    >
      <span className={`h-full flex items-center justify-center relative z-10 mt-[1px] ${
            iconRight ? `right-icon after:absolute after:right-3 ${iconRight}` : `flex-wrap`
          } ${
            iconRightEdge &&
            `${iconRightEdge} right-icon after:absolute after:right-[6%] after:z-10`
          } ${
            iconLeftEdge &&
            `${iconLeftEdge} left-icon before:absolute before:left-[6%] before:z-10`
          } ${iconLeft && `left-icon ${iconLeft}`}`}>
        <span className={`${text.split("\n").length > 1 && "leading-tight"}`}>
          {text.split("\n").map((t, i) => (
            <span key={i}>
              {t}
              <br />
            </span>
          ))}
        </span>
      </span>
    </a>
  );
};

export default BaseLinkButton;
