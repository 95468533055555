import React, { useState } from "react";
import { HttpRequest } from "../../../utils/common.js";
import { useForm } from "react-hook-form";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import Layout from "../../../components/Common/Layout";
import TextField from "../../../components/Common/Form/TextField";

type Props = {
  userName: string;
};

const Index: React.FC<Props> = (props) => {
  const [accountImg, setAccountImg] = useState<string>(
    "https://placehold.jp/120x120.png"
  );
  const { userName } = props;
  const [error, setError] = useState<boolean>(false);
  interface IFormInput {
    user_name: string;
  }

  const { handleSubmit, control, getValues } = useForm<IFormInput>({
    mode: "onChange",
    defaultValues: {
      user_name: userName
    }
  });

  const onSubmit = () => {
    HttpRequest.post("/mypage/account_info", {
      account: {
        user_name: getValues("user_name")
      }
    }).then(() => {
      location.href = "/mypage/notification/updated";
    });
  };

  const uploadImg = (e) => {
    if (e.target?.files[0]?.size > 1024 * 1024 * 1) {
      setError(true);
    } else {
      setAccountImg(window.URL.createObjectURL(e.target.files[0]));
      setError(false);
    }
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage"
        },
        {
          text: "ユーザー名の変更"
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mx-auto md:px-0 sm:mt-[60px]">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          ユーザー名の変更
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="sm:w-[515px] sm:flex sm:items-center sm:mx-auto sm:justify-center">
            {/* <div className="mb-[10px] w-[120px] h-[120px] sm:w-[160px] sm:h-[160px] sm:mr-[40px] relative mx-auto">
              <label
                htmlFor="user_icon"
                className="before:mr-0 icon-camera left-icon w-[120px] h-[120px] rounded-[50%] bg-[#1F2122] sm:w-[160px] sm:h-[160px] absolute flex flex-col items-center justify-center before:mb-3 text-white text-xs font-bold bg-opacity-50"
              >
                変更する
              </label>
              <input
                accept=".png,.jpeg,.jpg"
                type="file"
                id="user_icon"
                className="hidden"
                onChange={(e) => uploadImg(e)}
              />
              <img
                src={accountImg}
                alt=""
                className="rounded-[50%] object-cover"
              />
              <div className="text-[#FF0000] mt-1 text-xs">
                {error && (
                  <p className="before:content-['*']">
                    1MB以下の画像サイズにしてください
                  </p>
                )}
              </div>
            </div> */}
            <div className="sm:w-[315px]">
              <label htmlFor="name" className="block mb-1 text-sm font-medium">
                ユーザー名
              </label>
              <TextField
                name="user_name"
                id="user_name"
                control={control}
                placeholder="ユーザー名を入力してください"
                rules={{
                  required: true,
                  maxLength: 20,
                }}
                maxLengthErrorMessage="20文字以内で入力してください"
              />
              <p className="text-[#768084] mt-[10px] text-xs">
                サービス上で公開されるお名前ですので、本名ではなくあだ名やニックネームを推奨しています。
              </p>
            </div>
          </div>
          <div className="mt-[60px] sm:mx-auto h-[50px] sm:w-[250px]">
            <BaseButton
              text="上記の内容で変更する"
              fontSize="text-sm"
              isSubmit={true}
            />
          </div>
        </form>
        <div className="mt-[60px] text-center">
          <a
            href="/mypage"
            className="left-icon icon-stick-arrow-left text-sm font-medium"
          >
            マイページトップ
          </a>
        </div>
      </section>
    </Layout>
    
  );
};

export default Index;
