import React, { useState } from "react";
import { HttpRequest } from "../../../utils/common";
import { useForm } from "react-hook-form";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import Layout from "../../../components/Common/Layout";
import TextField from "../../../components/Common/Form/TextField";

type Props = {
  resetPasswordToken: string;
};

const Update: React.FC<Props> = (props) => {
  const { resetPasswordToken } = props;
  const [inputType, setInputType] = useState<string>("password")
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState({});

  interface IFormInput {
    password: string;
    password_confirmation: string;
  }

  const { handleSubmit, control } = useForm<IFormInput>({
    mode: "onChange",
    defaultValues: {
      password: "",
      password_confirmation: ""
    }
  });

  const onSubmit = async () => {
    await HttpRequest.put("/accounts/password", {
      account: {
        password: password,
        password_confirmation: passwordConfirmation,
        reset_password_token: resetPasswordToken,
      },
    }).then((res) => {
      location.href = res.data.redirect_path;
    }).catch((e) => {
      setErrors(e.response.data.errors);
    });
  };

  const togglePasswordDisplay = (e) => {
    if (e.target.checked) {
      setInputType("text")
    } else {
      setInputType("password")
    }
  };

  return (
    <Layout
    breadcrumbs={[
      {
        text: "HOME",
        href: "/",
      },
      {
        text: "パスワードの再設定",
      },
    ]}
    >
    <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
      <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
        パスワードの再設定
      </h2>
      <div className="sm:w-[700px] sm:mx-auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-[30px]">
            <div className="flex items-center mb-1">
              <label htmlFor="password" className="inline-block text-sm">
                新しいパスワード
              </label>
              <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                必須
              </span>
            </div>
            <TextField
              control={control}
              name="password"
              rules={{
                required: true,
                pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`˜~!@#$%^&*()_+-={}[\]¥|:;<>.?/])[A-Za-z\d`˜~!@#$%^&*()_+-={}[\]¥|:;<>.?/]{8,}$/
              }}
              placeholder="新しいパスワードを入力してください"
              id="password"
              patternErrorMessage="半角英数字・記号を組み合わせた8文字以上の文字列にしてください"
              autoComplete="password"
              type={inputType}
              onChange={({ target: { value } }) => setPassword(value)}
            />
            <p className="text-[#768084] mt-[10px] text-xs">
              半角英数字・記号を組み合わせた8文字以上で設定できます。
            </p>
            <div className="col-span-2 col-start-2 text-red-400">
              {errors["password"]}
            </div>
          </div>
          <div>
            <div className="flex items-center mb-1">
              <label htmlFor="password_confirmation" className="inline-block text-sm">
                確認用パスワード
              </label>
              <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                必須
              </span>
            </div>
            <TextField
              control={control}
              name="password_confirmation"
              rules={{
                required: true
              }}
              placeholder="パスワードをもう一度入力してください"
              id="password_confirmation"
              autoComplete="password-confirmation"
              type={inputType}
              onChange={({ target: { value } }) => setPasswordConfirmation(value)}
            />
            <div className="mt-3">
              <input
                onChange={(e) => togglePasswordDisplay(e)}
                type="checkbox"
                id="show_password"
                className="password-checkbox before:content-[''] before:border-[#B1C2CC] relative before:inline-block before:w-4 before:h-4 before:bg-white before:border invisible before:visible"
              />
              <label htmlFor="show_password" className="ml-2 text-sm">
                パスワードを表示
              </label>
            </div>
          </div>
          <div className="mt-[60px] sm:mx-auto h-[50px] sm:w-[250px]">
            <BaseButton
              text="上記の内容で変更する"
              isSubmit={true}
            />
          </div>
          <p className="text-[#FF0000] mt-3 mb-3 text-center text-xs">
            { errors["message"] }
          </p>
        </form>
      </div>
    </section>
    </Layout>
  );
};

export default Update;
