import React, { useEffect } from "react";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import { 
  Client,
  SocialProfile,
  MonthlyDonationForm
} from "../../../types/pages/Clients/MonthlyDonations/create"
import SecondaryButton from "../../../components/Common/Buttons/SecondaryButton";

type Props = {
  client: Client
  cardInformation: () => SocialProfile;
  canDonate: boolean;
  data: MonthlyDonationForm;
  movePreviousPage: () => void;
  isSubmitting: boolean;
  commission: number;
}

const Confirm: React.FC<Props> = ({
  client,
  cardInformation,
  data,
  movePreviousPage,
  isSubmitting,
  commission
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    card_brand,
    card_last4,
    card_exp_year,
    card_exp_month,
  } = cardInformation()

  return (
    <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto md:px-0">
      <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">支援のお手続き</h2>
      <div className="sm:w-[700px] sm:mx-auto">
        <section className="mb-[60px]">
          <div className="mb-[30px] flex">
            <div className="w-[80px] h-[60px] sm:w-[120px] sm:h-[90px] mr-3">
              <img
                className="object-cover"
                src={client.logo_image_url}
                alt={client.name}
              />
            </div>
            <h3 className="w-[calc(100%-92px)] sm:w-[calc(100%-132px)]">{client.name}</h3>
          </div>
          <div className="flex">
            <div className="w-[16%] border-[#1F2122] flex items-center justify-center border">
              1
            </div>
            <div className="h-[40px] bg-[#1F2122] w-[68%] relative flex items-center justify-center text-center text-white text-sm font-bold">
              <span className="text-[#1F2122] bg-[#768084] rounded-[50%] inline-block mr-1 w-5 h-5 text-center">
                2
              </span>
              入力内容の確認
              <div className="right-triangle-thin right-[-7px] absolute"></div>
            </div>
            <div className="w-[16%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
              3
            </div>
          </div>
        </section>
        <section className="mb-10">
          <div className="flex justify-between mb-5">
            <h3 className="font-bold">支援内容の確認</h3>
            <div className="w-[100px] h-[30px]">
              <SecondaryButton text="変更する" fontSize="text-xs" onClick={movePreviousPage} />
            </div>
          </div>
          <section className="sm:p-10 bg-[#F4F6F8] p-5">
            <h4 className="text-lg font-bold mb-5">チームへの継続支援</h4>
            <p className="text-[#FF0000]">※この支援は寄付のためリターンはありません。予めご了承ください</p>
          </section>
        </section>
        <section className="mb-10">
          <div className="flex justify-between mb-5">
            <h3 className="font-bold">決済情報の確認</h3>
            <div className="w-[100px] h-[30px]">
              <SecondaryButton text="変更する" fontSize="text-xs" onClick={movePreviousPage} />
            </div>
          </div>
          <div className="sm:p-10 bg-[#F4F6F8] p-5">
            <dl className="mb-5">
              <dt className="text-[#768084] text-xs">お支払い金額</dt>
              <dd className="text-sm">
                <dl className="flex justify-between">
                  <dt>支援金額(税込)</dt>
                  <dd className="font-bold text-right">{(Number(data.amount) + commission).toLocaleString()}円(月額課金)</dd>
                </dl>
              </dd>
            </dl>
            <dl className="mb-5">
              <dt className="text-[#768084] text-xs">内訳</dt>
              <dd className="text-sm">
                <dl className="flex justify-between mb-1">
                  <dt>継続支援の購入金額</dt>
                  <dd className="text-right">{Number(data.amount).toLocaleString()}円</dd>
                </dl>
                <dl className="flex justify-between">
                  <dt>プラットフォーム利用料</dt>
                  <dd className="text-right">{commission}円</dd>
                </dl>
              </dd>
            </dl>
            <dl>
              <dt className="text-[#768084] text-xs">決済方法</dt>
              <dd className="text-sm">
                <div>クレジットカード決済{card_brand}</div>
                <div>XXXX-XXXX-XXXX-{card_last4}</div>
                <div>
                  {card_exp_month}/{card_exp_year}
                </div>
              </dd>
            </dl>
          </div>
        </section>
        <section className="mb-10">
          <div className="flex justify-between mb-5">
            <h3 className="font-bold">アンケートの回答内容</h3>
            <div className="w-[100px] h-[30px]">
              <SecondaryButton text="変更する" fontSize="text-xs" onClick={movePreviousPage} />
            </div>
          </div>
          <div className="sm:p-10 bg-[#F4F6F8] p-5">
            {/* TODO: 継続寄付でアンケートとる仕様が現行にないため、調整する
            <div className="mb-[30px]">
              <dl className="mb-[10px]">
                <dt className="text-[#768084] text-xs mb-[10px]">Q1の回答</dt>
                <dd className="text-sm">
                  どの選手のメッセージが欲しいかご入力ください。
                </dd>
              </dl>
              <dl>
                <dt className="text-[#768084] text-xs">回答</dt>
                <dd className="text-sm">
                  {data.question1}
                </dd>
              </dl>
            </div>
            <div className="mb-[30px]">
              <dl className="mb-[10px]">
                <dt className="text-[#768084] text-xs mb-[10px]">Q2の回答</dt>
                <dd className="text-sm">
                  どの選手のメッセージが欲しいかご入力ください。
                </dd>
              </dl>
              <dl>
                <dt className="text-[#768084] text-xs">回答</dt>
                <dd className="text-sm">
                  {data.question2}
                </dd>
              </dl>
            </div>
            */}
            <div>
              <dl>
                <dt className="text-[#768084] text-xs mb-[10px]">支援理由</dt>
                <dd className="text-sm">
                  {data.reasons}
                </dd>
              </dl>
            </div>
          </div>
        </section>
        <section className="mb-10">
          <div className="flex justify-between mb-5">
            <h3 className="font-bold">応援メッセージの内容</h3>
            <div className="w-[100px] h-[30px]">
              <SecondaryButton text="変更する" fontSize="text-xs" onClick={movePreviousPage} />
            </div>
          </div>
          <div className="sm:p-10 bg-[#F4F6F8] p-5">
            <p className="text-[#FF0000] mb-7">応援メッセージは、支援者コミュニティで公開されます。</p>
            <dl className="mb-[10px]">
              <dt className="text-[#768084] text-xs">応援メッセージ</dt>
              <dd className="text-sm">
                {data.article_message}
              </dd>
            </dl>
          </div>
        </section>
        <div className="sm:mx-auto sm:w-[290px] h-[50px]">
          <BaseButton
            text="上記の内容で確定する"
            fontSize="text-sm"
            disabled={isSubmitting}
            isSubmit={true}
          />
        </div>
      </div>
    </section>
  );
};

export default Confirm;
