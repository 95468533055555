import { useState } from "react";

export const useRadio = (status = false) => {
  const [isActive, setIsActive] = useState<boolean>(status);

  const onChange = (e) => {
    if (e.target.value) {
      setIsActive(true)
    }
  }

  return { isActive, onChange };
};
