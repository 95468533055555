import React from 'react';

type Props = {
  text: string;
}

const LinkifyText: React.FC<Props> = ({ text }) => {
  const urlRegex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/g;
  const emailRegex = /\S+@\S+\.\S+/g;

  const linkifyText = (str: string): React.ReactNode[] => {
    const parts: React.ReactNode[] = [];
    const lines = str.split('\n');

    lines.forEach((line, lineIndex) => {
      if (lineIndex !== 0) {
        parts.push(<br key={`br-${lineIndex}`} />);
      }

      const urlMatches = line.match(urlRegex);
      const emailMatches = line.match(emailRegex);

      if (urlMatches || emailMatches) {
        const matches = [...(urlMatches || []), ...(emailMatches || [])];
        let lastIndex = 0;

        matches.forEach((match, index) => {
          const urlLink = urlRegex.test(match);
          const prefix = line.substring(lastIndex, line.indexOf(match));
          const suffix = line.substring(line.indexOf(match) + match.length);

          if (prefix) {
            parts.push(prefix);
          }

          if (urlLink) {
            const url = match.startsWith('http') ? match : `http://${match}`;
            parts.push(
              <a href={url} key={`link-${lineIndex}-${index}`} target="_blank" rel="noopener noreferrer" className="link-hover-none underline">
                {match}
              </a>
            );
          } else {
            parts.push(
              <a href={`mailto:${match}`} key={`link-${lineIndex}-${index}`} className="link-hover-none underline">
                {match}
              </a>
            );
          }

          lastIndex = line.indexOf(match) + match.length;

          if (suffix) {
            parts.push(suffix);
          }
        });
      } else {
        parts.push(line);
      }
    });

    return parts;
  };

  const processedText = linkifyText(text);

  return <div>{processedText}</div>;
};

export default LinkifyText;
