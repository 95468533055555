import React from "react";
import NoImg from "../../../../images/noimage.gif";

type Props = {
  clientName: string;
  investmentName: string;
  path: string;
  date: string;
  imageUrl: string;
};

const NewsBar: React.FC<Props> = (props) => {
  const { investmentName, clientName, imageUrl, date, path } = props;
  return (
    <div
      className="flex flex-row p-5 w-full hover:underline hover:bg-gray-100 border-b border-gray-300 cursor-pointer"
      onClick={() => {
        location.href = path;
      }}
    >
      {imageUrl ? (
        <img src={imageUrl} alt={investmentName} style={{ width: "48px", height: "48px" }} />
      ) : (
        <img src={NoImg} alt={investmentName} style={{ width: "48px", height: "48px" }} />
      )}
      <div className="flex flex-col items-center pl-2 w-full md:flex-row md:pl-3">
        <div className="line-clamp-2 md:line-clamp-1 group-hover:underline md:w-4/5">
          <span className="text-blue-500">{clientName}</span>の『
          <span className="text-blue-500 font-semibold">{investmentName}</span>
          』が開始されました。
        </div>
        <div className="text-gray-500">{date}</div>
      </div>
    </div>
  );
};

export default NewsBar;
