export const FormatCurrency = (value: number): string => {
  const parts = Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: "JPY",
  })
    .formatToParts(value)
    .filter((_) => _.type !== "currency")
    .map((_) => _.value);

  return parts.join("");
};

export const FormatPaymentType = (value: number): string => {
  switch (value) {
    case 2:
      return "入金済み";
    case 5:
      return "コンビニ支払い未";
    case 6:
      return "銀行振込未";
    default:
      return "";
  }
};

export const calcPostedTime = (date: string) => {
  const now = new Date().getTime();
  const diff = now - Date.parse(date);
  if (diff / 1000 / 60 < 60) {
    return String(Math.floor(diff / 1000 / 60)) + "分";
  } else if (diff / 1000 / 60 / 60 < 24) {
    return String(Math.floor(diff / 1000 / 60 / 60)) + "時間";
  } else if (diff / 1000 / 60 / 60 / 24 < 365) {
    return String(Math.floor(diff / 1000 / 60 / 60 / 24)) + "日";
  } else {
    return "1年以上";
  }
};

export const truncateText = (text: string, length: number) => {
  if (text?.length > length) {
    return text.slice(0, length) + "...";
  } else {
    return text;
  }
};
