import React from "react";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import ColumnImg from "../../../images/column_banner.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SecondaryLinkButton from "../../components/Common/Buttons/SecondaryLinkButton";

type Props = {
  ownerEmail: string;
  homepageURL: string;
  facebook: string;
  instagram: string;
  youtube: string;
  twitter: string;
};

const Column: React.FC<Props> = (props) => {
  const { ownerEmail, homepageURL, facebook, instagram, youtube, twitter } = props;
  return (
    <div className="py-[30px] sm:w-[54.2%] sm:flex sm:justify-between sm:pt-0">
      <div className="sm:border-[#DEDEDE] sm:w-[55%] sm:flex sm:flex-col sm:justify-center sm:px-5 sm:border-r">
        <div className="icon-external right-icon sm:after:bottom-2 sm:after:top-auto after:absolute relative after:right-0 after:top-1 mb-3">
          <div className="text-[#768084] text-xs">
            スポーツの熱い思いを届けるメディア
          </div>
          <a className="text-[17px] sm:text-[15px]" href="https://media.spportunity.com/">
            スポチュニティコラムにて掲載中
          </a>
        </div>
        <div className="h-[32vw] sm:max-w-[260px] sm:max-h-[100px] mb-6 w-full sm:mb-0">
          <img
            src={ColumnImg}
            alt="スポチュニティコラム"
            className="object-cover"
          />
        </div>
      </div>
      <div className="sm:w-[45%] sm:flex sm:flex-col sm:justify-center sm:pl-5">
        <div className="flex mb-9 sm:flex-col sm:mb-5">
          <div className="hidden md:block md:w-full mb-[10px] h-[50px]">
            <SecondaryLinkButton
              text={`メールでのご質問はこちら`}
              fontSize="text-sm"
              link={`mailto:${ownerEmail}`}
              disabled={ownerEmail === null}
            />
          </div>
          <div className="w-[50%] mr-2 sm:w-full md:hidden sm:mr-0 sm:mb-2 h-[50px]">
            <SecondaryLinkButton
              text={`メールでの\nご質問はこちら`}
              fontSize="text-sm"
              link={`mailto:${ownerEmail}`}
              disabled={ownerEmail === null}
            />
          </div>
          <div className="w-[50%] sm:w-full h-[50px]">
            <SecondaryLinkButton
              text="公式Webサイト"
              fontSize="text-sm"
              link={homepageURL}
              disabled={homepageURL === null}
            />
          </div>
        </div>
        <ul className="flex justify-center sm:justify-between">
          <li className="mr-10 sm:mr-0">
            <a href={facebook} className="link-hover-none">
              <FontAwesomeIcon icon={faFacebook} size="2x" color={facebook ? "#1F2122" : "#D2DFE5"} />
            </a>
          </li>
          <li className="mr-10 sm:mr-0">
            <a href={twitter && `https://twitter.com/${twitter}?ref_src=twsrc%5Etfw`} className="link-hover-none">
              <FontAwesomeIcon icon={faTwitter} size="2x" color={twitter ? "#1F2122" : "#D2DFE5"} />
            </a>
          </li>
          <li className="mr-10 sm:mr-0">
            <a href={instagram} className="link-hover-none">
              <FontAwesomeIcon icon={faInstagram} size="2x" color={instagram ? "#1F2122" : "#D2DFE5"} />
            </a>
          </li>
          <li>
            <a href={youtube} className="link-hover-none">
              <FontAwesomeIcon icon={faYoutube} size="2x" color={youtube ? "#1F2122" : "#D2DFE5"}/>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Column;
