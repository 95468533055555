import React from "react";
import { Control, UseFormSetValue, UseFormGetValues, UseFormWatch } from "react-hook-form";
import TextField from "../../components/Common/Form/TextField";
import { HttpRequest } from "../../utils/common";
import { ShippingAddressFormInput } from "../../types/common";

type Props = {
  showWarning?: boolean,
  control: Control<ShippingAddressFormInput, any>,
  setValue: UseFormSetValue<ShippingAddressFormInput>,
  getValues: UseFormGetValues<ShippingAddressFormInput>,
  watch: UseFormWatch<ShippingAddressFormInput>,
  onAddressEdit: (isEdited: boolean) => void;
};

const ShippingAddress: React.FC<Props> = (props) => {
  const {
    showWarning = false,
    control,
    setValue,
    getValues,
    watch,
    onAddressEdit
  } = props;
  
  const autoFullAddressFill = (postalCode: string): void => {
    if (postalCode.length != 7) {
      return
    }

    HttpRequest.get('/api/addresses/cities/' + postalCode).then((res) => {
      const addressData = res.data

      setValue('full_address', addressData.address)
      onAddressEdit(false);
    }).catch((e) => {
      console.log(e)
    })
  }

  const handleAddressChange = () => {
    onAddressEdit(true);
  };

  return (
    <>
      <div className="mb-5">
        {showWarning && (
          <p className="m-0 p-0 text-red-500 text-sm">
            ※名前・住所はサイト内では表示されません（リターン送付用）
          </p>
        )}
        <div className="flex items-center mb-1">
          <label htmlFor="last_name" className="inline-block text-sm">
            お名前
          </label>
          <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
            必須
          </span>
        </div>
        <div className="mb-5 sm:flex sm:justify-between">
          <div className="sm:w-[340px] mb-[10px] sm:mb-0">
            <TextField
              name="last_name"
              placeholder="姓を入力してください"
              id="last_name"
              control={control}
              rules={{
                required: true,
                maxLength: 20,
              }}
              maxLengthErrorMessage="20文字以内で入力してください"
            />
          </div>
          <div className="sm:w-[340px]">
            <TextField
              name="first_name"
              placeholder="名を入力してください"
              id="first_name"
              control={control}
              rules={{
                required: true,
                maxLength: 20,
              }}
              maxLengthErrorMessage="20文字以内で入力してください"
            />
          </div>
        </div>
      </div>
      <div className="mb-5">
        <div className="flex items-center mb-1">
          <label htmlFor="postal_code" className="inline-block text-sm">
            郵便番号
          </label>
          <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
            必須
          </span>
        </div>
        <div className="sm:hidden">
          <TextField
            name="postal_code"
            placeholder="郵便番号を入力してください"
            id="postal_code"
            control={control}
            rules={{
              required: true,
              pattern: /^[0-9]+$/
            }}
            onChange={(e) => {
              const postalCode = e.target.value.replace('-', '');
              setValue('postal_code', postalCode);
              autoFullAddressFill(postalCode);
            }}
            patternErrorMessage="半角数字で入力してください"
          />
          <p className="text-[#768084] mt-1 text-xs">
            -（ハイフン）は入力不要です。
          </p>
          {/*
            <div className="w-[160px] h-[30px] mx-auto mt-[30px]">
              <SecondaryButton
                iconLeft="icon-post"
                fontSize="text-xs"
                text="郵便番号から住所入力"
                color="text-[#1F2122]"
                onClick={() => autoFullAddressFill(getValues("postal_code"))}
              />
            </div>
          */}
        </div>
        <div className="hidden sm:block">
          <div className="sm:flex sm:items-end">
            <div className="w-[236px]">
              <TextField
                name="postal_code"
                placeholder="郵便番号を入力してください"
                id="postal_code"
                control={control}
                rules={{
                  required: true,
                  pattern: /^[0-9]+$/
                }}
                onChange={(e) => {
                  const postalCode = e.target.value.replace('-', '');
                  setValue('postal_code', postalCode);
                  autoFullAddressFill(postalCode);
                }}
                patternErrorMessage="半角数字で入力してください"
              />
            </div>
            {/*
              <div className="w-[160px] h-[30px] ml-[10px]">
                <SecondaryButton
                  iconLeft="icon-post"
                  fontSize="text-xs"
                  text="郵便番号から住所入力"
                  color="text-[#1F2122]"
                  onClick={() => autoFullAddressFill(getValues("postal_code"))}
                />
              </div>
            */}
          </div>
          <p className="text-[#768084] mt-1 text-xs">
            -（ハイフン）は入力不要です。
          </p>
        </div>
      </div>
      <div className="mb-5">
        <div className="flex items-center mb-1">
          <label htmlFor="prefecture" className="inline-block text-sm">
            住所
          </label>
          <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
            必須
          </span>
        </div>
        <TextField
          name="full_address"
          placeholder="住所(マンション名等を含む)"
          id="full_address"
          control={control}
          rules={{
            required: true,
            maxLength: 100,
          }}
          onChange={handleAddressChange}
          maxLengthErrorMessage="100文字以内で入力してください"
        />
      </div>
      <div className="mb-5">
        <div className="flex items-center mb-1">
          <label htmlFor="phone_number" className="inline-block text-sm">
            電話番号
          </label>
          <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
            必須
          </span>
        </div>
        <TextField
          name="phone_number"
          placeholder="例：09012345678"
          id="phone_number"
          control={control}
          rules={{
            required: true,
            pattern: /^0\d{9,10}$/
          }}
          patternErrorMessage="9〜10桁の半角数字で入力してください"
        />
        <p className="text-[#768084] mt-1 text-xs">
          -（ハイフン）は入力不要です。
        </p>
      </div>
      <p className="text-[#768084] mb-10 mt-5 text-xs">
        ご注文内容や配送について、電話またはメールにてご連絡させていただく事があります。
      </p>
    </>
  );
}

export default ShippingAddress;
