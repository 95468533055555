import React from "react";

type Props = {
  href: string;
  text: string;
};

const TwitterLoginButton: React.FC<Props> = (props) => {
  const { href, text } = props;
  return (
    <a
      href={href}
      className="link-hover-none hover:bg-[#000] before:mr-[10px] left-icon icon-twitter-white !bg-[#000] h-[50px] font-raleway rounded-default block flex items-center justify-center w-full text-white text-sm"
    >
      {text}
    </a>
  );
};

export default TwitterLoginButton;
