import React from "react";
import Noimg from "../../../../../../images/noimg.png";
import SecondaryLinkButton from "../../../../../components/Common/Buttons/SecondaryLinkButton";
import Layout from "../../../../../components/Common/Layout";

type Props = {
  payment_details_path: string;
};

const Updated: React.FC<Props> = (props) => {
  const {
    payment_details_path
  } = props;
  
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "支援履歴",
          href: "/mypage/support_history",
        },
        {
          text: "支払い明細",
          href: payment_details_path,
        },
        {
          text: "支払い明細 -お届け先の変更-",
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          支払い明細 - お届け先の変更 -
        </h2>
        <div className="mb-[45px] sm:text-center sm:mb-[60p]">
          <p>お届け先の変更が完了しました。</p>
        </div>
        <div className="w-[143px] h-[110px] mb-[40px] mx-auto sm:mb-[60px]">
          <img src={Noimg} alt="" />
        </div>
        <div className="sm:mx-auto h-[50px] sm:w-[400px]">
          <SecondaryLinkButton
            iconLeftEdge="icon-stick-arrow-left"
            text="このプロジェクトの支援履歴に戻る"
            fontSize="text-sm"
            link={payment_details_path}
          />
        </div>
        <div className="mt-[60px] text-center">
          <a
            href="/mypage"
            className="left-icon icon-stick-arrow-left text-sm font-medium"
          >
            マイページトップ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Updated;
