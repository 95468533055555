import { useEffect, useState } from "react";

export const useScrollToTarget = (state: number) => {
  const [scrollToTargetId, setScrollToTargetId] = useState<string>("")

  const scrollToTarget = (elId) => {
    const el = document.querySelector(elId);
    if (el) {
      const elRect = el.getBoundingClientRect().top;
      const y = window.scrollY;
      const headerHeight = 90;
      window.scrollTo({top: elRect + y - headerHeight, behavior: 'smooth'})
    }
  }

  useEffect(() => {
    if (!scrollToTargetId) {
      window.scroll(0, 0);
    } else {
      scrollToTarget(scrollToTargetId)
      setScrollToTargetId("")
    }
  }, [state])

  return { setScrollToTargetId };
}
