import React from "react";

type Props = {};

const ReturnCard: React.FC<Props> = (props) => {
  return (
    <section className="w-[180px] sm:w-[220px]">
      <div className="sm:h-[75%] mb-4 w-full h-auto">
        <a href="" className="link-hover-none block overflow-hidden">
          <img
            src="https://placehold.jp/300x225.png"
            alt=""
            className="img-expansion object-contain"
          />
        </a>
      </div>
      <h1 className="mb-[10px] h-[40px] sm:h-[48px] text-sm font-bold overflow-hidden sm:text-base">
        <a href="">
          【クラファン限定】選手デジタルサイン入り年賀状あああああああ
        </a>
      </h1>
      <div className="mb-[10px] flex items-center">
        <div className="w-[28px] h-[28px]">
          <img
            src="https://placehold.jp/28x28.png"
            alt=""
            className="object-contain"
          />
        </div>
        <div className="text-[#768084] line-clamp-1 w-[calc(100%-36px)] ml-2 h-4 text-xs">
          実行者名が入ります。実行者名が入ります。実行者名が入ります。実行者名が入ります。
        </div>
      </div>
      <div className="pb-4 pt-1">
        <div className="text-right font-bold">19,367,100円</div>
      </div>
    </section>
  );
};

export default ReturnCard;
