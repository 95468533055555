import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import BaseButton from "../Common/Buttons/BaseButton";
import SecondaryButton from "../Common/Buttons/SecondaryButton";
import TextField from "../Common/Form/TextField";
import { HttpRequest } from "../../utils/common";

type Props = {
  toggleModal: () => void;
  onSuccess: () => void;
  isActive: boolean;
};

const EmailConfirmationModal: React.FC<Props> = (props) => {
  const { toggleModal, isActive, onSuccess } = props;
  const [ errorMessage, setErrorMessage ] = useState<string>("");
  const [ message, setMessage ] = useState<string>("");

  const modalRef = useRef(null);
  if (isActive && modalRef.current) {
    const scroll = window.pageYOffset;
    if (window.innerWidth > 768) {
      modalRef.current.style.top = `${scroll + 120}px`;
    } else {
      modalRef.current.style.top = `${scroll + 30}px`;
    }
  }

  interface IFormInput {
    onetime_code: string;
  }

  const { handleSubmit, control, getValues }  = useForm<IFormInput>({
    mode: "onChange",
    defaultValues: {
      onetime_code: "",
    }
  });

  const confirmOnetimeCode = async () => {
    await HttpRequest.post('/api/accounts/email/confirm', {
      onetime_code: getValues("onetime_code")
    }).then(() => {
      setErrorMessage("");
      setMessage("ワンタイムコードの確認ができました。");
      onSuccess();
    }).catch((e) => {
      console.log(e)
      setMessage("");
      setErrorMessage("ワンタイムコードが確認できませんでした。再送よりお試しください。")
    })
  }

  const resendOnetimeCode = async () => {
    await HttpRequest.post('/api/accounts/email/resend', {
    }).then((res) => {
      setErrorMessage("");
      setMessage("ワンタイムコードを再送しました。")
    }).catch((e) => {
      console.log(e)
      setMessage("");
      setErrorMessage("ワンタイムコードが再送できませんでした。")
    })
  }

  return (
    <>
      <div
        className={`${
          isActive
            ? "block fixed z-50 top-0 w-screen h-screen bg-black opacity-60 right-0 left-0"
            : "hidden"
        }`}
      ></div>
      <div
        className={`${
          isActive
            ? "block z-50 absolute right-0 left-0 w-[90%] mx-auto"
            : "invisible z-0 absolute"
        }`}
        ref={modalRef}
      >
        <div className="sm:py-[60px] p-[30px] sm:px-0 bg-white sm:max-w-[900px] mx-auto">
          <div className="sm:w-[590px] sm:mx-auto">
            <p className="font-bold text-lg text-center sm:mb-[60px] mb-[30px]">ワンタイムコード確認</p>
            <div className="mb-[30px]">
              <p className="text-center mb-1">ご変更を希望されるメールアドレスに送信されたワンタイムコードを入力してください。</p>
            </div>
            <div className="mb-[30px]">
              <div className="flex items-center mb-1">
                <label htmlFor="conveni_tel" className="inline-block text-sm">
                  ワンタイムコード
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <TextField
                name="onetime_code"
                placeholder="ワンタイムコードを入力してください"
                id="onetime_code"
                rules={{
                  required: true,
                  pattern: /^\d{6}$/
                }}
                control={control}
                patternErrorMessage="6桁の半角数字で入力してください"
              />
            </div>
            {errorMessage !== "" && (
              <div className="text-center text-red-500 mb-[30px]">
                {errorMessage}
              </div>
            )}
            {message !== "" && (
              <div className="text-center mb-[30px]">
                {message}
              </div>
            )}
            <div className="md:flex md:flex-row-reverse items-center justify-center mt-3">
              <div className="w-[300px] mt-3 md:mr-3 h-[40px]">
                <BaseButton
                  text="ワンタイムコードを確認する"
                  fontSize="text-xs"
                  isSubmit={true}
                  onClick={() => confirmOnetimeCode()}
                />
              </div>
              <div className="w-[300px] mt-3 md:mr-3 h-[40px]">
                <SecondaryButton
                  text="ワンタイムコードを再送信する"
                  onClick={() => resendOnetimeCode()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <button
            type="button"
            onClick={() => toggleModal()}
            className="icon-close left-icon w-[180px] h-[40px] flex items-center justify-center font-raleway bg-white rounded-default opacity-80"
          >
            もどる
          </button>
        </div>
      </div>
    </>
  );
};

export default EmailConfirmationModal;
