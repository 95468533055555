import React from "react";

type Props = {
  showReferralMenu: string;
  showPostMenu: string;
  showQuestionnaireMenu: string;
  scrollToTarget: (elementId: string) => void;
}

const ProjectDetailHeaderMenu: React.FC<Props> = (props) => {
  const {showReferralMenu, showPostMenu, showQuestionnaireMenu, scrollToTarget} = props

  return (
    <ul className="sm:hidden h-[40px] flex items-center overflow-x-auto whitespace-nowrap bg-[#F4F6F8] mt-[50px] fixed z-50 w-full">
      <li className="px-2 border-[#D2DFE6] text-center border-r border-solid text-sm">
        <button onClick={() => scrollToTarget("overview")}>プロジェクト概要</button>
      </li>
      <li className="px-2 border-[#D2DFE6] text-center border-r border-solid text-sm">
        <button onClick={() => scrollToTarget("community")}>コミュニティ</button>
      </li>
      {showReferralMenu &&
        <li className="px-2 border-[#D2DFE6] text-center border-r border-solid text-sm">
          <button onClick={() => scrollToTarget("referral")}>紹介</button>
        </li>
      }
      {showPostMenu &&
        <li className="px-2 border-[#D2DFE6] text-center border-r border-solid text-sm">
          <button onClick={() => scrollToTarget("post")}>ポスト</button>
        </li>
      }
      {showQuestionnaireMenu &&
        <li className="px-2 border-[#D2DFE6] text-center border-r border-solid text-sm">
          <button onClick={() => scrollToTarget("questionnaire")}>アンケート</button>
        </li>
      }
    </ul>
  )
}

export default ProjectDetailHeaderMenu;
