import React from "react";
import RankingBody from "../components/Ranking/RankingBody";
import PrefectureItem from "../components/Ranking/PrefectureItem";
import "../../stylesheets/iconNumber.css"
import "../../stylesheets/ranking.scss"
import {
	ranker,
	clientRanking,
	total,
	prefectureInfo
} from "../types/pages/ranking"
import Layout from "../components/Common/Layout";

type Props = {
	total: total;
	rankers: ranker[];
	teams: clientRanking[];
	areatitle: string;
	prefsList: {[key: string]: prefectureInfo[]}[];
	allJapan: prefectureInfo;
	hokkaido: prefectureInfo;
	okinawa: prefectureInfo;
	areaClassName: {[key: string]: string}[];
}

const Ranking: React.FC<Props> = (props) => {
	const {
		total,
		rankers,
		teams,
		areatitle,
		prefsList,
		allJapan,
		hokkaido,
		okinawa,
		areaClassName,
	} = props;

	return (
		<Layout
			breadcrumbs={[
				{
					text: "HOME",
					href: "/",
				},
				{
					text: "スポチュニティ・ランキング",
				},
			]}
		>
			<section id="ranking" className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto md:px-0">
				<div className="flex flex-col justify-start py-3 w-full border-b border-gray-300 md:flex-row md:items-center md:py-5">
					<h1 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
						スポチュニティ・ランキング
					</h1>
				</div>
				<div className="flex py-5 maxsm:block flex-col sm:flex-row">
					<div className="modRanking__data maxsm:m-0 mr-10 w-full">
						<p className="mb-6 text-base leading-relaxed">
							{areatitle}からスポチュニティを通じて届けられた、支援金総額と支援者数は...
						</p>
						<dl className="mb-5">
							<dt className="mb-3 text-gray-700 text-base font-bold leading-none">支援金総額</dt>
							<dd className="text-gray-700 text-base font-bold">
								{Array.from(String(total.investment_sum_value)).map((investmentChar, index) => (
									<span key={index} className={`inline-block w-8 h-10 align-text-bottom icon icon--number${investmentChar}`}
										style={{marginRight: "1px"}}>
									</span>
								))}
								円
							</dd>
						</dl>
						<dl className="mb-5">
							<dt className="mb-3 text-gray-700 text-base font-bold leading-none">支援者数</dt>
							<dd className="text-gray-700 text-base font-bold">
								{Array.from(String(total.supporter_sum_value)).map((supporterChar, index) => (
									<span key={index} className={`inline-block w-8 h-10 align-text-bottom icon icon--number${supporterChar}`}
											style={{marginRight: "1px"}}>
									</span>
								))}
								人
							</dd>
						</dl>
						<p className="mb-6 text-base leading-relaxed">
							です。皆さまご支援ありがとうございます。引き続きクラブチームを、選手を、日本全国を元気にしていきましょう！
						</p>
					</div>

					<section className="modRanking__select">
						<header className="inline-block p-0 text-3xl leading-tight maxsm:hidden" style={{margin: "0 4px 16px 0"}}>
							<h1 className="text-xl font-bold after:content-[''] after:inline-block after:w-px after:h-6 after:ml-8 after:bg-gray-600 after:text-gray-600 after:align-middle">都道府県別ランキング</h1>
						</header>
						<ul className="accordionList">
							<PrefectureItem key={allJapan.name} innerItem={false} data={allJapan.data} current={allJapan.current} prefectureName={allJapan.name}/>
							<PrefectureItem key={hokkaido.name} innerItem={false} data={hokkaido.data} current={hokkaido.current} prefectureName={hokkaido.name}/>
							{Object.keys(prefsList).map((area) => {
								const areaPrefs = prefsList[area];
								return (
									<li key={area} className={"accordionList__item"+areaClassName[area]}>
										<input type="checkbox" className="button button--accordion" aria-hidden="true"></input>
										<span className="button--accordion__label" aria-hidden="true">{area}</span>
										<ul className="accordionList__inner">
											{Object.keys(areaPrefs).map((index) => {
												const pref = areaPrefs[index];
												return (
													<PrefectureItem key={pref.name} innerItem={true} data={pref.data} current={pref.current} prefectureName={pref.name}/>
												)
											})}
										</ul>
									</li>
								)
							})}
							<PrefectureItem key={okinawa.name} innerItem={false} data={okinawa.data} current={okinawa.current} prefectureName={okinawa.name}/>
						</ul>
					</section>
				</div>
				
				<div className="flex flex-col justify-start py-3 w-full bg-gray-200 md:flex-row md:items-center md:py-5 maxsm:hidden bg-[#D2DFE6]">
					<h1 className="font-bold pl-5 text-xl md:text-base">
							{areatitle}
					</h1>
				</div>
				<RankingBody rankers={rankers} teams={teams} />
				<p className="before:content-['※'] text-base block clear-both" 
					style={{padding: "0 20px 20px 20px"}}>
					ランキングは10分毎に更新されます
				</p>
			</section>
		</Layout>
	)
}

export default Ranking;
