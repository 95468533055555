import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BaseButton from "../../components/Common/Buttons/BaseButton";
import Radio from "../../components/Common/Form/Radio"
import TextField from "../../components/Common/Form/TextField";
import SelectBox from "../../components/Common/Form/SelectBox";
import Counter from "../../components/EcWebsettlement/Counter";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import { useRadio } from "../../hooks/Common/useRadio"
import SecondaryButton from "../../components/Common/Buttons/SecondaryButton";
import { EcReturn, EcReturnSku, ProjectInformation, PurchaseEcReturnFormValues } from "../../types/pages/EcWebsettlement/purchase_ec_return";
import { QUESTION_TYPE } from "../../utils/constants"
import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import LinkifyText from "../../components/Common/LinkifyText";

type Props = {
  ecReturn: EcReturn,
  projectInformation: ProjectInformation,
  control: Control<PurchaseEcReturnFormValues, any>,
  setValue: any,
  getValues: UseFormGetValues<PurchaseEcReturnFormValues>,
  watch: UseFormWatch<PurchaseEcReturnFormValues>,
  totalAmount: () => number,
  priceByAmount: () => number,
  onCancel: () => void,
  monthly_destination_value: number | null,
  commission: number,
  is_draft?: boolean
}

const SelectReturn = ({
  ecReturn,
  projectInformation,
  control,
  setValue,
  getValues,
  watch,
  totalAmount,
  priceByAmount,
  onCancel,
  monthly_destination_value,
  commission,
  is_draft
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showMoreBtn, setShowMoreBtn] = useState<boolean>(false);
  const {
    isActive: isQuestionTypeSelectActive,
    onChange: onQuestionTypeSelectChange
  } = useRadio(false);
  const { isActive: isSkuActive, onChange: onSkuChange } = useRadio(false);
  const ecReturnContentRef = useRef(null);
  const [nowAnswers, setNowAnswers] = useState(getValues("answers"));

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const ecReturnSlider = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          arrows: true,
        },
      },
    ],
  };

  const additionalAmountOptions = [
    "0",
    "500",
    "1,000",
    "1,500",
    "2,000",
    "2,500",
    "3,000",
    "3,500",
    "4,000",
    "4,500",
    "5,000",
    "5,500",
    "6,000",
    "6,500",
    "7,000",
    "7,500",
    "8,000",
    "8,500",
    "9,000",
    "9,500",
    "10,000",
    "20,000",
    "30,000",
    "40,000",
    "50,000",
    "60,000",
    "70,000",
    "80,000",
    "90,000",
    "100,000",
    "200,000",
    "300,000",
    "400,000",
    "500,000",
    "600,000",
    "700,000",
    "800,000",
    "900,000",
    "1,000,000",
    "2,000,000",
    "3,000,000",
    "4,000,000",
    "5,000,000",
    "6,000,000",
    "7,000,000",
    "8,000,000",
    "9,000,000"
  ]

  const additionalAmountValues = [
    0,
    500,
    1000,
    1500,
    2000,
    2500,
    3000,
    3500,
    4000,
    4500,
    5000,
    5500,
    6000,
    6500,
    7000,
    7500,
    8000,
    8500,
    9000,
    9500,
    10000,
    20000,
    30000,
    40000,
    50000,
    60000,
    70000,
    80000,
    90000,
    100000,
    200000,
    300000,
    400000,
    500000,
    600000,
    700000,
    800000,
    900000,
    1000000,
    2000000,
    3000000,
    4000000,
    5000000,
    6000000,
    7000000,
    8000000,
    9000000
  ]

  useEffect(() => {
    if (ecReturnContentRef.current.offsetHeight > 72) {
      setShowMoreBtn(true)
      ecReturnContentRef.current.classList.add("line-clamp-3")
    }
  }, [])

  const toggleShowMoreBtn = () => {
    setIsOpen(!isOpen)
    ecReturnContentRef.current.classList.toggle("line-clamp-3")
  }

  const checkedSku = (): EcReturnSku => {
    return ecReturn.ec_return_skus
      .filter((sku) => sku.id === getValues("checked_sku_id"))
      .shift();
  };

  return (
    <>
      <div className="mb-[30px] flex">
        <div className="h-[40px] bg-[#1F2122] w-[61%] relative flex items-center justify-center text-white text-sm font-bold">
          <span className="text-[#1F2122] bg-[#768084] rounded-[50%] inline-block mr-1 w-5 h-5 text-center">
            1
          </span>
          支援内容の入力
          <div className="right-triangle-thin right-[-7px] absolute"></div>
        </div>
        <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
          2
        </div>
        <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
          3
        </div>
        <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
          4
        </div>
      </div>
      <div className="mb-5">
        <Slider {...ecReturnSlider}>
          {ecReturn.investment_image_urls.map((imageUrl, index) => (
            <div key={index}>
              <img src={imageUrl} alt={ecReturn.investment_title} />
            </div>
          ))}
        </Slider>
      </div>
      <p className="text-lg mb-5 font-bold">{ecReturn.name}</p>
      <div className="pb-[15px] border-[#D2DFE6] border-b">
        <div ref={ecReturnContentRef}>
          <LinkifyText text={ecReturn.content} />
        </div>
        <button
          className={
            `${showMoreBtn ? "block" : "hidden"}
            ${isOpen
              ? "w-full right-icon icon-arrow-top flex items-center justify-center mt-5 font-raleway after:ml-2 after:mt-1"
              : "w-full icon-arrow-under right-icon flex items-center justify-center mt-5 font-raleway after:ml-2 after:mb-1"}`
          }
          onClick={() => toggleShowMoreBtn()}
          type="button"
        >
          {isOpen ? "Close" : "More"}
        </button>
      </div>
      <div className="border-[#D2DFE6] py-5 border-b sm:flex sm:justify-between">
        <dl className="flex justify-between items-center sm:after:content-[''] sm:after:rotate-[30deg] sm:after:w-[1px] sm:after:h-full sm:after:bg-[#768084] sm:w-[30%]">
          <dt className="text-[#768084] text-sm icon-human left-icon sm:mr-[10px]">支援数</dt>
          <dd>{ecReturn.support_count}件</dd>
        </dl>
        <dl className="flex justify-between items-center sm:after:content-[''] sm:after:rotate-[30deg] sm:after:w-[1px] sm:after:h-full sm:after:bg-[#768084] sm:w-[30%]">
          <dt className="text-[#768084] text-sm icon-stock left-icon sm:mr-[10px]">在庫数</dt>
          <dd>{ecReturn.actual_remaining_number !== null ? `${ecReturn.actual_remaining_number}個` : "余裕あり"}</dd>
        </dl>
        <p className="text-[#FF0000] icon-gift-large left-icon">{ecReturn.is_subscription ? "月額課金中は継続してご提供" : `${ecReturn.schedule_text}に実施予定`}</p>
      </div>
      {ecReturn.ec_return_skus.length > 0 && (
        <div className="py-[40px] border-[#D2DFE6] border-b">
          <div className="mb-[40px] flex mb-2 items-center">
            <p className="font-bold">{ecReturn.sku_title}</p>
            <span className="bg-[#006AC9] text-xxs justify-center px-1 text-white ml-2">
              必須
            </span>
          </div>
          {ecReturn.ec_return_skus.map((ec_return_sku) => (
            <div
              key={ec_return_sku.id}
              className="first:mt-0 mt-6 sm:flex sm:justify-between sm:mt-4"
            >
              <Radio
                checked={watch("checked_sku_id") === ec_return_sku.id}
                id={`${ec_return_sku.id}`}
                value={ec_return_sku.sku_name}
                label={ec_return_sku.sku_name}
                name={"checked_sku_id"}
                control={control}
                disabled={ec_return_sku.remaining_number <= 0}
                onChange={(e) => {
                  onSkuChange(e);
                  setValue("checked_sku_id", Number(e.target.id) || null);
                }}
                isActive={isSkuActive}
              />
              <div className="flex items-center maxsm:mt-2">
                <span className="text-[#768084] icon-stock left-icon mr-[10px] text-sm">
                  在庫数
                </span>
                {ec_return_sku.remaining_number > 0 ? (
                  <span>{`${ec_return_sku.remaining_number}個`}</span>
                ) : (
                  <span className="text-[#FF0000] font-bold">売り切れ</span>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {ecReturn.ec_return_questions.slice(0,3).filter(question => question.question_type !== QUESTION_TYPE.NONE).length > 0 && (
        <div className="py-[40px] border-[#D2DFE6] border-b">
          <p className="mb-[40px] font-bold">リターンに関する質問</p>
          {ecReturn.ec_return_questions.slice(0,3).filter(question => question.question_type !== QUESTION_TYPE.NONE).map((question, questionIndex) => (
            <div key={questionIndex} className="mb-[30px]">
              <div className="mb-5">
                <div className="text-[#768084] mb-2 text-xs">質問{questionIndex+1}</div>
                <p>{question.question}</p>
              </div>
              <div className="flex mb-2 items-center">
                <label className="text-[#768084] text-xs" htmlFor="ec_questionnaire1">回答</label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              {question.question_type === QUESTION_TYPE.TEXT && (
                <TextField
                  id={`question-${questionIndex}`}
                  type="text"
                  placeholder="回答を入力してください"
                  name={`answers.${questionIndex}`}
                  control={control}
                  maxLengthErrorMessage="255文字以内で入力してください"
                  rules={{
                    required: true,
                    maxLength: 255,
                  }}
                  onChange={({ target: { value } }) =>
                    setValue(`answers.${questionIndex}`, value || "")
                  }
                />
              )}
              {question.question_type === QUESTION_TYPE.SELECT && (
                question.ec_return_question_question_answers.map((ans, ansIndex) => (
                  <div key={ansIndex} className="mt-4 first:mt-0">
                    <Radio
                      checked={watch(`answers.${questionIndex}`) === ans.answer}
                      id={`answer-${ansIndex}-of-${questionIndex}`}
                      value={ans.answer}
                      label={ans.answer}
                      name={`answers.${questionIndex}`}
                      control={control}
                      onChange={(e) => {
                        onQuestionTypeSelectChange(e);
                        const copyAnswers = nowAnswers.slice(0, nowAnswers.length);
                        copyAnswers[questionIndex] = e.target.value; 
                        setNowAnswers(copyAnswers);
                        setValue(`answers.${questionIndex}`, e.target.value || "")
                      }}
                      isActive={isQuestionTypeSelectActive}
                    />
                  </div>
                  )
                )
              )}
            </div>
          ))}
        </div>
      )}
      <div className="py-[40px]">
        <div className="flex items-center mb-5">
          <p className="font-bold" id="purchase-amount">リターンの購入金額(税込)</p>
          <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
            必須
          </span>
        </div>
        <div className="text-[34px] mb-5 text-right font-bold">
          {priceByAmount().toLocaleString()}
          <span className="ml-1 text-lg font-bold">円</span>
        </div>
        <div className="flex items-center justify-end">
          <div className="text-[#768084] mr-3 text-sm">口数</div>
          <Counter
            control={control}
            setValue={setValue}
            name="number"
            id="number"
            rules={{
              required: true,
              pattern: /^[1-9][0-9]{0,1}$|^100$/,
              max:
                ecReturn.ec_return_skus?.length > 0
                  ? checkedSku().remaining_number
                  : ecReturn.remaining_number
            }}
            maxNumber={
              ecReturn.ec_return_skus?.length > 0
                ? checkedSku().remaining_number
                : ecReturn.remaining_number
            }
          />
        </div>
        {!ecReturn.is_subscription && (
        <div className="bg-[#F4F6F8] mt-[40px] p-5">
          <p className="mb-5 text-sm font-medium">
            あなたの気持ちをリターン購入金額に上乗せしよう
          </p>
          <div className="mb-[10px] flex items-end justify-between">
            <SelectBox
              id="additional_amount"
              name="additional_amount"
              placeholder="上乗せ金額を選択してください"
              control={control}
              options={additionalAmountOptions}
              values={additionalAmountValues}
            />
            <span className="font-medium ml-1">円</span>
          </div>
          <p className="text-[#768084] text-xs">
            ※500円以上の支援をお願いします
          </p>
        </div>
        )}
      </div>
      <div className="pb-[40px] border-[#D2DFE6] border-b">
        <p className="font-bold">合計の支援金額(税込)</p>
        <div className="text-[34px] mb-[10px] text-right font-bold">
          {totalAmount().toLocaleString()}
          <span className="text-lg">円</span>
        </div>
        {ecReturn.is_subscription ?
          (
            <>
              <p className="text-[#768084] text-sm mb-1">内訳:</p>
              <div className="flex items-end flex-col">
                <div className="text-xs mb-1">
                  月額課金リターンの購入金額:{`${priceByAmount().toLocaleString()}円(${getValues("number")}口×${ecReturn.price.toLocaleString()}円)`}
                </div>
                <div className="text-xs mb-1">
                  プラットフォーム利用料:{commission}円
                </div>
              </div>
              {!!monthly_destination_value && (
                <>
                  <p className="text-accent text-xs flex items-center justify-end mb-1">
                    現在の支援コース（月額）{monthly_destination_value.toLocaleString()}円
                  </p>
                  <p className="text-accent text-xs flex items-center justify-end">
                    次請求以降の個数の変更ができます。
                  </p>
                </>
              )}
            </>
          )
          :
          (
            <>
              <p className="text-[#768084] text-sm mb-1">内訳:</p>
              <div className="flex items-end flex-col">
                <div className="text-xs mb-1">
                  リターンの購入金額: {priceByAmount().toLocaleString()}円
                </div>
                <div className="text-xs mb-1">
                  上乗せ支援:{Number(watch("additional_amount")).toLocaleString()}円
                </div>
                <div className="text-xs">
                  プラットフォーム利用料:{commission}円
                </div>
              </div>
            </>
          )
        }
      </div>
      <div className="pt-[40px]">
          <div className="sm:mb-[60px] mb-10">
            <p className="font-bold mb-6">プラットフォーム利用料とは</p>
            <p className="mb-[10px]">クラウドファンディングのプロジェクトの支援時にプラットフォーム利用料をいただき、その一部を『スポチュニティ基金』として社会貢献活動を行う団体へ寄付すると共に、安心安全にクラウドファンディングが利用できるシステム環境の提供や運営のために利用いたします。</p>
            <p>詳しくは
              <a href="https://corp.spportunity.com/platform-fee/" target="_blank" rel="noopener noreferrer" className="underline link-hover-none">
                こちら
              </a>
            </p>
          </div>
        {!ecReturn.is_subscription && (
          <div className="sm:mb-[60px] mb-10">
            <p className="font-bold mb-6">リターンについて</p>
            <p>リターンはご支援いただいた方、全員へお渡しいたします。</p>
            <p>リターンが配布されるのは必ずしも購入直後ではありませんのでご注意下さい。</p>
            <p>スポチュニティはプロジェクトオーナーのプロジェクト実行能力を保証するものではありません。プロジェクトの実行やリターンの発送（履行）などは、全てプロジェクトオーナーの責任のもと行われます。</p>
          </div>
        )}
        <div className="mb-10 p-5 bg-[#F4F6F8]">
          <p className="font-bold mb-2">リターンに関するお問い合わせ先</p>
          <a href={`mailto:${projectInformation.owner_email}`} className="link-hover-none underline">{projectInformation.owner_email}</a>
        </div>
        <p className="text-[#768084] mb-[40px] text-xs">
          リターンの購入にあたり、
          <a
            href="https://corp.spportunity.com/term_of_use/"
            className="link-hover-none underline"
          >
            スポチュニティのサービス利用規約
          </a>
          および
          <a
            href="https://corp.spportunity.com/privacy_policy/"
            className="link-hover-none underline"
          >
            プライバシーポリシー
          </a>
          に同意したものとみなされます 。
        </p>
        {ecReturn.is_subscription && (
          <div className="text-[#FF0000] bg-[#F4F6F8] p-5 sm:mb-[60px] mb-10">
            <p>※選択された支援コースの金額は月額で自動課金されます。</p>
            <p>※選択された支援コースは、後から変更・キャンセル(解約)できます。</p>
          </div>
        )}
        <div className="mb-[60px] sm:mx-auto h-[50px] sm:w-[290px]">
          <BaseButton
            text="お支払い情報の入力へ進む"
            fontSize="text-sm"
            isSubmit={true}
            disabled={is_draft}
          />
        </div>
        {ecReturn.is_subscription && !!monthly_destination_value && (
          <>
            <ConfirmationModal
              isActive={isModalOpen}
              toggleModal={toggleModal}
              confirmText="OK"
              onSubmit={onCancel}
            >
              <div className="sm:text-left">
                <p>現在の支援コースをキャンセル(解約)しますか？</p>
              </div>        
            </ConfirmationModal>
            <div className="mt-10 sm:w-[315px] h-[60px] sm:mx-auto mb-10">
              <SecondaryButton
                text={`現在の支援コースをキャンセル(解約)する`}
                onClick={() => setIsModalOpen(true)}
              />
            </div>
          </>
        )}
        <div className="text-center">
          <a
            href={is_draft ? `${projectInformation.ec_return_path}${window.location.search}` : projectInformation.ec_return_path}
            className="left-icon icon-stick-arrow-left text-sm font-medium"
          >
            リターン詳細ページに戻る
          </a>
        </div>
        <div className="text-center mt-10">
          <a
            href={is_draft ? `${projectInformation.investment_path}${window.location.search}` : projectInformation.investment_path}
            className="left-icon icon-stick-arrow-left text-sm font-medium"
          >
            プロジェクトの詳細ページに戻る
          </a>
        </div>
      </div>
    </>
  );
};

export default SelectReturn;
