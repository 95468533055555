import React, { ElementType, FC } from "react";

type HeadingTextProps = {
  headingElement: number;
  children: React.ReactNode;
  className?: string;
};

const HeadingText: FC<HeadingTextProps> = ({ headingElement, children, className }) => {
  const Heading = `h${headingElement}` as ElementType;

  return <Heading className={className}>{children}</Heading>;
};
export default HeadingText;
