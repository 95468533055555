export const isCareerAddress = (email) => {
  const careerDomains = [
    "ezweb.ne.jp",
    "ido.ne.jp",
    "biz.ezweb.ne.jp",
    "augps.ezweb.ne.jp",
    "uqmobile.jp",
    "docomo.ne.jp",
    "mopera.net",
    "dwmail.jp",
    "disney.ne.jp",
    "i.softbank.jp",
    "softbank.ne.jp",
    "vodafone.ne.jp",
    "rakuten.jp"
  ]
  return careerDomains.includes(email.substring(email.indexOf('@')+1));
}