import React from "react";
import LineShareButton from "../../../images/line_share_btn.png";
import IconFacebook from "../../../images/icons/icon_facebook.png";
import IconTwitter from "../../../images/icons/icon_twitter.png";
import IconYoutube from "../../../images/icons/icon_youtube.png";
import IconInstagram from "../../../images/icons/icon_instagram.png"
type Props = {};

const SnsShareIcons: React.FC<Props> = (props) => {
  return (
    <div className="flex items-center">
      <div className="w-[25px] h-[25px]">
        <a href="https://www.facebook.com/spportunity" className="link-hover-none inline-block" target="_blank">
          <img src={IconFacebook} alt="facebook" />
        </a>
      </div>
      <div className="w-[24px] h-[25px] ml-5">
        <a href="https://twitter.com/Spportunity_JP" className="link-hover-none inline-block" target="_blank">
          <img src={IconTwitter} alt="twitter" />
        </a>
      </div>
      <div className="w-[24px] h-[25px] ml-5">
        <a href="https://www.instagram.com/spportunity/" className="link-hover-none inline-block" target="_blank">
          <img src={IconInstagram} alt="instagram" />
        </a>
      </div>
      <div className="w-[25px] h-[25px] ml-5">
        <a href="https://www.youtube.com/@SpportunityOfficial" className="link-hover-none inline-block" target="_blank">
          <img src={IconYoutube} alt="youtube" />
        </a>
      </div>
      <div className="btnLine ml-5 h-5">
        <a
          href="https://line.me/R/msg/text/?その応援がスポーツの未来を創る"
          className="link-hover-none w-[82px] inline-block"
        >
          <img src={LineShareButton} alt="" />
        </a>
      </div>
      <div className="btnHatena ml-[10px]">
        <a
          href={`http://b.hatena.ne.jp/entry/${location.href}`}
          className="hatena-bookmark-button"
          data-hatena-bookmark-title="mytitle"
          data-hatena-bookmark-layout="simple-balloon"
          title="このエントリーをはてなブックマークに追加"
        >
          <img
            src="https://b.st-hatena.com/images/entry-button/button-only@2x.png"
            alt="このエントリーをはてなブックマークに追加"
            width="20"
            height="20"
            style={{ border: "none" }}
          />
        </a>
      </div>
    </div>
  );
};

export default SnsShareIcons;
