import React, { useState } from "react";
import {
  DestinationDetail,
  Investment
} from "../../../types/pages/Mypage/payment_details";
import { FormatCurrency } from "../../../utils/TextUtil";
import BaseLinkButton from "../../Common/Buttons/BaseLinkButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import SecondaryLinkButton from "../../Common/Buttons/SecondaryLinkButton";
import ResendNotificationModal from "./ResendNotificationModal";
import { HttpRequest } from "../../../utils/common";
import PaymentDetailItem from './PaymentDetailItem';

type Props = {
  payment_detail: DestinationDetail;
  investment: Investment;
};

const PaymentDetailCard: React.FC<Props> = (props) => {
  const { payment_detail, investment } = props;
  const purchase_history = payment_detail.purchase_history;
  const is_scheduled_subscription_detail = !payment_detail.is_fixed_detail;
  const [isResendNotificationModalActive, setIsResendNotificationModalActive] =
    useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const resendNotification = (resendNotificationPath: string) => {
    setIsSubmitting(true);
    HttpRequest.put(resendNotificationPath)
      .then((res) => {
        setResponseMessage(res.data.message);
        toggleResendNotificationModal();
      })
      .catch((e) => {
        if (!e.response.data?.errors?.message) {
          setResponseMessage("不明なエラーが発生しました");
        } else {
          setResponseMessage(e.response.data.errors.message);
        }
        toggleResendNotificationModal();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const toggleResendNotificationModal = () => {
    setIsResendNotificationModalActive(!isResendNotificationModalActive);
  };

  return (
    <>
      <section className="mb-10">
        <div className="bg-[#D2DFE6] px-5 py-4 md:flex md:justify-between items-center">
          <div className="md:flex md:items-center">
            <div className="mb-[10px] md:mr-[10px] md:mb-0">
              <dl className="text-[#768084] flex mb-1 text-xs">
                <dt>支援日：</dt>
                <dd>{payment_detail.supported_at}</dd>
              </dl>
              {payment_detail.is_fixed_detail ? (
                <dl className="text-[#768084] flex text-xs">
                  <dt>決済日：</dt>
                  {payment_detail.is_waiting_payment ? (
                    <dd className="text-[#FF0000] sm:font-bold">
                      {payment_detail.payment_service_str.slice(0, -2)}未払い
                    </dd>
                  ) : (
                    <dd>{payment_detail.paid_at}</dd>
                  )}
                </dl>
              ) : (
                <dl className="flex text-xs font-bold">
                  <dt>次の決済日：</dt>
                  <dd>
                    {payment_detail.subscription_payment_date_schedule}決済予定
                  </dd>
                </dl>
              )}
            </div>
            <div className="mb-[10px] md:mr-[10px] flex md:mb-0">
              <div className="w-full bg-[#768084] h-[40px] leading-[40px] md:w-[150px] md:mr-[10px] mr-1 text-center text-white text-xs">
                {payment_detail.payment_service_str}
              </div>
              {(payment_detail.is_monthly_donation ||
                payment_detail.purchase_history.is_subscription_ec_return) && (
                <div className="bg-[#006AC9] w-[60px] h-[40px] leading-[40px] md:w-[40px] text-center text-white text-xs">
                  月額
                </div>
              )}
            </div>
            <div className="md:max-w-[380px]">
              <dl className="flex items-center">
                <dt className="text-[#768084] text-xs">
                  決済金額：
                  {(payment_detail.is_monthly_donation ||
                    payment_detail.purchase_history
                      .is_subscription_ec_return) && (
                    <span className="text-xs font-normal mr-1">
                      {payment_detail.subscription_month}
                    </span>
                  )}
                </dt>
                <dd className="text-sm font-bold">
                  {FormatCurrency(payment_detail.settlement_amount)}円
                </dd>
              </dl>
              <dl className="text-[#768084] mb-[10px] flex text-xs md:mb-0 flex-col">
                <dt className="mr-1">内訳</dt>
                {payment_detail.is_monthly_donation ? (
                  <dd>
                    <ul>
                      <li>・{FormatCurrency(payment_detail.amount)} 円</li>
                      <li>
                        {payment_detail.commission > 0 &&
                        `・プラットフォーム利用料 ${FormatCurrency(
                          payment_detail.commission
                        )} 円`}
                      </li>
                    </ul>
                  </dd>
                ) : (
                  payment_detail.is_tipping ? (
                    <dd>
                      <ul>
                        <li>・{FormatCurrency(payment_detail.amount)} 円</li>
                      </ul>
                    </dd>
                  ) : (
                    <dd>
                      <ul>
                        <li>・リターン購入金額合計 {FormatCurrency(payment_detail.amount - payment_detail.additional_support)} 円
                        </li>
                        <li>
                          {payment_detail.additional_support > 0 &&
                          `・上乗せ支援 ${FormatCurrency(
                            payment_detail.additional_support
                          )} 円`}
                        </li>
                        <li>
                          {payment_detail.commission > 0 &&
                          `・プラットフォーム利用料 ${FormatCurrency(
                            payment_detail.commission
                          )} 円`}
                        </li>  
                      </ul>
                    </dd>
                  )
                )}
              </dl>
            </div>
          </div>
          {payment_detail.is_fixed_detail && (
            <div className="flex justify-between">
              <div className="w-[32%] h-[30px] md:w-[80px] md:h-[40px] md:mr-1">
                <SecondaryLinkButton
                  text="領収書"
                  fontSize="text-xs"
                  link={payment_detail.receipt_path}
                  disabled={!payment_detail.receipt_path}
                />
              </div>
              <div className="w-[32%] h-[30px] md:w-[80px] md:h-[40px] md:mr-1">
                <SecondaryButton
                  text="通知再送"
                  fontSize="text-xs"
                  color="text-[#1F2122]"
                  disabled={
                    isSubmitting || !payment_detail.resend_notification_path
                  }
                  onClick={(e) => {
                    resendNotification(payment_detail.resend_notification_path);
                  }}
                />
              </div>
              <div className="w-[32%] h-[30px] md:w-[80px] md:h-[40px]">
                <SecondaryLinkButton
                  text="振込先情報"
                  fontSize="text-xs"
                  link={payment_detail.payment_info_path}
                  disabled={!payment_detail.payment_info_path}
                />
              </div>
            </div>
          )}
        </div>
        {payment_detail.has_ec_return ? (
          payment_detail.purchase_histories.map(purchase_history => (
            <PaymentDetailItem
              payment_detail={payment_detail}
              purchase_history={purchase_history}
              investment={investment}
              is_scheduled_subscription_detail={is_scheduled_subscription_detail}
            />
          ))
        ) : (
          <PaymentDetailItem
            payment_detail={payment_detail}
            purchase_history={purchase_history}
            investment={investment}
            is_scheduled_subscription_detail={is_scheduled_subscription_detail}
          />
        )}
      </section>
      {payment_detail.is_fixed_detail && (
        <ResendNotificationModal
          isActive={isResendNotificationModalActive}
          toggleModal={toggleResendNotificationModal}
          responseMessage={responseMessage}
        />
      )}
    </>
  );
};

export default PaymentDetailCard;
