import React, { useEffect } from "react";
import BaseLinkButton from "../../../../components/Common/Buttons/BaseLinkButton";
import SecondaryLinkButton from "../../../../components/Common/Buttons/SecondaryLinkButton";
import Layout from "../../../../components/Common/Layout";
import PaymentDetailCard from "../../../../components/Mypage/PaymentDetails/PaymentDetailCard";
import {
  DestinationDetail,
  Investment,
  Ranking,
  ReferralResults
} from "../../../../types/pages/Mypage/payment_details";
import { FormatCurrency } from "../../../../utils/TextUtil";

type Props = {
  investment: Investment;
  ranking: Ranking;
  referral_incentive_description?: string;
  referral_results: ReferralResults[];
  fixed_destination_details: DestinationDetail[];
  scheduled_subscription_returns: DestinationDetail[];
  scheduled_monthly_donations: DestinationDetail[];
};

const Index: React.FC<Props> = (props) => {
  const {
    investment,
    ranking,
    referral_incentive_description,
    referral_results,
    fixed_destination_details,
    scheduled_subscription_returns,
    scheduled_monthly_donations,
  } = props;

  useEffect(() => {
    const id = location.hash.split("#")[1]
    if (id) {
      const el = document.querySelector(`#el_${id}`)
      window.scrollTo({ top: el.getBoundingClientRect().top + window.pageYOffset - 90, behavior: "smooth"})
    }
  }, [])

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/"
        },
        {
          text: "マイページ",
          href: "/mypage"
        },
        {
          text: "支援履歴",
          href: "/mypage/support_history"
        },
        {
          text: "支払い明細"
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          支払い明細
        </h2>
        <section className="mb-[60px]">
          <div>
            {investment.is_active === false && (
              <div className="bg-[#1F2122] w-[158px] sm:w-[145px] mb-1 h-5 text-center text-white text-xs leading-5">
                終了
              </div>
            )}
            <div className="sm:flex sm:items-center">
              <div className="sm:mr-5">
                <div className="aspect-3/4 mb-[15px] sm:w-[230px] w-full sm:mb-0">
                  <a href={investment.investment_path}>
                    <img src={investment.image_url} alt="" />
                  </a>
                </div>
              </div>
              <div className="border-[#D2DFE6] sm:h-[172px] sm:max-w-[460px] sm:border-[#D2DFE6] pb-10 border-b sm:flex sm:flex-col sm:justify-center sm:pb-0 sm:pr-5 sm:border-b-0 sm:border-r">
                <div className="text-[#768084] mb-[10px] text-sm">
                  {investment.client_name}
                </div>
                <h3 className="mb-[10px] mb-5 underline text-lg font-bold sm:mb-5">
                  <a
                    href={investment.investment_path}
                    className="link-hover-none"
                  >
                    {investment.investment_name}
                  </a>
                </h3>
                <div className="text-[#768084] text-xs">
                  プロジェクト終了日:{investment.end_date}
                </div>
              </div>
              <div className="mt-10 sm:mt-0 sm:pl-5 sm:text-center">
                <h2 className="mb-[10px] font-medium">あなたの支援金累計額</h2>
                <div className="mb-[10px] sm:text-[30px] text-xl text-xl font-bold">
                  {FormatCurrency(ranking.investment_sum_value_paid_status)}円
                </div>
                <p className="text-[#768084] text-xs">
                  {`(未入金分含む${FormatCurrency(
                    ranking.investment_sum_value_paid_status +
                      ranking.investment_sum_value_wait_status
                  )}円 ${ranking.ranking}位/${ranking.ranking_size}人中)`}
                </p>
              </div>
            </div>
          </div>
          {referral_results.length > 0 && (
            <div className="border-[#D2DFE6] mt-10 pb-10 pt-10 border-b border-t">
              <div className="mb-8">
                <h2 className="mb-[10px] mb-7 text-lg font-bold sm:mb-5">
                  あなたが紹介した支援者/総支援額
                </h2>
                {referral_results.map((r) => (
                  <div className="mb-2">
                    {r.account_name} / {FormatCurrency(r.amount)}円
                  </div>
                ))}
              </div>
              <div>
                <h2 className="mb-[10px] mb-5 text-lg font-bold sm:mb-5">
                  あなたが紹介によってGETできるもの
                </h2>
                <div className="mb-2">
                  {referral_incentive_description}
                </div>
              </div>
            </div>
          )}
          <div className="mt-[100px]">
            <div className="mb-5">
              {fixed_destination_details.map((fixed_destination_detail) => (
                <div id={`el_${String(fixed_destination_detail.id)}`}>
                  <PaymentDetailCard
                    key={fixed_destination_detail.id}
                    payment_detail={fixed_destination_detail}
                    investment={investment}
                  />
                </div>
              ))}
            </div>
            <div className="h-[50px] sm:w-[400px] mb-10 w-full sm:mx-auto">
              <BaseLinkButton
                text="リターンの変更・追加支援"
                link={investment.investment_path}
                fontSize="text-sm"
              />
            </div>
            {scheduled_subscription_returns.map((scheduled_subscription) => (
              <div id={`el_${String(scheduled_subscription.id)}`}>
                <PaymentDetailCard
                  key={scheduled_subscription.id}
                  payment_detail={scheduled_subscription}
                  investment={investment}
                  />
              </div>
            ))}
            {scheduled_monthly_donations.map((scheduled_monthly_donation) => (
              <div id={`el_${String(scheduled_monthly_donation.id)}`}>
                <PaymentDetailCard
                  key={scheduled_monthly_donation.id}
                  payment_detail={scheduled_monthly_donation}
                  investment={investment}
                />
              </div>
            ))}
          </div>
        </section>
        <div className="h-[50px] sm:w-[400px] sm:mx-auto">
          <SecondaryLinkButton
            text="支援履歴一覧に戻る"
            link="/mypage/support_history"
            fontSize="text-sm"
            iconLeftEdge="icon-stick-arrow-left"
          />
        </div>
        <div className="mt-[60px] text-center">
          <a
            href="/mypage"
            className="left-icon icon-stick-arrow-left text-sm font-medium"
          >
            マイページトップ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
