import React from "react";
import Modal from "../Common/Modal";
import Noimg from "../../../images/noimg.png"

type Props = {
  toggleModal: () => void;
  isActive: boolean;
}

const URLCopyModal: React.FC<Props> = (props) => {
  const { toggleModal, isActive } = props

  return (
    <Modal
      toggleModal={toggleModal}
      isActive={isActive}
    >
      <div className="text-center">
        <p className="font-bold sm:mb-10 text-lg mb-[30px]">URLコピー</p>
        <p className="sm:mb-10 mb-5">コピーしました。</p>
        <div className="w-[143px] h-[110px] mx-auto">
          <img src={Noimg} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default URLCopyModal;
