import IconNew from "../../../../images/icon_new.png";
import React from "react";
import classNames from "classnames";

type Props = {
  clientName: string;
  investmentName: string;
  path: string;
  date: string;
  className?: string;
};

const HeaderNewsBar: React.FC<Props> = (props) => {
  const { className, clientName, investmentName, path, date } = props;
  return (
    <div
      className={classNames(
        className ?? "",
        "group flex items-center justify-center h-20 text-sm border-b border-gray-300 cursor-pointer md:h-12"
      )}
      onClick={() => {
        location.href = path;
      }}
    >
      <div className="flex flex-row items-center px-3 w-full h-full">
        <div className="flex items-center h-full md:pl-3">
          <span
            style={{
              width: "20px",
              height: "16px",
              backgroundImage: `url(${IconNew})`,
              backgroundSize: "20px auto",
            }}
          />
        </div>
        <div className="flex flex-col pl-2 w-full md:flex-row md:pl-3">
          <div className="line-clamp-2 md:line-clamp-1 group-hover:underline md:w-4/5">
            <span className="text-blue-500">{clientName}</span>の『
            <span className="text-blue-500 font-semibold">
              {investmentName}
            </span>
            』が開始されました。
          </div>
          <div className="text-gray-500">{date}</div>
        </div>
      </div>
    </div>
  );
};

export default HeaderNewsBar;
