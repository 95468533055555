import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import BaseButton from "../../../../components/Common/Buttons/BaseButton";
import SecondaryLinkButton from "../../../../components/Common/Buttons/SecondaryLinkButton";
import Layout from "../../../../components/Common/Layout";
import TextField from "../../../../components/Common/Form/TextField";
import { FormatCurrency } from "../../../../utils/TextUtil";

type PurchaseHistoryData = {
  ec_return_name: string;
  sku_name: string | null;
};

type Props = {
  authenticity_token: string;
  client_name: string;
  investment_name: string;
  purchase_histories_data: PurchaseHistoryData[];
  settlement_amount: number;
  paid_at: string;
  show_receipt_path: string;
  payment_details_path: string;
};

const Receipt: React.FC<Props> = (props) => {
  const {
    authenticity_token,
    client_name,
    investment_name,
    purchase_histories_data,
    settlement_amount,
    paid_at,
    show_receipt_path,
    payment_details_path
  } = props;

  interface IFormInput {
    name: string;
  }

  const { handleSubmit, control } = useForm<IFormInput>({
    mode: "onChange",
    defaultValues: {
      name: ""
    }
  });

  const formRef = useRef(null);

  const onSubmit = () => {
    formRef.current.submit();
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/"
        },
        {
          text: "マイページ",
          href: "/mypage"
        },
        {
          text: "支援履歴",
          href: "/mypage/support_history"
        },
        {
          text: "支払い明細",
          href: payment_details_path
        },
        {
          text: "領収書"
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-10 sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          支払い明細 - 領収書 -
        </h2>
        <div className="bg-[#F4F6F8] mb-[40px] sm:w-[700px] p-5 sm:mx-auto">
          <dl className="mb-5">
            <dt className="text-[#768084] text-xs">チーム名</dt>
            <dd>{client_name}</dd>
          </dl>
          <dl className="mb-5">
            <dt className="text-[#768084] text-xs">プロジェクト名</dt>
            <dd>{investment_name}</dd>
          </dl>
          {purchase_histories_data.map((ph, index) => (
            <dl className="mb-5" key={index}>
              <dt className="text-[#768084] text-xs">リターン名 {index + 1}</dt>
              <dd>
                {ph.ec_return_name}
                {ph.sku_name && (
                  <span className="ml-2">{ph.sku_name}</span>
                )}
              </dd>
            </dl>
          ))}
          <dl className="mb-5">
            <dt className="text-[#768084] text-xs">金額</dt>
            <dd>{FormatCurrency(settlement_amount)}円</dd>
          </dl>
          <dl>
            <dt className="text-[#768084] text-xs">決済日</dt>
            <dd>{paid_at}</dd>
          </dl>
        </div>
        <form
          action={show_receipt_path}
          method="POST"
          target="_blank"
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
          className="mb-[50px] sm:w-[400px] sm:mb-[60px] sm:mx-auto"
        >
          <input type="hidden" name="authenticity_token" value={authenticity_token}/>
          <div className="flex items-center mb-1">
            <label
              htmlFor="password_old"
              className="inline-block text-sm font-medium"
            >
              領収書の宛名
            </label>
            <span className="bg-[#006AC9] text-xxs flex items-center justify-center ml-2 px-1 text-white">
              必須
            </span>
          </div>
          <TextField
            control={control}
            name="name"
            id="name"
            placeholder="入力してください"
            rules={{
              required: true
            }}
          />
          <div className="h-[50px] sm:w-[400px] mt-10 sm:mx-auto">
            <BaseButton
              text="領収書を表示する"
              fontSize="text-sm"
              isSubmit={true}
            />
          </div>
        </form>
        <div className="h-[50px] sm:w-[400px] sm:mx-auto">
          <SecondaryLinkButton
            iconLeftEdge="icon-stick-arrow-left"
            text="このプロジェクトの支援履歴に戻る"
            fontSize="text-sm"
            link={payment_details_path}
          />
        </div>
        <div className="mt-[60px] text-center">
          <a
            href="/mypage"
            className="left-icon icon-stick-arrow-left text-sm font-medium"
          >
            マイページトップ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Receipt;
