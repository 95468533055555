import React from "react";
import Noimg from "../../../../images/noimg.png";
import Layout from "../../../components/Common/Layout";

type Props = {};
const Updated: React.FC<Props> = (props) => {
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "標準のお届け先情報",
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          標準のお届け先情報
        </h2>
        <div className="sm:w-[700px] sm:mx-auto">
          <div className="mb-[45px] sm:mb-[60px] sm:text-center">
            <p>変更が完了しました。</p>
          </div>
          <div className="w-[143px] h-[110px] mx-auto">
            <img src={Noimg} alt="" />
          </div>
          <div className="mt-[60px] text-center">
            <a
              href="/mypage"
              className="left-icon icon-stick-arrow-left text-sm font-medium"
            >
              マイページトップ
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Updated;
