import React, { useState } from "react";
import { HttpRequest } from "../../../utils/common";
import { useForm } from "react-hook-form";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import Layout from "../../../components/Common/Layout";
import TextField from "../../../components/Common/Form/TextField";

type Props = {};

const Index: React.FC<Props> = () => {
  const [inputType, setInputType] = useState<string>("password")
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  interface IFormInput {
    current_password: string;
    password: string;
  }

  const { handleSubmit, control } = useForm<IFormInput>({
    mode: "onChange",
    defaultValues: {
      current_password: "",
      password: ""
    }
  });

  const onSubmit = async () => {
    await HttpRequest.post("/mypage/user_password", {
      account: {
        current_password: currentPassword,
        password: password
      },
    }).then((res) => {
      location.href = res.data.redirect_path;
    }).catch((e) => {
      setErrors(e.response.data.errors);
    });
  };

  const togglePasswordDisplay = (e) => {
    if (e.target.checked) {
      setInputType("text")
    } else {
      setInputType("password")
    }
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "パスワードの変更",
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          パスワードの変更
        </h2>
        <div className="sm:w-[700px] sm:mx-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-[30px]">
              <div className="flex items-center mb-1">
                <label htmlFor="current_password" className="inline-block text-sm">
                  現在のパスワード
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <TextField
                control={control}
                name="current_password"
                rules={{
                  required: true
                }}
                placeholder="パスワードを入力してください"
                id="current_password"
                autoComplete="current-password"
                type={inputType}
                onChange={({ target: { value } }) => setCurrentPassword(value)}
              />
            </div>
            <div>
              <div className="flex items-center mb-1">
                <label htmlFor="password" className="inline-block text-sm">
                  新しいパスワード
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <TextField
                control={control}
                name="password"
                rules={{
                  required: true,
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`˜~!@#$%^&*()_+-={}[\]¥|:;<>.?/])[A-Za-z\d`˜~!@#$%^&*()_+-={}[\]¥|:;<>.?/]{8,}$/
                }}
                placeholder="パスワードを入力してください"
                id="password"
                patternErrorMessage="半角英数字・記号を組み合わせた8文字以上の文字列にしてください"
                autoComplete="new-passowrd"
                type={inputType}
                onChange={({ target: { value } }) => setPassword(value)}
              />
              <div className="col-span-2 col-start-2 text-red-400">
                {errors["password"]}
              </div>
              <p className="text-[#768084] mt-[10px] text-xs">
                半角英数字・記号を組み合わせた8文字以上で設定できます。
              </p>
              <div className="mt-3">
                <input
                  onChange={(e) => togglePasswordDisplay(e)}
                  type="checkbox"
                  id="show_password"
                  className="password-checkbox before:content-[''] before:border-[#B1C2CC] relative before:inline-block before:w-4 before:h-4 before:bg-white before:border invisible before:visible"
                />
                <label htmlFor="show_password" className="ml-2 text-sm">
                  パスワードを表示
                </label>
              </div>
            </div>
            <div className="mt-[60px] sm:mx-auto h-[50px] sm:w-[250px]">
              <BaseButton
                text="上記の内容で変更する"
                isSubmit={true}
              />
            </div>
            <p className="text-[#FF0000] mt-3 mb-3 text-center text-xs">
              { errors["message"] }
            </p>
          </form>
          <div className="mt-[60px] text-center">
            <a href="/mypage" className="left-icon icon-stick-arrow-left text-sm">
              マイページトップ
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
