import React from "react";
import Noimg from "../../../../images/noimg.png";
import Layout from "../../../components/Common/Layout";

type Props = {};
const Confirm: React.FC<Props> = (props) => {
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "パスワードをお忘れですか？",
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] text-lg font-bold sm:text-2xl sm:mb-[60px]">
          パスワードをお忘れですか？
        </h2>
        <div className="mb-[45px] sm:mb-[60px] sm:text-center">
          <p>
            登録されているメールアドレスにパスワードのリセット方法についてご連絡しました。
          </p>
        </div>
        <div className="w-[143px] h-[110px] mx-auto">
          <img src={Noimg} alt="" />
        </div>
        <div className="mt-[60px] text-center">
          <a
            href="/"
            className="left-icon icon-stick-arrow-left text-sm font-medium"
          >
            トップページへ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Confirm;
