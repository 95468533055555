import React from "react";
import IntroductionImgSp from "../../../images/introduction_sp.png";
import IntroductionImgPc from "../../../images/introduction_pc.png";

const HowToShare: React.FC = () => {
  return (
    <section>
      <div className="mb-[50px]">
        <h2 className="mb-[30px] text-xl font-bold">紹介方法について</h2>
        <p>
          紹介用シェアボタンを活用して、お知り合いの方を支援者としてご紹介いただくと、抽選で特別プレゼントがあります。ぜひご紹介ください！
        </p>
      </div>
      <div>
        <div className="mb-[30px] font-bold">紹介STEP</div>
        <div className="flex items-center justify-center mb-5 py-5 bg-white">
          <div className="w-[74.6vw] h-[48.5vw] sm:hidden">
            <img src={IntroductionImgSp} alt="紹介方法" className="object-contain" />
          </div>
          <div className="w-[510px] h-[180px] hidden sm:block">
            <img src={IntroductionImgPc} alt="紹介方法" />
          </div>
        </div>
        <ol className="sm:flex sm:justify-between">
          <li className="mb-6 sm:w-[30%]">
            <div className="sm:text-xs mb-[10px] after:left-[14px] after:content-['1'] h-[40px] border-[#1F2122] before:trapezoid before:content-[''] after:absolute relative flex before:inline-block items-center before:mr-3 after:text-white bg-white border">
              シェア
            </div>
            <p className="sm:text-sm">
              プロジェクトページ「紹介」タブのFacebook、Twitter、Lineのいずれかからシェア
            </p>
          </li>
          <li className="mb-6 sm:w-[30%]">
            <div className="sm:text-xs mb-[10px] after:left-[14px] after:content-['2'] h-[40px] border-[#1F2122] before:trapezoid before:content-[''] after:absolute relative flex before:inline-block items-center before:mr-3 after:text-white bg-white border">
              支援完了
            </div>
            <p className="sm:text-sm">
              紹介された方がそのシェアされたURLからログインし、ご支援いただく
            </p>
          </li>
          <li className="sm:w-[30%]">
            <div className="sm:text-xs mb-[10px] after:left-[14px] after:content-['3'] h-[40px] border-[#1F2122] before:trapezoid before:content-[''] after:absolute relative flex before:inline-block items-center before:mr-3 after:text-white bg-white border">
              支援完了メール<span className="text-xxs">(自動)</span>
            </div>
            <p className="sm:text-sm">支援完了メール（自動）が届く</p>
          </li>
        </ol>
      </div>
    </section>
  )
}

export default HowToShare
