import React from "react";
import classNames from "classnames";

type Props = {
  textLeftIcon?: any;
  textRightIcon?: any;
  buttonText: string;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
};

const DefaultButton: React.FC<Props> = (props) => {
  const {
    textLeftIcon,
    textRightIcon,
    buttonText,
    onClick,
    className = "",
    disabled = false,
  } = props;
  return (
    <button
      className={classNames(
        "flex items-center justify-center text-black text-xs hover:bg-gray-200 bg-white border border-black rounded",
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {textLeftIcon ?? ""}
      <span className="mx-1 text-white">{buttonText}</span>
      {textRightIcon ?? ""}
    </button>
  );
};

export default DefaultButton;
