import React, { useMemo, useState } from "react";
import { truncateText } from "../../../utils/TextUtil";
import Heading from "../../Common/Heading"
import Tipping from "../../Team/Tipping";
import { projectStatusToString } from "../../../utils/constants";
import "../../../../stylesheets/investmentCard.scss"
import { calcTargetAmountPercentage, calcProgressingBarPercentage } from "../../../utils/progressingUtil"
import { IsProjectActive } from "../../../utils/constants";

export type col = 3 | 4;

type Props = {
  projctID: number;
  isPublished?: boolean;
  investmentPath: string;
  clientName: string;
  remainDate: string;
  supporterCount?: number;
  totalAmount?: number;
  clientSupporterCount?: number;
  clientTotalAmount?: number;
  investmentName: string;
  headingElement: number
  imgUrl: string;
  teamIcon: string;
  targetAmount: number;
  col?: col;
  projectStatus: number;
  nextTargetAmount: number;
  canTip?: boolean;
};

const InvestmentLargeCard: React.FC<Props> = (props) => {
  const {
    projctID,
    isPublished = true,
    investmentPath,
    clientName,
    remainDate,
    supporterCount,
    totalAmount,
    clientSupporterCount,
    clientTotalAmount,
    investmentName,
    headingElement,
    imgUrl,
    teamIcon,
    targetAmount,
    col = 3,
    projectStatus,
    nextTargetAmount,
    canTip = false,
  } = props;

  const [totalAmountSync, setTotalAmountSync] = useState<number>(totalAmount);

  const status = useMemo(() => {
    return projectStatusToString(projectStatus)
  }, [projectStatus])

  const percent = useMemo(() => {
    return calcTargetAmountPercentage(totalAmountSync, targetAmount)
  }, [totalAmountSync, targetAmount]);

  return (
    <article className={"w-full investment-card" + (projectStatus === 5 ? " opacity-60" : "")}>
      <a href={investmentPath} draggable="false" className="link-hover-none">
        <div className="bg-[#1F2122] px-5 h-[20px] inline-block leading-[20px] font-raleway mb-1 text-center text-white text-xs">
          {status}
        </div>
        <div className="mb-4 w-full overflow-hidden">
          <img
            src={imgUrl}
            alt=""
            className="object-contain aspect-[4/3] bg-white key-visual"
          />
        </div>
        <Heading className={`${col === 4 ? "sm:h-[70px]" : ""} mb-[10px] font-bold h-[44px]`} headingElement={headingElement}>
          <span className="font-bold title">
            {truncateText(investmentName, 35)}
          </span>
        </Heading>
        <div className="mb-[10px] flex">
          <div className="w-[34px] h-[34px]">
            <img
              src={teamIcon}
              alt={clientName}
              className="object-contain"
            />
          </div>
          <div className="text-[#768084] h-[30px] w-[calc(100%-42px)] flex items-center ml-2 text-xs">
            {truncateText(clientName, 40)}
          </div>
        </div>
        {isPublished && (
          <>
            <div className="flex justify-between pb-3">
              <dl className="w-[50%] flex text-xs">
                <dt className="text-[#768084] mr-[10px]">支援者数</dt>
                <dd>{supporterCount.toLocaleString()}人</dd>
              </dl>
              <dl className="w-[50%] flex text-xs">
                <dt className="text-[#768084] mr-[10px]">残り</dt>
                <dd className={remainDate === '本日最終日' && 'text-red-500 font-bold'}>{remainDate}</dd>
              </dl>
            </div>
            <div className="w-[10px] h-[1px] bg-[#1F2122]"></div>
            <div className="pb-4 pt-1">
              <div className="flex items-center justify-between">
                <div className="text-[13px]">{percent}% Funded</div>
                <div className="text-lg font-bold">{totalAmountSync.toLocaleString()}円</div>
              </div>
              <div className="bg-[#D2DFE6] h-[6px] relative flex items-center">
                <div
                  className="bg-[#1F2122] h-[8px]"
                  style={{
                    width: `${calcProgressingBarPercentage(totalAmountSync, targetAmount, nextTargetAmount)[0]}%`,
                  }}
                ></div>
                {nextTargetAmount && (
                  <div
                    className={`bg-[#768084] h-[8px] absolute top-[-1px]`}
                    style={{
                      width: `${calcProgressingBarPercentage(totalAmountSync, targetAmount, nextTargetAmount)[1]}%`,
                      left: `${calcProgressingBarPercentage(totalAmountSync, targetAmount, nextTargetAmount)[0]}%`
                    }}
                  ></div>
                )}
              </div>
            </div>
            {(!!clientTotalAmount && !!clientSupporterCount) && (
              <div>
                <dl className="mb-[6px] flex justify-between text-xs">
                  <dt className="text-[#768084]">累計支援総額</dt>
                  <dd>{clientTotalAmount.toLocaleString()}円</dd>
                </dl>
                <dl className="flex justify-between text-xs">
                  <dt className="text-[#768084]">累計支援者数</dt>
                  <dd>{clientSupporterCount.toLocaleString()}人</dd>
                </dl>
              </div>
            )}
          </>
        )}
      </a>
      {canTip && IsProjectActive(projectStatus) && false && (
        <div className="mt-3">
          <Tipping
            projectID={projctID}
            setInvestmentTotalAmount={setTotalAmountSync}
            canTip={canTip}
            isLoggedIn={false}
            cardRegistered={false}
            payJpPublicKey={""}
          />
        </div>
      )}
    </article>
  );
};

export default InvestmentLargeCard;
