import React from "react";
import SecondaryLinkButton from "../../../components/Common/Buttons/SecondaryLinkButton";
import Layout from "../../../components/Common/Layout";
import Pagination from "../../../components/Common/Pagination";
import PurchaseHistoriesArea from "../../../components/Mypage/SupportHistory/PurchaseHistoriesArea";
import { PurchaseHistoriesByInvestment } from "../../../types/pages/Mypage/support_history";

type Props = {
  supportHistories: PurchaseHistoriesByInvestment[];
};

const Index: React.FC<Props> = (props) => {
  const { supportHistories } = props;

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/"
        },
        {
          text: "マイページ",
          href: "/mypage"
        },
        {
          text: "支援履歴"
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
        <div className="mb-[40px] sm:mb-[60px] flex items-center justify-between sm:items-baseline sm:justify-start">
          <h1 className="text-lg font-bold sm:text-2xl">支援履歴</h1>
          <div className="text-xs sm:ml-5">
            支援プロジェクト({supportHistories.length})
          </div>
        </div>
        <div className="sm:w-[700px] sm:mx-auto">
          <ul>
            {supportHistories.map((supportHistory, index) => (
              <PurchaseHistoriesArea
                supportHistory={supportHistory}
                key={index}
                historiesAreaKey={index}
              />
            ))}
          </ul>
          {/* TODO:ページネーションは後日実装 */}
          {/* <div className="mt-[60px]">
            <Pagination />
          </div> */}
          <div className="pt-[40px] border-[#1F2122] mx-[-30px] mt-[60px] border-t sm:mx-auto">
            <section className="px-[30px] sm:px-0">
              <h2 className="mb-[40px] font-bold">リターンについて</h2>
              <div>
                <p className="mb-6">
                  リターンの商品発送や権利は決済完了後、支援募集プロジェクトの主催チーム・団体より実施されます。
                </p>
                <p className="mb-6">なお、以下の点にはご注意ください。</p>
                <ul className="mb-[48px] pl-4 list-disc">
                  <li className="mb-6">
                    リターンは必ずしも購入直後に実施されるとは限りません。
                  </li>
                  <li className="mb-6">
                    リターンの商品発送や権利の実施時期は、支援募集プロジェクトの主催チーム・団体によって異なります。遅延等が発生した場合は、主催チーム・団体へお問い合わせください。
                  </li>
                  <li className="mb-6">
                    リターンのお届け先を変更する場合、該当するプロジェクトの「支払い明細を見る」ボタンを押していただいた後、お届け先を変更したいリターンの「お届け先の変更」ボタンからお手続きいただけます。
                  </li>
                  <li className="mb-6">
                    コンビニ決済や銀行振込を選択したものの、期限内にご入金いただけない場合、そのリターンの権利は失効いたします。お手数ですが、再度お申込みください。
                  </li>
                  <li>
                    複数支援の申込をされた場合、それぞれお振込みいただく口座番号は異なります。ご注意ください。
                  </li>
                </ul>
                <p>
                  その他、ご不明点がございましたら「よくある質問（FAQ）」をご覧ください。
                </p>
              </div>
              <div className="mt-[60px] text-center">
                <a
                  href="/mypage"
                  className="left-icon icon-stick-arrow-left text-sm"
                >
                  マイページトップ
                </a>
              </div>
            </section>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
