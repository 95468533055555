import React from "react";
import InvestmentCard from "../../components/Common/Cards/InvestmentCard";
import { investment, player } from "../../types/pages/search";
import Pagination from "../../components/Common/Pagination";
import Noimg from "../../../images/noimg.png";
import { useSearchInput } from "../../hooks/Yukari/useSearchInput";
import Layout from "../../components/Common/Layout";
import PlayerCard from "../../components/Common/Cards/PlayerCard";
import { useTabs } from "../../hooks/Yukari/useTabs"
import SliderWrapper from "../../components/Common/SliderWrapper";

type Props = {
  searchText: string;
  pickups: investment[];
  investments: investment[];
  players: player[];
};

const YukariSearch: React.FC<Props> = (props) => {
  const { searchText, pickups, investments, players } = props;
  const { value, handleChange, search, isShowSearchResult } =
    useSearchInput(searchText);
  const { activeTab, handleTabChange } = useTabs(investments, players)

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "ゆかり検索/キーワード検索",
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-10 sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">ゆかり検索/キーワード検索</h2>
        <div className="mb-[50px] sm:w-[700px] sm:mx-auto sm:mb-[80px]">
          <div className="mb-5 sm:mb-[65px]">
            <p>夢を実現するためにあなたの支援を必要としています。</p>
            <p>
              まずは、あなたとゆかりあるチームや選手のプロジェクトを探してみよう！
            </p>
          </div>
          <div>
            <div className="icon-search right-icon after:top-[22px] after:right-[10px] after:absolute relative">
              <input
                type="search"
                placeholder="入力したキーワードが入ります"
                className="border-[#B1C2CC] p-[15px] block w-full border"
                onKeyPress={search}
                value={value}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className={`${isShowSearchResult ? "block" : "hidden"} sm:bg-[#F4F6F8] sm:w-screen sm:mx-[calc(50%-50vw)]`}>
          <div className="sm:max-w-main sm:mx-auto">
            <div className="flex sm:pt-5 sm:border-b sm:border-[#D2DFE6]">
              <div
                className={`${activeTab === 0 ? "text-[#1f2122] border-[#1f2122] border-b-4" : "text-[#768084]"} pb-[10px] w-1/2 text-center hover:cursor-pointer sm:w-auto sm:px-[15px]`}
                onClick={() => handleTabChange(0)}
              >
                プロジェクト
              </div>
              <div
                className={`${activeTab === 1 ? "text-[#1f2122] border-[#1f2122] border-b-4" : "text-[#768084]"} pb-[10px] w-1/2 text-center hover:cursor-pointer sm:w-auto sm:px-[15px]`}
                onClick={() => handleTabChange(1)}
              >
                選手・スタッフ
              </div>
            </div>
            <div className="bg-[#F4F6F8] mx-[-30px] sm:mx-0">
              <div className={`${activeTab === 0 ? "block" : "hidden"}`}>
                {investments.length > 0 ? (
                  <div className="px-[30px] pb-[50px] md:px-0 sm:pb-[80px]">
                    <div className="pb-[40px] flex justify-between pt-5 sm:pb-[60px]">
                      <div className="text-xs">{investments.length}件</div>
                      {/* <div className="flex text-xs">
                        <div>並べ替え：</div>
                        <select name="" id="" className="bg-transparent">
                          <option value="">注目順</option>
                        </select>
                      </div> */}
                    </div>
                    <ul className="sm:grid-cols-3 sm:grid md:gap-x-10 md:grid-cols-4 sm:gap-x-8">
                      {investments.map((item, i) => (
                        <li key={i} className="mb-[60px] last:mb-0">
                          <InvestmentCard
                            investmentPath={item.investment_path}
                            clientName={item.client_name}
                            totalAmount={item.total_amount}
                            supporterCount={item.supporter_count}
                            clientTotalAmount={item.client_total_amount}
                            clientSupporterCount={item.client_supporter_count}
                            remainDate={item.remain_date}
                            investmentName={item.investment_name}
                            headingElement={3}
                            imgUrl={item.image_url}
                            teamIcon={item.team_icon_img}
                            targetAmount={item.target_amount}
                            projectStatus={item.project_status}
                            col={4}
                          />
                        </li>
                      ))}
                    </ul>
                    {/* <div className="mt-[50px] sm:mt-5">
                      <Pagination />
                    </div> */}
                  </div>
                ) : (
                  <div className="py-[100px]">
                    <div className="w-[143px] h-[110px] mb-[10px] mx-auto">
                      <img
                        src={Noimg}
                        alt="該当するプロジェクトはありませんでした"
                      />
                    </div>
                    <div className="text-[#768084] text-center text-xs">
                      該当するプロジェクトはありませんでした
                    </div>
                  </div>
                )}
              </div>
              <div className={`${activeTab === 1 ? "block" : "hidden"}`}>
                {players.length > 0 ? (
                  <div className="px-[30px] pb-[50px] md:px-0 sm:pb-[80px]">
                    <div className="pb-[40px] flex justify-between pt-5 sm:pb-[60px]">
                      <div className="text-xs">{players.length}件</div>
                    </div>
                    <ul className="sm:grid-cols-3 md:grid-cols-6 grid-cols-2 gap-x-8 grid">
                      {players.map((item, i) => (
                        <li
                          key={i}
                          className="mb-8"
                        >
                          <PlayerCard
                            headingElement={3}
                            name={item.client_name}
                            imgUrl={item.face_image_url}
                            link={""}
                            graduates={item.graduates_values}
                            description={item.point}
                          />
                        </li>
                      ))}
                    </ul>
                    {/* <div className="mt-[50px] sm:mt-5">
                      <Pagination />
                    </div> */}
                  </div>
                ) : (
                  <div className="py-[100px]">
                    <div className="w-[143px] h-[110px] mb-[10px] mx-auto">
                      <img
                        src={Noimg}
                        alt="該当する選手・スタッフはいませんでした"
                      />
                    </div>
                    <div className="ßtext-[#768084] text-center text-xs">
                      該当する選手・スタッフはいませんでした
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {investments.length === 0 && (
          <section className="pt-[40px] sm:pt-[60px]">
            <h3 className="sm:mb-[50px] md:before:w-[50px] md:before:left-[-55px] md:ml-[55px] before:left-[-30px] before:top-[50%] before:content-[''] before:w-[25px] before:h-[1px] before:bg-[#B1C2CC] before:absolute relative before:inline-block mb-5 font-medium">
              注目のプロジェクト
            </h3>
            <ul className="mr-[-30px] flex overflow-x-auto lg:hidden hidden-scrollbar">
              {pickups.map((item, i) => (
                <li className="mr-[30px]" key={i}>
                  <div className="w-[300px]">
                    <InvestmentCard
                      investmentPath={item.investment_path}
                      clientName={item.client_name}
                      totalAmount={item.total_amount}
                      supporterCount={item.supporter_count}
                      clientTotalAmount={item.client_total_amount}
                      clientSupporterCount={item.client_supporter_count}
                      remainDate={item.remain_date}
                      investmentName={item.investment_name}
                      headingElement={4}
                      imgUrl={item.image_url}
                      teamIcon={item.team_icon_img}
                      targetAmount={item.target_amount}
                      projectStatus={item.project_status}
                    />
                  </div>
                </li>
              ))}
            </ul>
            <ul className="hidden lg:block slider-col-3">
              <SliderWrapper col={3}>
                {pickups.map((item, i) => (
                  <li key={i}>
                    <div className="w-[300px]">
                      <InvestmentCard
                        investmentPath={item.investment_path}
                        clientName={item.client_name}
                        totalAmount={item.total_amount}
                        supporterCount={item.supporter_count}
                        clientTotalAmount={item.client_total_amount}
                        clientSupporterCount={item.client_supporter_count}
                        remainDate={item.remain_date}
                        investmentName={item.investment_name}
                        headingElement={4}
                        imgUrl={item.image_url}
                        teamIcon={item.team_icon_img}
                        targetAmount={item.target_amount}
                        projectStatus={item.project_status}
                      />
                    </div>
                  </li>
                ))}
              </SliderWrapper>
            </ul>
          </section>
        )}
      </section>
    </Layout>
  );
};

export default YukariSearch;
