import React, { useState } from "react";
import { FieldPath, FieldValues, useController, UseControllerProps } from "react-hook-form";


type Props = {
  placeholder: string;
  id: string;
  patternErrorMessage?: string;
  maxLengthErrorMessage?: string;
  type?: string;
  autoComplete?: string;
  isInitial?: boolean;
  onChange?: (e) => void;
}

function TextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  >({ type = "text", autoComplete = "off", isInitial = true, ...props }: UseControllerProps<TFieldValues, TName> & Props) {
  const {
    field,
    formState
  } = useController(props);
  const [isFocused, setIsFocused] = useState<boolean>(isInitial)

  const getErrorsFromFormState = () => {
    if (props.name.includes(".")) {
      const name = props.name.split(".")[0]
      const num = props.name.split(".")[1]
      return formState?.errors[name]?.[num]
    } else {
      return formState?.errors[props.name]
    }
  }

  return (
    <>
      <input
        {...field}
        id={props.id}
        placeholder={props.placeholder}
        value={field.value}
        autoComplete={autoComplete}
        type={type}
        onChange={(e) => {
          field.onChange(e);
          props.onChange && props.onChange(e);
        }}
        onFocus={() => {
          setIsFocused(false)
        }}
        className={`${getErrorsFromFormState() && "!bg-[#FFE6E6] !border-[#FF0000] !border-2"} ${!isFocused || field.value ? "bg-white" : "bg-[#D3EAFF] border-[#006AC9] border-2"} border-[#B1C2CC] h-[50px] px-[10px] placeholder-[#B1C2CC]::placeholder w-full text-sm border outline-none`}
      />
      <div className="text-[#FF0000] mt-1 text-xs">
        {getErrorsFromFormState()?.type === "required" && (
          <p className="before:content-['*']">必須項目です</p>
        )}
        {getErrorsFromFormState()?.type === "pattern" && (
          <p className="before:content-['*']">
            {props.patternErrorMessage}
          </p>
        )}
        {getErrorsFromFormState()?.type === "maxLength" && (
          <p className="before:content-['*']">
            {props.maxLengthErrorMessage}
          </p>
        )}
      </div>
    </>
  );
}

export default TextField;
