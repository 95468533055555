import React from "react";
import Noimg from "../../../images/noimg.png";
import Layout from "../../components/Common/Layout";

type Props = {};
const Error_404: React.FC<Props> = (props) => {
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "お探しのページは見つかりません",
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] text-lg font-bold sm:text-2xl sm:mb-[60px]">
          お探しのページは見つかりません
        </h2>
        <div className="sm:mb-[90px] sm:text-center mb-10">
          <p className="mb-7 sm:mb-0">お探しのページはURLが変更または削除された可能性がございます。</p>
          <p>※ブックマークを登録されている場合は、お手数ですが設定の変更をお願いいたします。</p>
        </div>
        <div className="w-[143px] h-[110px] mx-auto">
          <img src={Noimg} alt="" />
        </div>
        <div className="mt-[60px] text-center">
          <a
            href="/"
            className="left-icon icon-stick-arrow-left text-sm font-medium"
          >
            トップページへ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Error_404;
