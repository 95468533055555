import React from "react";
import { HttpRequest } from "../utils/common.js";
import SecondaryButton from "../components/Common/Buttons/SecondaryButton";
import SecondaryLinkButton from "../components/Common/Buttons/SecondaryLinkButton";
import Layout from "../components/Common/Layout"

type Props = {
  user_name: string;
  email: string;
};

const Mypage: React.FC<Props> = (props) => {
  const { user_name, email } = props;
  const logout = () => {
    HttpRequest.delete("/accounts/sign_out", {}).then(() => {
      location.href = "/"
    })
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          マイページ
        </h2>
        <div className="sm:w-[700px] sm:mx-auto">
          <div className="mb-[30px] sm:flex sm:justify-between">
            <a
              className="link-hover-none sm:w-[500px] flex items-center justify-between"
              href="/mypage/account_info"
            >
              {/* <div className="w-[60px] h-[60px] mr-[10px] border-[#B1C2CC] rounded-[50%]">
                <img
                  src="https://placehold.jp/60x60.png"
                  alt=""
                  className="rounded-[50%]"
                />
              </div> */}
              <div className="w-[calc(100%-70px)] text-sm underline">
                { user_name }
              </div>
            </a>
            <div className="mt-5 mx-auto sm:mt-0 h-[50px] sm:w-[170px]">
              <SecondaryLinkButton
                iconLeft="icon-history"
                text="支援履歴"
                link="/mypage/support_history"
                fontSize="text-sm"
              />
            </div>
          </div>
          <ul>
            <li className="mx-[-30px] border-[#D2DFE6] px-[30px] py-5 border-t sm:mx-0 sm:px-0 sm:py-6">
              <div className="mb-5 font-medium">プロフィール情報</div>
              <div>
                <a
                  href="/mypage/user_mail"
                  className="link-hover-none right-icon icon-stick-arrow-right flex items-center justify-between mb-5"
                >
                  <dl>
                    <dt className="text-[#768084] text-xs">メールアドレス</dt>
                    <dd>{ email }</dd>
                  </dl>
                </a>
                <a
                  href="/mypage/user_password"
                  className="link-hover-none right-icon icon-stick-arrow-right flex items-center justify-between"
                >
                  <dl>
                    <dt className="text-[#768084] text-xs">パスワード</dt>
                    <dd>*******</dd>
                  </dl>
                </a>
              </div>
            </li>
            <li className="mx-[-30px] border-[#D2DFE6] px-[30px] py-5 border-t sm:mx-0 sm:px-0 sm:py-6">
              <a
                href="/mypage/shipping_info"
                className="link-hover-none right-icon icon-stick-arrow-right flex items-center justify-between font-medium"
              >
                標準のお届け先情報
              </a>
            </li>
            {/* <li className="mx-[-30px] border-[#D2DFE6] px-[30px] py-5 border-t sm:mx-0 sm:px-0 sm:py-6">
              <a
                href="/mypage/favorite"
                className="link-hover-none right-icon icon-stick-arrow-right flex items-center justify-between font-medium"
              >
                お気に入り
              </a>
            </li> */}
            <li className="mx-[-30px] border-[#D2DFE6] px-[30px] py-5 border-t sm:mx-0 sm:px-0 sm:py-6">
              <a
                href="/mypage/notification"
                className="link-hover-none right-icon icon-stick-arrow-right flex items-center justify-between font-medium"
              >
                お知らせ設定
              </a>
            </li>
            <li className="mx-[-30px] border-[#D2DFE6] px-[30px] py-5 border-t sm:mx-0 sm:px-0 sm:py-6">
              <a
                href="/mypage/credit_card"
                className="link-hover-none right-icon icon-stick-arrow-right flex items-center justify-between font-medium"
              >
                クレジットカード情報の登録・変更
              </a>
            </li>
            <li className="mx-[-30px] border-[#D2DFE6] px-[30px] py-5 border-b border-t sm:mx-0 sm:px-0 sm:py-6">
              <a
                href="https://corp.spportunity.com/inquiry"
                className="link-hover-none right-icon icon-stick-arrow-right flex items-center justify-between font-medium"
              >
                お困りの方はこちら（お問い合わせ）
              </a>
            </li>
          </ul>
          <div className="mt-[60px] sm:mt-[50px] sm:mx-auto h-[50px] sm:w-[315px]">
            <SecondaryButton
              text="ログアウト"
              fontSize="text-sm"
              iconLeft="icon-logout"
              onClick={ logout }
            />
          </div>
          <p className="mt-[30px] text-center text-xs">
            退会手続きは
            <a href="/mypage/delete" className="link-hover-none underline">
              こちら
            </a>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default Mypage;
