import React, { useRef } from "react";
import BaseButton from "./Buttons/BaseButton";
import WarningImg from "../../../images/warning.png";

type Props = {
  toggleModal: () => void;
  onSubmit: () => void;
  isActive: boolean;
  isSubmit?: boolean;
  confirmText: string;
};

const ConfirmationModal: React.FC<Props> = (props) => {
  const { toggleModal, onSubmit, isActive, isSubmit = false, children, confirmText } = props;
  const modalRef = useRef(null);
  const buttonRef = useRef(null);
  if (isActive && modalRef.current) {
    const scroll = window.pageYOffset;
    if (window.innerWidth > 768) {
      modalRef.current.style.top = `${scroll + 120}px`;
    } else {
      modalRef.current.style.top = `${scroll + 30}px`;
    }
  }

  const deleteButton = () => {
    if (buttonRef.current) {
      buttonRef.current.style.display = "none"
      console.log('OK')
    }
  }

  return (
    <>
      <div
        className={`${
          isActive
            ? "block fixed z-50 top-0 w-screen h-screen bg-black opacity-60 left-0 right-0"
            : "hidden"
        }`}
      ></div>
      <div
        className={`${
          isActive
            ? "sm:w-[660px] w-[90%] visible absolute z-50 left-0 right-0 flex justify-center flex-col items-center px-[35px] pt-[60px] pb-[40px] bg-white mx-auto"
            : "invisible z-0 absolute"
        }`}
        ref={modalRef}
      >
        <div className="w-[45px] h-[40px] mb-5 mx-auto">
          <img src={WarningImg} alt="注意" />
        </div>
        {children}
        <div className="mt-[40px] w-full">
          <div className={`${isActive ? "block" : "hidden"} mb-[30px] sm:text-center h-[50px]`} ref={buttonRef}>
            <BaseButton
              isSubmit={isSubmit}
              text={confirmText}
              onClick={() => onSubmit()}
              fontSize="text-sm"
            />
          </div>
          <div>
            <button
              type="button"
              className="left-icon icon-stick-arrow-left w-full"
              onClick={() => toggleModal()}
            >
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
