import React, { useMemo } from "react";
import Heading from "../../Common/Heading"
import "../../../../stylesheets/pastInvestmentCard.scss";

type Props = {
  title: string;
  supporterCount: number;
  supportMoney: number;
  headingElement: number;
  imgUrl: string;
  link: string;
  targetAmount: number;
};
const PastInvestmentCard: React.FC<Props> = (props) => {
  const { title, supporterCount, supportMoney, headingElement, imgUrl, link, targetAmount } = props;
  const percent = useMemo(() => {
    return Math.floor((supportMoney / targetAmount) * 100);
  }, []);
  return (
    <article className="last:mr-0 mr-4">
      <a href={link} className="past-investment-card">
        <div className="w-full aspect-[4/3] mb-[10px] overflow-hidden">
          <img
            src={imgUrl}
            alt={title}
            className="object-contain aspect-[4/3] key-visual"
            />
        </div>
        <Heading className="mb-2 text-sm font-bold line-clamp-2 h-10" headingElement={headingElement}>
          <span className="title">{title}</span>
        </Heading>
        {supportMoney >= 0 && (
          <div className="pb-4 pt-1">
            <div className="flex items-center justify-between">
              <div className="text-[13px] flex font-raleway">{percent}%</div>
              <div className="font-bold">{supportMoney.toLocaleString()}円</div>
            </div>
            <div className="bg-[#D2DFE6] h-[6px] w-full">
              <div
                className="bg-[#1F2122] h-[6px]"
                style={{
                  width: `${Math.floor(Math.min(percent, 100))}%`,
                }}
              ></div>
            </div>
          </div>
        )}
        {supporterCount >= 0 && (
          <dl className="flex justify-between text-xs">
            <dt className="text-[#768084] mr-[10px]">支援者数</dt>
            <dd>{supporterCount.toLocaleString()}人</dd>
          </dl>
        )}
      </a>
    </article>
  );
};

export default PastInvestmentCard;
