import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import ConfirmationModal from "../ConfirmationModal";
import BaseButton from "./BaseButton";
import VisaCardImg from "../../../../images/card_visa.jpg"
import MasterCardImg from "../../../../images/card_mastercard.jpg"
import JCBCardImg from "../../../../images/card_jcb.jpg"
import AmexCardImg from "../../../../images/card_amex.jpg"
import DiscoverCardImg from "../../../../images/card_discover.jpg"
import DinersCoardImg from "../../../../images/card_diners.jpg"


type Props = {
  submitText: string;
  text: string;
  payJpPublicKey: string;
  callback: (token) => void;
  disabled?: () => boolean;
  isAddressEdited: boolean;
  postal_code: string;
  full_address: string;
};

declare global {
  interface Window {
    /* eslint-disable */
    Payjp: any;
    payjp: any;
    /* eslint-enable */
  }
}
const PaymentButton: React.FC<Props> = (props) => {
  const { submitText, text, payJpPublicKey, callback, disabled, postal_code, full_address, isAddressEdited } = props;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [numberElement, setNumberElement] = useState<any>()
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isAddressWarningModalActive, setIsAddressWarningModalActive] = useState<boolean>(false)
  const [brand, setBrand] = useState<string>(""); 

  useEffect(() => {
    (async () => {
      await createForm();
    })();
  }, []);

  const createForm = async () => {
    if (window.payjp === undefined) {
      for(;;) {
        if (window.Payjp) {
          Object.defineProperty(window, 'payjp', {
            value: window.Payjp(payJpPublicKey),
            writable: false
          });
          break;
        } else {
          await new Promise(resolve => setTimeout(resolve, 50))
        }
      }
    }

    const el = window.payjp.elements()
    const style = {
      base: {
        color: '#1f2122',
        '::placeholder': {
          color: '#b1c2cc',
        }
      },
      invalid: {
        color: '#ff0000',
      }
    }

    const numberEl = el.create('cardNumber', {style: style})
    const expiryEl = el.create('cardExpiry', {style: style})
    const cvcEl = el.create('cardCvc', {style: style})
    numberEl.mount('#number-form')
    expiryEl.mount('#expiry-form')
    cvcEl.mount('#cvc-form')

    numberEl.on('change', (event) => {
      const { brand } = event;

      setBrand(brand);
      updateOrInsertBrandIcon(brand);
    });

    setNumberElement(numberEl)
  }

  const toggleModal = () => {
    setIsActive(!isActive)
  }

  const setToken = (token: string) => {
    callback(token)
    setIsActive(!isActive)
  }

  const createToken = () => {
    window.payjp.createToken(numberElement).then(function (r) {
      const tokenSpan = document.querySelector('#token') as HTMLElement;
      if(tokenSpan) {
        if (r.error) {
          tokenSpan.innerText = r.error.message
        } else {
          tokenSpan.innerText = ""
          setToken(r.id)
        }
      }
    })
  }

  const toggleAddressWarningModal = () => {
    setIsAddressWarningModalActive(!isAddressWarningModalActive)
  }

  const openCreateTokenModal = () => {
    toggleAddressWarningModal()
    toggleModal()
  }

  const updateOrInsertBrandIcon = (brand) => {
    const numberForm = document.getElementById('number-form');
    const existingIcon = numberForm.querySelector('img');
  
    if (brand === 'unknown') {
      if (existingIcon) {
        existingIcon.remove();
      }
    } else {
      if (existingIcon) {
        existingIcon.src = getBrandIconUrl(brand);
        existingIcon.alt = brand;
      } else {
        const brandIcon = document.createElement('img');
        brandIcon.src = getBrandIconUrl(brand); 
        brandIcon.alt = brand; 
        brandIcon.className = "w-10 h-[25px] mr-[10px]";
        numberForm.insertBefore(brandIcon, numberForm.firstChild);
      }
    }
  };

  const getBrandIconUrl = (brand) => {
    switch (brand) {
      case 'Visa':
        return VisaCardImg;
      case 'MasterCard':
        return MasterCardImg;
      case 'JCB':
        return JCBCardImg;
      case 'American Express':
        return AmexCardImg;
      case 'Diners Club':
        return DinersCoardImg;
      case 'Discover':
        return DiscoverCardImg;
      default:
        return null;
    }
  };

  return (
    <>
      <BaseButton
        text={text}
        onClick={ isAddressEdited ? toggleModal : toggleAddressWarningModal }
        disabled={disabled()}
      />
      <ConfirmationModal
        toggleModal={toggleAddressWarningModal}
        onSubmit={openCreateTokenModal}
        isActive={isAddressWarningModalActive}
        confirmText="進む"
      >
        <div className="leading-[1.875]">
          <p className="mb-5">
            確実にリターン品をお届けするため、住所をご確認ください。
          </p>
          <p className="mb-1">
            〒{postal_code}
          </p>
          <p className="mb-5">
            {full_address}
          </p>
        </div>
      </ConfirmationModal>
      <Modal
        isActive={isActive}
        toggleModal={toggleModal}
      >
        <ul className="flex mb-5">
          <li className="w-10 h-[25px] mr-[10px]">
            <img src={VisaCardImg} alt="visaカード" />
          </li>
          <li className="w-10 h-[25px] mr-[10px]">
            <img src={MasterCardImg} alt="masterカード" />
          </li>
          <li className="w-10 h-[25px] mr-[10px]">
            <img src={JCBCardImg} alt="jcbカード" />
          </li>
          <li className="w-10 h-[25px] mr-[10px]">
            <img src={AmexCardImg} alt="american expressカード" />
          </li>
          <li className="w-10 h-[25px] mr-[10px]">
            <img src={DiscoverCardImg} alt="discoverカード" />
          </li>
        </ul>
        <div>
          <label htmlFor="number-form" className="w-[100px] mr-5 text-sm">カード番号</label>
          <div id="number-form" className="payjs-outer border-[#B1C2CC] border px-5 py-[15px] mb-5 flex items-center"></div>
          <label htmlFor="expiry-form" className="w-[100px] mr-5 text-sm">有効期限</label>
          <div id="expiry-form" className="payjs-outer border-[#B1C2CC] border px-5 py-[15px] mb-5"></div>
          <label htmlFor="cvc-form" className="w-[100px] mr-5 text-sm">CVC番号</label>
          <div id="cvc-form" className="payjs-outer border-[#B1C2CC] border px-5 py-[15px]"></div>
        </div>
        <div className="mt-10">
          <span id="token" className="text-[#FF0000] text-xs mb-[10px] block text-center" />
          <div className="w-[250px] mx-auto h-[50px]">
            <BaseButton
              onClick={createToken}
              text={submitText}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentButton;
