import React from "react";
import { Team } from "../../../types/pages/Project/Club/search";
import Noimg from "../../../../images/noimg.png";

type Props = {
  team: Team;
};

const TeamCard: React.FC<Props> = (props) => {
  const {
    team_name,
    image_url,
    team_path,
    team_category_icon_name,
    team_category_name
  } = props.team;

  return (
    <section className="flex mb-10 sm:flex-col">
      <div className="w-[80px] mr-3 h-auto sm:mr-0 sm:w-full">
        <a href={team_path} className="link-hover-none">
          {image_url ? (
            <img className="aspect-[4/3] object-cover" src={image_url} alt="" />
          ) : (
            <img className="aspect-[4/3] object-cover" src={Noimg} alt="" />
          )}
        </a>
      </div>
      <div className="w-[calc(100%-92px)] sm:w-full">
        <div
          className={`icon-${team_category_icon_name} left-icon text-[#768084] flex items-center mb-1 text-xs before:opacity-60 sm:mt-1`}
        >
          {team_category_name}
        </div>
        <h2 className="text-sm">
          <a href={team_path}>{team_name}</a>
        </h2>
      </div>
    </section>
  );
};

export default TeamCard;
