import React from "react";
import InvestmentCard from "../../components/Common/Cards/InvestmentCard";
import BaseLinkButton from "../../components/Common/Buttons/BaseLinkButton";
import { investment } from "../../types/pages/simulator";
import Layout from "../../components/Common/Layout";

type Props = {
  support_money: number;
  similar_projects: investment[];
};

const Result: React.FC<Props> = (props) => {
  const { support_money, similar_projects } = props;
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "支援金額シミュレーション",
          href: "/simulator"
        },
        {
          text: "支援金額シミュレーション結果",
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          支援金額シミュレーション結果
        </h2>
        <div className="mb-[40px]">
          <p className="mb-5 text-center">あなたのチームの推定支援金額は</p>
          <div className="mb-2 py-5 text-center bg-[#F4F6F8]">
            {support_money > 0 ? (
              <div className="text-3xl">
                約{support_money.toLocaleString()}円
              </div>
            ) : (
              <p>
                推定支援金額を算出できませんでした。
                <br />
                詳しくはご相談ください。
              </p>
            )}
          </div>
          <p className="sm:text-center text-xs">
            ※支援概算金額はあくまで統計的手法による推定に過ぎず、
            <br />
            クラウドファンディングプロジェクトの実施した結果の支援金額を約束するものではありません。
          </p>
        </div>
        <div className="mb-[80px]">
          <p className="mb-5">
            シミュレーション結果を見て、あなたのチームの支援プロジェクトを始めたいと思った方は下記リンクからお気軽にご相談ください！
          </p>
          <div className="sm:mx-auto h-[50px] sm:w-[360px] mb-5">
            <BaseLinkButton
              text="プロジェクトを相談したい"
              link="https://corp.spportunity.com/clubteam/"
            />
          </div>
          <div className="sm:mx-auto h-[50px] sm:w-[360px]">
            <BaseLinkButton
              text="資料請求する"
              link="https://corp.spportunity.com/document/"
            />
          </div>
        </div>
        <section>
          <h3 className="mb-10 pl-2 sm:text-xl font-bold border-l-4 border-[#006AC9]">
            あなたのチームと類似のプロジェクト
          </h3>
          <ul className="flex lg:justify-between mb-10 sm:mb-[80px] overflow-x-auto">
            {similar_projects.map((investment, i) => (
              <li key={i} className="w-[300px] min-w-[300px] mr-5 lg:mr-0">
                <InvestmentCard
                  investmentName={investment.investment_name}
                  clientName={investment.client_name}
                  totalAmount={investment.total_amount}
                  supporterCount={investment.supporter_count}
                  clientTotalAmount={investment.client_total_amount}
                  clientSupporterCount={investment.client_supporter_count}
                  imgUrl={investment.image_url}
                  teamIcon={investment.team_icon_img}
                  investmentPath={investment.investment_path}
                  headingElement={3}
                  remainDate={investment.remain_date}
                  targetAmount={investment.target_amount}
                  projectStatus={investment.project_status}
                  nextTargetAmount={investment.next_target_amount}
                  projctID={investment.project_id}
                />
              </li>
            ))}
          </ul>
        </section>
      </section>
    </Layout>
  );
};

export default Result;
