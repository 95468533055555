import React from "react";
import { PurchaseHistoriesByInvestment } from "../../../types/pages/Mypage/support_history";
import SecondaryLinkButton from "../../Common/Buttons/SecondaryLinkButton";
import PurchaseHistoryArea from "./PurchaseHistoryArea";

type Props = {
  supportHistory: PurchaseHistoriesByInvestment;
  historiesAreaKey: number;
};

const PurchaseHistoriesArea: React.FC<Props> = (props) => {
  const { supportHistory, historiesAreaKey } = props;

  return (
    <li key={historiesAreaKey} className="border-[#D2DFE6] first:pt-0 pt-5 border-b">
      <section className="support-history">
        <input
          type="checkbox"
          id={`history-${historiesAreaKey}`}
          className="hidden"
          defaultChecked={historiesAreaKey === 0 ? true : false}
        />
        <label
          htmlFor={`history-${historiesAreaKey}`}
          className="right-icon icon-arrow-under after:top-[30px] after:right-[2px] support-history-item sm:after:top-[44px] after:absolute relative flex mb-5 hover:cursor-pointer sm:items-center"
        >
          <div className="w-[80px] h-[60px] sm:w-[140px] sm:h-[105px] mr-3 sm:mr-5">
            <img src={supportHistory.investment.image_url} alt="" />
          </div>
          <div className="w-[calc(100%-112px)] sm:w-[calc(100%-180px)]">
            {supportHistory.investment.all_return_paid === false && (
              <div className="left-icon icon-warning text-[#FF0000] mb-1 text-xs font-bold">
                未払いあり
              </div>
            )}
            <h2 className="font-medium font-medium">
              {supportHistory.investment.investment_name}
            </h2>
          </div>
        </label>
        <div className="return-items">
          <section className="bg-[#F4F6F8] sm:py-[30px] p-5 sm:px-10">
            <h3 className="text-xs font-medium">支援リターン</h3>
            <ul>
              {supportHistory.purchase_histories.map((purchase_history) => (
                <PurchaseHistoryArea
                  purchaseHistory={purchase_history}
                  paymentDetailsPath={supportHistory.payment_details_path}
                />
              ))}
            </ul>
          </section>
          <div className="mb-[30px] w-[238px] h-[40px] mt-5 mx-auto sm:mb-10">
            <SecondaryLinkButton
              text="支払い明細を見る"
              link={supportHistory.payment_details_path}
              fontSize="text-xs"
            />
          </div>
        </div>
      </section>
    </li>
  );
};

export default PurchaseHistoriesArea;
