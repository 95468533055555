import React from "react";
import classNames from "classnames";

type Props = {
  title: string;
  className?: string;
};

const Title: React.FC<Props> = (props) => {
  const { title, className } = props;
  return (
    <>
      <h1
        className={classNames(
          "p-1 w-full text-center text-xl font-bold bg-grey-100 border-l-4 border-r-4 border-solid border-blue-300",
          className
        )}
        style={{ borderBottom: "4px solid rgb(215,215,215)" }}
      >
        {title}
      </h1>
    </>
  );
};

export default Title;
