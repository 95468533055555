import { useState, useEffect, useRef } from "react";

export const useProductVisibilityToggle = (listLength: number) => {
  const [showReadMoreBtn, setShowReadMoreBtn] = useState<boolean>(false);
  const productListRef = useRef(null);
  const threshold = 20;

  const toggleVisibility = () => {
    if (productListRef.current) {
      for (let i = threshold; i < productListRef.current.children.length; i++) {
        productListRef.current.children[i].classList.toggle("hidden")        
      }
    }
  }

  // https://trello.com/c/7LZfky5E
  const path = window.location.pathname;
  const pathEl = path.split("/");
  const teamIndex = pathEl.findIndex((el) => el === "team");
  const key = "readMoreBtnOpened";
  let pageId;
  if (pathEl.includes("invest")) {
    const investmentIndex = pathEl.findIndex((el) => el === "invest");
    pageId = `${pathEl[teamIndex + 1]}_${pathEl[investmentIndex + 1]}`;
  } else {
    pageId = `${pathEl[teamIndex + 1]}`;
  }

  useEffect(() => {
    // md以上の画面サイズの場合、リターン全て表示する
    // Team/Detail.tsxのisLargeDisplayの判定に準拠
    if (window.innerWidth >= 1000) {
      return;
    }

    if (listLength > threshold && (sessionStorage.getItem(key)?.includes(pageId) === false || sessionStorage.getItem(key) === null)) {
      setShowReadMoreBtn(true);
      toggleVisibility();
    }
  }, [])

  const showAllProducts = () => {
    setShowReadMoreBtn(false);
    toggleVisibility();

    let arr;
    if (sessionStorage.getItem(key) !== null) {
      arr = new Array(sessionStorage.getItem(key));
      arr.push(pageId);
    } else {
      arr = [pageId];
    }
    sessionStorage.setItem(key, arr);
  }
  
  return { showReadMoreBtn, showAllProducts, productListRef };
}

