import React from "react";
import Noimg from "../../../images/noimg.png";
import Layout from "../../components/Common/Layout";

type Props = {
    teamDetailPath: string
};
const Ended: React.FC<Props> = (props) => {
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "このプロジェクトは終了しました。",
        }
      ]}
    >
      <div className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
        <div className="sm:mb-[90px] sm:text-center mb-10">
          <p>このプロジェクトは終了しました。</p>
          <p className="mt-3"><a className="text-primary" href={ props.teamDetailPath }>支援募集プロジェクトへ戻る</a></p>
        </div>
        <div className="w-[143px] h-[110px] mx-auto">
          <img src={Noimg} alt="" />
        </div>
      </div>
    </Layout>
  );
};

export default Ended;
