import React from "react";
import Heading from "../../Common/Heading"

type Props = {
  headingElement: number;
  name: string;
  description: string;
  graduates: string[];
  link: string;
  imgUrl: string;
};

const PlayerCard: React.FC<Props> = (props) => {
  const { headingElement, name, graduates, link, imgUrl, description } = props
  return (
    <article className="w-full">
      <div className="mb-[10px]">
        {/* <a href={link} className="link-hover-none">
          <img src={imgUrl} alt="" className="aspect-[3/4] object-cover"/>
        </a> */}
        <img src={imgUrl} alt="" className="aspect-[3/4] object-cover"/>
      </div>
      <Heading className="mb-[10px] text-sm font-bold line-clamp-2" headingElement={headingElement}>
        {/* <a href={link} className="inline-block">{name}</a> */}
        {name}
      </Heading>
      <p className="text-[#768084] mb-[10px] mb-[10px] text-xs line-clamp-3 break-all">
        {description}
      </p>
      {graduates.map((item, i) => (
        <div className="text-[#768084] mb-[10px] text-xs" key={i}>{item}</div>
      ))}
    </article>
  );
};

export default PlayerCard;
