import React from "react";
import Noimg from "../../../images/noimg.png";
import Layout from "../../components/Common/Layout";

type Props = {};
const Confirm: React.FC<Props> = (props) => {
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "本人認証の完了"
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto sm:px-0">
        <h1 className="mb-[40px] text-lg font-bold sm:text-2xl">
          本人認証の完了
        </h1>
        <div className="mb-[45px] sm:mb-[60px] sm:text-center leading-loose">
          <p>本人認証が完了しました。</p>
          <p>会員登録も完了しています。</p>
          <p>
            下記、マイページトップのボタンを押してこのままご利用を続けてください。
          </p>
        </div>
        <div className="w-[143px] h-[110px] mx-auto">
          <img src={Noimg} alt="" />
        </div>
        <div className="mt-[60px] text-center">
          <a
            href="/mypage"
            className="left-icon icon-stick-arrow-left text-sm font-medium"
          >
            マイページトップ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Confirm;
