import React from "react";
import Noimg from "../../../../images/noimg.png";
import Layout from "../../../components/Common/Layout";

type Props = {};
const Completion: React.FC<Props> = (props) => {
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "メールアドレスの変更",
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
        <h1 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          メールアドレスの変更
        </h1>
        <div className="sm:w-[700px] sm:mx-auto">
          <div className="mb-[45px] sm:mb-[60px] sm:text-center">
            <p>本人認証が完了し、メールアドレスを変更しました。</p>
            <p>
              下記、マイページトップのボタンを押してこのままご利用を続けてください。
            </p>
          </div>
          <div className="w-[143px] h-[110px] mx-auto">
            <img src={Noimg} alt="" />
          </div>
          <div className="mt-[60px] text-center">
            <a href="/mypage" className="left-icon icon-stick-arrow-left text-sm">
              マイページトップ
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Completion;
