import React from "react";
import { HttpRequest } from "../../../utils/common";
import { useForm } from "react-hook-form";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import Layout from "../../../components/Common/Layout";
import TextField from "../../../components/Common/Form/TextField";
import { EMAIL_MATCH_PATTERN } from "../../../utils/constants"

type Props = {
  postPath: string;
};
const Reset: React.FC<Props> = (props) => {
  const { postPath } = props;

  const onSubmit = async (data) => {
    try {
      await HttpRequest.post(postPath, { email: data.email });
      location.href = "/accounts/password/new/completion";
    } catch (err) {
      if (err.response.status === 404) {
        location.href = "/accounts/password/new/completion";
      }
      // エラー画面を表示する
      console.error(err);
    }
  };

  interface IFormInput {
    email: string;
  }

  const { handleSubmit, control } = useForm<IFormInput>({
    mode: "onChange",
    defaultValues: {
      email: ""
    }
  });

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "パスワードをお忘れですか？",
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto sm:px-0">
        <h2 className="mb-[40px] text-lg font-bold sm:text-2xl">
          パスワードをお忘れですか？
        </h2>
        <div className="sm:w-[700px] sm:mx-auto">
          <div className="mb-[45px] sm:mb-[60px]">
            <p>
              パスワードを再設定されたい方は、登録されているメールアドレスを入力してください。
            </p>
            <p>パスワード再設定用のURLを送付します。</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center mb-1">
              <label htmlFor="email" className="inline-block text-sm">
                メールアドレス
              </label>
              <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                必須
              </span>
            </div>
            <TextField
              control={control}
              name="email"
              id="email"
              placeholder="メールアドレスを入力してください"
              rules={{
                required: true,
                pattern: EMAIL_MATCH_PATTERN
              }}
              patternErrorMessage="メールアドレスが正しくありません"
            />
            <div className="mt-[60px] mx-auto w-[250px] h-[50px]">
              <BaseButton
                text="パスワードをリセットする"
                fontSize="text-sm"
                isSubmit={true}
              />
            </div>
          </form>
          <div className="mt-[60px] text-center">
            <a
              href="/"
              className="left-icon icon-stick-arrow-left link-hover-none font-medium"
            >
              トップページへ
            </a>
          </div>
        </div>
      </section>
    </Layout>
    
  );
};

export default Reset;
