import React, { useState, useEffect } from "react";
import Heading from "../Heading";
import { truncateText } from "../../../utils/TextUtil";
import "../../../../stylesheets/ecReturnCard";

type Props = {
  link: string;
  id: number
  name: string;
  category: string;
  headingElement: number;
  imgUrl: string
  price: number;
  supportCount: number;
  stockQuantity: string;
  isNew: boolean;
  isSoldOut: boolean;
  showCheckbox?: boolean;
  onCheckboxChange?: (checked: boolean, itemId: number) => void;
  isChecked?: boolean;
  disabled?: boolean;
};

const EcReturnCard: React.FC<Props> = ({
    link,
    id,
    name,
    category,
    headingElement,
    imgUrl,
    price,
    supportCount,
    stockQuantity,
    isNew,
    isSoldOut,
    showCheckbox = false,
    onCheckboxChange,
    isChecked = false,
    disabled = false,
  }) => {
    const [checked, setChecked] = useState(isChecked);
  
    useEffect(() => {
      setChecked(isChecked);
    }, [isChecked]);
  
    const handleCheckboxChange = (checked: boolean) => {
      setChecked(checked);
      if (onCheckboxChange) {
        onCheckboxChange(checked, id);
      }
    };

  return (
    <>
      <article className="w-full ec-return-card relative">
        {showCheckbox ? (
          <>
            <label className="flex items-center pb-2 h-10 cursor-pointer">
              <input
                type="checkbox"
                className="w-4 h-4 mr-2"
                checked={checked}
                onChange={(e) => handleCheckboxChange(e.target.checked)}
                disabled={disabled}
              />
              <p className={`md:text-xs text-base line-clamp-1 ${isChecked ? 'text-blue-500' : 'text-[#768084]'}`}>
                {isChecked ? "おまとめ選択中" : `${category}`}
              </p>
            </label>
            <a href={link} className="llink-hover-none">
              <div className="relative">
                <div className="mb-[10px] overflow-hidden">
                  <img
                    src={imgUrl}
                    alt={name}
                    className="object-contain aspect-[4/3] key-visual"
                  />
                  {isNew &&
                    <p className="text-white bg-[#FF0000] border-white px-1 absolute inline-block border top-0 font-bold h-[25px]">NEW</p>
                  }
                  {isSoldOut &&
                    <>
                      <div className="bg-black opacity-30 absolute top-0 w-full h-full z-10" />
                      <p className="text-white underline sm:text-3xl font-bold absolute flex top-0 bottom-0 right-0 left-0 items-center justify-center z-20">SOLD OUT</p>
                    </>
                  }
                </div>
              </div>
              <div className="font-bold mb-[15px]">
                {price.toLocaleString()}<span className="text-xxs">円(税込)</span>
              </div>
              <Heading className="text-sm font-bold mb-[10px] md:h-10 h-[60px]" headingElement={headingElement}>
                <span className="font-bold title">
                  {truncateText(name, 25)}
                </span>
              </Heading>
              <div className="py-2 border-b border-[#D2DFE6] border-t hidden md:block">
                <dl className="text-xs flex justify-between mb-1">
                  <dt className="text-[#768084] left-icon icon-human">支援数</dt>
                  <dd>{supportCount.toLocaleString()}件</dd>
                </dl>
                <dl className="text-xs flex justify-between">
                  <dt className="text-[#768084] left-icon icon-clock-grey">在庫数</dt>
                  <dd>{stockQuantity}</dd>
                </dl>
              </div>
            </a>
          </>
        ) : (
          <a href={link} className="llink-hover-none">
            <label className="flex items-center pb-2 h-10 cursor-pointer">
              <p className={`md:text-xs text-base line-clamp-1 ${isChecked ? 'text-blue-500' : 'text-[#768084]'}`}>
                {category}
              </p>
            </label>
            <div className="relative">
              <div className="mb-[10px] overflow-hidden">
                <img
                  src={imgUrl}
                  alt={name}
                  className="object-contain aspect-[4/3] key-visual"
                />
                {isNew &&
                  <p className="text-white bg-[#FF0000] border-white px-1 absolute inline-block border top-0 font-bold h-[25px]">NEW</p>
                }
                {isSoldOut &&
                  <>
                    <div className="bg-black opacity-30 absolute top-0 w-full h-full z-10" />
                    <p className="text-white underline sm:text-3xl font-bold absolute flex top-0 bottom-0 right-0 left-0 items-center justify-center z-20">SOLD OUT</p>
                  </>
                }
              </div>
            </div>
            <div className="font-bold mb-[15px]">
              {price.toLocaleString()}<span className="text-xxs">円(税込)</span>
            </div>
            <Heading className="text-sm font-bold mb-[10px] md:h-10 h-[60px]" headingElement={headingElement}>
              <span className="font-bold title">
                {truncateText(name, 25)}
              </span>
            </Heading>
            <div className="py-2 border-b border-[#D2DFE6] border-t hidden md:block">
              <dl className="text-xs flex justify-between mb-1">
                <dt className="text-[#768084] left-icon icon-human">支援数</dt>
                <dd>{supportCount.toLocaleString()}件</dd>
              </dl>
              <dl className="text-xs flex justify-between">
                <dt className="text-[#768084] left-icon icon-clock-grey">在庫数</dt>
                <dd>{stockQuantity}</dd>
              </dl>
            </div>
          </a>
        )}
      </article>
    </>
  );
};

export default EcReturnCard;
