import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import BaseButton from "../../../../../components/Common/Buttons/BaseButton";
import BaseLinkButton from "../../../../../components/Common/Buttons/BaseLinkButton";
import SecondaryLinkButton from "../../../../../components/Common/Buttons/SecondaryLinkButton";
import Layout from "../../../../../components/Common/Layout";
import ShippingAddressComponent from "../../../../../components/Common/ShippingAddress";
import { ShippingAddressFormInput } from "../../../../../types/common";
import { ShippingAddress } from "../../../../../types/pages/Mypage/payment_details";
import ConfirmationModal from "../../../../../components/Common/ConfirmationModal";

type Props = {
  authenticity_token: string;
  shipping_address: ShippingAddress;
  payment_details_path: string;
  is_expired: boolean;
  investment_owner_email: string;
};

const Edit: React.FC<Props> = (props) => {
  const {
    authenticity_token,
    shipping_address,
    payment_details_path,
    is_expired = false,
    investment_owner_email,
  } = props;

  const { handleSubmit, control, setValue, getValues, watch } = useForm<ShippingAddressFormInput>({
    mode: "onChange",
    defaultValues: {
      last_name: shipping_address.last_name,
      first_name: shipping_address.first_name,
      postal_code: shipping_address.postal_code.toString(),
      full_address: shipping_address.full_address,
      phone_number: shipping_address.phone_number,
    }
  });

  const addressFormRef = useRef(null);

  const [isAddressEdited, setIsAddressEdited] = useState<boolean>(true) // 登録済み住所を利用する場合を考慮してデフォルトはTrue
  const [isAddressWarningModalActive, setIsAddressWarningModalActive] = useState<boolean>(false)

  const onSubmit = async () => {
    addressFormRef.current.submit();
  };

  const toggleAddressWarningModal = () => {
    setIsAddressWarningModalActive(!isAddressWarningModalActive)
    console.log(isAddressWarningModalActive);
  }

  const handleAddressEdit = (isEdited: boolean) => {
    setIsAddressEdited(isEdited);
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "支援履歴",
          href: "/mypage/support_history",
        },
        {
          text: "支払い明細",
          href: payment_details_path,
        },
        {
          text: "支払い明細 -お届け先の変更-"
        },
      ]}
    >
      <ConfirmationModal
        toggleModal={toggleAddressWarningModal}
        onSubmit={handleSubmit(onSubmit)}
        isActive={isAddressWarningModalActive}
        isSubmit={true}
        confirmText="上記の内容で変更する"
      >
        <div className="leading-[1.875]">
          <p className="mb-5">
            確実にリターン品をお届けするため、住所をご確認ください。
          </p>
          <p className="mb-1">
            〒{getValues("postal_code")}
          </p>
          <p className="mb-5">
            {getValues("full_address")}
          </p>
        </div>
      </ConfirmationModal>
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          支払い明細 - お届け先の変更 -
        </h2>
        {is_expired ? (
          <>
            <div className="mb-[40px] sm:text-center sm:mb-[60px]">
              <p>
                送付先は、
                <span className="text-[#FF0000]">
                  プロジェクト終了日時をすぎると変更できません
                </span>
                ので、予めご了承ください。
              </p>
              <p>
                プロジェクト終了日時をすぎて、変更をご希望の場合は、<br className="hidden sm:block"/>直接プロジェクト実行者にお問い合わせください。
              </p>
            </div>
            <div className="mb-[40px] h-[50px] sm:hidden">
              <BaseLinkButton
                text={`プロジェクト実行者に\nメールでお問い合わせ`}
                link={`mailto:${investment_owner_email}`}
                fontSize="text-sm"
                iconLeft="icon-email"
              />
            </div>
            <div className="hidden sm:block w-[400px] h-[50px] mx-auto mb-[40px]">
              <BaseLinkButton
                text={`プロジェクト実行者にメールでお問い合わせ`}
                fontSize="text-sm"
                link={`mailto:${investment_owner_email}`}
                iconLeft="icon-email"
              />
            </div>
          </>
        ) : (
          <div className="sm:w-[700px] sm:mx-auto mb-10">
            <p className="mb-[40px]">
              支援時に登録されたお届け先を変更したい場合は、この画面より変更できます。
            </p>
            <form onSubmit={handleSubmit(onSubmit)} method="POST" ref={addressFormRef}>
              <input type="hidden" name="authenticity_token" value={authenticity_token}/>
              <ShippingAddressComponent
                control={control}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                onAddressEdit={handleAddressEdit}
              />
              <div className="mt-[60px] sm:mx-auto h-[50px] sm:w-[250px]">
                <BaseButton
                  text="上記の内容で変更する"
                  fontSize="text-sm"
                  isSubmit={isAddressEdited ? true : false}
                  onClick={!isAddressEdited ? toggleAddressWarningModal : undefined}
                />
              </div>
            </form>
          </div>
        )}
        <div className="sm:mx-auto h-[50px] sm:w-[400px]">
          <SecondaryLinkButton
            iconLeftEdge="icon-stick-arrow-left"
            text="このプロジェクトの支援履歴に戻る"
            fontSize="text-sm"
            link={payment_details_path}
          />
        </div>
        <div className="mt-[60px] text-center">
          <a href="/mypage" className="left-icon icon-stick-arrow-left font-medium text-sm">
            マイページトップ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Edit;
