import React from "react";
import Noimg from "../../../../images/noimg.png";
import Layout from "../../../components/Common/Layout"

type Props = {};
const Updated: React.FC<Props> = (props) => {
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "退会手続き"
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
        <h1 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">退会手続き</h1>
        <div className="mb-[45px] sm:text-center">
          <p>アカウントを削除しました。</p>
          <p>ぜひまたのご利用をお待ちしております。</p>
        </div>
        <div className="w-[143px] h-[110px] mx-auto">
          <img src={Noimg} alt="" />
        </div>
        <div className="mt-[60px] text-center">
          <a href="/" className="left-icon icon-stick-arrow-left text-sm font-medium">
            トップページへ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Updated;
