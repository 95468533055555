import React from "react";

type Props = {
  path: string;
  text: string;
  token: string;
};

const GoogleLoginButton: React.FC<Props> = (props) => {
  const { path, text, token } = props;
  return (
    <form method="POST"
      action={path}
      className=""
    >
      <input type="hidden" name="authenticity_token" value={token} />
      <button
        type="submit"
        className="hover:cursor-pointer link-hover-none hover:bg-[#fff] before:mr-[10px] left-icon icon-google !bg-[#fff] h-[50px] font-raleway rounded-default block flex items-center justify-center w-full text-sm border-[#1F2122] border"
      >
        {text}
      </button>
    </form>
  );
};

export default GoogleLoginButton;
