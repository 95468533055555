import React, { useState } from "react";
import { HttpRequest } from "../../../utils/common";
import SelectReturn from "./SelectReturn";
import EnterProfile from "./EnterProfile";
import { usePurchaseEcReturnUtilities } from "../../../hooks/EcReturn/Websettlement/usePurchaseEcReturnUtilities";
import ConfirmSettlement from "./ConfirmSettlement";
import CompleteSettlement from "./CompleteSettlement";
import Error from "./Error";
import Layout from "../../../components/Common/Layout";
import { camelcaseKeys } from "../../../utils/ApiUtils";
import {
  EcReturn,
  ProjectInformation,
  Questionnaire,
  SocialProfile,
} from "../../../types/pages/EcWebsettlement/purchase_ec_return";
import { useScrollToTarget } from "../../../hooks/EcWebsettlement/useScrollToTarget";
import Loading from "../../../components/Common/Loading";

type Props = {
  ecReturns: EcReturn[];
  socialProfile: SocialProfile;
  questionnaire: Questionnaire;
  projectInformation: ProjectInformation;
  postPath: string;
  cancelPath: string;
  reservationPostPaths: string[];
  teamDetailCommunityPath: string;
  tweetPath: string;
  facebookSharePath: string;
  payJpPublicKey: string;
  paymentToken: string;
  widget_url: string;
  referral_url: string;
  current_email: string;
  commission: number;
  is_draft?: boolean;
};
const PurchaseEcReturn = ({
  ecReturns,
  socialProfile,
  questionnaire,
  projectInformation,
  postPath,
  reservationPostPaths,
  payJpPublicKey,
  paymentToken,
  widget_url,
  referral_url,
  commission,
  is_draft,
  current_email,
}: Props) => {
  enum CHECKOUT_STATES {
    SelectReturn,
    EnterProfile,
    ConfirmShippingAndPaymentInfo,
    Complete,
    Error,
  }
  const [settlementResultProps, setSettlementResultProps] = useState({
    previousFunding: 0,
    currentFunding: 0,
    remainingFunding: 0,
    finishedGoal: false,
    targetAmount: 0,
  });

  const [currentPageState, setCurrentPageState] = useState<number>(
    CHECKOUT_STATES.SelectReturn
  );

  const { setScrollToTargetId } = useScrollToTarget(currentPageState)

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    control,
    setValue,
    getValues,
    watch,
    trigger,
    register,
    handleSubmit,
    formState: { errors },
    totalAmount,
    priceByAmount,
    totalPriceByAmount,
    cardInformation,
  } = usePurchaseEcReturnUtilities(
    paymentToken,
    ecReturns,
    commission,
    current_email,
    socialProfile,
    questionnaire
  );

  const moveSelectReturnPage = (id?: string) => {
    if(id) {
      setScrollToTargetId(id)
    }
    setCurrentPageState(CHECKOUT_STATES.SelectReturn)
  }

  const moveEnterProfilePage = (id?: string) => {
    if(id) {
      setScrollToTargetId(id)
    }
    setCurrentPageState(CHECKOUT_STATES.EnterProfile)
  }

  const moveConfirmPage = () => {
    setCurrentPageState(CHECKOUT_STATES.ConfirmShippingAndPaymentInfo)
  }

  const currentPage = () => {
    switch (currentPageState) {
      case CHECKOUT_STATES.SelectReturn:
        return <SelectReturn {...selectReturnProps} />;
      case CHECKOUT_STATES.EnterProfile:
        return <EnterProfile {...enterProfileProps} />
      case CHECKOUT_STATES.ConfirmShippingAndPaymentInfo:
        return <ConfirmSettlement {...confirmSettlementProps} />
      case CHECKOUT_STATES.Complete:
        return <CompleteSettlement {...completeSettlementProps}/>
      case CHECKOUT_STATES.Error:
        return <Error errorViewMsg={errorMessage} projectPath={projectInformation.investment_path}/>;
    }
  };

  const onSubmit = (data) => {
    switch (currentPageState) {
      case CHECKOUT_STATES.SelectReturn:
        setIsSubmitting(true);
        Promise.all(reservationPostPaths.map((path, index) => new Promise((resolve, reject) => {
          setTimeout(() => {
            HttpRequest.post(path, { number: getValues(`ec_returns_form.${index}.number`), ec_return_sku_id: getValues(`ec_returns_form.${index}.checked_sku_id`) })
            .then((res) => {
              resolve(res);
            })
            .catch((e) => {
              reject(e);
            });
          }, Math.random()*1000);
        })))
        .then(() => {
          setCurrentPageState(CHECKOUT_STATES.EnterProfile);
        })
        .catch((e) => {
          if (e.response.data && e.response.data.code == 1) {
            setErrorMessage("リターンの在庫数が足りません。他のお客様が購入中の場合もございますので、購入個数の変更やしばらく時間を空けて再度お試しいただくようお願いいたします。");
          } else {
            // TODO: hundle other errors
            setErrorMessage("リターンの仮押さえ処理中にエラーが発生しました。再度やり直してもエラーが発生する場合は、お問い合わせよりご連絡ください。");
          }
          setCurrentPageState(CHECKOUT_STATES.Error);
        })
        .finally(() => {
          setIsSubmitting(false);
          setScrollToTargetId("#return-purchase-top");
        });
        break;
      case CHECKOUT_STATES.EnterProfile:
        setCurrentPageState(CHECKOUT_STATES.ConfirmShippingAndPaymentInfo)
        break;
      case CHECKOUT_STATES.ConfirmShippingAndPaymentInfo:
        onPurchase(data)
        break;
    }
  }

  const onPurchase = (data) => {
    setIsSubmitting(true);
    HttpRequest.post(postPath, data)
      .then((res) => {
        setSettlementResultProps(camelcaseKeys(res.data));
        setCurrentPageState(CHECKOUT_STATES.Complete);
        history.pushState("", "", `${projectInformation.ec_return_path}web_settlements/complete`);
        (window as any).gtag('config', 'UA-77972820-1', {
          'page_path': `${projectInformation.ec_return_path}web_settlements/complete`
        });
      })
      .catch((e) => {
        const errors = e.response.data.errors;
        if (errors?.reservation) {
          setErrorMessage(errors.reservation);
        } else if (errors?.maximum_price) {
          setErrorMessage(errors.maximum_price);
        } else if (errors?.maximum_price_conveni) {
          setErrorMessage(errors.maximum_price_conveni);
        } else if (errors?.ended) {
          setErrorMessage(errors.ended);
        } else if (errors?.conveni_params_invalid) {
          setErrorMessage(errors.conveni_params_invalid);
        } else if (errors?.remaining_number) {
          setErrorMessage(errors.remaining_number);
        } else if (errors?.card_declined) {
          setErrorMessage(errors.card_declined)
        } else if (errors?.incorrect_card_data) {
          setErrorMessage(errors.incorrect_card_data)
        } else if (errors?.invalid_sku_id) {
          setErrorMessage(errors.invalid_sku_id)
        } else {
          // TODO: hundle other errors
          if (getValues('payment_method') === 'conveni') {
            setErrorMessage(
              "リターン購入処理中にエラーが発生しました。決済事業者GMOイプシロン <sendonly@epsilon.jp> から、「コンビニ決済 お支払い受付番号のご案内」というメールが送信された場合、コンビニで入金せずもう一度ご支援をやり直してください。再度やり直してもエラーが発生する場合は、お問い合わせよりご連絡ください。"
            );
          } else {
            setErrorMessage("リターン購入処理中にエラーが発生しました。再度やり直してもエラーが発生する場合は、お問い合わせよりご連絡ください。");
          }
        }
        setCurrentPageState(CHECKOUT_STATES.Error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const selectReturnProps = {
    ecReturns: ecReturns,
    projectInformation,
    control,
    setValue,
    getValues,
    watch,
    totalAmount,
    totalPriceByAmount,
    priceByAmount,
    commission,
    is_draft
  };

  const enterProfileProps = {
    cardInformation,
    payJpPublicKey,
    questionnaire,
    projectInformation,
    control,
    trigger,
    register,
    setValue,
    watch,
    getValues,
    moveConfirmPage: () => moveConfirmPage(),
    moveSelectReturnPage: () => moveSelectReturnPage(),
    totalPriceByAmount,
    totalAmount,
    priceByAmount,
    commission,
    currentEmail: current_email,
  };

  const confirmSettlementProps = {
    ecReturns,
    questionnaire,
    moveSelectReturnPage: (elId?: string) => moveSelectReturnPage(elId),
    moveEnterProfilePage: (elId: string) => moveEnterProfilePage(elId),
    totalAmount,
    totalPriceByAmount,
    getValues,
    cardInformation,
    isSubmitting,
    commission
  }

  const completeSettlementProps = {
    settlementResultProps,
    projectInformation,
    widget_url,
    referral_url,
    getValues,
  }

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: projectInformation.investment_title,
          href: is_draft ? `${projectInformation.investment_path}${window.location.search}` : projectInformation.investment_path,
        },
        {
          text: "支援のお手続き"
        },
      ]}
    >
      <Loading
        show={isSubmitting}
      />
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl" id="return-purchase-top">支援のお手続き</h2>
        <div className="sm:w-[700px] sm:mx-auto">
          {[
            CHECKOUT_STATES.SelectReturn,
            CHECKOUT_STATES.EnterProfile,
            CHECKOUT_STATES.ConfirmShippingAndPaymentInfo,
            CHECKOUT_STATES.Complete
          ].includes(currentPageState) && (
            <div className="mb-[30px] flex">
              <div className="w-[80px] h-[60px] mr-3 sm:w-[120px] sm:h-[90px]">
                <img
                  className="object-cover"
                  src={projectInformation.investment_image}
                  alt={projectInformation.investment_title}
                />
              </div>
              <a className="w-[calc(100%-92px)] sm:w-[calc(100%-132px)] underline link-hover-none" href={projectInformation.investment_path}>{projectInformation.investment_title}</a>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>{currentPage()}</form>
        </div>
      </section>
    </Layout>
  )
};

export default PurchaseEcReturn;
