import React from "react";
import Pagination from "../../components/Common/Pagination";
import { useToggle } from "../../hooks/Common/useToggle";
import SearchModal from "../../components/Common/SearchModal";
import Layout from "../../components/Common/Layout";
import Noimg from "../../../images/noimg.png";
import { Team } from "../../types/pages/search";
import TeamCard from "../../components/Search/TeamCard";

type Props = {
  searched_teams: Team[];
};

const TeamSearch: React.FC<Props> = (props) => {
  const { searched_teams } = props;

  // TODO: 検索モーダルは追加の可能性がある
  // const { isActive: showCategoryAll, toggle: toggleCategoryAccordion } =
  //   useToggle(true);
  // const { isActive: showAreaAll, toggle: toggleAreaAccordion } =
  //   useToggle(true);
  // const { isActive: showModal, toggle: toggleModal } = useToggle();

  // const openModal = () => {
  //   toggleModal();
  // };

  // const search = () => {
  //   console.log("OK");
  // };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/"
        },
        {
          text: "クラブチームからさがす"
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:mt-[60px] sm:mb-10 sm:mx-auto sm:max-w-main md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          クラブチームからさがす
        </h2>
        {searched_teams.length > 0 ? (
          <>
            <div className="mb-[50px] mt-[10px] sm:mb-[60px] text-xs">
              {searched_teams.length}件
            </div>
            <ul className="mb-[60px] sm:mb-[10px] sm:grid sm:gap-x-10 sm:grid-cols-4">
              {searched_teams.map((team, i) => (
                <li key={i}>
                  <TeamCard team={team} />
                </li>
              ))}
            </ul>
          </>
        ) : (
          <div className="py-[100px]">
            <div className="w-[143px] h-[110px] mb-[10px] mx-auto">
              <img src={Noimg} alt="該当するプロジェクトはありませんでした" />
            </div>
            <div className="text-[#768084] text-center text-xs">
              該当するプロジェクトはありませんでした
            </div>
          </div>
        )}
        {/* TODO:ページネーション後日実装 <Pagination /> */}
      </section>
    </Layout>
  );
};

export default TeamSearch;
