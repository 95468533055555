import React from "react";
import { fontSize } from "../../../types/common";

type Props = {
  text: string;
  isSubmit?: boolean;
  iconLeft?: string;
  iconRight?: string;
  iconRightEdge?: string;
  iconLeftEdge?: string;
  fontSize?: fontSize;
  fontRaleway?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const BaseButton: React.FC<Props> = (props) => {
  const {
    text,
    onClick,
    isSubmit = false,
    iconLeft,
    iconRight,
    iconRightEdge,
    iconLeftEdge,
    fontSize,
    fontRaleway = false,
    disabled = false
  } = props;
  return (
    <button
      type={isSubmit ? "submit" : "button"}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      className={`${fontSize} ${fontRaleway && "font-raleway"} ${
        disabled && "opacity-30 pointer-events-none"
      } w-full h-full pb-0 inline-block btn-hover border-[#1F2122] text-center text-white bg-[#1F2122] border-2 rounded-default`}
      disabled={disabled}
    >
      <span
        className={`h-full flex items-center justify-center relative z-10 mt-[1px] ${
          iconRight
            ? `right-icon after:absolute after:right-3 ${iconRight}`
            : `flex-wrap`
        } ${
          iconRightEdge &&
          `${iconRightEdge} right-icon after:absolute after:right-[6%] after:z-10`
        } ${
          iconLeftEdge &&
          `${iconLeftEdge} left-icon before:absolute before:left-[6%] before:z-10`
        } ${iconLeft && `left-icon ${iconLeft}`}`}
      >
        <span className="flex flex-col items-center justify-center h-full">
          {text.split("\n").map((t, i) => (
            <span key={i}>
              {t}
              <br />
            </span>
          ))}
        </span>
      </span>
    </button>
  );
};

export default BaseButton;
