import React from "react";
import IconArrowTextPageTop from "../../../../images/icons/icon_arrow-text-pagetop.png";

const ScrollToPageTopButton: React.FC = () => {
  return (
    <button
      type="button"
      className="flex items-center justify-center w-11 h-11 bg-white rounded-full sm:w-14 sm:h-14"
      style={{
        border: "border: 25px solid var(--unnamed-color-ffffff)",
        boxShadow: "0px 3px 6px #00000029"
      }}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      <img
        src={IconArrowTextPageTop}
        className="h-[75%] w-[58%] mx-auto my-auto"
      />
    </button>
  );
};

export default ScrollToPageTopButton;
