import React, { useEffect } from "react";
import FacebookButton from "../../../components/Common/Buttons/FacebookButton";
import TwitterButton from "../../../components/Common/Buttons/TwitterButton";
import HowToShare from "../../../components/Common/HowToShare";
import Share from "../../../components/Common/Share"
import { Client, Investment, SettlementResult } from "../../../types/pages/Clients/MonthlyDonations/create";

type Props = {
  investment: Investment
  client: Client
  result: SettlementResult
}

const Complete: React.FC<Props> = ({client, investment, result}: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto md:px-0">
      <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">支援のお手続き</h2>
      <div className="sm:w-[700px] sm:mx-auto">
        <section className="mb-[60px]">
          <div className="mb-[30px] flex">
            <div className="w-[80px] h-[60px] mr-3 sm:w-[120px] sm:h-[90px]">
              <img
                className="object-cover"
                src={client.logo_image_url}
                alt={client.name}
              />
            </div>
            <h2 className="w-[calc(100%-92px)] sm:w-[calc(100%-132px)]">{client.name}</h2>
          </div>
          <div className="flex">
            <div className="w-[16%] border-[#1F2122] flex items-center justify-center border">
              1
            </div>
            <div className="w-[16%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
              2
            </div>
            <div className="h-[40px] bg-[#1F2122] w-[68%] relative flex items-center justify-center text-center text-white text-sm font-bold">
              <span className="text-[#1F2122] bg-[#768084] rounded-[50%] inline-block mr-1 w-5 h-5 text-center">
                3
              </span>
              支援完了
            </div>
          </div>
        </section>
        <p className="font-bold mb-[60px] text-center">ご支援ありがとうございます！</p>
        <section className="bg-[#F4F6F8] pt-9 pb-5 px-10 mb-[80px]">
          <div className="pb-5">
            <h3 className="font-bold text-center">現在のプロジェクト支援額</h3>
            <div className="text-center flex items-center justify-center flex-col sm:flex-row">
              <div className="font-bold text-3xl">{result.previous.toLocaleString()}<span className="text-lg">円</span></div>
              <div className="sm:right-triangle sm:mx-6 down-triangle-black mt-2 mb-1 sm:my-0"></div>
              <div className="font-bold text-[40px]">{result.current.toLocaleString()}<span className="text-lg">円</span></div>
            </div>
          </div>
          <div className="border-t border-[#D2DFE6] pt-5 text-center">
            {result.remaining <= 0 ? (
              <p className="text-sm">目標金額({result.target_amount.toLocaleString()}円)を達成しました！</p>
            ) : (
              <p className="text-sm">目標金額({result.target_amount.toLocaleString()}円)まで<br className="sm:hidden" />あと{result.remaining.toLocaleString()}円となりました！</p>
            )}
          </div>
        </section>
        <div className="w-screen mx-[calc(50%-50vw)] bg-[#F4F6F8] py-[80px] mb-[80px] px-[30px] sm:px-0">
          <div className="sm:w-[700px] sm:mx-auto bg-white">
            <div className="mb-5">
              <Share
                investmentTitle={investment.title}
                widgetURL={investment.widget_url}
                referralURL={investment.referral_url}
              />
            </div>
            <div className="px-10 mb-[30px]">
              <HowToShare />
            </div>
            <Share
              investmentTitle={investment.title}
              widgetURL={investment.widget_url}
              referralURL={investment.referral_url}
            />
          </div>
        </div>
        <div className="mb-[60px]">
          <p className="font-bold text-lg text-center sm:mb-10 mb-5">友達にお知らせして<br className="sm:hidden" />プロジェクトを成功させましょう</p>
          <div className="sm:flex sm:justify-between">
            <div className="sm:w-[335px] w-full mb-[10px] sm:mb-0">
              <TwitterButton href={investment.referral_url} text="ポストする" title={investment.title} />
            </div>
            <div className="sm:w-[335px] w-full">
              <FacebookButton href={investment.referral_url} text="シェアする" title={investment.title} />
            </div>
          </div>
        </div>
        <div className="text-center">
          <a href={`${client.investment_path}#community`} className="font-meidum text-sm right-icon icon-stick-arrow-right">みんなの応援メッセージを見る</a>
        </div>
      </div>
    </section>
  );
};

export default Complete;
