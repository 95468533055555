import React, { FC } from "react";

type BreadcrumbItem = {
  text: string;
  href?: string;
};

type BreadcrumbProps = {
  items: BreadcrumbItem[];
};

const BreadcrumbsSP: FC<BreadcrumbProps> = ({ items }) => {
  return (
    <div className="px-[30px] border-[#D2DFE6] py-5 w-full border-t border-solid sm:hidden">
      {items &&
        <ul>
          {items.map((item, i) =>
            item.href ? (
              <React.Fragment key={i}>
                {i === 0 ?
                  (
                    <li className="left-icon icon-arrow-up flex items-center mb-1">
                      <a className="link-hover-none text-[#B1C2CC] before:content-['-'] before:inline-block text-xs" href={item.href}>
                        {items[0].text}
                      </a>
                    </li>
                  ) : (
                    <li className="text-[#B1C2CC] before:content-['-'] before:inline-block text-xs pl-[18px] mb-1">
                      <a href={item.href} className="link-hover-none">
                        {item.text}
                      </a>
                    </li>
                  )
                }
              </React.Fragment>
            ) : (
              <li key={i} className="before:content-['-'] before:inline-block text-xs pl-[18px]">
                {item.text}
              </li>
            )
          )}
        </ul>
      }
    </div>
  );
};

export default BreadcrumbsSP;
