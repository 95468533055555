import React, { useMemo } from "react";
import { comment } from "../../types/pages/Team/detail";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { truncateText } from "../../utils/TextUtil";
import CommentSlider from "../Common/CommentSlider";
import SecondaryButton from "../Common/Buttons/SecondaryButton";

type Props = {
  commentList: Array<comment>;
  moveToCommunityTab: () => void;
  moveToCommunitySPTab: () => void;
};

const Comment: React.FC<Props> = (props) => {
  const { commentList, moveToCommunityTab, moveToCommunitySPTab } = props;

  const chunkedArr = useMemo(() => {
    const chunked = [];
    for (let i = 0; i < commentList?.length; i += 3) {
      chunked.push(commentList.slice(i, i + 3));
    }
    return chunked;
  }, [commentList]);

  return (
    <>
      <div className="bg-[#F4F6F8]">
        <section className="hidden sm:block max-w-main mx-auto py-[30px] px-[30px] md:px-0">
          <h3 className="text-sm mb-[15px]">
            コミュニティメッセージ
          </h3>
          <ul>
            <CommentSlider>
              {chunkedArr.map((arr, n) => (
                <li key={n}>
                  <ul className="flex">
                    {arr.map((item, m) => (
                      <li
                        className="border-[#D2DFE6] first:pl-0 last:pr-0 px-5 w-1/3 text-xs border-r last:border-none"
                        key={m}
                      >
                        <p className="text-[#768084] mb-4">
                          {truncateText(item.author, 20)}
                          {item.time_ago_in_words}
                        </p>
                        <p className="line-clamp-4">{item.comment}</p>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </CommentSlider>
          </ul>
          <div className="mx-auto w-[220px] h-[40px] mt-6">
            <SecondaryButton
              text="Read more"
              iconRight="icon-arrow-under-small"
              fontRaleway={true}
              onClick={moveToCommunityTab}
            />
          </div>
        </section>
      </div>
      <section className="sm:hidden py-8 bg-[#F4F6F8] px-[30px]">
        <h3 className="text-sm mb-[15px]">
          コミュニティメッセージ
        </h3>
        <ul>
          <CommentSlider>
            {commentList?.map((item, i) => (
              <li className="text-xs" key={i}>
                <p className="text-[#768084] mb-4">
                  {truncateText(item.author, 40)} {item.time_ago_in_words}
                </p>
                <p className="line-clamp-4">{item.comment}</p>
              </li>
            ))}
          </CommentSlider>
        </ul>
        <div className="mx-auto w-[150px] h-[40px] mt-6">
          <SecondaryButton
            text="Read more"
            iconRight="icon-arrow-under-small"
            fontRaleway={true}
            onClick={moveToCommunitySPTab}
          />
        </div>
      </section>
    </>
  );
};

export default Comment;
