import React from "react";
import IconXValid from "../../../../images/icons/x_logo_valid.svg";
import IconXInvalid from "../../../../images/icons/x_logo_invalid.svg";

type Props = {
  client: boolean;
};

const ClientTwitterButton: React.FC<Props> = ({ client }) => {
  const img = client ? IconXValid : IconXInvalid;

  return (
    <img
      src={img}
      alt="twitter"
      className="w-[25px] h-[25px]"
    />
  );
};

export default ClientTwitterButton;

