import axios from "axios";

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute("content"),
};

export const HttpRequest = axios;
