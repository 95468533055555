import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

type Props = {
  id: string;
  register: UseFormRegisterReturn;
  label: string;
  value: string;
  defaultChecked: boolean;
};
const Radio: React.FC<Props> = (props) => {
  const { id, register, label, value, defaultChecked } = props;

  return (
    <div className="flex">
      <input
        defaultChecked={defaultChecked}
        value={value}
        id={id}
        type="radio"
        {...register}
        className="radio-btn mr-[10px] before:content-[''] before:rounded-[50%] before:border-[#B1C2CC] relative flex before:inline-block items-center before:w-5 before:h-5 before:border appearance-none"
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Radio;
