import React from "react";
import CommentIcon from "../../../../images/icon_comment.svg";
import LogoIcon from "../../../../images/icon_logo.png";

type Props = {};

const KeyVisualProjectButton: React.FC<Props> = (props) => {
  return (
    <>
      <div className="flex flex-col justify-center w-full sm:flex-row">
        <div className="flex justify-center w-full sm:mr-5 sm:w-72">
          <button className="btn mb-10 w-11/12 w-72 max-w-xs h-16 text-white text-lg font-bold hover:bg-blue-500 bg-blue-600 border-4 border-white rounded-sm sm:w-72">
            <div className="flex flex-row justify-center">
              <div
                style={{
                  background: `url(${CommentIcon}) no-repeat`,
                  content: "",
                  width: "26px",
                  height: "22px",
                }}
                className="ml-2 mr-2 w-7 h-6"
              />
              <div>プロジェクトを相談したい</div>
            </div>
          </button>
        </div>

        <div className="flex justify-center w-full sm:ml-5 sm:w-72">
          <button className="btn w-11/12 max-w-xs h-16 hover:text-blue-500 text-blue-600 text-lg font-bold hover:bg-gray-100 bg-white border-4 border-blue-600 rounded-sm sm:w-72">
            <div className="flex flex-row justify-center">
              <div
                style={{
                  background: `url(${LogoIcon}) no-repeat`,
                  backgroundSize: "contain",
                  content: "",
                  width: "24px",
                  height: "24px",
                }}
                className="ml-2 mr-2 w-7 h-6"
              />
              <div>スポチュニティとは</div>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default KeyVisualProjectButton;
