import React, { useEffect, useMemo, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderWrapper: React.FC<{ col: number }> = ({ children, col }) => {
  const wrapper = useRef(null);

  useEffect(() => {
    const wrapperCurrent = wrapper.current;
    const totalItems = wrapperCurrent?.querySelectorAll(".slick-slide").length
    if (totalItems > col) {
      const stalker = wrapperCurrent.querySelector(".stalker");
      wrapperCurrent.addEventListener("mouseover", () => {
        if (stalker) {
          stalker.style.display = "block";
        }
      });
      wrapperCurrent.addEventListener("mouseout", () => {
        if (stalker) {
          stalker.style.display = "none";
        }
      });
      document.addEventListener("mousemove", (e) => {
        if (stalker) {
          stalker.style.transform =
            "translate(" + e.clientX + "px, " + e.clientY + "px)";
        }
      });
    }

  }, []);

  const cardCol = useMemo(() => {
    switch (col) {
      case 3:
        return {
          arrows: true,
          infinite: false,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1
        };
      case 4:
        return {
          arrows: true,
          infinite: false,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1
        };
      case 5:
        return {
          arrows: true,
          infinite: false,
          speed: 500,
          slidesToShow: 5,
          slidesToScroll: 1
        };
      case 6:
        return {
          arrows: true,
          infinite: false,
          speed: 500,
          slidesToShow: 6,
          slidesToScroll: 1,
        };;
      default:
        return null;
    }
  }, [col])

  return (
    <div className="slider-wrapper relative" ref={wrapper}>
      <Slider {...cardCol}>{children}</Slider>
      <div className="stalker"></div>
    </div>
  );
};

export default SliderWrapper;
