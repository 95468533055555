import React, { useRef } from "react";
import BaseButton from "./Buttons/BaseButton";

type Props = {
  toggleModal: () => void;
  onSubmit: () => void;
  isActive: boolean;
};

const SearchModal: React.FC<Props> = (props) => {
  const { toggleModal, onSubmit, isActive, children } = props;
  const modalRef = useRef(null);
  if (isActive && modalRef.current) {
    const scroll = window.pageYOffset;
    modalRef.current.style.top = `${scroll + 80}px`;
  }

  return (
    <>
      <div
        className={`${
          isActive
            ? "block fixed z-50 top-0 w-screen h-screen bg-black opacity-60 right-0 left-0"
            : "hidden"
        }`}
      ></div>
      <div
        className={`${
          isActive
            ? "block z-50 absolute right-0 left-0 w-[90%] mx-auto"
            : "hidden"
        }`}
        ref={modalRef}
      >
        <div className="p-[30px] bg-white sm:w-[900px] sm:mx-auto sm:py-[80px] sm:px-[100px]">
          {children}
          <div className="mt-[30px] sm:mx-auto h-[60px] sm:w-[275px] sm:h-[50px]">
            <BaseButton
              text="この条件で絞り込む"
              onClick={() => onSubmit()}
            />
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <button
            onClick={() => toggleModal()}
            className="icon-close left-icon w-[180px] h-[40px] flex items-center justify-center font-raleway bg-white rounded-default opacity-80"
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default SearchModal;
