import React from "react";
import { fontSize } from "../../../types/common";

type color = "text-[#1F2122]"

type Props = {
  text: string;
  iconLeft?: string;
  iconRight?: string;
  iconRightEdge?: string;
  iconLeftEdge?: string;
  disabled?: boolean;
  fontRaleway?: boolean;
  fontSize?: fontSize;
  color?: color;
  isSubmit?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const SecondaryButton: React.FC<Props> = (props) => {
  const { fontSize = "text-sm", text, iconRight, iconLeft, iconRightEdge, iconLeftEdge, disabled = false, fontRaleway = false, color = "text-[#768084]", isSubmit = false, onClick } = props;

  return (
    <button
      className={`${fontSize} ${color} ${disabled && "opacity-60 btn-hover-secondary pointer-events-none"} h-full w-full btn-hover-secondary border-[#B1C2CC] inline-block border rounded-default`}
      onClick={(e) => {if (onClick) {onClick(e)}}}
      disabled={disabled}
      type={isSubmit ? "submit" : "button"}
    >
      <span
        style={{ position: "relative" }}
        className={`${
          fontRaleway ? "font-raleway" : ""
        } z-10 ${iconLeftEdge} ${iconRightEdge} ${
          iconRightEdge ? "right-icon after:absolute after:right-3" : ""
        } ${iconLeftEdge ? "left-icon" : ""} flex items-center justify-center`}
      >
        <span className={`${iconRight ? `${iconRight} right-icon after:absolute after:-right-3 flex items-center relative` : ""} ${iconLeft ? `${iconLeft} left-icon after:absolute after:-left-3 flex items-center relative` : ""}`}>
          {text}
        </span>
      </span>
    </button>
  );
};

export default SecondaryButton;
