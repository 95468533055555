export const calcTargetAmountPercentage = (progressing: number, target: number) => {
  progressing ? progressing : 0;
  target ? target : 100;
  return Math.floor((progressing / target) * 100);
};

export const calcProgressingBarPercentage = (progressing: number, firstGoalAmount: number, nextGoalAmount?: number) => {
  if (nextGoalAmount) {
    if (firstGoalAmount > progressing) {
      return [Math.floor(progressing / nextGoalAmount * 100)]
    } else {
      return [Math.floor(firstGoalAmount / nextGoalAmount * 100), Math.min(100 - Math.floor(firstGoalAmount / nextGoalAmount * 100),  Math.floor((progressing - firstGoalAmount) / nextGoalAmount * 100))]
    }
  } else {
    return [Math.floor(progressing / firstGoalAmount * 100)]
  }
}
