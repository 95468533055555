import { useEffect, useState } from "react";

export const useSearchInput = (initialValue) => {
  const [value, setValue] = useState<string>(initialValue);
  const [isShowSearchResult, setIsShowSearchResult] = useState<boolean>(false);
  useEffect(() => {
    if (location.search) {
      setIsShowSearchResult(true);
    }
  }, []);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const search = (e) => {
    if (e.key === "Enter") {
      location.href = `/search?q=${value}`;
    }
  };

  return { value, setValue, handleChange, search, isShowSearchResult };
};
