import React, { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

type Props = {
  textLeftIcon?: any;
  textRightIcon?: any;
  buttonText: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  disabled?: boolean;
  style?: object;
  textClassName?: string;
  buttonType?: ButtonHTMLAttributes<string>['type']
};

const PrimaryButton: React.FC<Props> = (props) => {
  const {
    textLeftIcon,
    textRightIcon,
    buttonText,
    onClick,
    className = "",
    disabled = false,
    style = {},
    textClassName = "",
    buttonType = "button",
  } = props;
  return (
    <button
      className={classNames(
        "flex items-center justify-center w-full rounded",
        className,
        disabled
          ? "bg-primary opacity-70 hover:cursor-default"
          : "bg-primary hover:bg-blue-800"
      )}
      onClick={(e) => {if (onClick) {onClick(e)}}}
      type={buttonType}
      disabled={disabled}
      style={style}
    >
      {textLeftIcon ?? ""}
      <span className={classNames("mx-1 text-white", textClassName)}>
        {buttonText}
      </span>
      {textRightIcon ?? ""}
    </button>
  );
};

export default PrimaryButton;
