import React, { useMemo } from "react";
import Heading from "../../Common/Heading"
import { projectStatusToString } from "../../../utils/constants";
import "../../../../stylesheets/investmentSmallCard.scss"
import { truncateText } from "../../../utils/TextUtil";
import { calcTargetAmountPercentage, calcProgressingBarPercentage } from "../../../utils/progressingUtil"

type Props = {
  investmentName: string;
  clientName: string;
  investmentPath: string;
  supporterCount: number;
  totalAmount: number;
  headingElement: number;
  imgUrl: string;
  teamIcon: string;
  projectStatus: number;
  targetAmount: number;
  nextTargetAmount: number;
};

const InvestmentSmallCard: React.FC<Props> = (props) => {
  const {
    investmentName,
    clientName,
    investmentPath,
    supporterCount,
    totalAmount,
    headingElement,
    imgUrl,
    teamIcon,
    projectStatus,
    targetAmount,
    nextTargetAmount
  } = props

  const status = useMemo(() => {
    return projectStatusToString(projectStatus)
  }, [projectStatus])

  const percent = useMemo(() => {
    return calcTargetAmountPercentage(totalAmount, targetAmount)
  }, []);

  return (
    <article className={"w-full investment-small-card" + (projectStatus === 5 && " opacity-60")}>
      <a href={investmentPath} draggable="false" className="link-hover-none">
        <div className="bg-[#1F2122] px-5 h-[20px] leading-[20px] mb-1 text-center text-white text-xs font-raleway inline-block">
          {status}
        </div>
        <div className="mb-[10px] w-full overflow-hidden">
          <img
            src={imgUrl}
            alt={investmentName}
            className="aspect-[4/3] bg-white key-visual"
          />
        </div>
        <Heading className="mb-[10px] h-[48px] font-bold overflow-hidden" headingElement={headingElement}>
          <span className="text-sm font-bold title">
            {truncateText(investmentName, 25)}
          </span>
        </Heading>
        <div className="mb-[10px] flex items-center">
          <div className="w-[34px] h-[34px] hidden sm:block sm:mr-2">
            <img
              src={teamIcon}
              alt={clientName}
              className="object-contain"
            />
          </div>
          <div className="text-[#768084] line-clamp-1 h-[15px] w-[calc(100%-42px)] sm:line-clamp-2 sm:h-[30px] text-xs">
            {clientName}
          </div>
        </div>
        <div className="hidden justify-between pb-3 sm:flex">
          <dl className="hidden w-full text-xs sm:flex">
            <dt className="text-[#768084] mr-[10px]">支援者数</dt>
            <dd>{supporterCount.toLocaleString()}人</dd>
          </dl>
        </div>
        <div className="w-[10px] h-[1px] bg-[#1F2122]"></div>
        <div className="pb-4 pt-1">
          <div className="flex items-center justify-between">
            <div className="text-[13px] flex">
              {percent}% <span className="hidden sm:block">Funded</span>
            </div>
            <div className="font-bold">{totalAmount.toLocaleString()}円</div>
          </div>
          <div className="bg-[#D2DFE6] h-[6px] relative flex items-center">
            <div
              className="bg-[#1F2122] h-[8px]"
              style={{
                width: `${calcProgressingBarPercentage(totalAmount, targetAmount, nextTargetAmount)[0]}%`,
              }}
            ></div>
            {nextTargetAmount && (
              <div
                className={`bg-[#768084] h-[8px] absolute top-[-1px]`}
                style={{
                  width: `${calcProgressingBarPercentage(totalAmount, targetAmount, nextTargetAmount)[1]}%`,
                  left: `${calcProgressingBarPercentage(totalAmount, targetAmount, nextTargetAmount)[0]}%`
                }}
              ></div>
            )}
          </div>
        </div>
        <dl className="flex w-full text-xs sm:hidden">
          <dt className="text-[#768084] mr-[10px]">支援者数</dt>
          <dd>{supporterCount.toLocaleString()}人</dd>
        </dl>
      </a>
    </article>
  );
};

export default InvestmentSmallCard;
