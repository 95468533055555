import React from "react";
import { prefectureData } from "../../types/pages/ranking"

type Props = {
  innerItem: boolean;
  data: prefectureData;
  current: boolean;
  prefectureName: string;
}

const PrefectureItem: React.FC<Props> = (props) => {
  const {
    innerItem,
    data,
    current,
    prefectureName,
  } = props;
  
  const innerClassName = innerItem ? 'innerItem' : 'item'

  if (current) {
    return <li className={`accordionList__${innerClassName} accordionList__${innerClassName}--crnt`}><span>{prefectureName}</span></li>
  } else if (data == null) {
    return <li className={`accordionList__${innerClassName} accordionList__${innerClassName}--disable`}><span>{prefectureName}</span></li>
  } else {
    return <li className={`accordionList__${innerClassName}`}><a href={`/ranking/${data.slug}`} className="link-hover-none">{prefectureName}</a></li>
  }
}

export default PrefectureItem;
