import React from "react";
import Kv from "../../images/kv.jpg";
import KvSp from "../../images/kv_sp.jpg";
import BaseLinkButton from "../components/Common/Buttons/BaseLinkButton";
import SecondaryLinkButtonWhite from "../components/Common/Buttons/SecondaryLinkButtonWhite";
import InvestmentCard from "../components/Common/Cards/InvestmentCard";
import InvestmentSmallCard from "../components/Common/Cards/InvestmentSmallCard";
import Banner from "../components/Common/Banner";
import SliderWrapper from "../components/Common/SliderWrapper";
import ColumnImg from "../../images/column_banner.jpg";
import { truncateText } from "../utils/TextUtil";
import Layout from "../components/Common/Layout";
import {investment, news, sportsCategory} from "../types/pages/top";
import NoImg from "../../images/noimg.png";
import UsersVoiceImg1 from "../../images/users_voice007.jpg"
import UsersVoiceImg2 from "../../images/users_voice009.jpg"
import UsersVoiceImg3 from "../../images/users_voice008.jpg"
import UsersVoiceImg4 from "../../images/users_voice011.jpg"
import UsersVoiceImg5 from "../../images/users_voice05.jpg"
import UsersVoiceImg6 from "../../images/users_voice06.jpg"

type Props = {
  newss: news[],
  pickupInvestments: investment[],
  endingInvestments: investment[],
  incomingInvestments: investment[],
  activeInvestments: investment[],
  pastInvestments: investment[],
  sportsCategories: sportsCategory[];
  canTip: boolean;
};

const Top: React.FC<Props> = (props) => {
  const users_voice = [
    {
      link: "https://corp.spportunity.com/users_voice/voice007",
      client_name: "名古屋グランパス 谷藤宰様",
      title: "「次の30年の未来に向けて、グランパスファミリーのみなさんと共にクラブの未来を創っていく」、その誓いを込めて、新たに「GRAMPUS SOCIO PROJECT」を始動しました。",
      img: UsersVoiceImg1
    },
    {
      link: "https://corp.spportunity.com/users_voice/voice009",
      client_name: "ベガルタ仙台・庄子勝裕様",
      title: "設立30周年をファンの皆様とともに充実した1年にするために、2023シーズンの感謝を込めて、「ARIGATO SALE」と「カントリーロード大予約会」と題した２つのクラウドファンディングを実施しました。",
      img: UsersVoiceImg2
    },
    {
      link: "https://corp.spportunity.com/users_voice/voice008",
      client_name: "ジェフユナイテッド市原・千葉様",
      title: "クラウドファンディングの目的や意義をどのように考えていらっしゃるのか、スポチュニティのサービスには満足いただけたか、ジェフユナイテッド市原・千葉のプロジェクト実行者様にお話を伺いました。",
      img: UsersVoiceImg3
    },
    {
      link: "https://corp.spportunity.com/users_voice/voice011",
      client_name: "一般社団法人なでしこケア様",
      title: "クラウドファンディングの目的や意義をどのように考えていらっしゃるのか、スポチュニティのサービスには満足いただけたか、語っていただきました。",
      img: UsersVoiceImg4
    },
    {
      link: "https://corp.spportunity.com/users_voice/users_voice2/",
      client_name: "サフィルヴァ北海道",
      title: "人間関係を大事にクラウドファンディングを。選手のやる気が大きな成果を生んだ。",
      img: UsersVoiceImg5
    },
    {
      link: "https://corp.spportunity.com/users_voice/users_voice1/",
      client_name: "岩手ビッグブルズ",
      title: "クラウドファンディングは、ファンの皆様とのコミュニケーションツール。スポチュニティの親身なサポートには大変満足しています。",
      img: UsersVoiceImg6
    },
  ]
  return (
    <Layout>
      <div className="bg-[#0A0F15]">
        <div className="hidden sm:block">
          <img src={Kv} className="min-h-[470px] object-cover" />
        </div>
        <div className="h-[63.2vw] block w-full object-cover sm:hidden">
          <img src={KvSp} />
        </div>
        <div className="h-[288px] relative mx-auto max-w-main sm:h-auto">
          <section className="sm:px-[45px] sm:pt-[50px] sm:pb-[32px] absolute bottom-0 px-9 py-8 bg-white">
            <h2 className="mb-[10px] sm:mb-[14px] text-2xl font-bold">
              その応援が
              <br />
              スポーツの未来を創る
            </h2>
            <p className="text-[#768084] text-sm">
              どんな時でも思いをつむぐを大事にする
              <br />
              スポーツ特化型クラウドファンディング
            </p>
            <div className="mt-5 sm:mt-9">
              <div className="h-[50px]">
                <BaseLinkButton
                  text="プロジェクトをはじめたい"
                  link="https://corp.spportunity.com/clubteam/"
                />
              </div>
              <div className="mt-4 text-center sm:mt-6">
                <a href="https://corp.spportunity.com/clubteam/" className="inline-block">
                  <span className="icon-arrow-circle right-icon flex items-center text-sm">
                    選ばれる理由
                  </span>
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Banner />
      <div className="bg-[#F4F6F8] py-[80px] pl-[30px] sm:py-[160px] lg:pl-0">
        <section className="mx-auto w-full sm:max-w-main">
          <h2 className="text-[#B1C2CC] text-[48px] leading-[1.04] pb-[60px] sm:text-[60px] sm:pb-[80px] font-raleway">
            FEATURED PROJECT
          </h2>
          <section>
            <h3 className="text-[15px] before:h-[1px] before:bg-[#B1C2CC] before:content-[''] before:w-[25px] sm:before:w-[50px] sm:before:relative sm:mb-[50px] before:absolute before:left-0 flex before:inline-block items-center mb-5 before:mr-1">
              注目のプロジェクト
            </h3>
            <div className="hidden lg:block slider-col-3">
              <SliderWrapper col={3}>
                {props.pickupInvestments.map((item, i) => (
                  <div className="w-[300px]" key={i}>
                    <InvestmentCard
                      projctID={item.id}
                      investmentPath={item.investment_path}
                      clientName={item.client_name}
                      totalAmount={item.total_amount}
                      supporterCount={item.supporter_count}
                      clientTotalAmount={item.client_total_amount}
                      clientSupporterCount={item.client_supporter_count}
                      remainDate={item.remain_date}
                      investmentName={item.investment_name}
                      headingElement={3}
                      imgUrl={item.image_url}
                      teamIcon={item.team_icon}
                      targetAmount={item.target_amount}
                      projectStatus={item.project_status}
                      nextTargetAmount={item.next_target_amount}
                      canTip={props.canTip}
                    />
                  </div>
                ))}
              </SliderWrapper>
            </div>
            <div className="flex overflow-x-auto lg:hidden">
              {props.pickupInvestments.map((item, i) => (
                <React.Fragment>
                  <div className="min-w-[300px] mr-[30px]">
                    <InvestmentCard
                      projctID={item.id}
                      investmentPath={item.investment_path}
                      clientName={item.client_name}
                      totalAmount={item.total_amount}
                      supporterCount={item.supporter_count}
                      clientTotalAmount={item.client_total_amount}
                      clientSupporterCount={item.client_supporter_count}
                      remainDate={item.remain_date}
                      investmentName={item.investment_name}
                      headingElement={3}
                      imgUrl={item.image_url}
                      teamIcon={item.team_icon}
                      targetAmount={item.target_amount}
                      projectStatus={item.project_status}
                      nextTargetAmount={item.next_target_amount}
                      canTip={props.canTip}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
          </section>
          {/*<section className="mt-[120px]">*/}
          {/*  <h3 className="text-[15px] before:h-[1px] before:bg-[#B1C2CC] before:content-[''] before:w-[25px] sm:before:w-[50px] sm:before:relative sm:mb-[50px] before:absolute before:left-0 flex before:inline-block items-center mb-5 before:mr-1">*/}
          {/*    注目のリターン*/}
          {/*  </h3>*/}
          {/*  <div className="hidden lg:block slider-col-4">*/}
          {/*    <SliderWrapper>*/}
          {/*      <Slider {...cardCol4}>*/}
          {/*        {[1, 2, 3, 4, 5].map((i) => (*/}
          {/*          <React.Fragment key={i}>*/}
          {/*            <ReturnCard />*/}
          {/*          </React.Fragment>*/}
          {/*        ))}*/}
          {/*      </Slider>*/}
          {/*    </SliderWrapper>*/}
          {/*  </div>*/}
          {/*  <div className="flex overflow-x-auto lg:hidden">*/}
          {/*    {[1, 2, 3, 4, 5].map((i) => (*/}
          {/*      <div className="mr-[15px]" key={i}>*/}
          {/*        <ReturnCard />*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*</section>*/}
        </section>
      </div>
      <div className="py-[80px] pl-[30px] sm:py-[160px] lg:pl-0">
        <section className="mx-auto w-full sm:max-w-main">
          <h2 className="text-[#B1C2CC] text-[48px] leading-[1.04] pb-[60px] sm:text-[60px] sm:pb-[80px] font-raleway">
            PROJECT
          </h2>
          { props.endingInvestments.length > 0 && (
            <section className="mb-[120px]">
              <h3 className="text-[15px] before:h-[1px] before:bg-[#B1C2CC] before:content-[''] before:w-[25px] sm:before:w-[50px] sm:before:relative sm:mb-[50px] before:absolute before:left-0 flex before:inline-block items-center mb-5 before:mr-1">
                もうすぐ終了するプロジェクト
              </h3>
              <div className="hidden lg:block slider-col-3">
                <SliderWrapper col={3}>
                  {props.endingInvestments.map((item, i) => (
                    <div className="w-[300px]" key={i}>
                      <InvestmentCard
                        projctID={item.id}
                        investmentPath={item.investment_path}
                        clientName={item.client_name}
                        totalAmount={item.total_amount}
                        supporterCount={item.supporter_count}
                        clientTotalAmount={item.client_total_amount}
                        clientSupporterCount={item.client_supporter_count}
                        remainDate={item.remain_date}
                        investmentName={item.investment_name}
                        headingElement={4}
                        imgUrl={item.image_url}
                        teamIcon={item.team_icon}
                        targetAmount={item.target_amount}
                        projectStatus={item.project_status}
                        nextTargetAmount={item.next_target_amount}
                        canTip={props.canTip}
                      />
                    </div>
                  ))}
                </SliderWrapper>
              </div>
              <div className="flex overflow-x-auto lg:hidden">
                {props.endingInvestments.map((item, i) => (
                  <div className="min-w-[300px] mr-[30px]" key={i}>
                    <InvestmentCard
                      projctID={item.id}
                      investmentPath={item.investment_path}
                      clientName={item.client_name}
                      totalAmount={item.total_amount}
                      supporterCount={item.supporter_count}
                      clientTotalAmount={item.client_total_amount}
                      clientSupporterCount={item.client_supporter_count}
                      remainDate={item.remain_date}
                      investmentName={item.investment_name}
                      headingElement={4}
                      imgUrl={item.image_url}
                      teamIcon={item.team_icon}
                      targetAmount={item.target_amount}
                      projectStatus={item.project_status}
                      nextTargetAmount={item.next_target_amount}
                      canTip={props.canTip}
                    />
                  </div>
                ))}
              </div>
            </section>
          )}
          { props.activeInvestments.length > 0 && (
            <section className="mb-[120px]">
              <h3 className="text-[15px] before:h-[1px] before:bg-[#B1C2CC] before:content-[''] before:w-[25px] sm:before:w-[50px] sm:before:relative sm:mb-[50px] before:absolute before:left-0 flex before:inline-block items-center mb-5 before:mr-1">
                公開中のプロジェクト
              </h3>
              <div className="hidden lg:block slider-col-3">
                <SliderWrapper col={3}>
                  {props.activeInvestments.map((item, i) => (
                    <div className="w-[300px]" key={i}>
                      <InvestmentCard
                        projctID={item.id}
                        investmentPath={item.investment_path}
                        clientName={item.client_name}
                        totalAmount={item.total_amount}
                        supporterCount={item.supporter_count}
                        clientTotalAmount={item.client_total_amount}
                        clientSupporterCount={item.client_supporter_count}
                        remainDate={item.remain_date}
                        investmentName={item.investment_name}
                        headingElement={4}
                        imgUrl={item.image_url}
                        teamIcon={item.team_icon}
                        targetAmount={item.target_amount}
                        projectStatus={item.project_status}
                        nextTargetAmount={item.next_target_amount}
                        canTip={props.canTip}
                      />
                    </div>
                  ))}
                </SliderWrapper>
              </div>
              <div className="flex overflow-x-auto lg:hidden">
                {props.activeInvestments.map((item, i) => (
                  <div className="min-w-[300px] mr-[30px]" key={i}>
                    <InvestmentCard
                      projctID={item.id}
                      investmentPath={item.investment_path}
                      clientName={item.client_name}
                      totalAmount={item.total_amount}
                      supporterCount={item.supporter_count}
                      clientTotalAmount={item.client_total_amount}
                      clientSupporterCount={item.client_supporter_count}
                      remainDate={item.remain_date}
                      investmentName={item.investment_name}
                      headingElement={3}
                      imgUrl={item.image_url}
                      teamIcon={item.team_icon}
                      targetAmount={item.target_amount}
                      projectStatus={item.project_status}
                      nextTargetAmount={item.next_target_amount}
                      canTip={props.canTip}
                    />
                  </div>
                ))}
              </div>
            </section>
          )}
          { props.incomingInvestments.length > 0 && (
            <section className="mb-[120px]">
              <h3 className="text-[15px] before:h-[1px] before:bg-[#B1C2CC] before:content-[''] before:w-[25px] sm:before:w-[50px] sm:before:relative sm:mb-[50px] before:absolute before:left-0 flex before:inline-block items-center mb-5 before:mr-1">
                もうすぐ公開するプロジェクト
              </h3>
              <div className="hidden lg:block slider-col-3">
                <SliderWrapper col={3}>
                  {props.incomingInvestments.map((item, i) => (
                    <div className="w-[300px]" key={i}>
                      <InvestmentCard
                        projctID={item.id}
                        isPublished={false}
                        investmentPath={item.investment_path}
                        clientName={item.client_name}
                        totalAmount={item.total_amount}
                        supporterCount={item.supporter_count}
                        clientTotalAmount={item.client_total_amount}
                        clientSupporterCount={item.client_supporter_count}
                        remainDate={item.remain_date}
                        investmentName={item.investment_name}
                        headingElement={4}
                        imgUrl={item.image_url}
                        teamIcon={item.team_icon}
                        targetAmount={item.target_amount}
                        projectStatus={item.project_status}
                        nextTargetAmount={item.next_target_amount}
                        canTip={props.canTip}
                      />
                    </div>
                  ))}
                </SliderWrapper>
              </div>
              <div className="flex overflow-x-auto lg:hidden">
                {props.incomingInvestments.map((item, i) => (
                  <div className="min-w-[300px] mr-[30px]" key={i}>
                    <InvestmentCard
                      projctID={item.id}
                      isPublished={false}
                      investmentPath={item.investment_path}
                      clientName={item.client_name}
                      totalAmount={item.total_amount}
                      supporterCount={item.supporter_count}
                      clientTotalAmount={item.client_total_amount}
                      clientSupporterCount={item.client_supporter_count}
                      remainDate={item.remain_date}
                      investmentName={item.investment_name}
                      headingElement={3}
                      imgUrl={item.image_url}
                      teamIcon={item.team_icon}
                      targetAmount={item.target_amount}
                      projectStatus={item.project_status}
                      nextTargetAmount={item.next_target_amount}
                      canTip={props.canTip}
                    />
                  </div>
                ))}
              </div>
            </section>
          )}
          <section>
            <h3 className="text-[15px] before:h-[1px] before:bg-[#B1C2CC] before:content-[''] before:w-[25px] sm:before:w-[50px] sm:before:relative sm:mb-[50px] before:absolute before:left-0 flex before:inline-block items-center mb-5 before:mr-1">
              終了したプロジェクト
            </h3>
            <ul className="hidden lg:block slider-col-4">
              <SliderWrapper col={4}>
                {props.pastInvestments.map((item, i) => (
                  <li className="w-[150px] sm:w-[220px]" key={i}>
                    <InvestmentSmallCard
                      investmentPath={item.investment_path}
                      clientName={item.client_name}
                      totalAmount={item.total_amount}
                      supporterCount={item.supporter_count}
                      investmentName={item.investment_name}
                      headingElement={4}
                      imgUrl={item.image_url}
                      teamIcon={item.team_icon}
                      projectStatus={item.project_status}
                      targetAmount={item.target_amount}
                      nextTargetAmount={item.next_target_amount}
                    />
                  </li>
                ))}
              </SliderWrapper>
            </ul>
            <ul className="flex overflow-x-auto lg:hidden">
              {props.pastInvestments.map((item, i) => (
                <li className="min-w-[150px] sm:w-[220px] mr-[15px]" key={i}>
                  <InvestmentSmallCard
                    investmentPath={item.investment_path}
                    clientName={item.client_name}
                    totalAmount={item.total_amount}
                    supporterCount={item.supporter_count}
                    investmentName={item.investment_name}
                    headingElement={4}
                    imgUrl={item.image_url}
                    teamIcon={item.team_icon}
                    projectStatus={item.project_status}
                    targetAmount={item.target_amount}
                    nextTargetAmount={item.next_target_amount}
                  />
                </li>
              ))}
            </ul>
            <div className="mx-auto w-[220px] h-[40px] mt-6 justify-center">
              <SecondaryLinkButtonWhite
                text="Read more"
                iconRight="icon-arrow-under-small"
                link={`/search/status?checkedSportCategoryList=101,102,100,106,200,999&checkedProjectStatusList=5`}
                fontSize="text-sm"
              />
            </div>
          </section>
        </section>
      </div>
      <div className="bg-[#F4F6F8] py-[50px] px-[30px] sm:py-[80px] lg:px-0">
        <section className="mx-auto sm:max-w-main">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="mb-[30px] md:mb-0">
              <h2 className="text-[#B1C2CC] text-[38px] leading-[1.04] sm:text-[46px] font-raleway sm:mb-2">
                CATEGORY
              </h2>
              <div className="text-[#B1C2CC] text-xs">
                支援スポーツカテゴリー
              </div>
            </div>
            <ul className="sm:w-[660px] flex flex-wrap">
              {props.sportsCategories.map((sportsCategory) => {
                // 大学運動部のiconはclubだったが、スタイルではcollegeで、clubは別のアイコンで使用されてしまっている。
                // const sportsCategoryIcon = sportsCategory.id == 200 ? 'college' : sportsCategory.icon;
                return (
                  <li className={`left-icon icon-${sportsCategory.icon} w-[50%] sm:mb-[44px] sm:w-[160px] flex items-center mb-6`}>
                    <a href={`/search/team?category_type=${sportsCategory.id}`} className="text-[15px]">
                      {/* 大学運動部・サークルは文字長が長いため、途中で改行を入れないとスタイル崩れる */}
                      {sportsCategory.id == 200 ? (
                        <>
                          大学運動部・<br />
                          サークル
                        </>
                      ) :(
                        sportsCategory.name
                      )}
                    </a>
                  </li>
                )
              })}
              {/* <li className="left-icon icon-soccer w-[50%] sm:mb-[44px] sm:w-[160px] flex items-center mb-6">
                <a href="" className="text-[15px]">
                  サッカー
                </a>
              </li>
              <li className="left-icon icon-baseball w-[50%] sm:mb-[44px] sm:w-[160px] flex items-center mb-6">
                <a href="" className="text-[15px]">
                  野球
                </a>
              </li>
              <li className="left-icon icon-basketball w-[50%] sm:mb-[44px] sm:w-[220px] flex items-center mb-6">
                <a href="" className="text-[15px]">
                  バスケットボール
                </a>
              </li>
              <li className="left-icon icon-soccer w-[50%] sm:mb-[44px] sm:w-[120px] flex items-center mb-6">
                <a href="" className="text-[15px]">
                  バレーボール
                </a>
              </li>
              <li className="left-icon icon-football w-[50%] sm:mb-[44px] sm:w-[160px] flex items-center mb-6">
                <a href="" className="text-[15px]">
                  フットサル
                </a>
              </li>
              <li className="left-icon icon-regatta w-[50%] sm:mb-[44px] sm:w-[160px] flex items-center mb-6">
                <a href="" className="text-[15px]">
                  レガッタ
                </a>
              </li>
              <li className="left-icon icon-american-football w-[50%] sm:mb-[28px] sm:w-[220px] flex items-center mb-6">
                <a href="" className="text-[15px]">
                  アメリカン
                  <br />
                  フットボール
                </a>
              </li>
              <li className="left-icon icon-canoe w-[50%] sm:mb-[44px] sm:w-[120px] flex items-center mb-6">
                <a href="" className="text-[15px]">
                  カヌー
                </a>
              </li>
              <li className="left-icon icon-tennis w-[50%] sm:w-[160px] flex items-center mb-6">
                <a href="" className="text-[15px]">
                  テニス
                </a>
              </li>
              <li className="left-icon icon-swimming w-[50%] sm:w-[160px] flex items-center mb-6">
                <a href="" className="text-[15px]">
                  水泳
                </a>
              </li>
              <li className="left-icon icon-college w-[50%] sm:w-[220px] flex items-center">
                <a href="" className="text-[15px]">
                  大学運動部・
                  <br />
                  サークル
                </a>
              </li>
              <li className="left-icon icon-etc w-[50%] sm:w-[120px] flex items-center">
                <a href="" className="text-[15px]">
                  その他
                </a>
              </li> */}
            </ul>
          </div>
        </section>
      </div>
      <div className="py-[50px] px-[30px] sm:py-[80px] lg:px-0">
        <section className="mx-auto w-full sm:max-w-main">
          <div className="mb-[30px] sm:mb-[60px]">
            <h2 className="text-[#B1C2CC] text-[38px] leading-[1.04] sm:text-[46px] font-raleway sm:mb-2">
              INTERVIEW
            </h2>
            <div className="text-[#B1C2CC] text-xs">成功者インタビュー</div>
          </div>
          <div className="flex flex-wrap justify-between">
            {users_voice.map((item, i) => (
              <div className="mb-[44px] sm:mb-[50px] sm:w-[30%] w-full" key={i}>
                <section className="sm:max-w-[300px] w-full">
                  <div className="mb-2 w-full sm:mb-5">
                    <a
                      href={item.link}
                      className="link-hover-none block overflow-hidden"
                    >
                      <img
                        src={item.img}
                        alt={item.client_name}
                        className="img-expansion aspect-[4/3] object-contain"
                      />
                    </a>
                  </div>
                  <div className="mb-1 text-xs">{item.client_name}</div>
                  <h1 className="icon-external right-icon after:bottom-[-12px] after:absolute relative after:right-0">
                    <a href={item.link}>
                      {truncateText(item.title, 25)}
                    </a>
                  </h1>
                </section>
              </div>
            ))}
          </div>
          {/* <div className="mt-[60px] h-[50px] hidden text-center mx-auto w-[300px] sm:block">
            <BaseLinkButton
              text="全ての記事を見る"
              link="/"
              iconRightEdge="icon-external-white"
              fontSize="text-sm"
            />
          </div>
          <div className="mt-[60px] mx-auto sm:hidden w-[220px] h-[50px]">
            <BaseLinkButton
              text="ALL"
              link="/"
              iconRightEdge="icon-external-white"
              fontSize="text-sm"
            />
          </div> */}
          <div className="mx-auto w-[220px] h-[40px] mt-6 justify-center">
            <SecondaryLinkButtonWhite
              text="Read more"
              iconRight="icon-arrow-under-small"
              link={`https://corp.spportunity.com/users_voice/`}
              fontSize="text-sm"
            />
          </div>
        </section>
      </div>
      <div className="py-[50px] px-[30px] sm:py-[80px] lg:px-0">
        <section className="flex flex-col justify-between mx-auto w-full sm:flex-row sm:max-w-main">
          <div className="mb-6 sm:mb-0">
            <h2 className="text-[#B1C2CC] text-[38px] leading-[1.04] sm:text-[46px] font-raleway sm:mb-2">
              NEWS
            </h2>
            <div className="text-[#B1C2CC] text-xs">新着情報</div>
          </div>
          <div className="sm:maw-w-[700px] sm:w-[70%]">
            {props.newss.map((news) => (
              <section className="flex items-center mb-5" key={news.id}>
                <div className="w-[80px] h-[80px]">
                  <img src={news.image_url ? news.image_url : NoImg} alt={news.investment_name} className="object-contain" />
                </div>
                <div className="w-[calc(100%-104px)] ml-6">
                  <div className="text-[15px] text-[#768084]">{news.release_date}</div>
                  <h1 className="flex justify-between">
                    <a href={news.path} className="text-sm">
                      {news.body}
                    </a>
                    <div className="relative">
                      <span className="right-icon icon-external absolute bottom-0 ml-2"></span>
                    </div>
                  </h1>
                </div>
              </section>
            ))}
          </div>
        </section>
      </div>
      <div className="border-[#D2DFE6] h-[1px] mx-auto max-w-main border-b border-solid"></div>
      <div className="py-[40px] border-[#D2DFE6] sm:px-[30px] mx-auto max-w-main border-b lg:px-0">
        <section className="hidden justify-between mx-auto w-full sm:flex sm:max-w-main">
          <div>
            <div className="mb-[30px]">
              <h2 className="text-[#B1C2CC] font-raleway text-sm">COLUMN</h2>
              <a
                className="right-icon icon-arrow inline-block after:mb-1 after:ml-6 text-2xl"
                href="https://media.spportunity.com/"
              >
                スポチュニティコラム
              </a>
            </div>
            <p className="text-[#768084] sm:w-[416px]">
              普段スポットライトを浴びる機会の少ない競技やチーム、
              アスリート、スタッフ・裏方、ファンへ光を当て、
              そこにあるストーリーや熱い想いを世の中に届けたい。
              それがスポチュニティコラムです。
            </p>
          </div>
          <div className="w-[490px] h-[210px]">
            <a href="https://media.spportunity.com/" target="_blank" rel="noopener noreferrer" className="block overflow-hidden link-hover-none">
              <img
                src={ColumnImg}
                alt="スポチュニティコラム"
                className="object-cover img-expansion"
              />
            </a>
          </div>
        </section>
        <section className="px-[30px] justify-between mx-auto w-full sm:hidden sm:max-w-main">
          <div className="mb-6">
            <h2 className="text-[#B1C2CC] font-raleway text-sm">COLUMN</h2>
            <a
              className="right-icon icon-arrow inline-block after:mb-1 after:ml-6 text-2xl"
              href="https://media.spportunity.com/"
              target="_blank" rel="noopener noreferrer"
            >
              スポチュニティコラム
            </a>
          </div>
          <div className="h-[42%] mb-4 w-full">
            <a href="https://media.spportunity.com/" target="_blank" rel="noopener noreferrer">
              <img src={ColumnImg} alt="スポチュニティコラム" />
            </a>
          </div>
          <p className="text-[#768084]">
            普段スポットライトを浴びる機会の少ない競技やチーム、
            アスリート、スタッフ・裏方、ファンへ光を当て、
            そこにあるストーリーや熱い想いを世の中に届けたい。
            それがスポチュニティコラムです。
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default Top;
