export const projectStatusCodes = {
  PROJECT_STATUS_COMING_SOON: 0,
  PROJECT_STATUS_NEW: 1,
  PROJECT_STATUS_IN_PROGRESS: 2,
  PROJECT_STATUS_ACHIEVED: 3,
  PROJECT_STATUS_NEXT_GOAL: 4,
  PROJECT_STATUS_END: 5,
}

export const IsProjectActive = (projectStatusCode: number): boolean => {
  return [
    projectStatusCodes.PROJECT_STATUS_NEW,
    projectStatusCodes.PROJECT_STATUS_IN_PROGRESS,
    projectStatusCodes.PROJECT_STATUS_ACHIEVED,
    projectStatusCodes.PROJECT_STATUS_NEXT_GOAL
  ].includes(projectStatusCode);
};

export const projectStatusToString = (projectStatus) => {
  switch(projectStatus) {
    case projectStatusCodes.PROJECT_STATUS_COMING_SOON:
      return "もうすぐ公開";
    case projectStatusCodes.PROJECT_STATUS_NEW:
      return "NEW";
    case projectStatusCodes.PROJECT_STATUS_IN_PROGRESS:
      return "公開中";
    case projectStatusCodes.PROJECT_STATUS_ACHIEVED:
      return "目標達成";
    case projectStatusCodes.PROJECT_STATUS_NEXT_GOAL:
      return "NEXT GOAL挑戦中";
    case projectStatusCodes.PROJECT_STATUS_END:
      return "終了";
    default:
      return "";
  }
}

export const QUESTION_TYPE = {
  NONE: 0,
  TEXT: 1,
  SELECT: 2,
}

export const MAX_ARTICLE_LENGTH = 10000

export const EMAIL_MATCH_PATTERN = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
