import React from "react";

type Props = {
  text: string;
  link: string;
  iconRight?: string;
  iconLeft?: string;
  iconLeftEdge?: string;
  size?: string;
};

const LinkButton: React.FC<Props> = (props) => {
  const { text, link, iconRight, iconLeft, size, iconLeftEdge } = props;

  return (
    <a
      href={link}
      className={`${size} border-[#B1C2CC] link-hover-none btn-hover-white border rounded-default inline-block`}
    >
      <span
        className={`h-full flex items-center justify-center relative z-10 ${
          iconRight && `right-icon after:absolute after:right-3 ${iconRight}`
        } ${
          iconLeftEdge &&
          `${iconLeftEdge} left-icon before:absolute before:left-[6%]`
        } ${iconLeft && `left-icon ${iconLeft}`}`}
      >
        {text.split("\n").map((t, i) => (
          <span key={i}>
            {t}
            <br />
          </span>
        ))}
      </span>
    </a>
  );
};

export default LinkButton;
