import React from "react";
import classNames from "classnames";
import NoImg from "../../../images/noimage.gif";
import IconRank1 from "../../../images/icon_rank_01.png";
import IconRank2 from "../../../images/icon_rank_02.png";
import IconRank3 from "../../../images/icon_rank_03.png";
import IconTag from "../../../images/icon_tag.png";

type Props = {
  isTeam: boolean;
  ranking: number;
  teamOrClientName: string;
  supportAmount: number;
  DateOrSupportNumber: number;
  teamImageUrl?: string;
  prefectureSlug?: string;
  prefectureName?: string;
  categoryType?: number;
  categoryName?: string;
};

const selectIconRank = (ranking: number) : string => {
  if (ranking == 1) { return IconRank1; }
  if (ranking == 2) { return IconRank2; }
  if (ranking == 3) { return IconRank3; }
}

const DateOrSupportNumberStr = (DateOrSupportNumber: number, isTeam: boolean) : string => {
  if (DateOrSupportNumber == null) { return '' }
  if (isTeam) {
    return `/${DateOrSupportNumber}人`
  } else {
    return `/${DateOrSupportNumber}日`
  }
}

const RankingListCard: React.FC<Props> = (props) => {
  const {
    isTeam,
    ranking,
    teamOrClientName,
    supportAmount,
    DateOrSupportNumber,
    teamImageUrl,
    prefectureSlug,
    prefectureName,
    categoryType,
    categoryName,
  } = props;

  return (
    <div className="flex maxsm:pt-4 maxsm:pb-3 maxsm:border-b maxsm:border-gray-300 last:border-0 py-5">
      <p className="w-4 font-bold text-xl text-center mr-[10px]" style={{lineHeight: "1.14"}}>
        { ranking < 4 && (
          <span className="block w-4 h-3 overflow-hidden"
            style={{
              margin: "0 auto",
              background: `url(${selectIconRank(ranking)}) no-repeat 0 0`,
              backgroundSize: "16px auto",
              textIndent: "-9999px",
          }}></span>
        )}
        {ranking}
      </p>
      {isTeam &&
        <div className="w-10 h-10 sm:w-12 sm:h-12 mr-[10px] rounded-sm bordr-[#d0d9e3] border">
          <img
            src={teamImageUrl ? teamImageUrl : NoImg}
            alt={teamOrClientName}
            className="object-contain rounded-sm"
          />
        </div>
      }
      <div style={{
        maxWidth: "89%",
        flex: "1",
      }}>
        <p className="mb-1 font-bold line-clamp-1">{teamOrClientName}</p>
        <p className="relative w-full h-5 mb-2 bg-gray-100">
          <span className="block absolute top-0 left-0 max-w-full h-5 leading-5 text-xs bg-[#B1C2CC] sm:text-sm" style={{padding: "0 10px", whiteSpace: "nowrap"}}>
            支援金累計額：<span className="font-bold">{supportAmount}</span>円{DateOrSupportNumberStr(DateOrSupportNumber,isTeam)}
          </span>
        </p>
        <ul style={{
          paddingLeft: "28px",
          background: `url(${IconTag}) no-repeat`,
          backgroundPosition: "left center",
          backgroundSize: "15px auto",
          overflow: "hidden",
        }}
        >
          {prefectureSlug && prefectureName && (
            <li className="overflow-hidden max-w-full mb-0 float-left">
              <a href={`/ranking/${prefectureSlug}`}
                className="inline-block overflow-hidden w-auto max-w-full h-auto mr-2 p-0 underline align-middle whitespace-no-wrap text-sm"
                style={{
                  background: "none",
                  color: "#333",
                  lineHeight: "1.6",
                  textOverflow: "ellipsis",
                }}>
                {prefectureName}</a>
            </li>
          )}
          {isTeam && (
            <li className="overflow-hidden max-w-full mb-0 float-left">
              <a href={`/search/team?category_type=${categoryType}`}
                className="inline-block overflow-hidden w-auto max-w-full h-auto mr-1 p-0 underline align-middle whitespace-no-wrap text-sm"
                style={{
                  background: "none",
                  color: "#333",
                  lineHeight: "1.6",
                  textOverflow: "ellipsis",
                }}>
                {categoryName}</a>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
};

export default RankingListCard;
