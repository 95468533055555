import { useState } from "react";

export const useCheckbox = (list, defaultCheckedList = []) => {
  const [checkedList, setCheckedList] = useState<Array<number>>(defaultCheckedList);

  const toggleCheckboxState = (e) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, Number(e.target.id)])
    } else {
      setCheckedList(checkedList.filter((item) => item !== Number(e.target.id)))
    }
  }

  const toggelAllCheckboxState = (e) => {
    if (e.target.checked) {
      const arr = []
      for (let i = 0; i < list.length; i++) {
        if (!checkedList.includes(list[i].id)) {
          arr.push(list[i].id)
        }
      }
      setCheckedList(checkedList.concat(arr))
    } else {
      setCheckedList([])
    }
  }

  return { toggleCheckboxState, toggelAllCheckboxState, checkedList }
};
