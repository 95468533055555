import React, { useEffect } from "react";

type Props = {
  account: string
}

const Tweet: React.FC<Props> = (props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <div className="mb-[40px] font-bold">@{props.account}さんのポスト</div>
      <div className="twitter-widget h-[800px] overflow-y-scroll">
        <a
          className="twitter-timeline"
          href={`https://twitter.com/${props.account}?ref_src=twsrc%5Etfw`}
        ></a>
      </div>
    </>
  );
};

export default Tweet;
