import React, { useState } from "react";
import { FieldPath, FieldValues, useController, UseControllerProps } from "react-hook-form";


type Props = {
  placeholder: string;
  id: string;
  options: string[] | number[];
  values?: string[] | number[];
  isInitial?: boolean;
}

function SelectBox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  >({ isInitial = true, ...props }: UseControllerProps<TFieldValues, TName> & Props) {
  const {
    field,
    formState
  } = useController(props);
  const [isFocused, setIsFocused] = useState<boolean>(isInitial)

  return (
    <>
      <div className="right-icon icon-arrow-under relative after:absolute after:top-5 after:right-5 w-full">
        <select
          {...field}
          name={props.name}
          id={props.id}
          className={`${formState?.errors[props.name] && "!bg-[#FFE6E6] !border-[#FF0000] !border-2"} ${!isFocused || field.value ? "bg-white" : "bg-[#D3EAFF] border-[#006AC9] border-2"} border-[#B1C2CC] block border w-full h-[50px] py-[15px] px-5 leading-none outline-none`}
          onChange={(e) => {
            field.onChange(e);
          }}
          value={field.value || ""}
          onFocus={() => setIsFocused(false)}
        >
          <option value="" className="hidden" defaultValue="">{props.placeholder}</option>
          {props.options.map((option, i) => (
            <option value={props.values?.[i] ? props.values[i] : option} key={i}>{option}</option>
          ))}
        </select>
      </div>
      <div className="text-[#FF0000] mt-1 text-xs">
        {formState?.errors[props.name]?.type === "required" && (
          <p className="before:content-['*']">必須項目です</p>
        )}
      </div>
    </>
  );
}

export default SelectBox;
