import React from "react";
import BaseButton from "../../components/Common/Buttons/BaseButton";
import { EcReturn, ProjectInformation, PurchaseEcReturnFormValues, Questionnaire } from "../../types/pages/EcWebsettlement/purchase_ec_return";
import SecondaryButton from "../../components/Common/Buttons/SecondaryButton";
import { QUESTION_TYPE } from "../../utils/constants";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { getConvenienceStoreName } from "../../utils/convenienceStoreUtil"

type Props = {
  ecReturn: EcReturn,
  questionnaire: Questionnaire,
  projectInformation: ProjectInformation,
  moveSelectReturnPage: (elId?: string) => void,
  moveEnterProfilePage: (elId: string) => void,
  totalAmount: () => number,
  priceByAmount: () => number,
  getValues: UseFormGetValues<PurchaseEcReturnFormValues>,
  setValue: UseFormSetValue<PurchaseEcReturnFormValues>,
  cardInformation: any,
  isSubmitting: boolean,
  commission: number;
};

const ConfirmSettlement = ({
  ecReturn,
  questionnaire,
  projectInformation,
  moveSelectReturnPage,
  moveEnterProfilePage,
  totalAmount,
  priceByAmount,
  getValues,
  setValue,
  cardInformation,
  isSubmitting,
  commission
}: Props) => {
  const {
    brand,
    last4,
    exp_year,
    exp_month
  } = cardInformation()

  const getQuestionnaireAnswer = (questionId: number) => {
    const answer = questionnaire.selectable_answers.find((answer) => answer.id === Number(questionId));
    return answer ? answer.sentence : "";
  }

  return (
    <>
      <div className="mb-[60px]">
        <div className="flex">
          <div className="w-[13%] border-[#1F2122] flex items-center justify-center border">
            1
          </div>
          <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
            2
          </div>
          <div className="h-[40px] bg-[#1F2122] w-[61%] relative flex items-center justify-center text-center text-white text-sm font-bold">
            <span className="text-[#1F2122] bg-[#768084] rounded-[50%] inline-block mr-1 w-5 h-5 text-center">
              3
            </span>
            入力内容の確認
            <div className="right-triangle-thin right-[-7px] absolute"></div>
          </div>
          <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
            4
          </div>
        </div>
      </div>
      <div className="mb-10">
        <div className="flex justify-between mb-5">
          <h3 className="font-bold">支援内容の確認</h3>
          <div className="w-[100px] h-[30px]">
            <SecondaryButton
              text="変更する"
              fontSize="text-xs"
              onClick={() => moveSelectReturnPage()}
            />
          </div>
        </div>
        <div className="sm:p-10 bg-[#F4F6F8] p-5">
          <div className="pb-5">
            <div className="mb-5">
              <p className="text-lg font-bold mb-1">{ecReturn.name}</p>
              {ecReturn.ec_return_skus.length > 0 && (
                <p>{`${ecReturn.ec_return_skus.filter((sku) => sku.id === getValues('checked_sku_id')).shift().sku_name}`}</p>
              )}
            </div>
            <p className="mb-5">
              {ecReturn.content.split("\n").map((item: string, index: number) => (
                <p className="min-h-[1.25rem]" key={index}>{item}</p>
              ))}
            </p>
            <p className="text-[#FF0000] left-icon icon-gift">{ecReturn.is_subscription ? "月額課金中は継続してご提供" : `${ecReturn.schedule_text}に実施予定`}</p>
          </div>
          <div className="-mb-5">
            {ecReturn.ec_return_questions.filter(question => question.question_type !== QUESTION_TYPE.NONE).map((question, i) => (
              <div className="border-t border-[#D2DFE6] pt-5">
                <div key={i} className="mb-5">
                  <dl className="mb-[10px]">
                    <dt className="text-[#768084] text-xs">質問{i+1}</dt>
                    <dd className="text-sm">{question.question}</dd>
                  </dl>
                  <dl>
                    <dt className="text-[#768084] text-xs">回答</dt>
                    <dd className="text-sm">{getValues(`answers.${i}`)}</dd>
                  </dl>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mb-10">
        <div className="flex justify-between mb-5">
          <h3 className="font-bold">お支払い金額の確認</h3>
          <div className="w-[100px] h-[30px]">
            <SecondaryButton
              text="変更する"
              fontSize="text-xs"
              onClick={() => moveSelectReturnPage("#purchase-amount")}
            />
          </div>
        </div>
        <section className="sm:p-10 bg-[#F4F6F8] p-5">
          <dl className="mb-5">
            <dt className="text-[#768084] text-xs">お支払い金額</dt>
            <dd className="text-sm">
              <dl className="flex justify-between">
                <dt>支援金額(税込)</dt>
                <dd className="font-bold text-right">{totalAmount().toLocaleString()}円</dd>
              </dl>
            </dd>
          </dl>
          <dl>
            <dt className="text-[#768084] text-xs">内訳</dt>
            {ecReturn.is_subscription ?
              (
                <dd className="text-sm">
                  <dl className="flex justify-between mb-1">
                    <dt>月額課金リターンの購入金額</dt>
                    <dd className="text-right">{`${priceByAmount().toLocaleString()}円(${getValues("number")}口×${ecReturn.price.toLocaleString()}円)`}</dd>
                  </dl>
                  <dl className="flex justify-between">
                    <dt>プラットフォーム利用料</dt>
                    <dd className="text-right">{commission}円</dd>
                  </dl>
                </dd>
              )
              :
              (
                <dd className="text-sm">
                  <dl className="flex justify-between mb-1">
                    <dt>リターンの購入金額</dt>
                    <dd className="text-right">{`${priceByAmount().toLocaleString()}円(${getValues("number")}口×${ecReturn.price.toLocaleString()}円)`}</dd>
                  </dl>
                  { getValues("additional_amount") && Number(getValues("additional_amount")) > 0 &&
                    <dl className="flex justify-between mb-1">
                      <dt>上乗せ支援</dt>
                      <dd className="text-right">{Number(getValues("additional_amount")).toLocaleString()}円</dd>
                    </dl>
                  }
                  <dl className="flex justify-between">
                    <dt>プラットフォーム利用料</dt>
                    <dd className="text-right">{commission}円</dd>
                  </dl>
                </dd>
              )
            }
          </dl>
        </section>
      </div>
      <div className="mb-10">
        <div className="flex justify-between mb-5">
          <h3 className="font-bold">お届け先情報の確認</h3>
          <div className="w-[100px] h-[30px]">
            <SecondaryButton
              text="変更する"
              fontSize="text-xs"
              onClick={() => moveEnterProfilePage("#delivery-info")}
            />
          </div>
        </div>
        <section className="sm:p-10 bg-[#F4F6F8] p-5">
          <dl className="mb-5">
            <dt className="text-[#768084] text-xs">お名前</dt>
            <dd className="text-sm">{`${getValues("last_name")} ${getValues("first_name")}`}</dd>
          </dl>
          <dl className="mb-5">
            <dt className="text-[#768084] text-xs">お届け先住所</dt>
            <dd className="text-sm">{`〒${getValues("postal_code")}`}<br />{getValues("full_address")}</dd>
          </dl>
          <dl>
            <dt className="text-[#768084] text-xs">電話番号</dt>
            <dd className="text-sm">{getValues("phone_number")}</dd>
          </dl>
        </section>
      </div>
      <div className="mb-10">
        <div className="flex justify-between mb-5">
          <h3 className="font-bold">決済方法の確認</h3>
          <div className="w-[100px] h-[30px]">
            <SecondaryButton
              text="変更する"
              fontSize="text-xs"
              onClick={() => moveEnterProfilePage("#payment-method")}
            />
          </div>
        </div>
        <div className="sm:p-10 bg-[#F4F6F8] p-5">
          <dl>
            <dt className="text-[#768084] text-xs">決済方法</dt>
            <dd className="text-sm">
              { getValues("payment_method") == "credit_card" &&
                <>
                  <div>クレジットカード決済{brand}</div>
                  <div>XXXX-XXXX-XXXX-{last4}</div>
                  <div>{exp_month}/{exp_year}</div>
                </>
              }
              { getValues("payment_method") == "bank" && 
                <div>銀行振込決済</div>
              }
              { getValues("payment_method") == "conveni" &&
                <div>コンビニ決済</div>
              }
            </dd>
          </dl>
          {getValues("payment_method") == "conveni" &&
            <>
              <dl className="mt-5">
                <dt className="text-[#768084] text-xs">コンビニ名</dt>
              <dd className="text-sm">{getConvenienceStoreName(getValues("conveni_code"))}</dd>
              </dl>
              <dl className="mt-5">
                <dt className="text-[#768084] text-xs">電話番号</dt>
                <dd className="text-sm">{getValues("conveni_tel")}</dd>
              </dl>
              <dl className="mt-5">
                <dt className="text-[#768084] text-xs">お名前（カナ）</dt>
                <dd className="text-sm">{getValues("conveni_phonetic_full_name")}</dd>
              </dl>
            </>
          }
        </div>
      </div>
      <div className="mb-10">
        <div className="flex justify-between mb-5">
          <h3 className="font-bold">アンケートの回答内容</h3>
          <div className="w-[100px] h-[30px]">
            <SecondaryButton
              text="変更する"
              fontSize="text-xs"
              onClick={() => moveEnterProfilePage("#questionnaire")}
            />
          </div>
        </div>
        <div className="sm:p-10 bg-[#F4F6F8] p-5">
          {questionnaire.title && 
            <div className="mb-[30px]">
              <dl className="mb-[10px]">
                <dt className="text-[#768084] text-xs mb-[10px]">Q1の回答</dt>
                <dd className="text-sm">
                  {questionnaire.title}
                </dd>
              </dl>
              <dl>
                <dt className="text-[#768084] text-xs">回答</dt>
                <dd className="text-sm">
                  {getQuestionnaireAnswer(getValues("questionnaire_option_id")) || "回答なし"}
                </dd>
              </dl>
            </div>
          }
          {questionnaire.free_text_title && 
            <div className="mb-[30px]">
              <dl className="mb-[10px]">
                <dt className="text-[#768084] text-xs mb-[10px]">Q2の回答</dt>
                <dd className="text-sm">
                  {questionnaire.free_text_title}
                </dd>
              </dl>
              <dl>
                <dt className="text-[#768084] text-xs">回答</dt>
                <dd className="text-sm">
                {getValues("questionnaire_sentence")}
                </dd>
              </dl>
            </div>
          }
          <div>
            <dl>
              <dt className="text-[#768084] text-xs mb-[10px]">支援理由</dt>
              <dd className="text-sm">
                {getValues("destination_reason_text") || "選択なし"}
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div className="mb-10 sm:mb-[80px]">
        <div className="flex justify-between mb-5">
          <h3 className="font-bold">応援メッセージの内容</h3>
          <div className="w-[100px] h-[30px]">
            <SecondaryButton
              text="変更する"
              fontSize="text-xs"
              onClick={() => moveEnterProfilePage("#questionnaire")}
            />
          </div>
        </div>
        <div className="sm:p-10 bg-[#F4F6F8] p-5">
          <p className="text-[#FF0000] mb-7">応援メッセージは、支援者コミュニティで公開されます。</p>
          <dl className="mb-[10px]">
            <dt className="text-[#768084] text-xs">応援メッセージ</dt>
            <dd className="text-sm break-words whitespace-pre-wrap">
              {getValues("article_message")}
            </dd>
          </dl>
        </div>
      </div>
      <div className="sm:mx-auto sm:w-[290px] h-[50px]">
        <BaseButton
          text="上記の内容で確定する"
          fontSize="text-sm"
          disabled={isSubmitting}
          isSubmit={true}
        />
      </div>
    </>
  );
};

export default ConfirmSettlement;
