import React from "react";
import Modal from "../Common/Modal";
import { QRCodeSVG } from "qrcode.react";

type Props = {
  toggleModal: () => void;
  isActive: boolean;
  referralURL: string;
}

const QRCodeModal: React.FC<Props> = (props) => {
  const { toggleModal, isActive, referralURL } = props

  const downloadQRCode = () => {
    const svg = document.querySelector("#qr-code") as SVGSVGElement;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    canvas.width = svg.width.baseVal.value;
    canvas.height = svg.height.baseVal.value;

    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.onload = function(){
        ctx.drawImage( image, 0, 0 );
        const a = document.createElement("a");
        a.href = canvas.toDataURL("image/png");
        a.setAttribute("download", "image.png");
        a.dispatchEvent(new MouseEvent("click"));
    };
    image.src = "data:image/svg+xml;charset=utf-8;base64," + btoa(unescape(encodeURIComponent(svgData)));
  }

  return (
    <Modal
      toggleModal={toggleModal}
      isActive={isActive}
    >
      <div className="text-center">
        <p className="font-bold sm:mb-10 text-lg mb-[30px]">QRコード</p>
        <p className="sm:mb-10 mb-5">クリックするとダウンロードできます。</p>
        <div className="mx-auto w-[256px] h-[256px] hover:cursor-pointer">
          <QRCodeSVG
            value={referralURL}
            size={256}
            id="qr-code"
            onClick={downloadQRCode}
          />
        </div>
      </div>
    </Modal>
  );
};

export default QRCodeModal;
