import React from "react";
import Spinner from "../../../images/spinner.gif";

type Props = {
  show: boolean;
};

const Loading: React.FC<Props> = (props) => {
  const { show } = props;

  return (
    <>
      <div
        className={`${
          show
            ? "block fixed z-50 top-0 w-screen h-screen bg-black opacity-60 right-0 left-0"
            : "hidden"
        }`}
      ></div>
      <div
        className={`${
          show
            ? "block z-50 fixed right-0 left-0 top-[200px] mx-auto"
            : "hidden"
        }`}
      >
        <div className="flex items-center justify-center">
          <img className="w-[50px]" src={Spinner} alt={"読み込み中"} width={50} height={50} />
        </div>
      </div>
    </>
  );
};

export default Loading;
