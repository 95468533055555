import React, { useMemo, useState } from "react";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BaseLinkButton from "../../components/Common/Buttons/BaseLinkButton";
import SliderWrapper from "../../components/Common/SliderWrapper";
import Layout from "../../components/Common/Layout";
import EcReturnCard from "../../components/Common/Cards/EcReturnCard"
import { ecReturn, investment } from "../../types/pages/Team/detail";
import { IsProjectActive, projectStatusCodes } from "../../utils/constants";
import LinkifyText from "../../components/Common/LinkifyText";

type Props = {
  ec_return: ecReturn;
  investment: investment;
  prefecture: string;
  main_image: string;
  recently_purchased_ec_returns: Array<ecReturn>;
  from?: string;
  is_draft?: boolean;
  draft_id?: number;
  draft_path?: string;
};

const Index: React.FC<Props> = (props) => {
  const [largeNav, setLargeNav] = useState();
  const [smallNav, setSmallNav] = useState();

  const sliderLarge = {
    arrows: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          autoplay: true,
          autoplaySpeed: 4000
        },
      },
    ]
  }

  const getPurchaseButtonWording = () => {
    if (props.ec_return.purchase_available) {
      return "このリターンで支援"
    }
    if (props.investment.project_status === projectStatusCodes.PROJECT_STATUS_COMING_SOON) {
      return "開始前です"
    }
    return "終了しました"
  }

  const sliderSmall = useMemo(() => {
    switch (props.ec_return.ec_return_images.length) {
      case 2:
        return {
          arrows: false,
          slidesToShow: 2,
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                arrows: true,
                slidesToShow: 2,
              },
            },
          ]
        }
      case 3:
        return {
          arrows: false,
          slidesToShow: 3,
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                arrows: true,
                slidesToShow: 3,
              },
            },
          ]
        }
      case 4:
        return {
          arrows: false,
          slidesToShow: 4,
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                arrows: true,
                slidesToShow: 3,
              },
            },
          ]
        }
      default:
        return {
          arrows: false,
          slidesToShow: 5,
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                arrows: true,
                slidesToShow: 3,
              },
            },
          ]
        }
    }
  },[])

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: props.investment.title,
          href: props.is_draft ?
            `/${props.draft_path}/${props.prefecture}/${props.investment.client_id}/${props.draft_id}${window.location.search}`
            :
            `/${props.prefecture}/team/${props.investment.client_id}/invest/${props.investment.id}/detail`
        },
        {
          text: props.ec_return.name,
        },
      ]}
    >
      <section className="px-[30px] sm:max-w-main sm:mb-[40px] sm:mx-auto md:px-0 sm:mt-[60px]">
        <div className="sm:w-[700px] sm:mx-auto">
          <div className="flex mb-5 sm:mb-[35px]">
            <div className="w-[80px] h-[60px] mr-[10px] sm:w-[120px] sm:h-[90px] sm:mr-5">
              <img
                src={props.main_image}
                alt={props.investment.title}
                className="object-contain"
              />
            </div>
            <h2 className="sm:w-[calc(100%-140px)] w-[calc(100%-90px)] text-sm underline">
              <a
                className="link-hover-none"
                href={props.is_draft ?
                  `/${props.draft_path}/${props.prefecture}/${props.investment.client_id}/${props.draft_id}${window.location.search}`
                  :
                  `/${props.prefecture}/team/${props.investment.client_id}/invest/${props.investment.id}/detail`
                }
              >
                {props.investment.title}
              </a>
            </h2>
          </div>
          <section>
            <h3 className="text-lg font-bold mb-[30px] sm:text-xl sm:mb-10">{props.ec_return.name}</h3>
            {(IsProjectActive(props.investment.project_status) && props.ec_return.is_sold_out) && (
                <div className="bg-[#F4F6F8] w-[60%] mb-5 mx-auto py-5 text-center text-3xl font-bold rounded-default sm:text-4xl">
                  SOLD OUT
                </div>
            )}
            <div className="border-b border-[#D2DFE6] pb-10">
              <div className="mb-[10px] ec-return-slider-large">
                <Slider
                  asNavFor={smallNav}
                  ref={(slider1) => setLargeNav(slider1)}
                  {...sliderLarge}
                >
                  {props.ec_return.ec_return_images.map((item, i) => (
                    <div key={i}>
                      <img src={item.path} alt={props.ec_return.name} />
                    </div>
                  ))}
                </Slider>
              </div>
              {props.ec_return.ec_return_images.length > 1 &&
                <div className="ec-return-slider-small">
                  <Slider
                    asNavFor={largeNav}
                    ref={(slider2) => setSmallNav(slider2)}
                    {...sliderSmall}
                  >
                    {props.ec_return.ec_return_images.map((item, i) => (
                      <div key={i}>
                        <img
                          src={item.path}
                          alt={props.ec_return.name}
                          className="h-[80px] aspect-[4/3] object-contain"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              }
            </div>
            <div className="border-b border-[#D2DFE6] py-10 text-center">
              <div className="font-bold hidden sm:block">リターンの購入金額</div>
              <div className="font-bold text-[40px]">{props.ec_return.price.toLocaleString()}<span className="text-lg">円(税込)</span></div>
            </div>
            <div className="border-[#D2DFE6] py-5 border-b sm:flex sm:justify-between">
              <dl className="flex justify-between items-center sm:after:content-[''] sm:after:rotate-[30deg] sm:after:w-[1px] sm:after:h-full sm:after:bg-[#768084] sm:w-[30%]">
                <dt className="text-[#768084] text-sm icon-human left-icon">支援個数</dt>
                <dd>{props.ec_return.support_count}個</dd>
              </dl>
              <dl className="flex justify-between items-center sm:after:content-[''] sm:after:rotate-[30deg] sm:after:w-[1px] sm:after:h-full sm:after:bg-[#768084] sm:w-[30%]">
                <dt className="text-[#768084] text-sm icon-purchased left-icon">支払い済</dt>
                <dd>{props.ec_return.purchased_count}個</dd>
              </dl>
              <dl className="flex justify-between items-center sm:after:content-[''] sm:w-[30%]">
                <dt className="text-[#768084] text-sm icon-stock left-icon">在庫数</dt>
                <dd>{props.ec_return.remaining_number_string}</dd>
              </dl>
            </div>
            <div className="text-center py-10 border-b border-[#D2DFE6]">
              {props.ec_return.is_subscription ?
                <p className="mb-[10px] font-bold text-[#006AC9]">月額課金中は継続してご提供</p>
                :
                <p className="mb-[10px] font-bold">{props.ec_return.schedule} 実施予定</p>
              }
              <div className="sm:mx-auto h-[50px] sm:w-[285px]">
                <BaseLinkButton
                  text={getPurchaseButtonWording()}
                  fontSize="text-sm"
                  link={props.is_draft ? `/${props.draft_path}/${props.prefecture}/${props.investment.client_id}/${props.draft_id}/${props.ec_return.id}/web_settlements${window.location.search}` : `/ec_returns/${props.ec_return.id}/web_settlements/new` + (props.from ? `?from=${props.from}` : "")}
                  disabled={!props.ec_return.purchase_available}
                />
              </div>
              {props.ec_return.for_corporation > 0 &&
                <a href={`/ec_return/${props.ec_return.id}/corporation/`}
                  className="link-hover-none underline text-sm left-icon icon-document mt-[34px] flex items-center justify-center">
                  資料ダウンロードや見積書の確認
                </a>
              }
            </div>
            <div className="py-10 sm:pb-[80px]">
              <div>
                <div className="mb-10">
                  <div>
                    <LinkifyText text={props.ec_return.content} />
                  </div>
                </div>
                <div className="mb-10 p-5 bg-[#F4F6F8]">
                  <p className="font-bold mb-2">リターンに関するお問い合わせ先</p>
                  <a href={`mailto:${props.investment.owner_email}`} className="link-hover-none underline">{props.investment.owner_email}</a>
                </div>
              </div>
              <div className="text-center">
                {props.ec_return.is_subscription ?
                  <p className="mb-[10px] font-bold text-[#006AC9]">月額課金中は継続してご提供</p>
                  :
                  <p className="mb-[10px] font-bold">{props.ec_return.schedule} 実施予定</p>
                }
                <div className="sm:mx-auto h-[50px] sm:w-[285px]">
                  <BaseLinkButton
                    text={getPurchaseButtonWording()}
                    fontSize="text-sm"
                    link={props.is_draft ? `/${props.draft_path}/${props.prefecture}/${props.investment.client_id}/${props.draft_id}/${props.ec_return.id}/web_settlements${window.location.search}` : `/ec_returns/${props.ec_return.id}/web_settlements/new` + (props.from ? `?from=${props.from}` : "")}
                    disabled={!props.ec_return.purchase_available}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="pt-10 sm:mb-[60px] border-t border-[#D2DFE6] mb-10">
          <h3 className="mb-5 font-medium md:mb-10">この他のプロジェクトのリターン</h3>
          <div className="flex overflow-x-auto mr-[-30px] md:hidden hidden-scrollbar">
            {props.investment.ec_returns.filter(item => item.id !== props.ec_return.id).map((item: ecReturn, i: number) => (
              <div className="mr-[15px] min-w-[150px]" key={i}>
                <EcReturnCard
                  link={
                    props.is_draft ?
                      `/${props.draft_path}/${props.prefecture}/${props.investment.client_id}/${props.draft_id}/${item.id}${window.location.search}`
                      :
                      `/${props.prefecture}/team/${props.investment.client_id}/invest/${props.investment.id}/detail/${item.id}/ec_return` + (props.from ? `?from=${props.from}` : "")
                  }
                  name={item.name}
                  category={item.label}
                  headingElement={4}
                  imgUrl={item.ec_return_images[0]?.path}
                  price={item.price}
                  supportCount={item.support_count}
                  stockQuantity={item.remaining_number_string}
                  isNew={item.is_new_arrival}
                  isSoldOut={item.is_sold_out}
                />
              </div>
            ))}
          </div>
          <div className="hidden md:block slider-col-4">
            <SliderWrapper col={4}>
              {props.investment.ec_returns.filter(item => item.id !== props.ec_return.id).map((item: ecReturn, i: number) => (
                <div key={i}>
                  <EcReturnCard
                    link={
                      props.is_draft ?
                        `/${props.draft_path}/${props.prefecture}/${props.investment.client_id}/${props.draft_id}/${item.id}${window.location.search}`
                        :
                        `/${props.prefecture}/team/${props.investment.client_id}/invest/${props.investment.id}/detail/${item.id}/ec_return` + (props.from ? `?from=${props.from}` : "")
                    }
                    name={item.name}
                    category={item.label}
                    headingElement={4}
                    imgUrl={item.ec_return_images[0]?.path}
                    price={item.price}
                    supportCount={item.support_count}
                    stockQuantity={item.remaining_number_string}
                    isNew={item.is_new_arrival}
                    isSoldOut={item.is_sold_out}
                  />
                </div>
              ))}
            </SliderWrapper>
          </div>
        </section>
        {!props.is_draft && (
          <section className="mt-10">
            <h3 className="mb-5 font-medium">最近支援されたこのプロジェクトのリターン</h3>
            <div className="flex overflow-x-auto mr-[-30px] md:hidden hidden-scrollbar">
              {props.recently_purchased_ec_returns?.length > 0 &&
                props.recently_purchased_ec_returns.filter(item => item.id !== props.ec_return.id).map((item, i: number) => (
                <div className="mr-[15px] min-w-[150px]" key={i}>
                  <EcReturnCard
                    link={`/${props.prefecture}/team/${props.investment.client_id}/invest/${props.investment.id}/detail/${item.id}/ec_return` + (props.from ? `?from=${props.from}` : "")}
                    name={item.name}
                    category={item.label}
                    headingElement={4}
                    imgUrl={item.ec_return_images[0]?.path}
                    price={item.price}
                    supportCount={item.support_count}
                    stockQuantity={item.remaining_number_string}
                    isNew={item.is_new_arrival}
                    isSoldOut={item.is_sold_out}
                  />
                </div>
              ))}
            </div>
            <div className="hidden md:block slider-col-4">
              <SliderWrapper col={4}>
                {props.recently_purchased_ec_returns?.length > 0 &&
                  props.recently_purchased_ec_returns.filter(item => item.id !== props.ec_return.id).map((item, i: number) => (
                  <div key={i}>
                    <EcReturnCard
                      link={`/${props.prefecture}/team/${props.investment.client_id}/invest/${props.investment.id}/detail/${item.id}/ec_return` + (props.from ? `?from=${props.from}` : "")}
                      name={item.name}
                      category={item.label}
                      headingElement={4}
                      imgUrl={item.ec_return_images[0]?.path}
                      price={item.price}
                      supportCount={item.support_count}
                      stockQuantity={item.remaining_number_string}
                      isNew={item.is_new_arrival}
                      isSoldOut={item.is_sold_out}
                    />
                  </div>
                ))}
              </SliderWrapper>
            </div>
          </section>
        )}
        <div className="mt-[60px] text-center">
          <a
            href={props.is_draft ?
              `/${props.draft_path}/${props.prefecture}/${props.investment.client_id}/${props.draft_id}${window.location.search}`
              :
              `/${props.prefecture}/team/${props.investment.client_id}/invest/${props.investment.id}/detail`
            }
            className="left-icon icon-stick-arrow-left font-medium text-sm"
          >
            プロジェクトの詳細ページに戻る
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
