import { useState } from "react";

export const useToggle = (status = false) => {
  const [isActive, setIsActive] = useState<boolean>(status);

  const toggle = () => {
    setIsActive(!isActive);
  };

  return { isActive, toggle };
};
