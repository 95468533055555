import React from "react";
import Modal from "../../Common/Modal";
import Noimg from "../../../../images/noimg.png";

type Props = {
  toggleModal: () => void;
  isActive: boolean;
  responseMessage: string;
};

const ResendNotificationModal: React.FC<Props> = (props) => {
  const { toggleModal, isActive, responseMessage } = props;

  return (
    <Modal toggleModal={toggleModal} isActive={isActive}>
      <div className="text-center">
        <p className="mb-[30px] text-lg font-bold sm:mb-10">通知再送</p>
        <p className="mb-5 sm:mb-10">{responseMessage}</p>
        <div className="w-[143px] h-[110px] mx-auto">
          <img src={Noimg} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default ResendNotificationModal;
