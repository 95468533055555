import React, { useState } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import SecondaryButton from "../../../components/Common/Buttons/SecondaryButton";
import PaymentButton from "../../../components/Common/Buttons/PaymentButton";
import SelectBox from "../../../components/Common/Form/SelectBox";
import TextArea from "../../../components/Common/Form/TextArea";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";
import { HttpRequest } from "../../../utils/common";
import { 
  Client,
  Investment,
  SocialProfile,
  MonthlyDonationForm
} from "../../../types/pages/Clients/MonthlyDonations/create";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import VisaCardImg from "../../../../images/card_visa.jpg";
import MasterCardImg from "../../../../images/card_mastercard.jpg";
import JCBCardImg from "../../../../images/card_jcb.jpg";
import AmexCardImg from "../../../../images/card_amex.jpg";
import DiscoverCardImg from "../../../../images/card_discover.jpg";
import DinersCoardImg from "../../../../images/card_diners.jpg";

type Props = {
  client: Client,
  cardInformation: () => SocialProfile,
  payJpPublicKey: string,
  onSubmit: () => void,
  investment: Investment,
  canDonate: boolean,
  currentAmount?: number,
  onCancel: () => void,
  control: Control<MonthlyDonationForm, any>,
  setValue: UseFormSetValue<MonthlyDonationForm>,
};

const EnterProfile: React.FC<Props> = ({
  client,
  cardInformation,
  payJpPublicKey,
  onSubmit,
  investment,
  canDonate,
  currentAmount,
  onCancel,
  control,
  setValue
}: Props) => {
  const availableAmountOptions = [
    1000, 3000, 5000,
    10000, 30000, 50000,
    100000, 200000, 300000
  ]

  const reasons = [
    "チームのファンだから",
    "選手の知り合いだから",
    "チーム関係者の知り合いだから",
    "地元のチーム・選手だから",
    "この種目・競技に思い入れがあるから",
    "支援募集ページの文章の内容に共感したから",
    "リターンが魅力的だから",
    "その他"
  ]

  const {
    card_brand,
    card_last4,
    card_exp_year,
    card_exp_month
  } = cardInformation()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const cardRenewalCallback = (payjp_token: string) => {
    setIsSubmitting(true)
    HttpRequest.put('/api/accounts/payment_object_id', { payjp_token })
      .then(({ data }) => {
        setValue('card_brand', data.brand)
        setValue('card_exp_year', data.exp_year)
        setValue('card_exp_month', data.exp_month)
        setValue('card_last4', data.last4)
        onSubmit();
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const getBrandIconUrl = (brand) => {
    switch (brand) {
      case 'Visa':
        return VisaCardImg;
      case 'MasterCard':
        return MasterCardImg;
      case 'JCB':
        return JCBCardImg;
      case 'American Express':
        return AmexCardImg;
      case 'Diners Club':
        return DinersCoardImg;
      case 'Discover':
        return DiscoverCardImg;
      default:
        return null;
    }
  };


  return (
    <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto md:px-0">
      <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">支援のお手続き</h2>
      <div className="sm:w-[700px] sm:mx-auto">
        <section className="mb-[60px]">
          <div className="mb-[30px] flex">
            <div className="w-[80px] h-[60px] sm:w-[120px] sm:h-[90px] mr-3">
              <img
                className="object-cover"
                src={client.logo_image_url}
                alt={client.name}
              />
            </div>
            <h3 className="w-[calc(100%-92px)] sm:w-[calc(100%-132px)]">{client.name}</h3>
          </div>
          <div className="flex">
            <div className="h-[40px] bg-[#1F2122] w-[68%] relative flex items-center justify-center text-center text-white text-sm font-bold">
              <span className="text-[#1F2122] bg-[#768084] rounded-[50%] inline-block mr-1 w-5 h-5 text-center">
                1
              </span>
              お支払い情報の入力
              <div className="right-triangle-thin right-[-7px] absolute"></div>
            </div>
            <div className="w-[16%] border-[#1F2122] flex items-center justify-center border">
              2
            </div>
            <div className="w-[16%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
              3
            </div>
          </div>
        </section>
        <section className="mb-10">
          <h3 className="mb-6 font-bold">チームへの継続支援(税込)</h3>
          <div className="mb-[30px] sm:mb-10">
            <div className="flex justify-between mb-5 items-center sm:mb-[15px]">
              <p>現時点の支援コース(月額)</p>
              <div className="font-bold text-[22px]">{currentAmount ? currentAmount.toLocaleString() : 0}円</div>
            </div>
            <div className="text-center w-[200px] sm:w-[20%]">
              <div className="down-triangle-black-small inline-block"></div>
            </div>
            <div className="mt-[10px] sm:flex sm:justify-between sm:items-center">
              <div className="flex mb-[10px] items-center">
                <p>変更後の支援コース(月額)</p>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <div className="flex items-end">
                <div className="w-full sm:w-[440px]">
                  <SelectBox
                    id="amount"
                    name="amount"
                    control={control}
                    placeholder="選択してください"
                    options={availableAmountOptions}
                    rules={{
                      required: true
                    }}
                  />
                </div>
                <div className="ml-1 font-medium">円</div>
              </div>
            </div>
          </div>
          <div>
            <p className="font-meidum text-[#707070] bg-[#D2DFE6] text-center py-3 mb-5 sm:mb-10">当月分は<span className="text-[#006AC9]">翌月1日</span>に決済が行われます</p>
            <p className="mb-5">プロジェクト期間中は、チームに対して月額課金の形で継続的に支援(寄付)することができます！</p>
            <p className="text-[#FF0000] mb-5 sm:mb-10">※この支援は寄付のためリターンはありません。予めご了承ください。</p>
            { currentAmount && currentAmount > 0 && (
              <>
                <ConfirmationModal
                  isActive={isModalOpen}
                  toggleModal={toggleModal}
                  confirmText="OK"
                  onSubmit={onCancel}
                >
                  <div className="sm:text-left">
                    <p>現在の支援コースをキャンセル(解約)しますか？</p>
                  </div>        
                </ConfirmationModal>
                <div className="h-[60px] sm:w-[315px] sm:mx-auto">
                  <SecondaryButton
                    text={"現在の支援コースをキャンセル(解約)する"}
                    onClick={() => setIsModalOpen(true)}
                  />
                </div>
              </>
            )}
          </div>
        </section>
        <section className="border-t border-[#D2DFE6] pt-10 mb-10">
          <h3 className="font-bold mb-5">よろしければアンケートと一言コメントにお応えください(任意)</h3>
          <p className="text-[#FF0000] text-xs mb-10">※本回答はプロジェクト画面に公開されます</p>
          {/* TODO: 継続寄付でアンケートとる仕様が現行にないため、調整する
          <div className="mb-[30px]">
            <div className="mb-1 font-raleway text-xs text-[#768084]">
              Question1:
            </div>
            <div>
              <p className="mb-1 font-medium text-sm">
                アンケートタイトルが入ります。アンケートタイトルが入ります。
              </p>
              <SelectBox
                placeholder="選択してください"
                name="question1"
                id="question1"
                control={control}
                options={["a","b","c"]}
              />
            </div>
          </div>
          <div className="mb-[30px]">
            <div className="mb-1 font-raleway text-xs text-[#768084]">
              Question2:
            </div>
            <div>
              <p className="mb-1 font-medium text-sm">
                アンケートタイトルが入ります。アンケートタイトルが入ります。
              </p>
              <TextArea
                placeholder="入力してください"
                id="question2"
                name="question2"
                control={control}
              />
            </div>
          </div>
          */}
          <div className="mb-[30px]">
            <p className="font-medium text-sm mb-1">支援理由</p>
            <SelectBox
              placeholder="選択してください"
              name="reasons"
              id="reasons"
              control={control}
              options={reasons}
            />
          </div>
          <div>
            <p className="text-sm mb-1 font-medium">応援メッセージをお願いします</p>
            <TextArea
              placeholder="入力してください"
              name="article_message"
              id="article_message"
              control={control}
            />
          </div>
        </section>
        <section className="border-t border-[#D2DFE6] pt-10 mb-[60px]">
          <h3 className="font-bold mb-10">支払い方法の選択</h3>
          <div className="bg-[#F4F6F8] text-[#FF0000] mb-10 p-5">
            <p>※選択された支援コースをの金額は月額で自動課金されます。</p>
            <p>選択された支援コースは、後から変更・キャンセル(解約)できます。</p>
          </div>
          { card_brand ? (
            <>
              <div className="mb-[30px]">  
                <div className="h-[50px] mb-[10px]">
                  <BaseButton
                    text="登録中のクレジットカードで支払う"
                    isSubmit={true}
                    disabled={!canDonate}
                  />
                </div>
                <div>
                  <p className="mb-2 text-lg font-semibold">ご使用中のクレジットカード</p>
                  <div className="flex items-center mb-2">
                    <img 
                      src={getBrandIconUrl(card_brand)}
                      alt={`${card_brand}`}
                      className="mr-2"
                      style={{height: '30px', width: 'auto' }}
                    />
                  </div>
                  <p className="mb-2">XXXX-XXXX-XXXX-{card_last4}</p>
                  <p className="mb-2">
                    {card_exp_month}/{card_exp_year}
                  </p>
                </div>
              </div>
              <div className="mb-[30px]">
                <div className="h-[50px] mb-[10px]">
                  <PaymentButton
                    submitText="カードを変更する"
                    text="クレジットカードを変更する"
                    payJpPublicKey={payJpPublicKey}
                    callback={cardRenewalCallback}
                    disabled={() => {return isSubmitting || !canDonate}}
                  />
                </div>
                <p className="text-[#FF0000] text-xs">カードを変更した場合は、継続支援で支援中のお支払いカードも次回の決済時から変更されます。</p>
              </div>
            </>
          ): (
            <div className="mb-[30px]">
              <div className="h-[50px] mb-[10px]">
                <PaymentButton
                  submitText="クレジットカードで支払う"
                  text="クレジットカードで支払う"
                  payJpPublicKey={payJpPublicKey}
                  callback={cardRenewalCallback}
                  disabled={() => {return isSubmitting || !canDonate}}
                />
              </div>
              <p className="text-[#FF0000] text-xs">安全にご利用いただくために、クレジットカード情報は決済代行会社にて管理しており、第三者に開示されることは一切ありません。</p>
            </div>
          )}
        </section>
      </div>
      <div className="text-center">
        <a href={client.investment_path} className="text-sm font-medium left-icon icon-stick-arrow-left">プロジェクトページに戻る</a>
      </div>
    </section>
  );
};

export default EnterProfile;
