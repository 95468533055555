import React, { useState, useCallback } from "react";
import Modal from "../Common/Modal";
import BaseButton from "../Common/Buttons/BaseButton";
import SecondaryButton from "../Common/Buttons/SecondaryButton";
import PaymentButton from "../../components/Common/Buttons/PaymentButton";
import { HttpRequest } from "../../utils/common";
import TippingImg from "../../../images/tipping.jpeg";
import PrimaryButton from "../Common/Buttons/PrimaryButton";

type Props = {
  projectID: number;
  setInvestmentTotalAmount: (amount: number) => void;
  canTip: boolean;
  isLoggedIn: boolean;
  cardRegistered: boolean;
  payJpPublicKey: string;
}

const Tipping: React.FC<Props> = (props) => {
  const { 
    projectID,
    setInvestmentTotalAmount,
    canTip,
    isLoggedIn,
    cardRegistered,
    payJpPublicKey,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isTippingCompleted, setIsTippingCompleted] = useState<boolean>(false);
  const tippingOptions = [
    "300円",
    "500円",
    "700円",
    "1,000円",
  ];
  const tippingValues = [
    "300",
    "500",
    "700",
    "1000",
  ];
  const [tippingAmount, setTippingAmount] = useState<string>(tippingValues[0])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [cardDone, setCardDone] = useState<boolean>(cardRegistered);

  const cardRenewalCallback = async (payjp_token: string) => {
    HttpRequest.put('/api/accounts/payment_object_id', { payjp_token })
      .then(async () => {
        setCardDone(true)
      })
      .catch((error) => {
        console.log(error)
        return
      })
  }

  const onTipping = useCallback(() => {
    setIsSubmitting(true);
    HttpRequest.post(`/api/investments/${projectID}/tipping`, { amount: Number(tippingAmount) })
    .then((res) => {
      setIsTippingCompleted(true);
      setInvestmentTotalAmount(res.data.current_funding);
      setIsSubmitting(false);
    })
    .catch((e) => {
      const errors = e.response.data.errors;
      if (errors?.maximum_price) {
        setErrorMessage(errors.maximum_price);
      } else if (errors?.ended) {
        setErrorMessage(errors.ended);
      } else if (errors?.card_declined) {
        setErrorMessage(errors.card_declined)
      } else if (errors?.incorrect_card_data) {
        setErrorMessage(errors.incorrect_card_data)
      } else {
        setErrorMessage("投げ銭処理中にエラーが発生しました。再度やり直してもエラーが発生する場合は、お問い合わせよりご連絡ください。");
      }
      setIsTippingCompleted(true);
      setIsSubmitting(false);
    });
  }, [setInvestmentTotalAmount, setIsSubmitting, tippingAmount, projectID])

  const handleTippinButtonClick = () => {
    if (!canTip) {
      // 無反応でOK
      return;
    }
    if (!isLoggedIn) {
      window.location.assign("/accounts/sign_in");
      return;
    }
    setIsModalOpen(true)
  }

  return (
    <>
      <div className="h-10">
        <BaseButton
          text="投げ銭で応援する"
          fontSize="text-sm"
          disabled={!canTip}
          onClick={handleTippinButtonClick}
        />
      </div>
      <Modal
        isActive={isModalOpen}
        toggleModal={() => setIsModalOpen(false)}
        padding={30}
        showCloseButton={false}
      > 
        <div className="bg-white justify-center">
          {errorMessage !== "" && (
            <div className="pt-[80px] text-center">
              {errorMessage}
            </div>
          )}
          { cardDone ? (
            <>
             {isTippingCompleted ? (
                <>
                  <div className="text-center">
                    ありがとうございました。<br />
                    {tippingAmount}円を投げ銭しました。
                  </div>
                  <div className="flex items-center justify-center mt-3 mb-6">
                    <img className="w-[200px]" src={TippingImg} alt={"支援完了しました"} width={200} height={150} />
                  </div>
                  <div className="flex items-center justify-center mt-6">
                    <div className="w-[300px] h-[40px]">
                      <SecondaryButton
                        text="閉じる"
                        onClick={() => {
                          setIsModalOpen(false);
                          setIsTippingCompleted(false);
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="pt-5 text-center">
                    いくら投げ銭をしますか？
                  </div>
                  <div className="mt-5 text-left">
                    {tippingValues.map((tippingValue, i) => (
                      <div>
                        <label className="flex pb-1">
                          <input
                            defaultChecked={tippingValue === tippingAmount}
                            name={"checked_tipping_amount"}
                            value={tippingValue}
                            id={`checked_tipping_amount_${i.toString()}`}
                            disabled={isSubmitting}
                            onChange={(e) => {
                              setTippingAmount(e.target.value)
                            }}
                            type="radio"
                            className={`${tippingValue === tippingAmount ? "before:border-[#B1C2CC]" : "before:border-[#006AC9] before:border-2"} radio-btn mr-[10px] before:content-[''] before:rounded-[50%] relative flex before:inline-block items-center before:w-5 before:h-5 before:border appearance-none`}
                          />
                          {tippingOptions[i]}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="md:flex md:flex-row-reverse md:items-center md:justify-center mt-3">
                    <div className="w-[250px] mt-3 md:mr-3 h-[40px] mx-auto">
                      <BaseButton
                        text="投げ銭をする"
                        disabled={isSubmitting}
                        fontSize="text-xs"
                        onClick={onTipping}
                      />
                    </div>
                    <div className="w-[250px] mt-3 md:mr-3 h-[40px] mx-auto">
                      <SecondaryButton
                        text="やめる"
                        disabled={isSubmitting}
                        onClick={() => setIsModalOpen(false)}
                      />
                    </div>
                  </div>
                  <div className="mt-3 text-xxs">
                    ※投げ銭はご登録されたクレジットカードより決済されます。登録済みのカード情報を確認されたい方は、<a href="/mypage/credit_card" className="underline">こちら</a> より御確認できます。
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="text-center">
                ※投げ銭はクレジットカードのご登録が必要です。
              </div>
              <div className="md:flex md:flex-row-reverse md:items-center md:justify-center mt-[50px]">
                <div className="w-[250px] mt-3 h-[40px] mx-auto">
                  {/*
                  <PaymentButton
                    disabled={() => false}
                    submitText="カードを登録する"
                    text="カードを登録する"
                    payJpPublicKey={payJpPublicKey}
                    callback={cardRenewalCallback}
                  />
                  */}
                  <BaseButton
                    text="カードを登録する"
                    disabled={false}
                    fontSize="text-xs"
                    onClick={() => {window.location.assign("/mypage/credit_card");}}
                  />
                </div>
                <div className="w-[250px] mt-3 h-[40px] mx-auto">
                  <SecondaryButton
                    text="やめる"
                    disabled={isSubmitting}
                    onClick={() => setIsModalOpen(false)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Tipping;
