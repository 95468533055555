import React from "react";
import Layout from "../../components/Common/Layout";

type Props = {
  unconfirmedEmail: string;
};
const Confirm: React.FC<Props> = (props) => {
  const { unconfirmedEmail } = props;
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "新規会員登録の受付完了"
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto sm:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          新規会員登録の受付完了
        </h2>
        <div className="mb-[45px] sm:w-[700px] sm:mx-auto leading-[1.875]">
          <p className="mb-[30px]">
            まだ、ユーザーの新規登録は完了しておりません。
          </p>
          <div className="bg-[#F4F6F8] mb-[30px] py-4 text-center text-lg font-bold">
            { unconfirmedEmail }
          </div>
          <p className="mb-[30px]">
            上記のメールアドレスに「
            <span className="font-bold">
              【Spportunity】新規会員登録時における本人認証のお願い
            </span>
            」という件名でsupport@spportunity.co.jpから自動でメールが送信されました。
          </p>
          <p className="mb-[30px]">
            そのメールの本文に記載された認証用のURL（リンク）をクリックして、48時間以内に本人認証をおこなってください。
          </p>
          <p className="mb-[30px]">
            もしメールが届かない場合は、メールアドレスのスペルや迷惑メール設定をご確認ください。
          </p>
          <p className="mb-[30px]">
            本人認証メールが届いていない場合は、
            <a
              href="/accounts/confirmation/resend"
              className="link-hover-none text-[#006AC9] underline"
            >
              こちら
            </a>
            より再送できます。
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default Confirm;
