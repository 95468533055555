import React from "react";

type Props = {
  href: string;
  text: string;
};

const FacebookLoginButton: React.FC<Props> = (props) => {
  const { href, text } = props;
  return (
    <a
      href={href}
      className="link-hover-none hover:bg-[#4062A5] before:mr-[10px] left-icon icon-facebook-white !bg-[#4062A5] h-[50px] font-raleway rounded-default block flex items-center justify-center w-full text-white text-sm"
    >
      {text}
    </a>
  );
};

export default FacebookLoginButton;
