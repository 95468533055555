import { useEffect, useState } from "react";
import { comment } from "../../types/pages/Team/detail";
import { HttpRequest } from "../../utils/common";

export const useFetchCommunityMessage = (clientId, investmentId) => {
  const [commentList, setCommentList] = useState<Array<comment>>()
  
  useEffect(() => {
    HttpRequest.get(`/api/articles/pick_up?client_id=${clientId}&investment_id=${investmentId}`)
    .then((res) => {
      setCommentList(res.data);
    })
  }, [])
  
  return { commentList }
}
