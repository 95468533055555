import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useRef } from "react";

type Props = {};

// TODO: CommentSlider, SliderWrapper, Bannerはほぼ同じ構成なので、共通化する
const CommentSlider: React.FC<Props> = (props) => {
  const commentSliderSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true
  };

  const wrapper = useRef(null);

  useEffect(() => {
    const wrapperCurrent = wrapper.current;
    const totalItems = wrapperCurrent?.querySelectorAll(".slick-slide").length;
    if (totalItems > 3) {
      const stalker = wrapperCurrent.querySelector(".stalker");
      wrapperCurrent.addEventListener("mouseover", () => {
        if (stalker) {
          stalker.style.display = "block";
        }
      });
      wrapperCurrent.addEventListener("mouseout", () => {
        if (stalker) {
          stalker.style.display = "none";
        }
      });
      document.addEventListener("mousemove", (e) => {
        if (stalker) {
          stalker.style.transform =
            "translate(" + e.clientX + "px, " + e.clientY + "px)";
        }
      });
    }
  }, []);

  return (
    <div className="slider-wrapper relative" ref={wrapper}>
      <div className="stalker"></div>
      <Slider {...commentSliderSettings}>{props.children}</Slider>
    </div>
  );
};

export default CommentSlider;
