import React, { useMemo, useState } from "react";
import { FieldPath, FieldValues, useController, UseControllerProps, UseFormSetValue } from "react-hook-form";


type Props = {
  id: string;
  maxNumber?: number;
  setValue: (value: string) => void;
}

function Counter<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  >({ ...props }: UseControllerProps<TFieldValues, TName> & Props) {
  const {
    field,
    formState,
  } = useController(props);
  const [isActive, setIsActive] = useState<boolean>(false)

  const increment = () => {
    if (!isActive) {
      setIsActive(true)
    }
    props.setValue(String(Number(field.value) + 1))
  }

  const decrement = () => {
    if (!isActive) {
      setIsActive(true)
    }
    props.setValue(String(Number(field.value) - 1))
  }

  const canDecrement = useMemo(() => field.value > 1, [field.value])
  const canIncrement = useMemo(() => field.value < 100, [field.value])

  const name1 = props.name.split(".")[0]
  const num = props.name.split(".")[1]
  const name2 = props.name.split(".")[2]

  return (
    <div className="flex flex-col">
      <div className="flex">
        <input
          {...field}
          name={props.name}
          id={props.id}
          className={`${formState?.errors[name1]?.[num]?.[name2] && "bg-[#FFE6E6] border-[#FF0000] border-2"} ${isActive ? "bg-white" : "bg-[#D3EAFF] border-[#006AC9] border-2"} w-[150px] h-[40px] border-[#1F2122] flex items-center justify-center mr-3 text-center border`}
          onFocus={() => setIsActive(true)}
          onChange={(e) => {
            field.onChange(e);
          }}
          type="text"
        />
        <button
          type="button"
          className={`${canDecrement ? "opacity-100" : "opacity-30"} mr-2 w-[40px] h-[40px] rounded-[50%] border-[#1F2122] before:content-[''] before:h-[1px] before:bg-[#1F2122] flex inline-block before:inline-block items-center justify-center before:w-3 border`}
          onClick={decrement}
          disabled={!canDecrement}
        />
        <button
          type="button"
          className={`${canIncrement ? "opacity-100" : "opacity-30"} w-[40px] h-[40px] rounded-[50%] border-[#1F2122] before:content-[''] before:h-[1px] before:bg-[#1F2122] after:content-[''] after:content-[''] after:w-[1px] after:bg-[#1F2122] after:absolute flex inline-block inline-block before:inline-block items-center justify-center before:w-3 after:h-3 border`}
          onClick={increment}
          disabled={!canIncrement}
        />
      </div>
      <div className="text-[#FF0000] mt-1 text-xs">
        {formState?.errors[name1]?.[num]?.[name2]?.type === "required" && (
          <p className="before:content-['*']">必須項目です</p>
        )}
      </div>
      <div className="text-[#FF0000] mt-1 text-xs">
        {formState?.errors[name1]?.[num]?.[name2]?.type === "pattern" && (
          <p className="before:content-['*']">1以上100以下の半角数字で入力してください</p>
        )}
      </div>
      <div className="text-[#FF0000] mt-1 text-xs">
        {formState?.errors[name1]?.[num]?.[name2]?.type === "max" && (
          <p className="before:content-['*']">{props.maxNumber ?? 100}以下の個数を入力してください</p>
        )}
      </div>
    </div>
  );
}

export default Counter;
