export const CONVENI_CODES = {
  FAMILY_MART: '21',
  LAWSON: '31',
  SEIKO_MART: '32',
  MINISTOP: '33',
}

export const getConvenienceStoreName = (conveniCode: number) => {
  switch (String(conveniCode)) {
    case CONVENI_CODES.FAMILY_MART:
      return "ファミリーマート";
    case CONVENI_CODES.LAWSON:
      return "ローソン";
    case CONVENI_CODES.SEIKO_MART:
      return "セイコーマート";
    case CONVENI_CODES.MINISTOP:
      return "ミニストップ";
    default:
      return "該当コンビニ無し";
  }
};
