import { useRef } from "react"

export const useTabRef = () => {
  const tabRef = useRef(null);

  const moveToTab = () => {
    const tabArea = document.querySelector(".project-detail-tab-area");
    if (tabRef.current) {
      tabRef.current.click();
      tabArea.scrollIntoView({ behavior: "smooth" });
    }
  }

  return { ref: tabRef, moveToTab };
}
