import React, { useState } from "react";
import { HttpRequest } from "../../../utils/common";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import { useForm } from "react-hook-form";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";
import Layout from "../../../components/Common/Layout";
import TextField from "../../../components/Common/Form/TextField";
import { isCareerAddress } from "../../../utils/FormUtil";
import { EMAIL_MATCH_PATTERN } from "../../../utils/constants"

type Props = {
  postPath: string;
};

const Reset: React.FC<Props> = (props) => {
  const { postPath } = props;
  const [isConfirmModalActive, setIsConfirmModalActive] = useState<boolean>(false);
  const [errors, setErrors] = useState({});

  const onSubmit = async () => {
    HttpRequest.post(postPath, {
      account: {
        email: getValues("email")
      }
    }).then((res) => {
      location.href = "/accounts/confirmation/resend/acception";
    }).catch((e) => {
      setErrors(e.response.data.errors);
      if (isCareerAddress(getValues("email"))) {
        toggleConfirmModal();
      }
    });
  }

  interface IFormInput {
    email: string;
  }

  const { handleSubmit, control, getValues } = useForm<IFormInput>({
    mode: "onChange",
    defaultValues: {
      email: ""
    }
  });

  const toggleConfirmModal = () => {
    if (isCareerAddress(getValues("email"))) {
      setIsConfirmModalActive(!isConfirmModalActive);
    } else {
      onSubmit();
    }
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "本人認証メールを再送します",
        }
      ]}
    >
      <ConfirmationModal
        toggleModal={() => toggleConfirmModal()}
        onSubmit={() => onSubmit()}
        isActive={isConfirmModalActive}
        confirmText="OK"
      >
        <p className="mb-[40px] text-lg font-bold">
          キャリアのメールアドレスを
          <br />
          登録される方へお願い
        </p>
        <div className="leading-[1.875]">
          <p className="mb-5">
            ※ご登録後、support@spportunity.co.jpから重要なメールが届きます。予め受信設定をお願いいたします。
          </p>
          <p>
            ※携帯キャリアのメールアドレスでは、システムからのメールが届かない場合がありますのでご注意ください。
          </p>
        </div>
      </ConfirmationModal>
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto sm:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          本人認証メールを再送します
        </h2>
        <div className="bg-[#F4F6F8] sm:p-[50px] sm:w-[700px] p-5 sm:mx-auto">
          <form onSubmit={handleSubmit(toggleConfirmModal)}>
            <div>
              <div className="flex items-center mb-1">
                <label htmlFor="email">メールアドレス</label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <TextField
                control={control}
                name="email"
                rules={{
                  required: true,
                  pattern: EMAIL_MATCH_PATTERN
                }}
                placeholder="メールアドレスを入力してください"
                id="email"
                patternErrorMessage="メールアドレスが正しくありません"
              />
            </div>
            <div className="mt-[30px] h-[50px] sm:w-[360px] sm:mx-auto">
              <BaseButton
                isSubmit={true}
                text="本人認証メールを再送する"
              />
            </div>
            <p className="text-[#FF0000] mt-3 mb-3 text-center text-xs">
              { errors["message"] }
            </p>
          </form>
        </div>
      </section>
    </Layout>
  );
};

export default Reset;
