import React from "react";
import IconBaseball from "../../../images/icons/icon_baseball.png";
import IconBasket from "../../../images/icons/icon_basket.png";
import IconCollege from "../../../images/icons/icon_college.png";
import IconEtc from "../../../images/icons/icon_etc.png";
import IconSoccer from "../../../images/icons/icon_soccer.png";
import IconVolleyball from "../../../images/icons/icon_volleyball.png";

type Props = {
  type: string;
  style?: any;
  className?: any;
};

const SportsIcon: React.FC<Props> = (props) => {
  const { type, ...rest } = props;

  switch (type) {
    case "baseball":
      return <img src={IconBaseball} alt={"野球"} {...rest} />;
    case "soccer":
      return <img src={IconSoccer} alt={"サッカー"} {...rest} />;
    case "college":
      return <img src={IconCollege} alt={"大学運動部・サークル"} {...rest} />;
    case "basketball":
      return <img src={IconBasket} alt={"バスケットボール"} {...rest} />;
    case "etc":
      return <img src={IconEtc} alt={"その他"} {...rest} />;
    case "volleyball":
      return <img src={IconVolleyball} alt={"バレーボール"} {...rest} />;
    default:
      return null;
  }
};

export default SportsIcon;
