import { useEffect, useState } from "react";

export const useFormInitialStatus = (obj) => {
  const [isInitial, setIsInitial] = useState<boolean>(true);

  useEffect(() => {
    for (const el in obj) {
      if (obj[el] !== "" || obj[el] !== null) {
        setIsInitial(false)
        break;
      }
    }
  },[])

  return { isInitial }
};
