import React, { useRef, useState } from "react";
import { HttpRequest } from "../../utils/common";
import FacebookLoginButton from "../../components/Common/Buttons/FacebookLoginButton";
import LineLoginButton from "../../components/Common/Buttons/LineLoginButton";
import BaseButton from "../../components/Common/Buttons/BaseButton";
import Modal from "../../components/Common/Modal";
import BaseLinkButton from "../../components/Common/Buttons/BaseLinkButton";
import { useForm } from "react-hook-form";
import Layout from "../../components/Common/Layout";
import TextField from "../../components/Common/Form/TextField";
import NoImg from "../../../images/noimg.png";
import { EMAIL_MATCH_PATTERN } from "../../utils/constants"
import TwitterLoginButton from "../../components/Common/Buttons/TwitterLoginButton";
import GoogleLoginButton from "../../components/Common/Buttons/GoogleLoginButton";

type Props = {
  targetPath: string;
  flashAlert?: string;
  facebookLoginPath: string;
  lineLoginPath: string;
  passwordResetPath: string;
  twitterLoginPath: string;
  googleLoginPath: string;
  token: string;
};

const Login: React.FC<Props> = (props) => {
  const { targetPath, flashAlert, passwordResetPath, facebookLoginPath, lineLoginPath, twitterLoginPath, googleLoginPath, token } = props;

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isFailureLogin, setIsFaillureLogin] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>("password");
  const [flashAlertMessage, setFlashAlertMessage] = useState<string>(flashAlert);
  const arrowRef = useRef(null);

  const { handleSubmit, control } = useForm<IFormInput>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: ""
    }
  });

  interface IFormInput {
    email: string;
    password: string;
  }

  const onSubmit = async (data) => {
    return await HttpRequest.post(targetPath, {
      account: {
        email: data.email,
        password: data.password,
        remember_me: rememberMe,
      },
    })
      .then((res) => {
        location.href = res.headers.location;
      })
      .catch(() => {
        setIsFaillureLogin(true);
      });
  };

  const togglePasswordDisplay = (e) => {
    if (e.target.checked) {
      setInputType("text")
    } else {
      setInputType("password")
    }
  };

  const toggleLoginStatus = (e) => {
    if (e.target.checked) {
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
  };

  const deleteFlashAlertMessage = () => {
    setFlashAlertMessage("");
  }

  const arrowUp = () => {
    arrowRef.current.classList.remove("icon-arrow-under");
    arrowRef.current.classList.add("icon-arrow-top");
  };

  const arrowDown = () => {
    arrowRef.current.classList.remove("icon-arrow-top");
    arrowRef.current.classList.add("icon-arrow-under");
  };

  const toggleFormItems = () => {
    if (arrowRef.current && !isOpen) {
      arrowUp();
    } else if (arrowRef.current && isOpen) {
      arrowDown();
    }
    setIsOpen(!isOpen);
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "ログイン"
        }
      ]}
    >
      <Modal
        isActive={!!flashAlertMessage}
        toggleModal={deleteFlashAlertMessage}
      >
        <div className="mb-10 w-[143px] h-[110px] mx-auto">
          <img src={NoImg} alt="" />
        </div>
        <p className="text-center">{flashAlert}</p>
      </Modal>
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mx-auto sm:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          ログイン
        </h2>
        <div className="sm:w-[700px] sm:mx-auto">
          <section className="mb-[40px]">
            <h2
              ref={arrowRef}
              className="right-icon icon-arrow-under sm:mb-[30px] flex items-center justify-between mb-5 font-medium"
              onClick={() => toggleFormItems()}
            >
              メールアドレスでログイン
            </h2>
            <div className="bg-[#F4F6F8] sm:p-[50px] p-5">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="flex items-center mb-1">
                    <label
                      htmlFor="email"
                      className="font-meidum inline-block text-sm"
                    >
                      メールアドレス
                    </label>
                    <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                      必須
                    </span>
                  </div>
                  <TextField
                    control={control}
                    name="email"
                    rules={{
                      required: true,
                      pattern: EMAIL_MATCH_PATTERN
                    }}
                    onChange={() => {
                      setIsOpen(true);
                      arrowUp();
                    }}
                    placeholder="メールアドレスを入力してください"
                    id="email"
                    patternErrorMessage="メールアドレスが正しくありません"
                  />
                </div>
                {isOpen && (
                  <>
                    <div className="mt-[30px]">
                      <div className="flex items-center mb-1">
                        <label
                          htmlFor="password"
                          className="font-meidum inline-block text-sm"
                        >
                          パスワード
                        </label>
                        <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                          必須
                        </span>
                      </div>
                      <TextField
                        control={control}
                        name="password"
                        rules={{ required: true }}
                        placeholder="パスワードを入力してください"
                        id="password"
                        type={inputType}
                      />
                      <div className="mt-3">
                        <input
                          onChange={(e) => togglePasswordDisplay(e)}
                          type="checkbox"
                          id="show_password"
                          className="password-checkbox before:content-[''] before:border-[#B1C2CC] relative before:inline-block before:w-4 before:h-4 before:bg-white before:border invisible before:visible"
                        />
                        <label htmlFor="show_password" className="ml-2 text-sm">
                          パスワードを表示
                        </label>
                      </div>
                    </div>
                    <div className="mb-[30px] mt-[40px] flex items-center justify-center">
                      <input
                        onChange={(e) => toggleLoginStatus(e)}
                        id="login"
                        type="checkbox"
                        className="before:top-[-4px] login-checkbox before:content-[''] before:border-[#B1C2CC] before:absolute relative before:inline-block before:w-5 before:h-5 before:bg-white before:border invisible before:visible"
                      />
                      <label htmlFor="login" className="ml-3 text-sm font-medium">
                        ログイン状態を保持する
                      </label>
                    </div>
                    {isFailureLogin && (
                      <p className="text-[#FF0000] mb-1 text-center text-xs">
                        メールアドレスまたはパスワードが違います。
                      </p>
                    )}
                    <div className="h-[50px] sm:w-[360px] sm:mx-auto">
                      <BaseButton
                        isSubmit={true}
                        text="メールアドレスでログイン"
                        fontSize="text-sm"
                      />
                    </div>
                  </>
                )}
                <p className="mt-[30px] text-center text-xs">
                  パスワードをお忘れの方は
                  <a
                    href={passwordResetPath}
                    className="link-hover-none underline"
                  >
                    こちら
                  </a>
                </p>
                {isOpen && (
                  <p className="text-[#768084] mt-[30px] mb-[10px] text-xs">
                    ※本人認証メールが届かない場合は
                    <a
                      href="/accounts/confirmation/resend"
                      className="link-hover-none text-[#006AC9] underline"
                    >
                      こちら
                    </a>
                    から再送手続きをしてください。その再送手続きをしてもまだ本人認証メールが届かない場合は、
                    <a
                      className="link-hover-none text-[#006AC9] underline"
                      href="https://corp.spportunity.com/faq/%E6%96%B0%E8%A6%8F%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E7%99%BB%E9%8C%B2%E3%81%AE%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%8C%E5%B1%8A%E3%81%8D%E3%81%BE%E3%81%9B%E3%82%93/"
                    >
                      こちらのFAQ
                    </a>
                    をご確認ください。
                  </p>
                )}
              </form>
            </div>
          </section>
          <div className="text-[#768084] after:content-[''] after:w-[40%] after:h-[1px] after:bg-[#D2DFE6] before:bg-[#D2DFE6] before:content-[''] before:h-[1px] before:w-[40%] sm:after:w-[41%] sm:before:w-[41%] flex after:inline-block before:inline-block items-center justify-between text-sm">
            または
          </div>
          <section className="mt-[40px]">
            <h2 className="font-meidum mb-5">外部アカウントでログイン</h2>
            <div className="sm:flex sm:flex-wrap sm:justify-between">
              <div className="mb-[15px] sm:w-[330px]">
                <FacebookLoginButton href={facebookLoginPath} text="Facebookでログインする" />
              </div>
              <div className="mb-[15px] sm:w-[330px]">
                <LineLoginButton href={lineLoginPath} text="LINEでログインする" />
              </div>
              <div className="mb-[15px] sm:w-[330px]">
                <TwitterLoginButton href={twitterLoginPath} text="Xでログインする" />
              </div>
              <div className="mb-[15px] sm:w-[330px]">
                <GoogleLoginButton path={googleLoginPath} token={token} text="Googleでログインする" />
              </div>
            </div>
          </section>
          <div className="mt-[40px]">
            <div className="text-[#768084] after:content-[''] after:w-[16%] after:h-[1px] after:bg-[#D2DFE6] before:bg-[#D2DFE6] before:content-[''] before:h-[1px] before:w-[16%] sm:after:w-[32%] sm:before:w-[32%] flex after:inline-block before:inline-block items-center justify-between text-sm">
              会員登録がまだお済みでない方
            </div>
            <div className="mt-[40px] h-[50px] sm:w-[360px] mx-auto">
              <BaseLinkButton
                text="新規会員登録"
                fontSize="text-sm"
                link="/accounts/sign_up"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
    
  );
};

export default Login;
