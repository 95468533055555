import React from "react";
import Noimg from "../../../../images/noimg.png";

type Props = {
  errorViewMsg?: string;
  projectPath?: string;
}
const Error: React.FC<Props> = (props) => {
  return (
    <div className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
    <div className="sm:mb-[90px] sm:text-center mb-10">
        { props.errorViewMsg ? (
        <p>{ props.errorViewMsg }</p>
        ) : (
        <>
            <p>メンテナンス中、もしくはアクセスが集中しています。</p>
            <p>ご不便をおかけしますが、しばらく経ってから再度アクセスをお願いします。</p>
        </>
        )}
        <p className="mt-3">
        {props.projectPath ? 
        <a className="text-primary" href={props.projectPath}>支援募集プロジェクトに戻る</a>
        :
        <a className="text-primary" href="/">トップへ</a>
        }
        </p>
    </div>
    <div className="w-[143px] h-[110px] mx-auto">
        <img src={Noimg} alt="" />
    </div>
    </div>
  );
};

export default Error;
