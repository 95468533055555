import React, { useMemo } from "react";
import { FormatCurrency } from "../../../utils/TextUtil";
import NoImg from "../../../../images/noimage.gif";
import classNames from "classnames";

type Props = {
  clientName: string;
  investmentName: string;
  imageUrl: string;
  investmentPath: string;
  totalAmount: number;
  totalCount: number;
  logoImageUrl?: string;
  teamIcon?: string;
  targetAmount: number;
  totalTargetAmount?: number;
};

const InvestmentCard: React.FC<Props> = (props) => {
  const {
    clientName,
    investmentName,
    imageUrl,
    investmentPath,
    totalAmount,
    totalCount,
    teamIcon,
    targetAmount,
  } = props;

  const percent = useMemo(() => {
    return Math.min(Math.floor((totalAmount / targetAmount) * 100), 100);
  }, []);

  return (
    <a href={investmentPath} className={classNames("group relative")}>
      <div className="rounded shadow-lg overflow-hidden md:mx-0">
        <div className="h-[75%] w-full">
          {imageUrl ? (
            <img
              src={imageUrl}
              className="w-full object-contain"
              style={{ aspectRatio: "4/3" }}
            />
          ) : (
            <img
              src={NoImg}
              className="w-full h-full object-contain"
              style={{ aspectRatio: "4/3" }}
            />
          )}
        </div>
        <div className="px-5 py-4">
          <div className="line-clamp-2 h-[50px] mb-1 group-hover:underline font-bold">
            {investmentName}
          </div>
          <div className="flex items-center">
            <div className="flex items-center mr-2 w-5 h-5">
              <img src={teamIcon} alt="" className="object-contain" />
            </div>
            <div className="line-clamp-1 w-[calc(100%-28px)] h-4 text-gray-500 group-hover:underline text-sm">
              {clientName}
            </div>
          </div>
        </div>
        <div className="mx-5 py-4 border-t border-grey-500">
          <dl>
            <dt>支援総額</dt>
            <dd className="block text-right text-xl font-bold">
              {FormatCurrency(totalAmount)}円
            </dd>
          </dl>
          <div className="flex flex-row items-center my-4">
            <div className="relative w-full h-5 bg-gray-300 rounded-2xl overflow-hidden">
              <div
                className="h-5 bg-gradient-to-r rounded-2xl from-blue-200 to-blue-600"
                style={{
                  width: `${percent}%`,
                }}
              >
                <span className="absolute flex items-center ml-3 h-full text-white text-sm">
                  {percent + "%"}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <dl className="flex items-center text-sm">
                <dt className="text-grey-400 left-icon icon-group flex items-center mr-2">
                  支援数
                </dt>
                <dd>{totalCount}件</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default InvestmentCard;
