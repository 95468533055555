import React from "react";
import { UseFormGetValues } from "react-hook-form";
import FacebookButton from "../../../components/Common/Buttons/FacebookButton";
import TwitterButton from "../../../components/Common/Buttons/TwitterButton";
import HowToShare from "../../../components/Common/HowToShare";
import Share from "../../../components/Common/Share";
import { ProjectInformation } from "../../../types/pages/EcWebsettlement/purchase_ec_return";
import { PurchaseEcReturnFormValues } from "../../../types/pages/EcReturn/Websettlement/purchase_ec_return";


type Props = {
  settlementResultProps: {
    previousFunding: number;
    currentFunding: number;
    remainingFunding: number;
    finishedGoal: boolean;
    targetAmount: number;
    conveniPaymentInfo?: {
      item_name: string;
      item_price: number;
      conveni_limit: string;
      conveni_code: number;
      conveni_name: string;
      receipt_no: string;
      kigyou_code: number;
    }
    bankTransferInfo?: {
      item_name: string;
      item_price: number;
      bank_name: string;
      bank_code: string;
      branch_name: string;
      branch_code: string;
      account_no: string;
      account_name: string;
      bank_limit: string;
    };
  }
  projectInformation: ProjectInformation;
  widget_url: string;
  referral_url: string;
  getValues: UseFormGetValues<PurchaseEcReturnFormValues>;
};

const ConfirmSettlement = ({
  settlementResultProps,
  projectInformation,
  widget_url,
  referral_url,
  getValues
}: Props) => {
  return (
    <>
      <div className="mb-[60px]">
        {
          getValues("payment_method") === "credit_card" &&
          <div className="flex">
            <div className="w-[13%] border-[#1F2122] flex items-center justify-center border">
              1
            </div>
            <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
              2
            </div>
            <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
              3
            </div>
            <div className="h-[40px] bg-[#1F2122] w-[61%] relative flex items-center justify-center text-center text-white text-sm font-bold">
              <span className="text-[#1F2122] bg-[#768084] rounded-[50%] inline-block mr-1 w-5 h-5 text-center">
                4
              </span>
              支援完了
            </div>
          </div>
        }
        {
          getValues("payment_method") === "conveni" &&
          <div className="flex">
            <div className="w-[13%] border-[#1F2122] flex items-center justify-center border">
              1
            </div>
            <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
              2
            </div>
            <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
              3
            </div>
            <div className="h-[40px] bg-[#1F2122] sm:w-[45%] w-[38%] relative flex items-center justify-center text-center text-white sm:text-sm font-bold text-xs">
              <span className="text-[#1F2122] bg-[#768084] rounded-[50%] inline-block mr-1 w-5 h-5 flex justify-center items-center">
                4
              </span>
              仮支援受け付け
              <div className="right-triangle-thin right-[-7px] absolute"></div>
            </div>
            <div className="sm:w-[16%] w-[23%] flex items-center justify-center bg-[#006AC9]">
              <span className="text-white text-xs font-bold">コンビニへ</span>
            </div>
          </div>
        }
        {
          getValues("payment_method") === "bank" &&
          <div className="flex">
            <div className="w-[13%] border-[#1F2122] flex items-center justify-center border">
              1
            </div>
            <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
              2
            </div>
            <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
              3
            </div>
            <div className="h-[40px] bg-[#1F2122] sm:w-[45%] w-[38%] relative flex items-center justify-center text-center text-white sm:text-sm font-bold text-xs">
              <span className="text-[#1F2122] bg-[#768084] rounded-[50%] inline-block mr-1 w-5 h-5 flex justify-center items-center">
                4
              </span>
              仮支援受け付け
              <div className="right-triangle-thin right-[-7px] absolute"></div>
            </div>
            <div className="sm:w-[16%] w-[23%] flex items-center justify-center bg-[#006AC9]">
              <span className="text-white text-xs font-bold">銀行へ</span>
            </div>
          </div>
        }
      </div>
      {
        getValues("payment_method") === "credit_card" &&
        <>
          <p className="font-bold mb-[60px] text-center">ご支援ありがとうございます！</p>
          <div className="bg-[#D2DFE6] sm:p-10 mb-[50px] mx-[-30px] sm:mx-auto p-[30px]">
            <p className="text-2xl font-bold mb-10 text-[#006AC9] text-center">リターン購入完了!!</p>
            <div className="mb-5 w-[320px] aspect-3/4 mx-auto">
              <img src={projectInformation.investment_image} alt={projectInformation.investment_title} />
            </div>
            <p className="mb-[10px]">{projectInformation.investment_title}</p>
            <div className="text-sm">
              <p>正常にクレジットカードでの決済が完了しました。</p>
              <p>ご登録いただいたメールアドレスに支援完了のメールをお送りいたしました。</p>
              <p>また、マイページの「支援履歴」からも支援内容をご確認いただけます。</p>
            </div>
          </div>
        </>
      }
      {
        getValues("payment_method") === "conveni" &&
        <>
          <div className="mb-10">
            <p className="font-bold mb-5 text-center">ありがとうございます。<br className="sm:hidden" />仮支援を受け付けました。</p>
            <p className="mb-10 sm:text-center">ご登録いただいたメールアドレスに受け付けのメールをお送りいたしました。</p>
            <p className="text-[#FF0000] text-lg sm:text-center">本支援は完了していませんので、<br className="hidden sm:block" />下記の手順に従って、お支払いを完了させてください。</p>
          </div>
          <div className="bg-[#D2DFE6] sm:p-10 mb-10 p-[30px]">
            <p className="text-[#006AC9] font-bold text-lg mb-5 text-center">コンビニでの支払いを<br className="sm:hidden" />お願いします</p>
            <p>概ね5分以内に支払情報が記載された自動メールが届きます。</p>
            <p className="mb-[10px]">内容をご確認の上「お支払期限」({settlementResultProps.conveniPaymentInfo.conveni_limit})までに{settlementResultProps.conveniPaymentInfo.conveni_name}にて代金をお支払いください。</p>
            <div className="p-5 sm:mb-5 mb-[10px] bg-white">
              {settlementResultProps.conveniPaymentInfo.conveni_name === "ファミリーマート" ? (
                <>
                  <dl className="flex">
                    <dt>企業コード：</dt>
                    <dd>自動メールにてお知らせします。</dd>
                  </dl>
                  <dl className="flex">
                    <dt>注文番号：</dt>
                    <dd>自動メールにてお知らせします。</dd>
                  </dl>
                </>
              ) : (
                <dl className="flex">
                  <dt>受付番号：</dt>
                  <dd>自動メールにてお知らせします。</dd>
                </dl>
              )}
              <dl className="flex">
                <dt>お支払い期限：</dt>
                <dd>{settlementResultProps.conveniPaymentInfo.conveni_limit}</dd>
              </dl>
            </div>
            {settlementResultProps.conveniPaymentInfo.conveni_name === "ファミリーマート" ?
            (
              <>
                <p className="mb-5">コンビニ店頭のFamiポートを操作し、画面支持に従って「企業コード(5桁)」と「注文番号(12桁)」を入力してください。</p>
                <p className="text-sm">ファミリーマート決済のお支払方法はこちら</p>
                <a href="https://www.epsilon.jp/mb/conv/famima/index.html" target="_blank" rel="noopener">http://www.epsilon.jp/mb/conv/famima/index.html</a>
              </>
            ) : (
              <>
                <p className="mb-5">コンビニ店頭の設置端末を操作し、受付番号{settlementResultProps.conveniPaymentInfo.receipt_no}を入力ください。</p>
                <p className="text-sm">ローソン決済のお支払方法はこちら</p>
                <a href="https://www.epsilon.jp/mb/conv/lawson/index.html" target="_blank" rel="noopener">https://www.epsilon.jp/mb/conv/lawson/index.html</a>
                <p className="text-sm">ミニストップ決済のお支払方法はこちら</p>
                <a href="https://www.epsilon.jp/service/conv_ministop.html" target="_blank" rel="noopener">https://www.epsilon.jp/service/conv_ministop.html</a>
                <p className="text-sm">セイコーマート決済のお支払方法はこちら</p>
                <a href="https://www.epsilon.jp/mb/conv/seico/index.html" target="_blank" rel="noopener">https://www.epsilon.jp/mb/conv/seico/index.html</a>
              </>
            )}
            <p className="mt-5 text-sm">万が一、30分以上経過しても自動送信メールが届かない場合は、スポチュニティ・カスタマーサポート(support@spportunity.co.jp)までお問い合わせください。</p>
          </div>
        </>
      }
      {
        getValues("payment_method") === "bank" &&
        <>
          <div className="mb-10">
            <p className="font-bold mb-5 text-center">ありがとうございます。<br className="sm:hidden" />仮支援を受け付けました。</p>
            <p className="mb-10 sm:text-center">ご登録いただいたメールアドレスに受け付けのメールをお送りいたしました。</p>
            <p className="text-[#FF0000] text-lg sm:text-center">本支援は完了していませんので、<br className="hidden sm:block" />下記の手順に従って、お支払いを完了させてください。</p>
          </div>
          <div className="bg-[#D2DFE6] sm:p-10 mb-10 p-[30px]">
            <p className="text-[#006AC9] font-bold text-lg mb-5 text-center">銀行での支払いを<br className="sm:hidden" />お願いします</p>
            <p>概ね5分以内に振込先が記載された自動メールが届きます。</p>
            <p className="mb-[10px]">振込期限({settlementResultProps.bankTransferInfo.bank_limit})までに、メール記載の振込先へお振込みください。</p>
            <div className="text-[#768084] text-sm mb-5">
              <p>※振込手数料は支援者様のご負担となります。予めご了承ください。</p>
              <p>※複数支援の申込みをされた場合、それぞれお振込みいただく口座番号は異なります。ご注意ください。</p>
            </div>
            <p>万が一、30分以上経過してもメールが届かない場合は、スポチュニティ・カスタマーサポート(support@spportunity.co.jp)までお問い合わせください。</p>
          </div>
        </>

      }
      <div className="bg-[#F4F6F8] pt-9 pb-5 px-10 mb-[80px]">
        <div className="pb-5">
          <p className="font-bold text-center">現在のプロジェクト支援額</p>
          <div className="text-center flex items-center justify-center flex-col sm:flex-row">
            <div className="font-bold text-3xl">{settlementResultProps.previousFunding.toLocaleString()}<span className="text-lg">円</span></div>
            <div className="sm:right-triangle sm:mx-6 down-triangle-black mt-2 mb-1 sm:my-0"></div>
            <div className="font-bold text-[40px]">{settlementResultProps.currentFunding.toLocaleString()}<span className="text-lg">円</span></div>
          </div>
        </div>
        <div className="border-t border-[#D2DFE6] pt-5 text-center">
          {settlementResultProps.finishedGoal ? (
            <p className="text-sm">目標金額({settlementResultProps.targetAmount.toLocaleString()}円)を達成しました！</p>
          ) : (
            <p className="text-sm">目標金額({settlementResultProps.targetAmount.toLocaleString()}円)まで<br className="sm:hidden" />あと{settlementResultProps.remainingFunding.toLocaleString()}円となりました！</p>
          )}
        </div>
      </div>
      <div className="w-screen mx-[calc(50%-50vw)] bg-[#F4F6F8] py-[80px] mb-[80px] px-[30px] sm:px-0">
        <p className="text-center font-bold sm:text-2xl mb-10 text-lg">スポチュニティのアカウントに<br className="sm:hidden" />ログインして<br />紹介インセンティブをゲットしよう</p>
        <div className="sm:w-[700px] sm:mx-auto bg-white">
          <div className="mb-5">
            <Share 
              investmentTitle={projectInformation.investment_title}
              widgetURL={widget_url}
              referralURL={referral_url}
            />
          </div>
          <div className="px-10 mb-[30px]">
            <HowToShare />
          </div>
          <Share 
            investmentTitle={projectInformation.investment_title}
            widgetURL={widget_url}
            referralURL={referral_url}
          />
        </div>
      </div>
      <div className="mb-[60px]">
        <p className="font-bold text-lg text-center sm:mb-10 mb-5">友達にお知らせして<br className="sm:hidden" />プロジェクトを成功させましょう</p>
        <div className="sm:flex sm:justify-between">
          <div className="sm:w-[335px] w-full mb-[10px] sm:mb-0">
            <TwitterButton href={referral_url} text="ポストする" title={projectInformation.investment_title} />
          </div>
          <div className="sm:w-[335px] w-full">
            <FacebookButton href={referral_url} text="シェアする" title={projectInformation.investment_title} />
          </div>
        </div>
      </div>
      <div className="text-center">
        <a href={`${projectInformation.investment_path}#community`} className="font-meidum text-sm right-icon icon-stick-arrow-right">みんなの応援メッセージを見る</a>
      </div>
    </>
  );
};

export default ConfirmSettlement;
