import React, { useState } from "react";
import Layout from "../../../components/Common/Layout"
import EnterProfile from "./EnterProfile";
import Confirm from "./Confirm";
import Complete from "./Complete";
import Canceled from "./Canceled";
import Error from "./Error";
import { useForm } from "react-hook-form";
import { HttpRequest } from "../../../utils/common";
import { 
  Client,
  Investment,
  SocialProfile,
  MonthlyDonationForm,
  SettlementResult,
} from "../../../types/pages/Clients/MonthlyDonations/create"

type Props = { 
  client: Client,
  investment: Investment,
  socialProfile: SocialProfile,
  payJpPublicKey: string,
  postPath: string,
  cancelPath: string,
  canDonate: boolean,
  currentAmount?: number,
  commission: number,
};

const Create: React.FC<Props> = ({
  client,
  investment,
  socialProfile,
  payJpPublicKey,
  postPath,
  cancelPath,
  canDonate,
  currentAmount,
  commission
}: Props
) => {
  enum CHECKOUT_STATES {
    EnterProfile,
    Confirm,
    Complete,
    Canceled,
    Error,
  }

  const [currentPageState, setCurrentPageState] = useState(CHECKOUT_STATES.EnterProfile)
  const [donationData, setDonationData] = useState<MonthlyDonationForm>()
  const [errorMessage, setErrorMessage] = useState("")
  const [settlementResultProps, setSettlementResultProps] = useState<SettlementResult | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { handleSubmit, control, watch, setValue } = useForm<MonthlyDonationForm>({
    mode: "all",
    defaultValues: {
      card_brand: socialProfile?.card_brand,
      card_last4: socialProfile?.card_last4,
      card_exp_year: socialProfile?.card_exp_year,
      card_exp_month: socialProfile?.card_exp_month,
      amount: "1000",
      reasons: "",
      article_message: "応援しています。頑張ってください。",
    }
  });

  const cardInformation = () => {
    return {
      card_brand: watch('card_brand'),
      card_last4: watch('card_last4'),
      card_exp_year: watch('card_exp_year'),
      card_exp_month: watch('card_exp_month')
    } as SocialProfile
  }

  const check = (data) => {
    setDonationData(data)
    setCurrentPageState(CHECKOUT_STATES.Confirm)
  }

  const donate = (data) => {
    setIsSubmitting(true)
    HttpRequest.post(postPath, data)
      .then((res) => {
        setSettlementResultProps(res.data)
        setCurrentPageState(CHECKOUT_STATES.Complete)
      })
      .catch((e) => {
        // TODO: hundle other errors
        setErrorMessage("処理中にエラーが発生しました。再度やり直してもエラーが発生する場合は、お問い合わせよりご連絡ください。");
        setCurrentPageState(CHECKOUT_STATES.Error);
      });
  }

  const onCancel = () => {
    HttpRequest.delete(cancelPath)
      .then(() => {
        setCurrentPageState(CHECKOUT_STATES.Canceled)
      })
      .catch(() => {
        // TODO: hundle other errors
        setErrorMessage("処理中にエラーが発生しました。再度やり直してもエラーが発生する場合は、お問い合わせよりご連絡ください。");
        setCurrentPageState(CHECKOUT_STATES.Error);
      });
  }

  const onSubmit = (data) => {
    switch (currentPageState) {
      case CHECKOUT_STATES.EnterProfile:
        return check(data)
      case CHECKOUT_STATES.Confirm:
        return donate(data)
    }
  }

  const enterProfileProps = {
    client,
    cardInformation,
    payJpPublicKey,
    onSubmit: () => {
      setDonationData(watch)
      setCurrentPageState(CHECKOUT_STATES.Confirm)
    },
    investment,
    canDonate,
    currentAmount,
    control,
    setValue,
    onCancel,
  }

  const confirmProps = {
    client,
    cardInformation,
    canDonate,
    data: donationData,
    movePreviousPage: () => setCurrentPageState(CHECKOUT_STATES.EnterProfile),
    isSubmitting,
    commission,
  }
  
  const currentPage = () => {
    switch (currentPageState) {
      case CHECKOUT_STATES.EnterProfile:
        return <EnterProfile {...enterProfileProps}/>
      case CHECKOUT_STATES.Confirm:
        return <Confirm {...confirmProps}/>
      case CHECKOUT_STATES.Complete:
        return <Complete client={client} investment={investment} result={settlementResultProps}/>
      case CHECKOUT_STATES.Canceled:
        return <Canceled projectPath={client.investment_path} />
      case CHECKOUT_STATES.Error:
        return <Error errorViewMsg={errorMessage} projectPath={client.investment_path}/>
    }
  }
  
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          href: client.investment_path,
          text: client.name,
        },
        {
          text: "支援のお手続き",
        },
      ]}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {currentPage()}
      </form>
    </Layout>
  );
};

export default Create;
