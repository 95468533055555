import React from "react";
import Layout from "../../../components/Common/Layout";

type Props = {
  unconfirmedEmail: string;
};

const Acception: React.FC<Props> = (props) => {
  const { unconfirmedEmail } = props;
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "メールアドレスの変更",
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-[40px] sm:mx-auto md:px-0">
        <h1 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          メールアドレスの変更
        </h1>
        <div className="sm:w-[700px] sm:mx-auto">
          <div className="bg-[#F4F6F8] mb-[30px] py-4 text-center text-lg font-bold">
            { unconfirmedEmail }
          </div>
          <div className="mb-[60px]">
            <p className="mb-[30px]">
              上記のメールアドレスに 「
              <span className="font-bold">
                【Spportunity】メールアドレス変更における本人認証のお願い
              </span>
              」という件名でsupport@spportunity.co.jpから自動でメールが送信されました。
            </p>
            <p className="mb-[30px]">
              そのメールの本文に記載された認証用のURL（リンク）をクリックして、48時間以内に本人認証をおこなってください。
            </p>
            <p>
              もしメールが届かない場合は、メールアドレスのスペルや迷惑メール設定をご確認ください。
            </p>
          </div>
          <div className="text-center">
            <a href="/mypage" className="left-icon icon-stick-arrow-left text-sm">
              マイページトップ
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Acception;
