import React from "react";
import classNames from "classnames";
import {
  ranker,
  clientRanking,
} from "../../types/pages/ranking";
import RankingListCard from "./RankingListCard";
import "../../../stylesheets/rankingBody.css"

type Props = {
  rankers: ranker[];
  teams: clientRanking[];
  showCaption?: boolean;
};

const RankingBody: React.FC<Props> = (props) => {
  const {
    rankers,
    teams,
    showCaption,
  } = props;

  return (
    <div className="overflow-hidden p-0 flex flex-col sm:flex-row">
      <section className="rankingBody__section maxsm:mb-0 maxsm:w-full mb-3 sm:px-[10px]">
      <h1 className="maxsm:pt-4 maxsm:pb-3 maxsm:bg-gray-200 maxsm:text-sm text-xl font-bold border-b border-gray-300 py-5">
        サポーターランキング
        {showCaption && <span className="block mt-0 ml-0 text-xs text-gray-500 font-normal">該当する都道府県に住んでる人のランキングです</span>}
        <span className="before:content-['※'] before:box-border block mt-0.5 ml-0 text-xs text-gray-500 font-normal">支援金累計額が同額の場合は支援を開始していただいた日時が早いサポーターを上位としています</span>
      </h1>
      {rankers.map((ranker, index) => (
        <RankingListCard
          key={ranker.member_id}
          isTeam={false}
          ranking={index+1}
          teamOrClientName={ranker.user_name}
          supportAmount={ranker.investment_sum_value}
          DateOrSupportNumber={ranker.days}
          prefectureSlug={ranker.slug}
          prefectureName={ranker.prefecture}
        />
      ))}
      </section>

      <section className="rankingBody__section maxsm:mb-0 maxsm:w-full mb-3 sm:px-[10px]">
        <h1 className="maxsm:pt-4 maxsm:pb-3 maxsm:bg-gray-200 maxsm:text-sm text-xl font-bold border-b border-gray-300 py-5">
          支援獲得ランキング
          {showCaption && <span className="block mt-0 ml-0 text-xs text-gray-500 font-normal">該当する都道府県に所属するチームのランキングです</span>}
          <span className="before:content-['※'] before:box-border block mt-0.5 ml-0 text-xs text-gray-500 font-normal">支援金累計額が同額の場合は支援を開始していただいた日時が早いチームを上位としています</span>
        </h1>
        {teams.map((team, index) => (
          <RankingListCard
            key={team.id}
            isTeam={true}
            ranking={index+1}
            teamOrClientName={team.client_name}
            supportAmount={team.investment_sum_value}
            DateOrSupportNumber={team.total_account}
            prefectureSlug={team.slug}
            prefectureName={team.prefecture}
            categoryType={team.category_type}
            categoryName={team.category_name}
            teamImageUrl={team.team_image_url}
          />
        ))}
      </section>
    </div>
  )
};

export default RankingBody;
