import React, { useState } from "react";
import Layout from "../../components/Common/Layout";
import TextField from "../../components/Common/Form/TextField";
import { useForm } from "react-hook-form";
import { HttpRequest } from "../../utils/common";
import BaseButton from "../../components/Common/Buttons/BaseButton";
import Modal from "../../components/Common/Modal";
import NoImg from "../../../images/noimg.png";
import { EMAIL_MATCH_PATTERN } from "../../utils/constants"

type Props = {
  document_url?: string;
  original_name?: string;
  quotation_path: string;
  return_name: string;
  project_title: string;
  main_image: string;
  team_detail_path: string;
  ec_return_path: string;
  ec_return_contact_path: string;
  ec_return_quotation_path: string;
};

const Corporation: React.FC<Props> = (props) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [interest, setInterest] = useState<string>("")
  interface IFormInput {
    company: string;
    department: string;
    position: string;
    last_name: string;
    first_name: string;
    email: string;
    postal_code: string;
    full_address: string;
    phone_number: string;
    interest: number;
  }

  const { handleSubmit, control, setValue, trigger } = useForm<IFormInput>({
    mode: "onChange",
    defaultValues: {
      company: "",
      department: "",
      position: "",
      last_name: "",
      first_name: "",
      email: "",
      postal_code: "",
      full_address: "",
      phone_number: "",
      interest: 1
    }
  });

  const checkInfo = async () => {
    return await trigger(["last_name", "first_name", "postal_code", "full_address", "phone_number"])
  }

  const autoFullAddressFill = (postalCode: string): void => {
    if (postalCode.length != 7) {
      return
    }

    HttpRequest.get('/api/addresses/cities/' + postalCode).then((res) => {
      const addressData = res.data
      setValue('full_address', addressData.address)
    }).catch((e) => {
      console.log(e)
    })
  }

  const onSubmit = async (data) => {
    const result = await checkInfo()
    if (!result) { return }
    setIsSubmitting(true)
    HttpRequest.post(props.ec_return_contact_path, data)
    .then((res) => {
      switch (interest) {
        case "contact":
          toggleModal()
          break
        case "quotation":
          openQuotation(res.data.id)
          break
        case "documents":
          downloadFile()
          break
      }
    })
    .catch(() => {
      // TODO: handle error
    });
    setIsSubmitting(false)
  }

  const toggleModal = () => {
    setIsActive(!isActive)
  }

  const openQuotation = (companyId: number) => {
    const quotationLink = document.createElement("a");
    quotationLink.href = props.ec_return_quotation_path + "?company_id=" + companyId;
    quotationLink.target = '_blank';

    document.body.appendChild(quotationLink);
    quotationLink.click();
    document.body.removeChild(quotationLink);
  }

  const downloadFile = () => {
    if (!props.document_url || !props.original_name) {
      return
    }
    const downloadLink = document.createElement("a");
    downloadLink.href = props.document_url;
    downloadLink.download = props.original_name;
    downloadLink.target = '_blank';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: props.project_title,
          href: props.team_detail_path,
        },
        {
          text: props.return_name,
          href: props.ec_return_path,
        },
        {
          text: "資料ダウンロードや見積書の確認",
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:mt-[60px] sm:mb-[40px] sm:mx-auto sm:max-w-main md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">資料ダウンロードや見積書の確認</h2>
        <div className="sm:w-[700px] sm:mx-auto">
          <div className="flex mb-5 sm:mb-[35px]">
            <div className="w-[80px] h-[60px] mr-[10px] sm:w-[120px] sm:h-[90px] sm:mr-5">
              <img
                src={props.main_image}
                alt={props.project_title}
                className="object-contain"
              />
            </div>
            <h3 className="sm:w-[calc(100%-140px)] w-[calc(100%-90px)] text-sm underline">
              <a className="link-hover-none" href={props.team_detail_path}>{props.project_title}</a>
            </h3>
          </div>
          <p className="font-bold text-lg mb-10">{props.return_name}</p>
          <p className="text-[#FF0000] mb-10">下記、必要事項を入力の上、ご希望の内容を選択してください。</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-5">
              <div className="flex items-center mb-1">
                <label htmlFor="current_password" className="inline-block text-sm">
                  会社名
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <TextField
                control={control}
                name="company"
                rules={{
                  required: true
                }}
                placeholder="会社名を入力してください"
                id="company"
              />
            </div>
            <div className="mb-5">
              <div className="flex items-center mb-1">
                <label htmlFor="current_password" className="inline-block text-sm">
                  部署名
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <TextField
                control={control}
                name="department"
                rules={{
                  required: true
                }}
                placeholder="部署名を入力してください"
                id="department"
              />
            </div>
            <div className="mb-5">
              <div className="flex items-center mb-1">
                <label htmlFor="current_password" className="inline-block text-sm">
                  役職
                </label>
              </div>
              <TextField
                control={control}
                name="position"
                placeholder="役職を入力してください"
                id="position"
              />
            </div>
            <div className="mb-5">
              <div className="flex items-center mb-1">
                <label htmlFor="last_name" className="inline-block text-sm">
                  お名前
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <div className="sm:flex sm:justify-between">
                <div className="sm:w-[340px] mb-[10px] sm:mb-0">
                  <TextField
                    name="last_name"
                    placeholder="姓を入力してください"
                    id="last_name"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 20,
                    }}
                    maxLengthErrorMessage="20文字以内で入力してください"
                  />
                </div>
                <div className="sm:w-[340px]">
                  <TextField
                    name="first_name"
                    placeholder="名を入力してください"
                    id="first_name"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 20,
                    }}
                    maxLengthErrorMessage="20文字以内で入力してください"
                  />
                </div>
              </div>
            </div>
            <div className="mb-5">
              <div className="flex items-center mb-1">
                <label htmlFor="current_password" className="inline-block text-sm">
                  メールアドレス
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <TextField
                control={control}
                name="email"
                rules={{
                  required: true,
                  pattern: EMAIL_MATCH_PATTERN
                }}
                placeholder="メールアドレスを入力してください"
                id="email"
                patternErrorMessage="メールアドレスが正しくありません"
              />
            </div>
            <div className="mb-5">
              <div className="flex items-center mb-1">
                <label htmlFor="postal_code" className="inline-block text-sm">
                  郵便番号
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <div className="sm:hidden">
                <TextField
                  name="postal_code"
                  placeholder="郵便番号を入力してください"
                  id="postal_code"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^[0-9]+$/
                  }}
                  onChange={(e) => {
                    const postalCode = e.target.value.replace('-', '');
                    setValue('postal_code', postalCode);
                    autoFullAddressFill(postalCode);
                  }}
                  patternErrorMessage="半角数字で入力してください"
                />
                <p className="text-[#768084] mt-1 text-xs">
                  -（ハイフン）は入力不要です。
                </p>
              </div>
              <div className="hidden sm:block">
                <div className="sm:flex sm:items-end">
                  <div className="w-[236px]">
                    <TextField
                      name="postal_code"
                      placeholder="郵便番号を入力してください"
                      id="postal_code"
                      control={control}
                      rules={{
                        required: true,
                        pattern: /^[0-9]+$/
                      }}
                      onChange={(e) => {
                        const postalCode = e.target.value.replace('-', '');
                        setValue('postal_code', postalCode);
                        autoFullAddressFill(postalCode);
                      }}
                      patternErrorMessage="半角数字で入力してください"
                    />
                  </div>
                </div>
                <p className="text-[#768084] mt-1 text-xs">
                  -（ハイフン）は入力不要です。
                </p>
              </div>
            </div>
            <div className="mb-5">
              <div className="flex items-center mb-1">
                <label htmlFor="current_password" className="inline-block text-sm">
                  住所
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <TextField
                control={control}
                name="full_address"
                rules={{
                  required: true
                }}
                placeholder="住所を入力してください"
                id="full_address"
              />
            </div>
            <div className="mb-5">
              <div className="flex items-center mb-1">
                <label htmlFor="current_password" className="inline-block text-sm">
                  電話番号
                </label>
                <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                  必須
                </span>
              </div>
              <div className="sm:w-[340px]">
                <TextField
                  control={control}
                  name="phone_number"
                  rules={{
                    required: true,
                    pattern: /^0\d{9,10}$/
                  }}
                  patternErrorMessage="9〜10桁の半角数字で入力してください"
                  placeholder="電話番号を入力してください"
                  id="phone_number"
                />
              </div>
            </div>
            <div className="mt-[60px] sm:mx-auto h-[50px] sm:w-[350px]">
              <BaseButton
                text="説明の打ち合わせを打診する"
                fontSize="text-sm"
                isSubmit={true}
                onClick={() => {
                  setInterest("contact")
                  setValue('interest', 1)
                }}
              />
            </div>
            <div className="mt-[30px] sm:mx-auto h-[50px] sm:w-[350px]">
              <BaseButton
                text="見積書を確認する"
                fontSize="text-sm"
                isSubmit={true}
                onClick={() => {
                  setInterest("quotation")
                  setValue('interest', 2)
                }}
              />
            </div>
            { props.document_url && props.original_name && (
              <div className="mt-[30px] sm:mx-auto h-[50px] sm:w-[350px]">
                <BaseButton
                  text="説明資料をダウンロードする"
                  fontSize="text-sm"
                  disabled={isSubmitting}
                  isSubmit={true}
                  onClick={() => {
                    setInterest("documents")
                    setValue('interest', 3)
                  }}
                />
              </div>
            )}
          </form>
          <div className="text-center mt-[80px]">
            <a
              href={props.ec_return_path}
              className="left-icon icon-stick-arrow-left text-sm font-medium"
            >
              リターン詳細ページに戻る
            </a>
          </div>
        </div>
        <Modal
          isActive={isActive}
          toggleModal={toggleModal}
        >
          <div className="mb-10 w-[143px] h-[110px] mx-auto">
            <img src={NoImg} alt="" />
          </div>
          <p className="text-center">お問い合わせが完了しました。</p>
        </Modal>
      </section>
    </Layout>
  )
 }


export default Corporation;
