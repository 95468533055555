import React from "react";
import Modal from "../Common/Modal";

type Props = {
  toggleModal: () => void;
  isActive: boolean;
  widgetURL: string;
}

const WidgetModal: React.FC<Props> = (props) => {
  const { toggleModal, isActive, widgetURL } = props

  const selectAllText = (e) => {
    e.target.select();
  };

  return (
    <Modal
      toggleModal={toggleModal}
      isActive={isActive}
    >
      <div className="text-center">
        <p className="font-bold sm:mb-10 text-lg mb-[30px]">埋め込みコード</p>
        <p className="sm:mb-10 mb-5 sm:text-center">下記のコードをコピーして、あなたのWebサイトやブログで表示したいページの<br className="hidden md:block" />HTMLにペーストしましょう。左のようにプロジェクトが表示されます。</p>
        <div className="justify-between flex flex-col sm:flex-row">
          <div id="embed">
            <iframe frameBorder="0" height="450" src={widgetURL} scrolling="no" width="300"></iframe>
          </div>
          <textarea defaultValue={`<iframe frameborder="0" height="450" scrolling="no" src="${widgetURL}" width="245"></iframe>`} onClick={selectAllText} className="mt-5 sm:mt-0 sm:w-[350px] h-[256px] border border-[#B1C2CC] p-[15px]"></textarea>
        </div>
      </div>
    </Modal>
  );
};

export default WidgetModal;
