import { useForm } from "react-hook-form";
import { QUESTION_TYPE } from "../../../utils/constants";
import {
  CardInformation,
  EcReturn,
  PurchaseEcReturnFormValues,
  Questionnaire,
  SocialProfile,
} from "../../../types/pages/EcReturn/Websettlement/purchase_ec_return";

export const usePurchaseEcReturnUtilities = (
  paymentToken: string,
  ecReturns: EcReturn[],
  commission: number,
  current_email: string,
  socialProfile?: SocialProfile,
  questionnaire?: Questionnaire,
) => {
  const {
    control,
    setValue,
    getValues,
    watch,
    trigger,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PurchaseEcReturnFormValues>({
    mode: "all",
    defaultValues: {
      is_terms_checked: true,
      ec_returns_form: ecReturns.map(ecReturn => ({
        id: ecReturn.id,
        number: 1,
        answers: ecReturn ? ecReturn.ec_return_questions.filter(question => question.question_type !== QUESTION_TYPE.NONE).map(question => {
          if(question.question_type == QUESTION_TYPE.SELECT){
            return question.ec_return_question_question_answers[0].answer
          } else { return '' }}) : [],
        checked_sku_id: ecReturn.ec_return_skus.length > 0 ? ecReturn.ec_return_skus.filter((sku) => sku.remaining_number > 0).shift().id : null,
      })),
      additional_amount: "",
      last_name: socialProfile?.last_name || "",
      first_name: socialProfile?.first_name || "",
      postal_code: socialProfile?.postal_code || "",
      full_address: socialProfile?.full_address || "",
      phone_number: socialProfile?.phone_number || "",
      email: current_email,
      card_brand: socialProfile?.card_brand,
      card_last4: socialProfile?.card_last4,
      card_exp_year: socialProfile?.card_exp_year,
      card_exp_month: socialProfile?.card_exp_month,
      questionnaire_id: questionnaire?.id,
      questionnaire_option_id: null,
      questionnaire_sentence: null,
      destination_reason_text: null,
      payment_token: paymentToken,
      payment_method: "credit_card",
      article_message: "応援しています。頑張ってください。",
      commission: commission,
      conveni_code: null,
      conveni_tel: null,
      conveni_phonetic_full_name: ""
    },
  });

  const variableBgColor = (withError) => {
    if (withError) {
      return "bg-red-100";
    } else {
      return "bg-blue-200";
    }
  };

  const cardInformation = (): CardInformation => {
    return {
      brand: watch('card_brand'),
      last4: watch('card_last4'),
      exp_year: watch('card_exp_year'),
      exp_month: watch('card_exp_month'),
    }
  }

  const numerableAdditionalAmount = () => {
    return parseInt(watch("additional_amount") || "0");
  };

  const priceByAmount = () => {
    return ecReturns.map((ecReturn, index) => ecReturn.price * Number(watch(`ec_returns_form.${index}.number`)))
  }

  const totalPriceByAmount = () => {
    return priceByAmount().reduce((sum, element) => sum + element, 0)
  }

  const totalAmount = () => {
    return totalPriceByAmount() + Number(watch("additional_amount")) + commission
  }

  const amountDetailText = () => {
    if (numerableAdditionalAmount() > 0) {
      return `(${totalAmount}円 + 上乗せ支援${numerableAdditionalAmount().toLocaleString()}円)`;
    } else {
      return `(${totalAmount}円`;
    }
  };

  return {
    control,
    cardInformation,
    variableBgColor,
    setValue,
    getValues,
    watch,
    trigger,
    register,
    handleSubmit,
    formState: { errors },
    numerableAdditionalAmount,
    totalAmount,
    totalPriceByAmount,
    priceByAmount,
    amountDetailText,
  };
};
