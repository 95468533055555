import React from "react";
import BaseLinkButton from "../../../components/Common/Buttons/BaseLinkButton";
import SecondaryButton from "../../../components/Common/Buttons/SecondaryButton";
import Layout from "../../../components/Common/Layout";
import Pagination from "../../../components/Common/Pagination";

type Props = {};

const Index: React.FC<Props> = () => {
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "お気に入り",
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mt-[60px] sm:mb-10 sm:mx-auto md:px-0">
        <h1 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          お気に入り
        </h1>
        <div className="pt-[10px] border-[#B1C2CC] sm:mb-[60px] flex justify-between mb-5 text-xs border-t">
          <div>NNN件</div>
          <div className="flex">
            <div>並べ替え：</div>
            <select>
              <option value="">注目順</option>
            </select>
          </div>
        </div>
        <ul className="mb-[60px] flex flex-wrap justify-between sm:grid-cols-3 sm:grid md:gap-x-10 md:grid-cols-4 sm:gap-x-8">
          {[1, 2, 3, 4, 5].map((i) => (
            <li key={i} className="mb-[60px] w-[calc((100vw-75px)/2)] last:mb-0 sm:w-auto">
              <section>
                <div className="mb-[10px] aspect-3/4 w-full">
                  <img src="https://placehold.jp/315x236.png" alt="" />
                </div>
                <div className="mb-[10px]">
                  <div className="flex items-center">
                    <div className="text-[#768084] line-through text-xs font-bold">
                      123,456,000<span className="text-[10px]">円(税込)</span>
                    </div>
                    <div className="inline-block ml-1">
                      <div className="bg-[#006AC9] text-center text-white text-xs font-bold leading-none">
                        50<span className="text-[10px]">%OFF</span>
                      </div>
                    </div>
                  </div>
                  <div className="text-sm font-bold sm:text-base">
                    123,456,00<span className="text-[10px]">円(税込)</span>
                  </div>
                </div>
                <h2 className="mb-[10px] font-bold">
                  <a href="/" className="text-sm">
                    オリジナルフォトブック （チアリーダー直筆メッセージ入り）
                  </a>
                </h2>
                <div className="sm:flex sm:justify-between">
                  <div className="mb-[10px] sm:w-[calc((100%-10px)/2)] w-full sm:mb-0 sm:h-[30px] h-[40px]">
                    <SecondaryButton
                      text="お気に入り解除"
                      fontSize="text-xxs"
                      onClick={() => console.log("OK")}
                      iconLeft="icon-close"
                    />
                  </div>
                  <div className="sm:w-[calc((100%-10px)/2)] w-full h-[40px] sm:h-[30px]">
                    <BaseLinkButton
                      text="詳しくみる"
                      fontSize="text-xxs"
                      link="/"
                    />
                  </div>
                </div>
              </section>
            </li>
          ))}
        </ul>
        <Pagination />
        <div className="mt-[60px] text-center">
          <a
            href="/mypage"
            className="left-icon icon-stick-arrow-left font-meidum text-sm"
          >
            マイページトップ
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
