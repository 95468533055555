import React from "react";
import { PurchaseHistory } from "../../../types/pages/Mypage/support_history";
import { FormatCurrency } from "../../../utils/TextUtil";

type Props = {
  purchaseHistory: PurchaseHistory;
  paymentDetailsPath: string;
};

const PurchaseHistoryArea: React.FC<Props> = (props) => {
  const { purchaseHistory, paymentDetailsPath } = props;
  const isUnpaid =
    purchaseHistory.wait_bank_payment || purchaseHistory.wait_conveni_payment;

  return (
    <li className="border-[#D2DFE6] py-5 border-b">
      <section className="flex sm:items-center">
        <div className="w-[58px] aspect-3/4 h-[44px] sm:w-[88px] sm:h-[65px] mr-3">
          <img src={purchaseHistory.ec_return_image_url} alt="" />
        </div>
        <div className="w-[calc(100%-70px)] sm:w-[calc(100%-100px)]">
          <a href={paymentDetailsPath+`#${purchaseHistory.destination_detail_id}`}>
            <h4 className="mb-[10px] underline text-xs font-medium">
              {purchaseHistory.ec_return_name}
            </h4>
          </a>
          <div className="sm:flex">
            {isUnpaid ? (
              <div className="text-[#768084] left-icon icon-calender-warning text-[#FF0000] flex items-center text-xs font-bold sm:mr-5">
                未払い
              </div>
            ) : (
              <div className="text-[#768084] left-icon icon-calender flex items-center text-xs sm:mr-5">
                {purchaseHistory.paid_at}
              </div>
            )}
            <div className="text-[#768084] left-icon icon-yen flex items-center text-xs sm:mr-5">
              {`${FormatCurrency(purchaseHistory.ec_return_price)}円`}
            </div>
            <div className="text-[#768084] left-icon icon-gift-small flex items-center text-xs">
              {purchaseHistory.ec_return_schedule}
            </div>
          </div>
        </div>
      </section>
    </li>
  );
};

export default PurchaseHistoryArea;
