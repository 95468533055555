import React, { useEffect, useState } from "react";
import BaseButton from "../Common/Buttons/BaseButton";
import ConfirmationModal from "../Common/ConfirmationModal";
import { comment, article } from "../../types/pages/Team/detail";
import { HttpRequest } from "../../utils/common"
import LinkifyText from "../Common/LinkifyText";
import { MAX_ARTICLE_LENGTH } from "../../utils/constants"

type Props = {
  article?: article;
  comment?: comment;
  isChild?: boolean;
  articleID?: number;
  refreshArticles?: () => void;
}

const Message: React.FC<Props> = (props) => {
  const {isChild = false, article, comment, refreshArticles, articleID} = props
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>(isChild ? comment.comment : article.article)
  const [errors, setErrors] = useState<object>({});

  useEffect(() => {
    if (isChild) {
      setMessage(comment.comment)
    } else {
      setMessage(article.article)
    }
  }, [article, comment]);

  const editMessage = () => {
    setIsEdit(true)
  }

  const openDeleteConfirmationModal = () => {
    setIsModalOpen(true)
  }

  const updateArticle = (article_id: number) => {
    if (message === "") {
      return;
    }
    if (message.length > MAX_ARTICLE_LENGTH) {
      setErrors((other) => ({ ...other, comment: "メッセージが長すぎます。" }));
      return;
    }
    HttpRequest.put("/api/articles", {
      article_id: article_id,
      article: message,
    })
    .then(() => {
      refreshArticles();
      setErrors({});
    })
    .catch((e) => {
      console.error(e);
    })
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const toggleGoodArticle = () => {
    HttpRequest.post("/api/articles/toggle_good_article", {
      article_id: article.id,
    })
    .then(() => {
      refreshArticles();
    })
    .catch((e) => {
      console.error(e);
    })
  }


  const deleteComment = () => {
    HttpRequest.delete(`/api/articles/delete_comment?article_id=${articleID}&comment_id=${comment.id}`)
      .then(() => {
      setIsModalOpen(false)
      refreshArticles();
    })
    .catch((e) => {
      console.error(e);
    })
  }


  const deleteArticle = () => {
    HttpRequest.delete(`/api/articles/delete?article_id=${article.id}`)
      .then(() => {
      setIsModalOpen(false)
      refreshArticles();
    })
    .catch((e) => {
      console.error(e);
    })
  }

  return (
    <>
      {isChild ?
        <>
          <div className="flex justify-between">
            <div className="text-[#768084] mb-1 text-xs">{comment.author}</div>
            <div>
              {comment.can_edit && <button className="underline text-xs pr-1" onClick={editMessage}>編集</button>}
              {comment.can_delete && <button className="underline text-xs pl-1 border-l border-[#D2DFE6]" onClick={openDeleteConfirmationModal}>削除</button>}
            </div>
          </div>
          <div className={`${isEdit ? "hidden" : "block"} mb-[10px]`}>
            <LinkifyText text={ comment.comment } />
          </div>
          <textarea
            className={`${isEdit ? "block" : "hidden"} w-full p-[15px] border border-[#B1C2CC] bg-white mb-5`}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <p className="text-[#FF0000] text-sm mt-1">{errors["comment"]}</p>
          <div className={`${isEdit ? "flex" : "hidden"} mb-5`}>
            <button onClick={() => setIsEdit(false)}>キャンセル</button>
            <div className="w-[100px] h-[40px] ml-5">
              <BaseButton
                text="更新"
                onClick={() => {
                  updateArticle(comment.id);
                  setIsEdit(false)
                }}
              />
            </div>
          </div>
          <div className="left-icon icon-clock text-[#768084] flex items-center text-xs">
            {comment.time_ago_in_words}前
          </div>
          <ConfirmationModal
            isActive={isModalOpen}
            toggleModal={toggleModal}
            confirmText="OK"
            onSubmit={deleteComment}
          >
            <div className="sm:text-left">
              <p>この投稿を本当に削除しますか？</p>
              <p>削除は元に戻せませんので、ご注意ください。</p>
            </div>        
          </ConfirmationModal>
        </>
        :
        <>
          {article.file.attatched && (
            <img
              src={article.file.path}
              alt={article.article}
              className="w-full object-cover mb-3"
            />
          )}
          <div className="flex justify-between">
            <div className="text-[#768084] mb-1 text-xs">{article.author}</div>
            <div>
              {article.can_edit && <button className="underline text-xs pr-1" onClick={editMessage}>編集</button>}
              {article.can_delete && <button className="underline text-xs pl-1 border-l border-[#D2DFE6]" onClick={openDeleteConfirmationModal}>削除</button>}
            </div>
          </div>
          <div className={`${isEdit ? "hidden" : "block"} mb-[10px]`} >
            <LinkifyText text={ article.article } />
          </div>
          <textarea
            className={`${isEdit ? "block" : "hidden"} w-full p-[15px] border border-[#B1C2CC] bg-white mb-5`}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <p className="text-[#FF0000] text-sm mt-1">{errors["comment"]}</p>
          <div className={`${isEdit ? "flex" : "hidden"} mb-5`}>
            <button onClick={() => setIsEdit(false)}>キャンセル</button>
            <div className="w-[100px] h-[40px] ml-5">
              <BaseButton
                text="更新"
                onClick={() => {
                  updateArticle(article.id);
                  setIsEdit(false)
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="left-icon icon-clock text-[#768084] flex items-center text-xs">
              {article.time_ago_in_words}前
            </div>
            <div className="flex">
              <div className="border-[#D2DFE6] pr-2 text-xs border-r">
                コメント<span className="text-[#006AC9] ml-1">{article.active_comments.length}</span>
              </div>
              <button className="pl-2 text-xs inline-block" onClick={toggleGoodArticle} disabled={!article.can_good}>
                いいね!<span className="text-[#006AC9] ml-1">{article.good_count}</span>
              </button>          
            </div>
          </div>
          <ConfirmationModal
            isActive={isModalOpen}
            toggleModal={toggleModal}
            confirmText="OK"
            onSubmit={deleteArticle}
          >
            <div className="sm:text-left">
              <p>この投稿を本当に削除しますか？</p>
              <p>削除は元に戻せませんので、ご注意ください。</p>
            </div>        
          </ConfirmationModal>
        </>
      }
    </>
  );
};

export default Message;
