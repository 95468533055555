import React from "react";
import KeyVisualImage from "../../../images/desktop_kv.jpg";
import KeyVisualProjectButton from "./Buttons/KeyVisualProjectButton";
type Props = {};

const KeyVisual: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        background: `url(${KeyVisualImage}) no-repeat`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
      className="w-full h-48 h-full bg-cover sm:h-3/4"
    >
      <div className="text-shadow flex flex-col mx-5 w-auto text-white sm:mx-20">
        <p className="mb-7 mt-20 text-4xl font-bold leading-normal sm:text-5xl">
          その応援がスポーツの未来を創る
        </p>
        <p className="text-lg font-bold leading-normal sm:text-xl">
          どんな時でも想いをつむぐを大事にする
          <br />
          スポーツ特化型クラウドファンディング
        </p>
      </div>
      <div className="flex justify-center mb-2 mt-20 pb-5 sm:mt-40 sm:pb-0">
        <KeyVisualProjectButton />
      </div>
    </div>
  );
};

export default KeyVisual;
