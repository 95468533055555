import React from "react";
import Layout from "../../../components/Common/Layout";

type Props = {
  email: string;
};

const Acception: React.FC<Props> = (props) => {
  const { email } = props;
  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "本人認証メールを再送しました",
        }
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto sm:px-0">
        <h1 className="mb-[30px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          本人認証メールを再送しました
        </h1>
        <div className="sm:w-[700px] sm:mx-auto">
          <div className="bg-[#F4F6F8] mb-[30px] py-4 text-center text-lg font-bold">
            { email }
          </div>
          <div>
            <p className="mb-[30px]">
              上記のメールアドレスに「
              <span className="font-bold">
                【スポチュニティ】新規会員登録時における本人認証のお願い
              </span>
              」という件名でsupport@spportunity.co.jpから自動でメールが送信されました。
            </p>
            <p className="mb-[30px]">
              そのメールの本文に記載された認証用のURL（リンク）をクリックして、48時間以内に本人認証をおこなってください。
            </p>
            <p>
              もしメールが届かない場合は、メールアドレスのスペルや迷惑メール設定をご確認ください。
            </p>
          </div>
        </div>
      </section>
    </Layout>
    
  );
};

export default Acception;
