import React from "react";
import { FieldPath, FieldValues, UseControllerProps, useController } from "react-hook-form";


type Props = {
  name: string;
  id: string;
  value: string;
  label: string;
  isActive: boolean;
  checked: boolean;
  disabled?: boolean;
  onChange: (e) => void;
}

function Radio<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  >({ disabled = false, ...props }: UseControllerProps<TFieldValues, TName> & Props) {
  
  const {
    field
  } = useController(props);

  return (
    <div className="flex">
      <input
        {...field}
        defaultChecked={props.checked}
        name={props.name}
        value={props.value}
        id={props.id}
        disabled={disabled}
        onChange={(e) => {
          field.onChange(e);
          props.onChange(e);
        }}
        type="radio"
        className={`${props.isActive ? "before:border-[#B1C2CC]" : "before:border-[#006AC9] before:border-2"} radio-btn mr-[10px] before:content-[''] before:rounded-[50%] relative flex before:inline-block items-center before:w-5 before:h-5 before:border appearance-none`}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </div>
  );
}

export default Radio;
