import React from "react";
import SecondaryLinkButton from "../../components/Common/Buttons/SecondaryLinkButton";
import IconArrowFirst from "../../../images/icons/icon_arrow-first.png";
import IconArrowLast from "../../../images/icons/icon_arrow-last.png";

type Props = {};

const Pagination: React.FC<Props> = (props) => {
  return (
    <div>
      <div className="mb-[10px] text-center text-xs">1ページ(全23ページ中)</div>
      <div className="flex justify-center">
        <a
          href=""
          className="link-hover-none border-[#B1C2CC] rounded-[50%] w-[40px] h-[40px] flex inline-block items-center justify-center border border-solid"
        >
          <img
            src={IconArrowFirst}
            alt="最初のページへ"
            className="w-[11px] h-[9px]"
          />
        </a>
        <div className="mx-[10px] w-[110px] sm:w-[140px] h-[40px]">
          <SecondaryLinkButton
            iconLeft="icon-stick-arrow-left-small"
            text="前のページ"
            fontSize="text-xs"
            link=""
          />
        </div>
        <div className="mr-[10px] w-[110px] sm:w-[140px] h-[40px]">
          <SecondaryLinkButton
            iconRight="icon-stick-arrow-right-small"
            text="次のページ"
            fontSize="text-xs"
            link=""
          />
        </div>
        <a
          href=""
          className="link-hover-none border-[#B1C2CC] rounded-[50%] w-[40px] h-[40px] flex inline-block items-center justify-center border border-solid"
        >
          <img
            src={IconArrowLast}
            alt="最初のページへ"
            className="w-[11px] h-[9px]"
          />
        </a>
      </div>
    </div>
  );
};

export default Pagination;
