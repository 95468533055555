import React, { useState } from "react";
import { HttpRequest } from "../../../utils/common.js";
import { useForm } from "react-hook-form";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";
import Layout from "../../../components/Common/Layout";
import TextField from "../../../components/Common/Form/TextField";
import { isCareerAddress } from "../../../utils/FormUtil";
import { EMAIL_MATCH_PATTERN } from "../../../utils/constants"

type Props = {
  currentEmail: string;
};

const Email: React.FC<Props> = (props) => {
  const { currentEmail } = props;
  const [errors, setErrors] = useState({});
  const [isConfirmModalActive, setIsConfirmModalActive] =
    useState<boolean>(false);
  interface IFormInput {
    email: string;
  }

  const { handleSubmit, control, getValues } = useForm<IFormInput>({
    mode: "onChange",
    defaultValues: {
      email: currentEmail
    }
  });

  const onSubmit = async () => {
    HttpRequest.patch("/accounts", {
      account: {
        unconfirmed_email: getValues("email")
      }
    }).then(() => {
      location.href = "/mypage/user_mail/acception"
    }).catch((e) => {
      setErrors(e.response.data.errors);
      if (isCareerAddress(getValues("email"))) {
        toggleConfirmModal();
      }
    });
  };

  const toggleConfirmModal = () => {
    if (isCareerAddress(getValues("email"))) {
      setIsConfirmModalActive(!isConfirmModalActive);
    } else {
      onSubmit();
    }
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "マイページ",
          href: "/mypage",
        },
        {
          text: "メールアドレスの変更",
        },
      ]}
    >
      <ConfirmationModal
        toggleModal={() => toggleConfirmModal()}
        onSubmit={() => onSubmit()}
        isActive={isConfirmModalActive}
        confirmText="OK"
      >
        <p className="mb-[40px] text-lg font-bold">
          キャリアのメールアドレスを
          <br />
          登録される方へお願い
        </p>
        <div className="leading-[1.875]">
          <p className="mb-5">
            ※ご登録後、support@spportunity.co.jpから重要なメールが届きます。予め受信設定をお願いいたします。
          </p>
          <p>
            ※携帯キャリアのメールアドレスでは、システムからのメールが届かない場合がありますのでご注意ください。
          </p>
        </div>
      </ConfirmationModal>
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">
          メールアドレスの変更
        </h2>
        <div className="sm:w-[700px] sm:mx-auto">
          <form onSubmit={handleSubmit(toggleConfirmModal)}>
            <div>
              <label htmlFor="email" className="inline-block mb-1 text-sm">
                メールアドレス
              </label>
              <TextField
                name="email"
                id="email"
                control={control}
                placeholder="メールアドレスを入力してください"
                rules={{
                  required: true,
                  pattern: EMAIL_MATCH_PATTERN
                }}
                patternErrorMessage="メールアドレスが正しくありません"
              />
            </div>
            <div className="mt-[60px] sm:mt-[40px] sm:mx-auto h-[50px] sm:w-[250px]">
              <BaseButton
                text="上記の内容で変更する"
                fontSize="text-sm"
                isSubmit={true}
              />
            </div>
            <p className="text-[#FF0000] mt-3 mb-3 text-center text-xs">
              { errors["message"] }
            </p>
          </form>
          <div className="mt-[60px] text-center">
            <a href="/mypage" className="left-icon icon-stick-arrow-left text-sm">
              マイページトップ
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Email;
